import PageContent from '@core/components/atoms/PageContent';
import ClientFooter from '@core/components/organisms/ClientFooter';
import ClientSubpageHeader from '@core/components/organisms/ClientSubpageHeader';
import { useAppSelector } from '@core/hooks/requestUtils';
import { Box, Button, Text } from 'grommet';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import PostBrowser from '../categories/PostBrowser';
import PageNavigation from '../../../molecules/PageNavigation';
import * as Icons from 'grommet-icons';
import { PostModerationSettings } from '@server/lib/utils/permissionsConfig';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentSiteId } from '@core/redux/siteConfig';
import { createAPostModalOpen } from '@core/redux/createAPostModal/actions';

export default function () {
  const params = useParams<{ categoryId?: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const siteId = useSelector(selectCurrentSiteId);
  const handleChangeCategory = (categoryId: string) => {
    if (!categoryId) {
      history.push('/feedback');
      return;
    }
    history.replace(`/feedback/category/${categoryId}`);
  };

  const handleCreatePost = () => {
    if (params.categoryId) {
      dispatch(
        createAPostModalOpen(siteId as unknown as number, params.categoryId)
      );
    }
  };

  const category = useAppSelector(
    (state) => state.entities.voteCategory[params.categoryId || '']
  );
  return (
    <Box background="dark-1" style={{ minHeight: '100vh' }}>
      <ClientSubpageHeader />
      <PageContent pad="medium">
        <Box direction="row-responsive" justify="between" align="center">
          <PageNavigation
            items={[
              { label: 'All Posts', onClick: () => history.push('/feedback') },
              {
                label: category.title || 'Category',
              },
            ]}
          />
          {category.postModerationSettings !==
            PostModerationSettings.Disabled && (
            <Box direction="row" align="center" gap="small">
              <Text>Got something to say?</Text>
              <Button
                onClick={handleCreatePost}
                size="small"
                label="Post New"
                icon={<Icons.FormAdd />}
              />
            </Box>
          )}
        </Box>
        <PostBrowser
          categoryId={params.categoryId}
          onCategoryChange={handleChangeCategory}
        />
      </PageContent>
      <ClientFooter />
    </Box>
  );
}
