import { combineReducers } from 'redux';
// <inject:import>
import {
  reducer as steamImporterReducer,
  SteamImporterReducerType,
} from './steamImporter';
import {
  reducer as sitesEditorReducer,
  SitesEditorReducerType,
} from './sitesEditor';
import { reducer as userReducer, UserReducerType } from './user';
import {
  reducer as siteConfigReducer,
  SiteConfigReducerType,
} from './siteConfig';
import { reducer as entitiesReducer, EntitiesState } from './entities/reducer';
import {
  reducer as createAPostModalReducer,
  CreateAPostModalState,
} from './createAPostModal/reducer';
// </inject:import>

export interface AppState {
  //  <inject:reducer-type>
  steamImporter: SteamImporterReducerType;
  sitesEditor: SitesEditorReducerType;
  user: UserReducerType;
  siteConfig: SiteConfigReducerType;
  entities: EntitiesState;
  createAPostModal: CreateAPostModalState;
  //  </inject:reducer-type>
}

export const reducer = combineReducers<AppState>({
  //  <inject:reducer>
  steamImporter: steamImporterReducer,
  sitesEditor: sitesEditorReducer,
  user: userReducer,
  siteConfig: siteConfigReducer,
  entities: entitiesReducer,
  createAPostModal: createAPostModalReducer,
  //  </inject:reducer>
});
