import {
  Box,
  Button,
  Text,
  FormField,
  Heading,
  TextInput,
  Paragraph,
  ThemeContext,
  Tabs,
  Tab,
  TextArea,
  Anchor,
} from "grommet";
import * as React from "react";
import { PreviewComponentScaled } from "@core/components/blind/PreviewComponent";
import ClientSocialsPage from "@core/components/pages/client/ClientSocialsPage";
import { defaultSiteConfig } from "@core/common/siteConfigs";
import { SiteConfig, ThankYouPageConfig } from "@core/redux/siteConfig";
import * as Icons from "grommet-icons";
import MessageBox from "@core/components/atoms/MessageBox";
import { checkboxProps, inputProps } from "@core/common/siteConfigsHelpers";
import { SubsectionProps } from "../pages/publeash/games/id/EditContacts";
import SectionHeading from "../atoms/SectionHeading";
import FormFieldCheckBox from "../atoms/FormFieldCheckBox";
import SectionTabThemeProvider from "../blind/SectionTabThemeProvider";

export default function EditContactsThankYouSettings({
  site,
  onUpdateSite,
}: SubsectionProps) {
  const collectionEnabled =
    site.config.general.collectNewsletterEmails || false;
  const keys: (keyof SiteConfig["general"])[] = [
    "steamAppId",
    "twitchURL",
    "twitterURL",
    "youtubeChannelURL",
    "discordURL",
    "facebookURL",
  ];
  const allSocialsMissing = keys.every((key) => !site.config.general[key]);

  const tpConfig =
    site.config.thankYouPage ||
    (defaultSiteConfig.thankYouPage as ThankYouPageConfig);

  return (
    <Box
      direction="row-responsive"
      background="dark-1"
      style={{
        opacity: collectionEnabled ? 1 : 0.5,
        pointerEvents: collectionEnabled ? "auto" : "none",
      }}
    >
      <Box flex>
        <Box pad="medium" direction="row">
          <Box width="medium">
            <SectionHeading>Thank You Page</SectionHeading>
            <Paragraph>This is where most of the magic happens.</Paragraph>
            <FormFieldCheckBox
              checkboxProps={checkboxProps(
                site,
                onUpdateSite,
                "thankYouPage",
                "enableActions",
                "Enable Viral Actions"
              )}
            />
            <FormFieldCheckBox
              checkboxProps={checkboxProps(
                site,
                onUpdateSite,
                "thankYouPage",
                "enableAmbassador",
                "Enable Ambassador Text"
              )}
            />
          </Box>
          <Box
            flex
            style={{
              minHeight: "400px",
            }}
          >
            <PreviewComponentScaled
              flex
              height="50vh"
              site={site}
              style={{
                opacity: collectionEnabled ? 1 : 0.5,
              }}
            >
              <ClientSocialsPage />
            </PreviewComponentScaled>
          </Box>
        </Box>
        <SectionTabThemeProvider>
          <Tabs justify="start" margin="medium">
            <Tab title="Thank You">
              <Box
                pad="medium"
                style={{
                  minHeight: "400px",
                }}
                direction="row"
              >
                <Box
                  fill
                  border={{
                    side: "right",
                    size: "small",
                    color: "dark-4",
                  }}
                  pad={{
                    right: "medium",
                  }}
                  margin={{
                    right: "medium",
                  }}
                >
                  <FormField label="Title">
                    <TextInput
                      {...inputProps(
                        site,
                        onUpdateSite,
                        "thankYouPage",
                        "successTitle"
                      )}
                    />
                  </FormField>
                  <FormField
                    label="Message for user"
                    info="Markdown is supported"
                  >
                    <TextArea
                      style={{
                        minHeight: "250px",
                      }}
                      {...inputProps(
                        site,
                        onUpdateSite,
                        "thankYouPage",
                        "successText"
                      )}
                    />
                  </FormField>
                </Box>
                <Box fill gap="small">
                  Contact Details
                  <FormFieldCheckBox
                    checkboxProps={checkboxProps(
                      site,
                      onUpdateSite,
                      "thankYouPage",
                      "enableAskForUserDetails",
                      "Ask for contact details"
                    )}
                  />
                  <MessageBox status="unknown">
                    When we launched our beta, 57% of the people decided to fill
                    in extra details.
                  </MessageBox>
                  <MessageBox status="info">
                    You can ask the people that signed up for anything, from
                    system specs to devices they use or even if they are a
                    streamer or youtuber.
                  </MessageBox>
                </Box>
              </Box>
            </Tab>
            <Tab disabled={!tpConfig.enableActions} title="Virality">
              <Box
                pad="medium"
                style={{
                  minHeight: "400px",
                }}
                direction="row"
              >
                <Box
                  fill
                  border={{
                    side: "right",
                    size: "small",
                    color: "dark-4",
                  }}
                  pad={{
                    right: "medium",
                  }}
                  margin={{
                    right: "medium",
                  }}
                >
                  <FormField label="Title">
                    <TextInput
                      {...inputProps(
                        site,
                        onUpdateSite,
                        "thankYouPage",
                        "actionsTitle"
                      )}
                    />
                  </FormField>
                  <FormField
                    label="Message for user"
                    info="Markdown is supported"
                  >
                    <TextArea
                      {...inputProps(
                        site,
                        onUpdateSite,
                        "thankYouPage",
                        "actionsText"
                      )}
                    />
                  </FormField>
                  <FormField
                    label="Contact Details Entries"
                    help="How many entries will users get if they fill in the details?"
                    info={
                      !tpConfig.enableAskForUserDetails &&
                      'Please enable "Ask for user details" in Thank You tab'
                    }
                  >
                    <TextInput
                      {...inputProps(
                        site,
                        onUpdateSite,
                        "thankYouPage",
                        "contactWorthPoints"
                      )}
                      type="number"
                      disabled={!tpConfig.enableAskForUserDetails}
                    />
                  </FormField>
                </Box>
                <Box fill gap="small">
                  Actions
                  {allSocialsMissing ? (
                    <MessageBox
                      margin="medium"
                      animation="pulse"
                      title="Please add some social links"
                      status="critical"
                    >
                      Viral actions are generated based on the social media you
                      connected to your website. Connect some in website editor
                      and they will appear here.
                    </MessageBox>
                  ) : (
                    <MessageBox status="unknown">
                      Actions are auto generated based on the socials you filled
                      in.
                    </MessageBox>
                  )}
                  <MessageBox status="unknown">
                    When we launched our beta 50% of the people did at least one
                    of the actions. And then from those people on average they
                    did 3+ actions.
                  </MessageBox>
                  <MessageBox>
                    Currently we cannot verify those actions were succesfully
                    completed, so they will automatically get points after
                    visitng a link. We are looking to improve this in the
                    future.
                  </MessageBox>
                </Box>
              </Box>
            </Tab>
            <Tab disabled={!tpConfig.enableAmbassador} title="Ambassador">
              <Box
                pad="medium"
                style={{
                  minHeight: "400px",
                }}
                direction="row"
              >
                <Box
                  fill
                  border={{
                    side: "right",
                    size: "small",
                    color: "dark-4",
                  }}
                  pad={{
                    right: "medium",
                  }}
                  margin={{
                    right: "medium",
                  }}
                >
                  <FormField label="Title">
                    <TextInput
                      {...inputProps(
                        site,
                        onUpdateSite,
                        "thankYouPage",
                        "ambassadorTitle"
                      )}
                    />
                  </FormField>
                  <FormField
                    label="Message for user"
                    info="Markdown is supported"
                  >
                    <TextArea
                      style={{
                        minHeight: "250px",
                      }}
                      {...inputProps(
                        site,
                        onUpdateSite,
                        "thankYouPage",
                        "ambassadorText"
                      )}
                    />
                  </FormField>
                  <MessageBox status="info">
                    You can use this box for extra things users can do to become
                    more valuable, such as ambassadors for the game. But it's
                    not limited to that, you can offer different ways to enter a
                    giveaway here.
                  </MessageBox>
                </Box>
                <Box fill gap="small">
                  <Box direction="row" gap="small" wrap={true}>
                    <Text weight="bold">Add from template:</Text>
                    <Anchor label="Participate in Discord" />
                    <Anchor label="Extra ways to enter," />
                    <Anchor label="Give Feedback," />
                    <Anchor label="Help the community" />
                    <Anchor label="Report bugs" />
                  </Box>
                  {tpConfig.ambassadorBoxes.map((val, i) => {
                    return (
                      <Box
                        key={i}
                        round="small"
                        border={{
                          size: "1px",
                          color: "dark-4",
                        }}
                        pad="small"
                        background="light-4"
                      >
                        <Heading margin="0" level={5}>
                          {val.title}
                        </Heading>
                        <Text color="dark-4" size="small">
                          {val.description}
                        </Text>
                        <Box
                          direction="row"
                          gap="small"
                          align="end"
                          justify="end"
                          fill
                          pad={{
                            top: "xsmall",
                          }}
                        >
                          {val.link && (
                            <Button
                              size="small"
                              icon={<Icons.Trash size="small" />}
                              primary
                              label="View Link"
                            />
                          )}
                          <Button
                            size="small"
                            icon={<Icons.Trash size="small" />}
                            secondary
                            label="Remove"
                          />
                        </Box>
                      </Box>
                    );
                  })}
                  <ThemeContext.Extend
                    value={{
                      textArea: {
                        extend: () => "",
                      },
                    }}
                  >
                    <Box
                      gap="xsmall"
                      pad="small"
                      background="light-4"
                      round="small"
                    >
                      <TextInput size="small" placeholder="Title" />
                      <TextArea size="small" placeholder="Text..." />
                      <TextInput size="small" type="url" placeholder="Link" />
                      <Button size="small" primary label="New Box" />
                    </Box>
                  </ThemeContext.Extend>
                </Box>
              </Box>
            </Tab>
          </Tabs>
        </SectionTabThemeProvider>
      </Box>
    </Box>
  );
}
