import PageContent from "@core/components/atoms/PageContent";
import ClientFooter from "@core/components/organisms/ClientFooter";
import ClientSubpageHeader from "@core/components/organisms/ClientSubpageHeader";
import {
  useApiCategories,
  useApiVotePostComment,
  useApiVotePostGetComments,
  useApiVotePostLoad,
} from "@core/hooks/requests";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Heading,
  Layer,
  Markdown,
  Text,
  TextArea,
  TextInput,
} from "grommet";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import useColor from "@core/hooks/useColor";
import { VoteActions } from "@core/components/organisms/LandingVoting";
import { useAppSelector } from "@core/hooks/requestUtils";
import PageNavigation from "../../../molecules/PageNavigation";
import LoadingIndicator from "../../../atoms/LoadingIndicator";
import { selectCurrentSiteId } from "@core/redux/siteConfig";
import useRound from "@core/hooks/useRound";
import toast from "react-hot-toast";
import { PostModerationSettings } from "@server/lib/utils/permissionsConfig";
import * as Icons from "grommet-icons";
import useSessionContact from "@core/hooks/useSessionContact";
import ContactNameText from "../../../molecules/ContactNameText";
import ContactAvatar from "../../../molecules/ContactAvatar";
import { AnchorLink } from "@core/components/atoms/AnchorLink";
import Comment from "../../../molecules/Comment";

export default function FeedbackPost() {
  const params = useParams<{ postId: string }>();
  const siteId = useAppSelector(selectCurrentSiteId);
  const votePosts = useApiVotePostLoad(siteId || "", params.postId);
  const post = votePosts.data[0];

  const category = useAppSelector(
    (state) => state.entities.voteCategory[post?.categoryId]
  );
  useApiCategories(siteId);
  const history = useHistory();
  const timeStamp =
    post?.updatedAt && post?.updatedAt !== post?.createdAt
      ? `Updated ${new Date(post?.updatedAt).toLocaleDateString()}`
      : `Posted ${new Date(post?.createdAt).toLocaleDateString()}`;

  const canComment =
    category?.commentModerationSettings !== PostModerationSettings.Disabled;

  return (
    <Box background="dark-1" style={{ minHeight: "100vh" }}>
      <ClientSubpageHeader />
      <PageContent pad={{ vertical: "medium", horizontal: "medium" }}>
        <PageNavigation
          items={[
            {
              label: "All Posts",
              onClick: () => history.push("/feedback"),
            },
            {
              label: category?.title || "Category",
              onClick: () => history.push(`/feedback/category/${category?.id}`),
            },
            {
              label: "This Post",
            },
          ]}
        />
        <div />
        <Card
          gridArea="main"
          background="dark-2"
          margin={{ vertical: "medium" }}
        >
          <CardHeader
            background="dark-3"
            pad={{ horizontal: "medium", vertical: "small" }}
            gap="none"
            direction="column"
            align="start"
          >
            <Grid
              rows={["auto", "auto"]}
              columns={["auto", "auto", "flex"]}
              gap="xsmall"
              align="center"
              areas={[
                { name: "ratingIcon", start: [0, 0], end: [1, 0] },
                { name: "ratingNumber", start: [1, 0], end: [2, 0] },
                { name: "title", start: [2, 0], end: [2, 0] },
                { name: "ratingLabel", start: [0, 1], end: [2, 1] },
                { name: "date", start: [2, 1], end: [2, 1] },
              ]}
            >
              <Box
                gridArea="ratingIcon"
                style={{
                  fontSize: 28,
                  textShadow: "0px 0.5px 3px rgba(0,0,0,0.3)",
                }}
              >
                👍
              </Box>
              <Heading
                gridArea="ratingNumber"
                margin={{ right: "small", vertical: "none" }}
                level={3}
              >
                {post?.rating}
              </Heading>
              <Heading gridArea="title" margin={{ vertical: "none" }} level={2}>
                {post?.title}
              </Heading>
              <Text gridArea="ratingLabel" size="small">
                Vote Ratio
              </Text>
              <Text gridArea="date" size="small">
                {timeStamp}
              </Text>
            </Grid>
          </CardHeader>
          <CardBody pad="medium">
            <Markdown>{post?.content || ""}</Markdown>
            <LoadingIndicator size="large" loading={votePosts.isLoading} />
          </CardBody>
          <CardFooter background="dark-2" pad="small">
            <VoteActions hideDiscussionLink={true} votePostId={params.postId} />
          </CardFooter>
        </Card>
        <Card background="dark-2">
          <CardHeader
            pad={{ horizontal: "medium", vertical: "small" }}
            background="dark-4"
          >
            <Heading margin={{ right: "xsmall", vertical: "none" }} level={4}>
              Comments
            </Heading>
          </CardHeader>
          <CardBody pad="medium">
            <Comments votePostId={post?.id || ""} siteId={siteId} />
            {canComment ? (
              <CommentForm votePostId={post?.id || ""} siteId={siteId} />
            ) : (
              <Box direction="row" gap="small">
                <Icons.Lock color="light-3" />
                <Text color="light-3">
                  Commenting is disabled in this category.
                </Text>
              </Box>
            )}
          </CardBody>
        </Card>
      </PageContent>
      <ClientFooter />
    </Box>
  );
}

function Comments({
  siteId,
  votePostId,
}: {
  siteId: string;
  votePostId: string;
}) {
  const query = useApiVotePostGetComments({ siteId, votePostId });
  return (
    <Box gap="small" margin={{ bottom: "medium" }}>
      {query.data.map((c) => (
        <Comment {...c} key={c.id} />
      ))}
    </Box>
  );
}

function CommentForm({
  siteId,
  votePostId,
}: {
  siteId: string;
  votePostId: string;
}) {
  const textAreaBackground = useColor("dark-1");
  const textareaRadius = useRound("small");
  const [text, setText] = React.useState("");

  const [contact] = useSessionContact();

  const makeCommentMutation = useApiVotePostComment({
    text,
    siteId,
    votePostId,
    onSuccess: () => {
      toast.success("Comment posted!");
      setText("");
    },
  });

  const handleCreate = () => {
    makeCommentMutation.mutate();
  };

  return (
    <Box gap="small">
      {false && (
        <Layer>
          <Box width="medium" pad="small" gap="small">
            You can change your name only once.
            <TextInput placeholder="Mighty Talker" />
            <Button primary label="Change name" />
          </Box>
        </Layer>
      )}
      <TextArea
        style={{
          background: textAreaBackground,
          borderRadius: textareaRadius,
        }}
        value={text}
        onChange={(e) => setText(e.currentTarget.value)}
        placeholder="Post a comment..."
      />
      <Box justify="between" direction="row" align="center">
        <Box direction="row" align="center">
          <ContactAvatar contactId={contact?.id} />
          <Box direction="column" margin={{ left: "small" }}>
            <ContactNameText
              contactId={contact?.id}
              size="small"
              weight="bold"
            />
            <AnchorLink to="/contact/edit" size="xsmall">
              Change name / avatar?
            </AnchorLink>
          </Box>
        </Box>
        <Button
          disabled={!text}
          color={text && "dark-1"}
          label="Post"
          style={{ width: "300px" }}
          onClick={handleCreate}
        />
      </Box>
    </Box>
  );
}
