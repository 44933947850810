import { AnchorLink } from '@core/components/atoms/AnchorLink';
import PageContent from '@core/components/atoms/PageContent';
import { Box, Footer as GrommetFooter, Text } from 'grommet';
import * as React from 'react';

export default function Footer() {
  return (
    <GrommetFooter background="brand-4">
      <PageContent pad="large" direction="row-responsive" justify="between">
        <Box direction="row" gap="medium" flex={{ shrink: 0 }}>
          <Box pad="small" gap="xsmall">
            <AnchorLink color="accent-5" to="/pricing">
              Pricing
            </AnchorLink>
            <AnchorLink color="accent-4" to="/demo">
              Book a Demo
            </AnchorLink>
            <AnchorLink color="accent-3" to="/contact">
              Contact Us
            </AnchorLink>
          </Box>
          <Box pad="small" gap="xsmall">
            <AnchorLink color="accent-3" to="/blog">
              Blog
            </AnchorLink>
            <AnchorLink color="accent-2" to="/terms">
              Terms & Conditions
            </AnchorLink>
            <AnchorLink color="accent-1" to="/privacy">
              Privacy Policy
            </AnchorLink>
          </Box>
        </Box>
        <Box pad="small" flex={{ shrink: 0 }}>
          <Text size="xsmall" color="accent-1">
            Otivia Ltd. &copy; 2021 <br />
            Company number 10057840 <br />
            Intouch Accounting
            <br />
            Suite 1 Second Floor Everdene House
            <br />
            Deansleigh Road, Bournemouth, United Kingdom, BH7 7DU
          </Text>
        </Box>
      </PageContent>
    </GrommetFooter>
  );
}
