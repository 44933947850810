import * as React from 'react';
import { SocialLinks } from '@core/redux/siteConfig';
import { Box, Anchor } from 'grommet';
import * as Icons from 'grommet-icons';
import Discord from '../atoms/icons/Discord';
import Twitch from '../atoms/icons/Twitch';
import Steam from '../atoms/icons/Steam';
import { IconProps } from 'grommet-icons';

interface LinkProps {
  url?: string;
  Icon: React.ComponentType<IconProps>;
  size?: string;
}

function Link({ url, Icon, size = '20px' }: LinkProps) {
  if (!url) return null;
  return (
    <Anchor href={url} style={{ borderRadius: '50%' }} target="_blank">
      <Box
        background="rgba(0,0,0,0.1)"
        hoverIndicator={{ color: 'dark-4', opacity: 1 }}
        onClick={Function}
        pad="small"
        margin="small"
        style={{ borderRadius: '50%' }}>
        <Icon color="light-1" size={size} />
      </Box>
    </Anchor>
  );
}

export default function SocialLinksC({
  youtubeChannelURL,
  discordURL,
  twitterURL,
  twitchURL,
  patreonURL,
  facebookURL,
  steamAppId,
  size = '20px'
}: SocialLinks & { steamAppId?: string; size?: string }) {
  const steamUrl = `https://store.steampowered.com/app/${steamAppId}/`;
  return (
    <Box direction="row">
      <Link size={size} url={facebookURL} Icon={Icons.Facebook} />
      <Link size={size} url={twitterURL} Icon={Icons.Twitter} />
      <Link size={size} url={youtubeChannelURL} Icon={Icons.Youtube} />
      <Link size={size} url={discordURL} Icon={Discord} />
      <Link size={size} url={twitchURL} Icon={Twitch} />
      {steamAppId && <Link size={size} url={steamUrl} Icon={Steam} />}
    </Box>
  );
}
