import * as React from 'react';
import PageContent from '@core/components/atoms/PageContent';
import { SiteView } from '@core/redux/sitesEditor';
import {
  Text,
  Anchor,
  Box,
  Button,
  Heading,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Layer,
  Tip,
  Select,
} from 'grommet';
import { useDispatch } from 'react-redux';
import SitesEditorAction from '@core/redux/sitesEditor/actions';
import { useHistory } from 'react-router';
import ConnectDomainsForm from '../../molecules/ConnectDomainsForm';
import * as Icons from 'grommet-icons';

interface Props {
  site: SiteView;
  hasChanges: boolean;
  generalChanges: string[];
  themeChanges: boolean;
  featuresChanged: boolean;
  savedSite: SiteView;
  onUpdateSite(site: SiteView): void;
}

const generalKeyNames: Record<string, string> = {
  gameName: 'Game Name',
  title: 'Title',
  subTitle: 'Sub Title',
  studioName: 'Studio Name',
  favicon: 'Site Icon',
  logoImage: 'Logo',
  landingBackgroundImage: 'Background Image',
  steamAppId: 'Steam App Id',
  domain: 'Domain',
  googleAnalyticsUTMTag: 'Google Analytics Tag',
  siteMeta: 'Sharing Description/Image',
  contactEmail: 'Contact Email',
  enableFeatures: 'Features',

  youTubeTrailerURL: 'YouTube Trailer URL',
  discordURL: 'Discord URL',
  youtubeChannelURL: 'YouTube Channel URL',
  twitterURL: 'Twitter Url',
  facebookURL: 'Facebook Url',
  patreonURL: 'Patreon Url',
  twitchURL: 'Twitch Url',
  mainDomain: 'Main Domain (traffic will be redirected to)',
  collectNewsletterEmails: 'Newsletter Collection',
};

function getValue(
  value: object | boolean | string | number | undefined | null
) {
  const isObject = typeof value === 'object';
  const isBoolean = typeof value === 'boolean';
  return isObject ? '' : isBoolean ? (value ? 'Enabled' : 'Disabled') : value;
}
export default function PublishTab({
  site,
  generalChanges,
  hasChanges,
  savedSite,
  featuresChanged,
  themeChanges,
  onUpdateSite,
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const handlePublishChanges = () => {
    dispatch(SitesEditorAction.updateConfig(site));
  };

  const handleCreateAndSave = () => {
    localStorage.setItem('temp-site', JSON.stringify(site));
    history.push('/signup');
  };

  const isTemp = site.id === -1;

  const [isAddingDomain, setIsAddingDomain] = React.useState<boolean>(false);

  const handleSetMainDomain = (hostname: string) => {
    onUpdateSite({
      ...site,
      config: {
        ...site.config,
        general: {
          ...site.config.general,
          mainDomain: hostname,
        },
      },
    });
  };

  const handleConnectDomain = () => {
    setIsAddingDomain(!isAddingDomain);
  };

  return (
    <>
      {isAddingDomain && (
        <Layer onClickOutside={handleConnectDomain}>
          <ConnectDomainsForm siteId={site.id} onClose={handleConnectDomain} />
        </Layer>
      )}
      <PageContent pad="large" gap="medium">
        {isTemp && (
          <Card background="dark-4" pad="medium">
            <Heading level={2}>
              You will need an account to save & publish
            </Heading>
            <Button
              id="button-create-account-and-save"
              primary
              label="Create Account and Save"
              onClick={handleCreateAndSave}
            />
          </Card>
        )}
        <Box direction="row-responsive" gap="medium">
          <Box flex={{ grow: 1, shrink: 1 }}>
            <Card background="dark-4">
              <CardHeader pad="small">
                <Heading margin="0" level={3}>
                  Publish Changes
                </Heading>
              </CardHeader>
              <CardBody pad="small">
                <Text>
                  After you publish your changes, your website will be available
                  at <br />
                  <Anchor href={`https://${site.domains[0].hostname}`}>
                    {site.domains[0].hostname}
                  </Anchor>
                  &nbsp;and on <strong>connected domains</strong>.
                </Text>
                <hr />
                {hasChanges && <Heading level={3}>Changes made:</Heading>}
                {generalChanges.map((key) => {
                  const value = getValue(site.config.general[key]);
                  const added = savedSite && !savedSite.config.general[key];
                  return (
                    <Box key={key} direction="row" align="center">
                      <Text weight="bold">{generalKeyNames[key]}</Text>:
                      {!!savedSite && !!savedSite.config.general[key] && (
                        <Box
                          border={{ side: 'bottom', color: 'status-error' }}
                          pad={{ horizontal: 'xsmall', vertical: '2xsmall' }}
                          color="status-error"
                        >
                          {getValue(savedSite.config.general[key])}
                        </Box>
                      )}
                      {added ? '' : '->'}
                      <Box
                        pad={{ horizontal: 'xsmall', vertical: '2xsmall' }}
                        border={{ side: 'bottom', color: 'status-ok' }}
                      >
                        <Text>{value}</Text>
                      </Box>
                    </Box>
                  );
                })}
                {featuresChanged && <Text weight="bold">Features</Text>}
                {themeChanges && <Text weight="bold">Theme</Text>}
                <hr />
              </CardBody>
              <CardFooter pad="small">
                <Box>
                  <Button
                    disabled={isTemp || !hasChanges}
                    label="Publish Changes"
                    primary
                    onClick={handlePublishChanges}
                  />
                  {!hasChanges && (
                    <Text margin={{ horizontal: 'medium' }} size="small">
                      No Changes Detected
                    </Text>
                  )}
                </Box>
              </CardFooter>
            </Card>
          </Box>
          <Box width="medium" flex={{ shrink: 0 }}>
            <Card background="dark-4">
              <CardHeader pad="small">
                <Heading level={3} margin="0">
                  Connected Domains
                </Heading>
              </CardHeader>
              <CardBody>
                <Box pad="small" background="dark-3">
                  Main Domain:
                  <Text size="small">
                    All traffic will be redirected to this domain.
                  </Text>
                  <Select
                    options={site.domains.map((domain) => domain.hostname)}
                    size="small"
                    onChange={({ option }) => handleSetMainDomain(option)}
                    value={site.config.general.mainDomain}
                    placeholder="Select main domain..."
                  />
                </Box>
                {site.domains.map(({ id, hostname }, index) => {
                  const background = `brand-${(index % 2) + 1}`;
                  return (
                    <Box
                      pad="small"
                      background={background}
                      key={id || index}
                      direction="row"
                      align="center"
                    >
                      <Box
                        width="24px"
                        margin={{ right: 'small' }}
                        align="center"
                        justify="center"
                      >
                        {site.config.general.mainDomain === hostname ? (
                          <Icons.Link size="small" />
                        ) : (
                          <Tip
                            plain
                            content={
                              <Box
                                round="small"
                                background="dark-1"
                                pad="xsmall"
                                border={{ color: 'dark-3', size: 'xsmall' }}
                                margin="xsmall"
                              >
                                Set as main domain. All traffic will be
                                redirected to it.
                              </Box>
                            }
                          >
                            <Box
                              hoverIndicator
                              onClick={() => handleSetMainDomain(hostname)}
                              background="rgba(255,255,255,0.2)"
                              width="24px"
                              height="24px"
                              align="center"
                              justify="center"
                              round="small"
                            >
                              <Icons.Link color={background} size="small" />
                            </Box>
                          </Tip>
                        )}
                      </Box>
                      {hostname}
                    </Box>
                  );
                })}
              </CardBody>
              <CardFooter pad="small" align="start" gap="0">
                <Button
                  onClick={handleConnectDomain}
                  disabled={isTemp}
                  style={{ color: 'black' }}
                  label="Connect&nbsp;New"
                  color="dark-3"
                />
                <Text size="small" margin={{ horizontal: 'small' }}>
                  We recommend you connect both <strong>www.</strong>game.com
                  and game.com
                </Text>
              </CardFooter>
            </Card>
          </Box>
        </Box>
      </PageContent>
    </>
  );
}
