import { AnchorLink } from '@core/components/atoms/AnchorLink';
import OwnNav from '@core/components/organisms/OwnNav';
import { accountsPassword } from '@core/redux/accounts/client';
import UserAction from '@core/redux/user/actions';
import { selectIsLoggedIn } from '@core/redux/user/selectors';
import { Box, Button, Form, FormExtendedEvent, FormField, Heading, Layer, Text, TextInput } from 'grommet';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
export default function Signup() {
  const [error, setError] = React.useState<null | string>(null);
  const dispatch = useDispatch();
  const handleFormSubmit = (e: FormExtendedEvent<{ email: string; password: string }>) => {
    accountsPassword
      .login({
        user: e.value.email,
        password: e.value.password
      })
      .then(_createdUser => {
        dispatch(UserAction.setIsLoggedIn(true));
      })
      .catch(failed => {
        setError(failed.message);
      });
  };

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const onBack = () => window.history.back();

  return (
    <>
      <OwnNav />
      <Layer onClickOutside={onBack} onEsc={onBack}>
        {isLoggedIn && <Redirect to="/" />}
        <Box pad="medium">
          <Heading alignSelf="center" level="3">
            Log In
          </Heading>
          <Form onSubmit={handleFormSubmit}>
            <FormField label="Email" error={error}>
              <TextInput id="email-text-input" name="email" />
            </FormField>
            <FormField label="Password">
              <TextInput id="password-text-input" name="password" type="password" />
            </FormField>
            <Box pad="small">
              <Text size="small">
                By Signing Up you agree to <AnchorLink to="/terms-conditions">Terms & Conditions</AnchorLink>
              </Text>
              <Box pad="small">
                <Button
                  id="log-in-submit"
                  type="submit"
                  style={{ color: 'white' }}
                  size="medium"
                  primary
                  label="Log In"
                />
              </Box>
            </Box>
          </Form>
        </Box>
      </Layer>
    </>
  );
}
