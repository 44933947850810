import { Button, ButtonExtendedProps } from "grommet";
import * as React from "react";
import { useHistory } from "react-router";

export default function ButtonLink({ href, ...props }: ButtonExtendedProps) {
  const history = useHistory();

  const handlePress = () => {
    if (href) {
      history.push(href);
    }
  };

  return <Button onClick={handlePress} {...props}></Button>;
}
