import { Actions, SteamImporterReducerType, ESteamImporterAction } from './types';

const initialState: SteamImporterReducerType = {
  themes: [],
  screenShots: [],
  siteId: null
};

export const reducer = (state = initialState, action: Actions): SteamImporterReducerType => {
  switch (action.type) {
    case ESteamImporterAction.SET_RETREIVED_DATA:
      return {
        ...state,
        themes: action.payload.themes,
        screenShots: action.payload.screenShots,
        siteId: action.payload.siteId
      };
    default:
      return state;
  }
};
