import PageContent from '@core/components/atoms/PageContent';
import { PreviewComponentScaled } from '@core/components/blind/PreviewComponent';
import { Text, Heading, Box } from 'grommet';
import * as React from 'react';
import ClientSocialsPage from '../../client/ClientSocialsPage';
import FeedbackCategoryList from '../../feedback/categories/FeedbackCategoryList';
import { siteConfigExample, ThemeSelector } from './StartSmall';

export default function GoViral() {
  const [previewSiteConfig, setPreviewSiteConfig] =
    React.useState(siteConfigExample);
  return (
    <PageContent align="center">
      <Heading margin={{ bottom: 'none' }}>Grow your player base</Heading>
      <Box width="large">
        <Text size="large" textAlign="center" margin="medium">
          Set up viral actions with just a couple of clicks. This increased our
          reach by more than <strong>2x</strong> during beta.
        </Text>
      </Box>
      <ThemeSelector
        previewSiteConfig={previewSiteConfig}
        setPreviewSiteConfig={setPreviewSiteConfig}
      />
      <Text>+ 1000s of other combinations</Text>
      <PreviewComponentScaled
        key={JSON.stringify(previewSiteConfig)}
        margin={{ vertical: 'medium' }}
        height="large"
        site={previewSiteConfig}
        style={{ minWidth: '100%' }}
      >
        <ClientSocialsPage />
      </PreviewComponentScaled>
      <Heading margin={{ bottom: 'none' }}>Keep your players engaged</Heading>
      <Box width="large">
        <Text size="large" textAlign="center" margin="medium">
          Set up your own feedback board. As if you had your own mini reddit but
          with more power in your hands.
        </Text>
      </Box>
      <PreviewComponentScaled
        key={JSON.stringify(previewSiteConfig) + '-2nd'}
        margin={{ vertical: 'medium' }}
        height="xlarge"
        site={previewSiteConfig}
        style={{ minWidth: '100%' }}
      >
        <FeedbackCategoryList />
      </PreviewComponentScaled>
      <Heading margin={{ bottom: 'none' }}>With Deep Integrations</Heading>
      <Heading level={3}>Discord, Twitter and more</Heading>
    </PageContent>
  );
}
