import {
  Button,
  Text,
  Box,
  Grid,
  Heading,
  Card,
  CardHeader,
  Layer,
  Form,
  FormField,
  TextInput,
  TextArea,
  CardFooter,
  RadioButtonGroup,
} from 'grommet';
import * as Icons from 'grommet-icons';
import * as React from 'react';
import FormFieldCheckBox from '@core/components/atoms/FormFieldCheckBox';
import { VoteCategoryView } from '@core/types';
import { PostModerationSettings } from '@server/lib/utils/permissionsConfig';
import toast from 'react-hot-toast';
import {
  useApiCategoryCreate,
  useApiCategoryUpdate,
} from '@core/hooks/requests';
import { useSiteFromParams } from '../pages/publeash/games/id/EditSitePage';

const postModerationSettingsOptions = [
  {
    label: 'All posts are visible immediatelly',
    value: PostModerationSettings.AllVisible,
  },
  {
    label:
      'Posts from verified users are visible, others go into moderation queue',
    value: PostModerationSettings.VerifiedVisible,
  },
  {
    label: 'All posts go into moderation queue',
    value: PostModerationSettings.NoneVisible,
  },
  { label: 'Only admins can post', value: PostModerationSettings.Disabled },
];

const commentModerationSettingsOptions = [
  {
    label: 'All comments are visible immediatelly',
    value: PostModerationSettings.AllVisible,
  },
  {
    label:
      'Comments from verified users are visible, others go into moderation queue',
    value: PostModerationSettings.VerifiedVisible,
  },
  {
    label: 'All comments go into moderation queue',
    value: PostModerationSettings.NoneVisible,
  },
  { label: 'Only admins can comment', value: PostModerationSettings.Disabled },
];

interface Props {
  category: Partial<VoteCategoryView>;
  onClose(): void;
}

export default function ManageCategoryModal({ onClose, category }: Props) {
  const [color, setColor] = React.useState(category.color || '#cccccc');
  const [title, setTitle] = React.useState(category.title || '');
  const [postModerationSettings, setPostModerationSettings] =
    React.useState<PostModerationSettings>(
      category.postModerationSettings || PostModerationSettings.AllVisible
    );
  const [commentModerationSettings, setCommentModerationSettings] =
    React.useState<PostModerationSettings>(
      category.commentModerationSettings || PostModerationSettings.AllVisible
    );
  const [description, setDescription] = React.useState(
    category.description || ''
  );
  const [hidden, setHidden] = React.useState(category.hidden || false);
  const [isPrivate, setPrivate] = React.useState<boolean>(
    typeof category.private !== 'undefined' ? category.private : true
  );
  const [site] = useSiteFromParams();

  const data = {
    ...(category || {}),
    title,
    color,
    postModerationSettings,
    commentModerationSettings,
    description,
    hidden,
    private: isPrivate,
  };

  const createRequest = useApiCategoryCreate(
    data,
    String(site.id),
    () => {
      toast.success('Created a new category. Make sure to add some posts!');
      onClose();
    },
    () => {
      toast.error('Problem creating the category. Please try again later.');
    }
  );
  const updateRequest = useApiCategoryUpdate(
    data,
    String(site.id),
    () => {
      toast.success('Updated!');
      onClose();
    },
    () => {
      toast.error('Problem updating the category. Please try again later.');
    }
  );

  const handleSave = () => {
    if (!title) {
      toast.error('You need to set category title');
      return;
    }
    if (data.id) {
      updateRequest.mutate();
    } else {
      createRequest.mutate();
    }
  };

  // TODO:
  // delete
  // const [template, setTemplate] = React.useState(category.template || '');
  return (
    <Layer background='transparent' onClickOutside={onClose} onEsc={onClose}>
      <Card background='dark-1'>
        <CardHeader pad='small' background='dark-2'>
          <Heading margin='0' level={4}>
            Manage Category
          </Heading>
        </CardHeader>
        <Box pad='small' overflow={{ vertical: 'scroll' }}>
          <Form>
            <Grid
              columns={['flex', 'flex', 'flex']}
              rows={['auto', 'auto']}
              areas={[
                { name: 'top-left', start: [0, 0], end: [1, 0] },
                { name: 'top-middle', start: [1, 0], end: [2, 0] },
                { name: 'top-right', start: [2, 0], end: [2, 0] },
                { name: 'bottom-left', start: [0, 1], end: [1, 1] },
                { name: 'bottom-right', start: [1, 1], end: [2, 1] },
              ]}
              gap={{ column: 'small', row: '0' }}
            >
              <FormField
                gridArea='top-left'
                title='Name'
                name='Name'
                label='Name'
                margin={{ bottom: '0' }}
              >
                <TextInput
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder='Bugs, Ideas, ...'
                />
              </FormField>
              <FormField
                gridArea='top-middle'
                title='Color'
                name='Color'
                label='Color'
                margin={{ bottom: '0' }}
                width='small'
              >
                <input
                  style={{ width: '100%', height: '45px', border: 0 }}
                  type='color'
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </FormField>
              <Box gridArea='top-right' justify='end'>
                <Button
                  style={{ borderRadius: 4 }}
                  icon={<Icons.DocumentText size='small' />}
                  label='Edit Template Text'
                />
              </Box>
              <FormFieldCheckBox
                gridArea='bottom-left'
                checkboxProps={{
                  label: 'Private',
                  checked: isPrivate,
                  onChange: (e) => setPrivate(e.target.checked),
                }}
                info={
                  <Text size='small' margin={{ top: '-8px', left: '-8px' }}>
                    This category is not accessible at all from the website
                  </Text>
                }
              />
              <FormFieldCheckBox
                gridArea='bottom-right'
                checkboxProps={{
                  label: 'Hidden',
                  checked: hidden,
                  onChange: (e) => setHidden(e.target.checked),
                }}
                info={
                  <Text size='small' margin={{ top: '-8px', left: '-8px' }}>
                    Category will only be accessible via the URL and it will not
                    be shown anywhere on the website.
                  </Text>
                }
              />
            </Grid>
            <FormField
              width='large'
              label='Description'
              info='Markdown is supported'
            >
              <TextArea
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </FormField>
            <Heading margin={{ bottom: 'xsmall' }} level={4}>
              Posts
            </Heading>
            <RadioButtonGroup
              name='postModerationSettings'
              value={postModerationSettings}
              onChange={(e) =>
                setPostModerationSettings(
                  e.target.value as PostModerationSettings
                )
              }
              options={postModerationSettingsOptions}
            />
            <Heading margin={{ bottom: 'xsmall' }} level={4}>
              Comments
            </Heading>
            <RadioButtonGroup
              name='commentModerationSettings'
              onChange={(e) =>
                setCommentModerationSettings(
                  e.target.value as PostModerationSettings
                )
              }
              options={commentModerationSettingsOptions}
              value={commentModerationSettings}
            />
          </Form>
        </Box>
        <CardFooter
          background='dark-2'
          pad='small'
          align='stretch'
          direction='row'
          justify='between'
        >
          <Button primary color='status-critical' label='Delete' />
          <Button
            style={{ padding: '0 64px' }}
            primary
            label='Save'
            onClick={handleSave}
          />
        </CardFooter>
      </Card>
    </Layer>
  );
}
