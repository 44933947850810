import * as React from 'react';
import { Card, CardHeader, Heading } from 'grommet';
import useRound from '@core/hooks/useRound';

export default function LargeVerticalCard({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  const round = useRound();
  return (
    <Card width="medium" background="dark-1" round={round} animation="fadeIn">
      <CardHeader pad="medium" background="dark-4" animation="slideDown">
        <Heading margin="0" level={3}>
          {title}
        </Heading>
      </CardHeader>
      {children}
    </Card>
  );
}
