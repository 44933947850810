import OwnNav from '@core/components/organisms/OwnNav';
import PageContent from '@core/components/atoms/PageContent';
import { Text } from 'grommet';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Redirect() {
  const query = useQuery();
  const history = useHistory();
  const delay = parseInt(query.get('delay') || '0', 10);
  const to = query.get('to') || '/';

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      history.push(to);
    }, delay * 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <OwnNav />
      <PageContent pad="medium" align="center" justify="center">
        <Text>Redirecting...</Text>
      </PageContent>
    </>
  );
}
