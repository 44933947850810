import { Box } from 'grommet';
import * as Icons from 'grommet-icons';
import * as React from 'react';

export default function NavBoxItems({ items }: { items: NavBoxItem[] }) {
  return (
    <Box direction="column" gap="small" margin="xsmall">
      {items.map((item, index) => (
        <Box key={index} align="center" direction="row" gap="small">
          {typeof item === 'string' ? (
            <Icons.CaretRightFill size="small" />
          ) : !item.completed ? (
            <Icons.Checkbox size="small" color="dark-5" />
          ) : (
            <Icons.CheckboxSelected size="small" color="light-1" />
          )}
          {typeof item === 'string' ? item : item.title}
        </Box>
      ))}
    </Box>
  );
}
export type NavBoxItem = string | { title: string; completed?: boolean };
