import * as React from 'react';
import { Box } from 'grommet';
// import { useSessionContact } from '@core/components/organisms/ClientLandingSection';
import ClientSubpageHeader from '@core/components/organisms/ClientSubpageHeader';
import ClientFooter from '@core/components/organisms/ClientFooter';
import useThankYouPageConfig from '@core/hooks/useThankYouPageConfig';
import BasicSocialContent from '../../molecules/BasicSocialContent';
import AmbasadorSocialContent from '../../molecules/AmbasadorSocialContent';
import ViralSocialContent from '../../molecules/ViralSocialContent';
import LargeVerticalCard from '../../molecules/LargeVerticalCard';
import useColor from '@core/hooks/useColor';
import { defaultSiteConfig } from '@core/common/siteConfigs';

export default function ClientSocialsPage() {
  // const [contact] = useSessionContact();
  const thankYouConfig = useThankYouPageConfig();
  const enableVirality = thankYouConfig.enableActions;
  const enableAmbassador = thankYouConfig.enableAmbassador;
  const bgColor = useColor('dark-1');
  return (
    <Box>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      body, html { 
        background: ${bgColor};
      }
      `,
        }}
      />
      <ClientSubpageHeader />
      <Box
        pad="medium"
        gap="medium"
        direction="row-responsive"
        align="stretch"
        justify="center"
        background={bgColor}
      >
        <LargeVerticalCard
          title={
            thankYouConfig.successTitle ||
            defaultSiteConfig.thankYouPage?.successTitle ||
            ''
          }
        >
          <BasicSocialContent />
        </LargeVerticalCard>
        {enableVirality && (
          <LargeVerticalCard
            title={
              thankYouConfig.actionsTitle ||
              defaultSiteConfig.thankYouPage?.actionsTitle ||
              ''
            }
          >
            <ViralSocialContent />
          </LargeVerticalCard>
        )}
        {enableAmbassador && (
          <LargeVerticalCard
            title={
              thankYouConfig.ambassadorTitle ||
              defaultSiteConfig.thankYouPage?.ambassadorTitle ||
              ''
            }
          >
            <AmbasadorSocialContent />
          </LargeVerticalCard>
        )}
      </Box>
      <ClientFooter />
    </Box>
  );
}
