import * as React from "react";
import { defaultPlatforms, SupportedPlatform } from "@core/config/platforms";

export type FieldVariations = Record<SupportedPlatform | "default", string>;

export interface UpdateData {
  platforms: SupportedPlatform[];
  fields: Record<string, FieldVariations>;
}

export function useUpdateData() {
  const [updateData, setUpdateData] = React.useState<UpdateData>({
    platforms: defaultPlatforms,
    fields: {},
  });

  const setPlatforms = (platforms: SupportedPlatform[]) => {
    setUpdateData({
      ...updateData,
      platforms: platforms,
    });
  };
  const platforms = updateData.platforms;
  const togglePlatform = (chan: SupportedPlatform) => {
    const index = platforms.indexOf(chan);
    if (isPlatformEnabled(chan)) {
      setPlatforms(
        platforms.slice(0, index).concat(platforms.slice(index + 1))
      );
    } else {
      setPlatforms(platforms.concat([chan]));
    }
  };

  const isPlatformEnabled = (chan: SupportedPlatform) => {
    return platforms.includes(chan);
  };
  return {
    platforms,
    togglePlatform,
    isPlatformEnabled,
    updateData,
    setUpdateData,
  };
}
