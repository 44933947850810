import { Drop, TextInput } from "grommet";
import * as React from "react";
import {
  // AtomicBlockUtils,
  ContentBlock,
  Editor,
  EditorState,
  Entity,
  RawDraftContentState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import {
  Button as GrommetButton,
  Box,
  Text,
  BoxExtendedProps,
  ButtonExtendedProps,
} from "grommet";
import * as Icons from "grommet-icons";
import styled from "styled-components";
import useColor from "@core/hooks/useColor";
import { PlatformAsset } from "./PrepareContentMedia";

export const styleMap = {
  STRIKETHROUGH: {
    textDecoration: "line-through",
  },
};

export const Audio = (props: any) => {
  return (
    <audio
      controls
      src={props.src}
      style={{ maxWidth: "100%", objectFit: "contain" }}
    />
  );
};

export const Image = (props: any) => {
  return (
    <img src={props.src} style={{ maxWidth: "100%", objectFit: "contain" }} />
  );
};

export const Video = (props: any) => {
  return (
    <video
      controls
      src={props.src}
      style={{ maxWidth: "100%", objectFit: "contain" }}
    />
  );
};

const Button = styled(GrommetButton)`
  height: 32px;
  padding: 0 6px;
  margin: 4px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  &:hover {
    background: #ddd;
  }
`;
interface Props extends Omit<BoxExtendedProps, "onChange"> {
  valueRawState?: RawDraftContentState;
  disabled?: boolean;
  onRawStateChange?(value: RawDraftContentState): void;
}
const ToolbarButton = (props: ButtonExtendedProps) => {
  const background = useColor("dark-1");
  return <Button style={{ background }} {...props}></Button>;
};
export default function BetterEditor({
  onRawStateChange,
  valueRawState,
  disabled,
  ...boxProps
}: Props) {
  const editorRef = React.useRef<Editor | null>(null);
  const [linkEditorVisible, setLinkEditorVisible] = React.useState(false);
  const [mediaEditorVisible, setMediaEditorVisible] = React.useState(false);
  const [link, setLink] = React.useState("");
  const toolbarRef = React.useRef<HTMLDivElement | null>(null);

  const initialValue: EditorState = React.useMemo(() => {
    if (valueRawState) {
      EditorState.createWithContent(convertFromRaw(valueRawState));
    }
    return EditorState.createEmpty();
  }, []);

  const [editorState, setEditorState] = React.useState(initialValue);

  React.useEffect(() => {
    console.log(convertToRaw(editorState.getCurrentContent()));
    if (onRawStateChange) {
      onRawStateChange(convertToRaw(editorState.getCurrentContent()));
    }
  }, [editorState, onRawStateChange]);

  const handleUnderline = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
    setImmediate(() => {
      editorRef?.current?.focus();
    });
  };
  const handleStrikethrough = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "STRIKETHROUGH"));
    setImmediate(() => {
      editorRef?.current?.focus();
    });
  };
  const handleBold = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
    setImmediate(() => {
      editorRef?.current?.focus();
    });
  };

  const handleItalic = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
    setImmediate(() => {
      editorRef?.current?.focus();
    });
  };

  const editorSetLink = (url: string) => {
    const entityKey = Entity.create("LINK", "MUTABLE", { url: url });
    const selection = editorState.getSelection();
    setEditorState(RichUtils.toggleLink(editorState, selection, entityKey));
    setImmediate(() => {
      editorRef?.current?.focus();
    });
  };

  // const handleFileChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
  //   // @ts-expect-error It's actually there
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = function () {
  //       addMedia(file.type, reader.result as string);
  //     };
  //     reader.onerror = function (error) {
  //       console.log("Error: ", error);
  //     };
  //   }
  //   setMediaEditorVisible(false);
  // };
  // const addMedia = (urlType: string, urlValue: string) => {
  //   const contentState = editorState.getCurrentContent();
  //   const contentStateWithEntity = contentState.createEntity(
  //     urlType,
  //     "IMMUTABLE",
  //     { src: urlValue }
  //   );
  //   const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  //   const newEditorState = EditorState.set(editorState, {
  //     currentContent: contentStateWithEntity,
  //   });

  //   setEditorState(
  //     // The third parameter here is a space string, not an empty string
  //     // If you set an empty string, you will get an error: Unknown DraftEntity key: null
  //     AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
  //   );
  // };

  const handleLink = () => setLinkEditorVisible(!linkEditorVisible);

  const handleLinkConfirm = () => {
    editorSetLink(link);
    setLink("");
    setLinkEditorVisible(false);
  };

  const handleUl = () => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, "unordered-list-item")
    );
    setImmediate(() => {
      editorRef?.current?.focus();
    });
  };
  const handleOl = () => {
    setEditorState(RichUtils.toggleBlockType(editorState, "ordered-list-item"));
    setImmediate(() => {
      editorRef?.current?.focus();
    });
  };

  const handleMedia = () => setMediaEditorVisible(!mediaEditorVisible);

  const Media = (props: any) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const type = entity.getType();

    const imageTypes: string[] = ["image/jpeg", "image/png"];
    const videoTypes: string[] = ["video/mp4", "video/quicktime"];
    const audioTypes: string[] = [];

    let media;
    if (audioTypes.indexOf(type) !== -1) {
      media = <Audio src={src} />;
    } else if (imageTypes.indexOf(type) !== -1) {
      media = <Image src={src} />;
    } else if (videoTypes.indexOf(type) !== -1) {
      media = <Video src={src} />;
    }
    return media ? media : null;
  };

  const mediaBlockRenderer = (block: ContentBlock) => {
    if (block.getType() === "atomic") {
      return {
        component: Media,
        editable: false,
      };
    }

    return null;
  };

  const handleHolderClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      editorRef.current?.focus();
    }
  };

  const handleHeader = (type: string) => () => {
    setEditorState(RichUtils.toggleBlockType(editorState, type));
    setImmediate(() => {
      editorRef?.current?.focus();
    });
  };

  return (
    <Box {...boxProps}>
      <Box
        direction="row"
        pad="xsmall"
        background="dark-2"
        ref={(ref) => (toolbarRef.current = ref)}
      >
        <ToolbarButton onClick={handleBold} disabled={disabled}>
          <Text weight="bold" size="small" color="light-1">
            Bold
          </Text>
        </ToolbarButton>
        <ToolbarButton disabled={disabled}>
          <Text
            size="small"
            style={{ fontStyle: "italic" }}
            onClick={handleItalic}
            color="light-1"
          >
            Italic
          </Text>
        </ToolbarButton>
        <ToolbarButton onClick={handleUl} disabled={disabled}>
          <Icons.List color="light-1" size="small" />
        </ToolbarButton>
        <ToolbarButton onClick={handleOl} disabled={disabled}>
          <Icons.OrderedList color="light-1" size="small" />
        </ToolbarButton>
        <ToolbarButton onClick={handleUnderline} disabled={disabled}>
          <Icons.Underline color="light-1" size="small" />
        </ToolbarButton>
        <ToolbarButton onClick={handleStrikethrough} disabled={disabled}>
          <Icons.StrikeThrough color="light-1" size="small" />
        </ToolbarButton>
        <ToolbarButton disabled={disabled} onClick={handleMedia}>
          <Icons.Video color="light-1" size="small" />
          &nbsp;
          <Icons.Image color="light-1" size="small" />
        </ToolbarButton>
        <ToolbarButton onClick={handleLink} disabled={disabled}>
          <Text color="light-1" size="small">
            Link
          </Text>
          &nbsp;
          <Icons.Link color="light-1" size="small" />
        </ToolbarButton>
        <ToolbarButton onClick={handleHeader("header-one")}>H1</ToolbarButton>
        <ToolbarButton onClick={handleHeader("header-two")}>H2</ToolbarButton>
        <ToolbarButton onClick={handleHeader("header-three")}>H3</ToolbarButton>
        <ToolbarButton onClick={handleHeader("paragraph")}>
          <Text size="small">Normal</Text>
        </ToolbarButton>
        {toolbarRef.current && (linkEditorVisible || mediaEditorVisible) && (
          <Drop
            align={{ top: "bottom" }}
            target={toolbarRef.current}
            round="small"
          >
            {mediaEditorVisible && (
              <Box>
                <PlatformAsset />
              </Box>
            )}
            {linkEditorVisible && (
              <Box direction="row">
                <TextInput
                  style={{
                    outline: "none",
                    boxShadow: "none",
                    borderRadius: 0,
                    border: 0,
                    borderRight: "1px solid",
                  }}
                  placeholder="https://"
                  size="small"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
                <GrommetButton
                  style={{ width: 64, paddingLeft: 16, paddingRight: 16 }}
                  onClick={handleLinkConfirm}
                >
                  <Icons.Add />
                </GrommetButton>
              </Box>
            )}
          </Drop>
        )}
      </Box>
      <Box
        border={{ color: "dark-1", size: "small", side: "all" }}
        style={{ minHeight: 400, boxShadow: "none" }}
        pad="small"
        background="white"
        onClick={handleHolderClick}
      >
        <Editor
          ref={editorRef}
          blockRendererFn={mediaBlockRenderer}
          editorState={editorState}
          onChange={setEditorState}
          customStyleMap={styleMap}
        />
      </Box>
    </Box>
  );
}
