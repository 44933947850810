import { Text, TextExtendedProps } from "grommet";
import * as React from "react";
import { useAppSelector } from "@core/hooks/requestUtils";
import Tag from "../atoms/Tag";

export default function ContactNameText({
  contactId,
  ...rest
}: { contactId?: string } & TextExtendedProps) {
  const contact = useAppSelector(
    (state) => state.entities.contact[contactId || -1]
  );
  return (
    <Text {...rest}>
      {contact?.name || "Anonymous"}
      {contact?.publicTags?.map((tag, index) => (
        <Tag
          margin={{ left: "xsmall" }}
          size="small"
          key={index}
          color={tag.color}
        >
          {tag.text}
        </Tag>
      ))}
    </Text>
  );
}
