import PageContent from '@core/components/atoms/PageContent';
import { selectCurrentSiteId } from '@core/redux/siteConfig';
import ClientSubpageHeader from '@core/components/organisms/ClientSubpageHeader';
import { useApiCategories } from '@core/hooks/requests';
import { useAppSelector } from '@core/hooks/requestUtils';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Heading,
  ResponsiveContext,
} from 'grommet';
import * as React from 'react';
import * as Icons from 'grommet-icons';
import ClientFooter from '@core/components/organisms/ClientFooter';
import TC from 'tinycolor2';
import PostBrowser from './PostBrowser';
import { useHistory } from 'react-router';
import LoadingIndicator from '../../../atoms/LoadingIndicator';
import { PostModerationSettings } from '@server/lib/utils/permissionsConfig';
import { VoteCategoryView } from '@core/types';
import { useDispatch } from 'react-redux';
import { createAPostModalOpen } from '@core/redux/createAPostModal/actions';

export default function FeedbackCategoryList() {
  const siteId = useAppSelector(selectCurrentSiteId);
  const categories = useApiCategories(siteId);
  const history = useHistory();
  const dispatch = useDispatch();
  const deviceSize = React.useContext(ResponsiveContext);
  const handleChangeCategory = (categoryId: string) => {
    setTimeout(() => {
      history.push(`/feedback/category/${categoryId}`);
    }, 17);
  };

  const handleAddPost =
    (category: VoteCategoryView) => (e: React.SyntheticEvent) => {
      dispatch(createAPostModalOpen(siteId as unknown as number, category.id));
      e.stopPropagation();
    };
  const hasNoCategories =
    !categories.isLoading &&
    categories.isSuccess &&
    categories.data.length === 0;
  return (
    <Box background="dark-1" style={{ minHeight: '100vh' }}>
      <ClientSubpageHeader />
      <PageContent pad="medium">
        {!hasNoCategories && (
          <Card
            pad="small"
            background="dark-2"
            elevation="xlarge"
            border={{
              size: '1px',
              side: 'top',
              style: 'solid',
              color: 'dark-1',
            }}
          >
            <Heading
              margin={{ bottom: 'medium', top: '0', horizontal: 'small' }}
              level={3}
            >
              Categories
            </Heading>
            <Grid
              columns={deviceSize === 'small' ? ['flex'] : ['flex', 'flex']}
              gap="small"
            >
              {categories.data.map((category) => (
                <Card
                  elevation="medium"
                  key={category.id}
                  background={new TC(category.color || 'white')
                    .setAlpha(0.1)
                    .desaturate(30)
                    .toRgbString()}
                  hoverIndicator={{
                    color: new TC(category.color || 'white')
                      .setAlpha(0.2)
                      .toRgbString(),
                  }}
                  onClick={() => handleChangeCategory(category.id)}
                >
                  <CardHeader
                    background={new TC(category.color || 'white').toRgbString()}
                    pad="small"
                  >
                    <Heading margin="0" level={3}>
                      {category.title}
                    </Heading>
                  </CardHeader>
                  <CardBody pad="small">{category.description}</CardBody>
                  {category.postModerationSettings !==
                    PostModerationSettings.Disabled && (
                    <CardFooter pad="small" justify="end">
                      <Button
                        onClick={handleAddPost(category)}
                        icon={<Icons.FormAdd />}
                        label="Post New"
                      />
                    </CardFooter>
                  )}
                </Card>
              ))}
            </Grid>
            <LoadingIndicator loading={categories.isLoading} size="medium" />
          </Card>
        )}
        <PostBrowser onCategoryChange={handleChangeCategory} />
      </PageContent>
      <ClientFooter />
    </Box>
  );
}
