import { SiteConfig } from '../siteConfig';

export type PartialThemes = Partial<SiteConfig['theme']>[];

export interface SteamImporterReducerType {
  themes: Partial<SiteConfig['theme']>[];
  siteId: number | null;
  screenShots: string[];
}

export enum ESteamImporterAction {
  GET_ALL = 'steamImporter_get_all',
  SET_RETREIVED_DATA = 'steamImporter_set_retreived_data'
}

export type Actions = ActionReducerType<
  { themes: PartialThemes; siteId: number; screenShots: string[] },
  ESteamImporterAction.SET_RETREIVED_DATA
>;
