import {
  Button,
  Text,
  Box,
  Grid,
  Heading,
  Tabs,
  Tab,
  Card,
  CardHeader,
  CardBody,
  TextInput,
  Select,
} from "grommet";
import * as Icons from "grommet-icons";
import * as React from "react";
import PostBrowserItem from "../../../../molecules/PostBrowserItem";
import CircleNumber from "../../../../atoms/CircleNumber";
import HelpComponent from "../../../../molecules/HelpComponent";
import TutorialStep from "../../../../blind/TutorialStep";
import OwnNav from "@core/components/organisms/OwnNav";
import { WithGameSidebar } from "@core/components/molecules/WithGameSidebar";
import SectionTabThemeProvider from "@core/components/blind/SectionTabThemeProvider";
import { Redirect, useHistory, useLocation } from "react-router";
import { useSiteFromParams } from "./EditSitePage";
import FormFieldCheckBox from "@core/components/atoms/FormFieldCheckBox";
import { useDispatch } from "react-redux";
import SitesEditorAction from "@core/redux/sitesEditor/actions";
import { checkboxProps, inputProps } from "@core/common/siteConfigsHelpers";
import { useApiCategories } from "@core/hooks/requests";
import FloatingSaveButton from "@core/components/molecules/FloatingSaveButton";
import { VoteCategoryView } from "@core/types";
import MessageBox from "@core/components/atoms/MessageBox";
import useRound from "@core/hooks/useRound";
import ManageCategoryModal from "../../../../organisms/ManageCategoryModal";
import toast from "react-hot-toast";
import ModerationQueue from "../../../../organisms/ModerationQueue";
import CreateAPostModal from "@core/components/organisms/CreateAPostModal";

export default function EditFeedback() {
  const location = useLocation();
  const [site, setSite, hasChanged] = useSiteFromParams();
  const dispatch = useDispatch();
  const isModeration = location.pathname.endsWith("moderation");
  const [tab, setTab] = React.useState(isModeration ? 1 : 0);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [editCategory, setEditCategory] =
    React.useState<Partial<VoteCategoryView> | null>(null);

  const handleEdit = (category: VoteCategoryView) => {
    setEditCategory(category);
    setIsModalVisible(true);
  };

  const handleNew = () => {
    setEditCategory({});
    setIsModalVisible(true);
  };
  const history = useHistory();
  const onActiveChange = (index: number) => {
    switch (index) {
      case 0:
        history.replace(`/edit/${site.id}/feedback`);
        return;
      case 1:
        history.replace(`/edit/${site.id}/feedback/moderation`);
        return;
      default:
        toast.success("Please save the website first then go to your domain.");
        return;
    }
  };

  const categories = useApiCategories(String(site.id));

  React.useEffect(() => {
    const isNewLinkModeration = location.pathname.endsWith("moderation");
    setTab(isNewLinkModeration ? 1 : 0);
  }, [location.pathname]);

  if (!site || !site.config) return <Redirect to="/edit/sites" />;

  const handleSave = () => {
    dispatch(SitesEditorAction.updateConfig(site));
  };

  const round = useRound("small");
  const roundL = useRound("medium");

  const updateDefaultVoteCategoryId = (categoryId: string | undefined) => {
    setSite({
      ...site,
      config: {
        ...site.config,
        general: {
          ...site.config.general,
          defaultVoteCategoryId: categoryId,
        },
      },
    });
  };
  return (
    <>
      <OwnNav />
      <WithGameSidebar>
        <Heading level={2}>Feedback Management</Heading>
        <SectionTabThemeProvider>
          <Box
            round={roundL}
            overflow="hidden"
            style={{
              border: "1px solid white",
            }}
          >
            <Tabs justify="start" activeIndex={tab} onActive={onActiveChange}>
              <Tab
                id="tab-overview"
                title="Overview"
                icon={<Icons.Overview size="small" />}
              >
                <Grid columns={["flex", "flex"]} gap="small" margin="small">
                  <Card background="white" round={round}>
                    <CardHeader pad="small" background="dark-1">
                      <Heading margin="0" level={4}>
                        Basic Settings
                      </Heading>
                    </CardHeader>
                    <CardBody pad="small">
                      <FormFieldCheckBox
                        checkboxProps={checkboxProps(
                          site,
                          setSite,
                          "general",
                          "enableFeedback",
                          "Enable Feedback Page"
                        )}
                      />
                      <Text>
                        How do you want to call your "feedback portal"?
                      </Text>
                      <TextInput
                        type="text"
                        {...inputProps(
                          site,
                          setSite,
                          "general",
                          "feedbackPageMenuName"
                        )}
                      />
                      <MessageBox status="info" margin={{ top: "small" }}>
                        <Text size="small">
                          This will make{" "}
                          <i style={{ color: "#cccccc" }}>
                            yourdomain.com/feedback
                          </i>{" "}
                          page available to your users.
                          <br />
                          Feedback link will also be automatically shown in
                          different menus.
                        </Text>
                      </MessageBox>
                      <Heading level={4} margin={{ bottom: "small" }}>
                        Landing Page Category Spotlight
                      </Heading>
                      <Select
                        onChange={({ option }) => {
                          const categoryId = categories.data[option]?.id;
                          updateDefaultVoteCategoryId(categoryId);
                        }}
                        placeholder="None"
                        value={
                          <Box pad="small">
                            <Text>
                              {categories.data.find(
                                (c) =>
                                  c.id ===
                                  site.config.general.defaultVoteCategoryId
                              )?.title || "None"}
                            </Text>
                          </Box>
                        }
                        options={[-1].concat(categories.data.map((_c, i) => i))}
                      >
                        {(i, state) => {
                          const category = categories.data[i];
                          return (
                            <Box
                              key={category?.id || "none"}
                              direction="row"
                              gap="small"
                              align="center"
                              pad="small"
                              margin={{ bottom: "1px" }}
                            >
                              <div
                                style={{
                                  width: 16,
                                  height: 16,
                                  borderRadius: 8,
                                  background: category?.color || "#fff",
                                }}
                              />
                              <Text>{category?.title || "None"}</Text>
                            </Box>
                          );
                        }}
                      </Select>
                      <MessageBox status="info" margin={{ vertical: "small" }}>
                        Choose a category to show on your landing page. This is
                        a quick and easy way to test which features users might
                        be interested in.
                      </MessageBox>
                      <MessageBox status="unknown">
                        Make sure you have some ideas written in that category
                        before choosing it.
                      </MessageBox>
                    </CardBody>
                  </Card>
                  <TutorialStep
                    position="left"
                    name="categories"
                    helpComponent={
                      <HelpComponent
                        title="Manage Categories"
                        buttonText="Next"
                        content="Configure feedback categories that public can post into, use it as a roadmap, collect bugs and more."
                      />
                    }
                  >
                    <Card background="white" round={round}>
                      <CardHeader pad="small" background="dark-1">
                        <Heading margin="0" level={4}>
                          Categories
                        </Heading>
                      </CardHeader>
                      <CardBody pad="small" direction="column" gap="small">
                        {categories.data.map((category) => (
                          <Box
                            key={category.title}
                            direction="row"
                            background={`linear-gradient(to right, ${category.color} 0%, #ccc 50%)`}
                            round="medium"
                            pad="xsmall"
                            align="center"
                            style={{
                              boxShadow: `inset 0 0 6px ${category.color}`,
                            }}
                          >
                            <Text
                              margin={{ left: "small" }}
                              weight="bold"
                              color="white"
                            >
                              {category.title}
                            </Text>
                            <Box flex />
                            <Button
                              label="Edit"
                              size="small"
                              onClick={() => handleEdit(category)}
                            />
                          </Box>
                        ))}
                        <Button onClick={handleNew} label="Create New" />
                        {!categories.isLoading && !categories.data.length && (
                          <MessageBox status="critical" title="No Categories">
                            Posts need to be posted in some kind of category.
                            You can configure who can post what in which
                            category.
                          </MessageBox>
                        )}
                        <MessageBox
                          margin={{ top: "small" }}
                          status="info"
                          title="Some ideas for categories"
                        >
                          Bugs, Roadmap, Ideas, Beta Feedback, Upcoming, ...
                        </MessageBox>
                      </CardBody>
                    </Card>
                  </TutorialStep>
                  <Card>
                    <CardHeader pad="small" background="dark-1">
                      <Heading margin="0" level={4}>
                        Latest Comments & Posts
                      </Heading>
                    </CardHeader>
                    <CardBody background="dark-2" pad="small" gap="small">
                      <PostBrowserItem
                        size="small"
                        post={{
                          id: "123",
                          rating: 123,
                          categoryId: "123",
                          commentsCount: 2,
                          title: "Alo",
                          content: "Aloalo",
                          imageUrl: "",
                          updatedAt: new Date().toUTCString(),
                          createdAt: new Date().toUTCString(),
                        }}
                      />
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader pad="small" background="dark-1">
                      <Heading margin="0" level={4}>
                        Top Posts
                      </Heading>
                    </CardHeader>
                  </Card>
                </Grid>
              </Tab>
              <Tab
                id="tab-overview"
                title={
                  <TutorialStep
                    padding={22}
                    position="bottom"
                    name="queue"
                    helpComponent={
                      <HelpComponent
                        title="Ease of use & flexibility"
                        content="You can make public categories and then review via moderation queue or you can make it fully open if you want."
                        buttonText="Next"
                      />
                    }
                  >
                    <span>Moderation Queue</span>
                  </TutorialStep>
                }
                icon={<CircleNumber counter="0&nbsp;" />}
              >
                <ModerationQueue siteId={site.id} />
              </Tab>
              <Tab
                disabled={!site.config.general.enableFeedback}
                id="tab-overview"
                title="View Live"
                icon={<Icons.Link size="small" />}
              >
                Preview
              </Tab>
            </Tabs>
          </Box>
        </SectionTabThemeProvider>
      </WithGameSidebar>
      <FloatingSaveButton hasChanged={hasChanged} onSavePress={handleSave} />
      {isModalVisible && editCategory && (
        <ManageCategoryModal
          category={editCategory}
          onClose={() => setIsModalVisible(false)}
        />
      )}
      <CreateAPostModal />
    </>
  );
}
