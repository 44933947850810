import { useApiContactGet } from "@core/hooks/requests";
import {
  Box,
  Text,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Layer,
  NameValueList,
  NameValuePair,
  CheckBox,
  DropButton,
  TextInput,
  FormField,
} from "grommet";
import * as React from "react";
import { useSiteFromParams } from "./EditSitePage";
import * as Icons from "grommet-icons";
import Tag from "@core/components/atoms/Tag";
import { TagView } from "@core/types";

export default function ViewContactModal({
  contactId,
  isOpen,
  onClose,
}: {
  contactId?: string | null;
  isOpen: boolean;
  onClose(): void;
}) {
  const [site] = useSiteFromParams();
  const contactRequest = useApiContactGet({
    contactId,
    siteId: site.id,
  });

  const handleClose = () => {
    setIsPublicTagModelOpen(false);
    setIsPrivateTagModalOpen(false);
    requestAnimationFrame(() => {
      onClose();
    });
  };

  const contact = contactRequest.data?.[0];
  // const [isAdmin, setIsAdmin] = React.useState(contact?.isAdmin);

  const [isPublicTagModalOpen, setIsPublicTagModelOpen] = React.useState(false);
  const [isPrivateTagModalOpen, setIsPrivateTagModalOpen] =
    React.useState(false);
  const [newPrivateTags, setNewPrivateTags] = React.useState<TagView[]>([]);
  const [newPublicTags, setNewPublicTags] = React.useState<TagView[]>([]);

  const handleAddPrivateTag = (tag: TagView) => {
    setNewPrivateTags([...newPrivateTags, tag]);
    setIsPrivateTagModalOpen(false);
  };

  const handleAddPublicTag = (tag: TagView) => {
    setNewPublicTags([...newPublicTags, tag]);
    setIsPublicTagModelOpen(false);
  };
  if (!isOpen) return null;

  return (
    <>
      <Layer onClickOutside={handleClose} background="transparent">
        <Card background="dark-1" width="600px">
          <CardHeader background="dark-2">
            <Heading margin="small" level={4}>
              {contact?.name || "Anonymous"}
            </Heading>
            <Box
              onClick={handleClose}
              hoverIndicator={{ background: "dark-3" }}
              pad="small"
            >
              <Icons.Close />
            </Box>
          </CardHeader>
          <CardBody pad="small" overflow={{ vertical: "scroll" }}>
            <NameValueList>
              <NameValuePair name="id">
                <Text size="small">{contact?.id}</Text>
              </NameValuePair>
              <NameValuePair name="Is Admin?">
                <CheckBox label="No"></CheckBox>
              </NameValuePair>
              <NameValuePair name="Invite Code">
                {contact?.inviteCode}
              </NameValuePair>
              <NameValuePair
                name={
                  <Text weight="bold">
                    E-mail
                    <Text
                      margin={{ left: "small" }}
                      weight="normal"
                      size="small"
                    >
                      ({contact?.verified ? "Verified" : "Not Verified"})
                    </Text>
                  </Text>
                }
              >
                {contact?.email}
              </NameValuePair>
              <NameValuePair name="About">{contact?.about}</NameValuePair>
              <NameValuePair name="Created at">
                {new Date(contact?.createdAt || 0).toLocaleString()}
              </NameValuePair>
              <NameValuePair
                name={
                  <Text weight="bold">
                    <Icons.Validate size="small" color="status-ok" /> Entries
                  </Text>
                }
              >
                {contact?.entries}
              </NameValuePair>
              <NameValuePair name="Public Tags">
                <Box align="start" direction="row" style={{ flexWrap: "wrap" }}>
                  {contact?.publicTags?.map((tag, index) => (
                    <Tag key={index} color={tag.color}>
                      {tag.text}
                    </Tag>
                  ))}
                  {newPublicTags.map((tag, index) => (
                    <Tag key={index} color={tag.color}>
                      {tag.text}
                    </Tag>
                  ))}
                  <DropButton
                    open={isPublicTagModalOpen}
                    onOpen={() => setIsPublicTagModelOpen(true)}
                    onClose={() => setIsPublicTagModelOpen(false)}
                    dropProps={{
                      margin: "small",
                      // @ts-expect-error this is actually valid
                      pad: "small",
                    }}
                    dropContent={<TagForm onAdd={handleAddPublicTag} />}
                  >
                    <Icons.AddCircle />
                  </DropButton>
                </Box>
              </NameValuePair>
              <NameValuePair name="Private Tags">
                <Box align="start" direction="row" style={{ flexWrap: "wrap" }}>
                  {contact?.privateTags?.map((tag, index) => (
                    <Tag key={index} color={tag.color}>
                      {tag.text}
                    </Tag>
                  ))}
                  {newPrivateTags.map((tag, index) => (
                    <Tag key={index} color={tag.color}>
                      <Box align="center" direction="row" gap="xsmall">
                        <Text size="small">{tag.text}</Text>
                        <Button
                          onClick={handleClose}
                          size="small"
                          plain
                          style={{
                            borderRadius: "6px",
                            padding: "2px",
                          }}
                          hoverIndicator={{
                            background: "status-critical",
                            elevation: "0",
                          }}
                          icon={<Icons.Close size="xsmall" />}
                        ></Button>
                      </Box>
                    </Tag>
                  ))}
                  <DropButton
                    open={isPrivateTagModalOpen}
                    onOpen={() => setIsPrivateTagModalOpen(true)}
                    onClose={() => setIsPrivateTagModalOpen(false)}
                    dropProps={{
                      margin: "small",
                      // @ts-expect-error this is actually valid
                      pad: "small",
                    }}
                    dropContent={<TagForm onAdd={handleAddPrivateTag} />}
                  >
                    <Icons.AddCircle />
                  </DropButton>
                </Box>
              </NameValuePair>
            </NameValueList>
          </CardBody>
          <CardFooter
            background="dark-2"
            pad="small"
            align="stretch"
            direction="row"
          >
            <Button color="status-critical" label="Delete" />
            <Button
              disabled={!newPrivateTags.length && !newPublicTags.length}
              label="Save"
              primary
            />
          </CardFooter>
        </Card>
      </Layer>
    </>
  );
}

function TagForm({ onAdd }: { onAdd(tag: TagView): void }) {
  const [color, setColor] = React.useState("#ee111d");
  const [text, setText] = React.useState("");
  return (
    <>
      <FormField label="Tag Color">
        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
      </FormField>
      <FormField label="Tag Text">
        <TextInput
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Beta User, MVP, ..."
        ></TextInput>
      </FormField>
      <Button
        label="Add"
        onClick={() => onAdd({ color, text })}
        icon={<Icons.Add />}
      ></Button>
    </>
  );
}
