import useColor from "@core/hooks/useColor";
import PageContent from "@core/components/atoms/PageContent";
import { config } from "@core/config/config";
import { accountsClient } from "@core/redux/accounts/client";
import UserAction from "@core/redux/user/actions";
import { selectIsLoggedIn } from "@core/redux/user/selectors";
import {
  Box,
  Button,
  GrommetProps,
  Header,
  Menu,
  Nav,
  ResponsiveContext,
  Stack,
} from "grommet";
import * as Icons from "grommet-icons";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled, { useTheme } from "styled-components";
import { AnchorLink, AnchorLinkProps } from "../atoms/AnchorLink";
import TC from "tinycolor2";
import posthog from "posthog-js";
import { FeatureFlag } from "@core/components/blind/contexts/FeatureFlagProvider";

const NavButton = styled(Button)`
  white-space: nowrap;
`;

export default function OwnNav({ fixed }: { fixed?: boolean }) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogout = async () => {
    await accountsClient.logout();
    await accountsClient.clearTokens();
    if (config.posthogEnabled) {
      posthog.reset();
    }
    dispatch(UserAction.setIsLoggedIn(false));
  };
  const handleEditor = () => {
    history.push("/edit/sites");
  };
  const handleHome = () => {
    history.push("/");
  };
  const handleSettings = () => {
    history.push("/settings");
  };
  const topHeaderColor = useColor("dark-4");
  const bottomHeaderColor = "transparent"; // useColor('dark-1');
  const c = useColor("dark-4");
  const shadow = `0px 0px 2px 2px ${c}`;
  const headerShadow = new TC(useColor("dark-1")).setAlpha(0.1).toRgbString();

  const [isHeaderCollapsed, setIsHeaderCollapsed] = React.useState(false);
  const headerBackground = fixed
    ? isHeaderCollapsed
      ? topHeaderColor
      : bottomHeaderColor
    : "brand-1";

  const textLinkColor = fixed ? "light-1" : "dark-5";

  const deviceSize = React.useContext(ResponsiveContext);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const listener = () => {
        if (window.scrollY > 80 && !isHeaderCollapsed) {
          setIsHeaderCollapsed(true);
        } else if (window.scrollY < 80 && isHeaderCollapsed) {
          setIsHeaderCollapsed(false);
        }
      };
      window.addEventListener("scroll", listener);
      return () => window.removeEventListener("scroll", listener);
    }
    return undefined;
  }, []);
  const theme = useTheme() as GrommetProps["theme"];
  // @ts-expect-error theme doesn't seem to be correctly typed
  const headingFontFamily = theme?.heading?.font?.family;

  const buttonSizes = deviceSize === "small" ? "small" : "medium";
  const buttonPaddings = deviceSize === "small" ? "8px" : undefined;

  return (
    <Stack guidingChild={1}>
      <Box pad="small" fill />
      <Header
        background={headerBackground}
        style={{
          transition: "background 300ms ease-out, box-shadow 300ms ease-out",
          boxShadow: isHeaderCollapsed ? `0 3px 8px 0px ${headerShadow}` : "",
          position: fixed ? "fixed" : "initial",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <PageContent direction="row" gap="medium" pad="xsmall">
          <NavButton
            size={buttonSizes}
            style={{
              padding: buttonPaddings,
              boxShadow: shadow,
              fontWeight: 600,
              fontFamily: `${headingFontFamily}, sans-serif`,
            }}
            onClick={handleHome}
            label={config.productName}
          />
          <Box fill />
          <FeatureFlag name="Pricing_Page_Enabled" exists>
            <HeaderLink
              color={textLinkColor}
              size="medium"
              id="header-pricing"
              to="/pricing"
              label="Pricing"
            />
          </FeatureFlag>
          <NavButton
            size={buttonSizes}
            style={{ boxShadow: shadow, padding: buttonPaddings }}
            onClick={handleEditor}
            icon={
              isLoggedIn ? (
                <Icons.Sort size={buttonSizes} color="light-1" />
              ) : (
                <Icons.AppsRounded size={buttonSizes} color="light-1" />
              )
            }
            id="header-sites"
            label={isLoggedIn ? "My Games" : "Try it now"}
          />
          {isLoggedIn ? (
            <Menu
              color="brand-4"
              label="Profile"
              items={[
                { label: "Settings", onClick: handleSettings },
                { label: "Logout", onClick: handleLogout },
              ]}
              secondary
              hoverIndicator
            />
          ) : (
            <Nav gap="small" direction="row">
              <HeaderLink
                color={textLinkColor}
                id="header-signup"
                to="/signup"
                label="Sign Up"
              />
              <HeaderLink
                color={textLinkColor}
                to="/login"
                label="Log In"
                id="header-login"
              />
            </Nav>
          )}
        </PageContent>
      </Header>
    </Stack>
  );
}

const HeaderLink = (props: AnchorLinkProps) => {
  const deviceSize = React.useContext(ResponsiveContext);
  const padding = deviceSize === "small" ? "4px" : "8px";

  return (
    <AnchorLink
      size={deviceSize}
      alignSelf="center"
      style={{ padding, whiteSpace: "nowrap" }}
      {...props}
    />
  );
};
