import * as React from 'react';
import { Text, CardBody, Heading, Box } from 'grommet';
import * as Icons from 'grommet-icons';
import Discord from '@core/components/atoms/icons/Discord';
import useColor from '@core/hooks/useColor';
import { Twitter, Youtube } from 'grommet-icons';
import Steam from '@core/components/atoms/icons/Steam';
import useRound from '@core/hooks/useRound';
import CondensedMarkdown from './CondensedMarkdown';
import useThankYouPageConfig from '@core/hooks/useThankYouPageConfig';
import SocialActionInput from '../atoms/SocialActionInput';
import { SiteConfigContext } from '../blind/contexts/SiteConfigContext';
import { defaultSiteConfig } from '@core/common/siteConfigs';
import { SiteConfig } from '@core/redux/siteConfig';
import useSessionContact from '@core/hooks/useSessionContact';

export default function ViralSocialContent() {
  const [contact] = useSessionContact();
  const socialColors = {
    discord: useColor('discord'),
    twitter: useColor('twitter'),
    steam: useColor('steam'),
    youtube: useColor('youtube'),
  };
  const gradientEnd = useColor('dark-2');
  const gradientStart = useColor('dark-1');
  const linearGradient = `linear-gradient(to right, ${gradientStart} 50%, ${gradientEnd} 80%);`;
  const round = useRound();
  const siteConfig = React.useContext(SiteConfigContext)?.general;
  const tpConfig = useThankYouPageConfig();
  const steamUrl = siteConfig?.steamAppId
    ? `https://store.steampowered.com/app/${siteConfig.steamAppId}/`
    : undefined;
  const keys: (keyof SiteConfig['general'])[] = [
    'steamAppId',
    'twitchURL',
    'twitterURL',
    'youtubeChannelURL',
    'discordURL',
    'facebookURL',
  ];
  const site = React.useContext(SiteConfigContext);
  const generalConfig = site?.general || {};
  const allSocialsMissing = keys.every((key) => !generalConfig[key]);

  return (
    <CardBody pad="medium" background="dark-2">
      <CondensedMarkdown
        content={
          tpConfig.actionsText ||
          defaultSiteConfig.thankYouPage?.actionsText ||
          ''
        }
      />
      <Box
        margin={{ top: 'medium' }}
        border={{
          size: '1px',
          color: 'dark-4',
        }}
        direction="row"
        round={round}
        overflow="hidden"
        background={linearGradient}
      >
        <Box flex pad="small">
          <Heading level={3} margin="0">
            Your Entries
          </Heading>
        </Box>
        <Box
          background={gradientEnd}
          direction="row"
          align="center"
          justify="center"
          pad="small"
          width="72px"
        >
          <Icons.New size="small" />
          &nbsp; <Text weight="bold">{contact?.entries}</Text>
        </Box>
      </Box>
      {!allSocialsMissing && (
        <Heading level={3} margin={{ bottom: 'small', top: 'medium' }}>
          Increase your chances...
        </Heading>
      )}
      <SocialActionInput
        actionType="subscribed_to_youtube"
        link={siteConfig?.youtubeChannelURL}
        Icon={Youtube}
        instructions="Please subscribe on YouTube. Waiting..."
        color={socialColors.youtube}
        name="Subscribe on YouTube"
        draws={3}
      />
      <SocialActionInput
        actionType="joined_discord"
        link={siteConfig?.discordURL}
        Icon={Discord}
        color={socialColors.discord}
        instructions="Please join Discord server. Waiting..."
        name="Join Discord"
        draws={3}
      />
      <SocialActionInput
        actionType="wishlisted_on_steam"
        link={steamUrl}
        Icon={Steam}
        color={socialColors.steam}
        instructions="Please wishlist on Steam. Waiting..."
        name="Wishlist on Steam"
        draws={3}
      />
      <SocialActionInput
        actionType="followed_on_twitter"
        link={siteConfig?.twitterURL}
        Icon={Twitter}
        color={socialColors.twitter}
        name="Follow on Twitter"
        instructions="Please follow us on Twitter. Waiting..."
        draws={3}
      />
    </CardBody>
  );
}
