import { Box, Heading, Tip } from 'grommet';
import * as Icons from 'grommet-icons';
import * as React from 'react';
import { useHistory } from 'react-router';
import { NavBoxItem } from './NavBoxItems';

interface Props {
  title: string;
  children?: React.ReactChild;
  icon?: React.ReactNode;
  to?: string;
  wip?: boolean;
  disabled?: boolean;
  id?: string;
  onClick?(): void;
}
const NavBox = React.forwardRef(
  (
    { title, children, icon, to, wip, disabled, id, onClick }: Props,
    ref: React.RefObject<HTMLDivElement>
  ) => {
    const history = useHistory();
    const hasItemsToComplete =
      typeof children === 'object'
        ? (children.props?.items as NavBoxItem[]).some(
            (item) => typeof item === 'object'
          )
        : false;
    const hasIncompleteItems =
      typeof children === 'object'
        ? (children.props?.items as NavBoxItem[]).some(
            (item) => typeof item === 'object' && !item.completed
          )
        : false;

    const borderColor =
      !hasItemsToComplete || disabled
        ? 'dark-5'
        : hasIncompleteItems
        ? '#FFCA58'
        : 'status-ok';

    const handleClick = () => {
      if (to) {
        history.push(to);
      }
      if (onClick) {
        onClick();
      }
    };

    return (
      <Box
        ref={ref}
        id={id}
        round="small"
        direction="column"
        pad="small"
        background={wip ? '#f3f3f3' : 'dark-1'}
        hoverIndicator
        onClick={handleClick}
        border={{ color: 'dark-3', size: hasItemsToComplete ? '1px' : '1px' }}
      >
        <Box direction="row" align="center">
          <Box
            fill
            pad={{ vertical: 'xsmall' }}
            gap="small"
            direction="row"
            align="center"
            style={{ flexShrink: 1 }}
          >
            {(hasItemsToComplete || disabled) && (
              <Tip
                content={hasIncompleteItems ? 'Not Completed or Enabled' : ''}
                dropProps={{ align: { bottom: 'top' } }}
              >
                <Box>
                  <Icons.StatusGoodSmall size="small" color={borderColor} />
                </Box>
              </Tip>
            )}
            <Heading level={4} margin="0">
              {title}
            </Heading>
          </Box>
          <Box fill style={{ flexShrink: 2 }} />
          {icon ? icon : <Icons.CaretNext color="dark-5" />}
        </Box>
        {children}
      </Box>
    );
  }
);

export default NavBox;
