import * as React from "react";

import { getAppRoutes } from "./common/routes";
import TopLevelRoutes from "./components/blind/TopLevelRoutes";

import "./assets/styles/global.scss";
import { SiteConfigContext } from "./components/blind/contexts/SiteConfigContext";
import { Grommet } from "grommet";
import UserContextProvider from "./components/blind/contexts/UserContext";
import { Helmet, HelmetProps } from "react-helmet";
import MaskProvider from "./components/blind/contexts/MaskProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { config } from "./config/config";
import SiteConfigProvider from "./components/blind/contexts/SiteConfigProvider";
import SiteConfigTheme from "./components/blind/contexts/SiteConfigThemeProvider";
import { Toaster } from "react-hot-toast";
import ContactContextProvider from "./components/blind/contexts/ContactContext";
import CreateAPostModal from "./components/organisms/CreateAPostModal";

function MetaTags() {
  const siteConfig = React.useContext(SiteConfigContext);
  if (!siteConfig) return null;
  const title = siteConfig.general.title;
  const description =
    siteConfig.general.siteMeta.description || siteConfig.general.title;
  const metaImage = siteConfig.general.siteMeta.imageUrl;

  let links: HelmetProps["link"] = [];

  const gameName = siteConfig.general.gameName || "A game";

  if (siteConfig.general.favicon) {
    const cdnEndpoint = config.cdnHost;
    const favicon = siteConfig.general.favicon;
    if (favicon) {
      const img = (ext: string) => `${cdnEndpoint}${favicon}${ext}`;
      links = links.concat([
        {
          href: img(".ico"),
          rel: "shortcut icon",
        },
        {
          rel: "icon",
          type: "image/png",
          href: img("-32.png"),
          sizes: "32x32",
        },
        {
          rel: "icon",
          type: "image/png",
          href: img("-48.png"),
          sizes: "48x48",
        },
        {
          rel: "icon",
          type: "image/png",
          href: img("-192.png"),
          sizes: "192x192",
        },
        {
          rel: "apple-touch-icon",
          type: "image/png",
          href: img("-167.png"),
          sizes: "167x167",
        },
        {
          rel: "apple-touch-icon",
          type: "image/png",
          href: img("-180.png"),
          sizes: "180x180",
        },
      ]);
    }
  }
  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        titleTemplate={`${gameName} - %s`}
        defaultTitle={title}
        title={title}
        meta={[
          {
            name: "description",
            content: description,
          },
          {
            name: "keywords",
            content: description,
          },
          // {
          //   property: 'og:url',
          //   content: url,
          // },
          {
            property: "og:title",
            content: siteConfig.general.gameName,
          },
          {
            name: "og:description",
            content: description,
          },
          {
            name: "twitter:card",
            content: "summary",
          },
          {
            name: "twitter:title",
            content: siteConfig.general.title,
          },
          {
            name: "twitter:description",
            content: description,
          },
        ].concat(
          metaImage
            ? [
                { property: "og:image", content: metaImage },
                { name: "twitter:image", content: metaImage },
              ]
            : []
        )}
        link={links}
      >
        <title lang="en">{siteConfig.general.title || ""}</title>
      </Helmet>
    </>
  );
}

const queryClient = new QueryClient();

export const App = ({ isOwn }: { isOwn: boolean }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ContactContextProvider>
        <UserContextProvider>
          <SiteConfigProvider>
            <SiteConfigTheme>
              {(theme) => (
                <Grommet theme={theme}>
                  <MetaTags />
                  <Toaster />
                  <MaskProvider>
                    <TopLevelRoutes routes={getAppRoutes(isOwn)} />
                    <CreateAPostModal />
                  </MaskProvider>
                </Grommet>
              )}
            </SiteConfigTheme>
          </SiteConfigProvider>
        </UserContextProvider>
      </ContactContextProvider>
    </QueryClientProvider>
  );
};
