import {
  Box,
  Button,
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  Tip,
} from 'grommet';
import * as React from 'react';
import * as Icons from 'grommet-icons';
import MessageBox from '@core/components/atoms/MessageBox';
import StageRow from '@core/components/molecules/StageRow';
import { VoteActions } from '@core/components/organisms/LandingVoting';
import SectionHeading from '../atoms/SectionHeading';
import { SiteView } from '@core/redux/sitesEditor';
import { accountsClient } from '@core/redux/accounts/client';

export default function EditContactsContactSection({
  site,
}: {
  site: SiteView;
}) {
  const handleDownloadClick = async () => {
    const tokens = await accountsClient.getTokens();
    const token = tokens?.accessToken;
    const headers = new Headers();
    if (token) {
      headers.set('authorization', token);
    }

    const response = await fetch(`/api/sites/${site.id}/contacts/download`, {
      headers,
    });
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'contacts.csv';
    link.click();
  };
  return (
    <Box flex background="dark-1" pad="medium">
      <SectionHeading title="Collected Contacts" />
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell colSpan={3} />
            <TableCell>Signups</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <StageRow
            info="All contacts go in here"
            enableEdit={false}
            signupCount={0}
            name="All"
            href={`/edit/${site.id}/contacts/all`}
            onDownload={handleDownloadClick}
          />
          <StageRow
            from="1. May"
            to="5. May"
            signupCount={0}
            name="Closed Alpha"
            finished
          />
          <StageRow
            from="1. June"
            to="5. June"
            signupCount={0}
            name="Alpha Weekend"
            finished
          />
          <StageRow
            from="1. July"
            to="5. July"
            signupCount={0}
            name="Open Beta"
            finished
          />
          <StageRow
            finished
            from="1. August"
            to="5. August"
            signupCount={0}
            name="Open Beta Weekend"
          />
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={7} gap="small">
              <Tip
                dropProps={{
                  elevation: 'medium',
                }}
                content={
                  <MessageBox elevation="small" status="info">
                    Time limited events are still work in progress.
                    <br />
                    <br /> Do you think we should prioritise the development?
                    <VoteActions
                      size="small"
                      direction="vertical"
                      votePostId="wip_edit_contacts"
                    />
                  </MessageBox>
                }
                plain
              >
                <Box alignSelf="end">
                  <Button
                    disabled
                    label="Create new Event"
                    icon={<Icons.Add />}
                    primary
                  />
                </Box>
              </Tip>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}
