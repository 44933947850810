import { Text, Box } from 'grommet';
import * as React from 'react';

export default function CircleNumber({ counter }: { counter: string }) {
  return (
    <Box
      style={{ borderRadius: 12, position: 'relative', width: 24, height: 24 }}
      background="brand-4"
      align="center"
      justify="center">
      <Text size="small" color="dark-1" weight="bold" style={{ marginRight: -3, marginTop: -1.5 }}>
        {counter}
      </Text>
    </Box>
  );
}
