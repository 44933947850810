import * as Icons from 'grommet-icons';
import Discord from '@core/components/atoms/icons/Discord';
import Steam from '@core/components/atoms/icons/Steam';

export type SupportedPlatform =
  | 'reddit'
  | 'steam'
  | 'discord'
  | 'facebook'
  | 'emails'
  | 'twitter'
  | 'website';

export const platformsMap: Record<
  SupportedPlatform,
  { key: SupportedPlatform; name: string; color: string; icon: any }
> = {
  reddit: {
    key: 'reddit',
    name: 'Reddit',
    color: 'reddit',
    icon: Icons.Reddit,
  },
  steam: {
    key: 'steam',
    name: 'Steam',
    color: 'steam',
    icon: Steam,
  },
  discord: {
    key: 'discord',
    name: 'Discord',
    color: 'discord',
    icon: Discord,
  },
  facebook: {
    key: 'facebook',
    name: 'Facebook',
    color: 'facebook',
    icon: Icons.Facebook,
  },
  emails: {
    key: 'emails',
    name: 'Emails',
    color: 'emails',
    icon: Icons.Mail,
  },
  twitter: {
    key: 'twitter',
    name: 'Twitter',
    color: 'twitter',
    icon: Icons.Twitter,
  },
  website: {
    key: 'website',
    name: 'Website',
    color: 'website',
    icon: Icons.Domain,
  },
};

export const allPlatformKeys = Object.keys(platformsMap) as SupportedPlatform[];
export const allPlatforms = Object.values(platformsMap);
export const allPlatformNames = allPlatforms.map((val) => val.name);

export const defaultPlatforms: SupportedPlatform[] = [
  'reddit',
  'discord',
  'steam',
  'emails',
  'website',
];

export const cheapPlatforms = defaultPlatforms.map((key) => platformsMap[key]);
export const expensivePlatforms = ['twitter', 'facebook'].map(
  (key) => platformsMap[key]
);
