import * as React from 'react';
import posthog from 'posthog-js';
import { config } from '@core/config/config';

const FeatureFlagContext = React.createContext<string[]>([]);

export function FeatureFlagProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentFlags, setCurrentFlags] = React.useState<string[]>([]);

  React.useEffect(() => {
    // This is needed for dev mode, because onFeatureFlags does not provide unsubscribe method
    let mounted = true;
    if (config.posthogEnabled) {
      posthog.onFeatureFlags((flags) => {
        if (mounted) {
          setCurrentFlags(flags || []);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <FeatureFlagContext.Provider value={currentFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

interface DoesntExist { doesntExist: true }
interface Exists { exists: true }

type FeatureFlagProps = { children: React.ReactNode; name: string } & (
  | DoesntExist
  | Exists
);

export function FeatureFlag(props: FeatureFlagProps) {
  const t = React.useContext(FeatureFlagContext);
  if ('exists' in props) {
    if (t.includes(props.name)) {
      return <>{props.children}</>;
    }
  }
  if ('doesntExist' in props) {
    if (!t.includes(props.name)) {
      return <>{props.children}</>;
    }
  }
  return null;
}
