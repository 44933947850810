import { ThankYouPageConfig } from '@core/redux/siteConfig';
import { SiteView } from '@core/redux/sitesEditor';
import { defaultSiteConfig } from './siteConfigs';

const handleConfigUpdate = (
  site: SiteView,
  onUpdateSite: (site: SiteView) => void,
  rootKey: keyof SiteView['config'],
  key: keyof SiteView['config']['general'] | keyof ThankYouPageConfig,
  val: boolean | string | number
) => {
  onUpdateSite({
    ...site,
    config: {
      ...site.config,
      [rootKey]: {
        ...defaultSiteConfig[rootKey],
        ...site.config[rootKey],
        [key]: val,
      },
    },
  });
};

export const checkboxProps = (
  site: SiteView,
  onUpdateSite: (site: SiteView) => void,
  rootKey: keyof SiteView['config'],
  valueKey: keyof SiteView['config']['general'] | keyof ThankYouPageConfig,
  label: string
) => {
  const value = site.config[rootKey]
    ? site.config[rootKey]?.[valueKey]
    : defaultSiteConfig[rootKey]?.[valueKey];
  return {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleConfigUpdate(
        site,
        onUpdateSite,
        rootKey,
        valueKey,
        e.target.checked
      ),
    checked: value,
    label: label,
  };
};

export const inputProps = (
  site: SiteView,
  onUpdateSite: (site: SiteView) => void,
  rootKey: keyof SiteView['config'],
  valueKey: keyof SiteView['config']['general'] | keyof ThankYouPageConfig
) => {
  const placeholder = site.config[rootKey]?.[valueKey]
    ? site.config[rootKey]?.[valueKey]
    : defaultSiteConfig[rootKey]?.[valueKey] || '';
  return {
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      handleConfigUpdate(site, onUpdateSite, rootKey, valueKey, e.target.value),
    value: site.config[rootKey]?.[valueKey] || '',
    placeholder,
  };
};
