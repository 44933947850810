import { Box } from 'grommet';
import * as React from 'react';
import ErrorIndicator from './ErrorIndicator';
import LoadingIndicator from './LoadingIndicator';

interface Props {
  isError?: boolean;
  error: Error | null;
  isLoading: boolean;
  refetch(): void;
}

export default function QueryStatusIndicator(props: Props) {
  if (!props.isLoading && !props.isError) return null;
  return (
    <Box flex fill align="center" justify="center" gap="small">
      <ErrorIndicator {...props} />
      <LoadingIndicator loading={props.isLoading} size="large" />
    </Box>
  );
}
