import OwnNav from '@core/components/organisms/OwnNav';
import { Box, Heading } from 'grommet';
import * as React from 'react';
import { useSiteFromParams } from './EditSitePage';
import { WithGameSidebar } from '../../../../molecules/WithGameSidebar';
import { Redirect } from 'react-router';
import { SiteView } from '@core/redux/sitesEditor';
import { useDispatch } from 'react-redux';
import SitesEditorAction from '@core/redux/sitesEditor/actions';
import EditContactsThankYouSettings from '../../../../organisms/EditContactsThankYouSettings';
import EditContactsBasicSettings from '../../../../organisms/EditContactsBasicSettings';
import EditContactsContactSection from '../../../../organisms/EditContactsContactSection';
import FloatingSaveButton from '@core/components/molecules/FloatingSaveButton';

export interface SubsectionProps {
  site: SiteView;
  onUpdateSite(site: SiteView): void;
}

export default function EditContacts() {
  const [site, setSite, hasChanged] = useSiteFromParams();
  const dispatch = useDispatch();
  if (!site || !site.config) return <Redirect to="/edit/sites" />;

  const handleSave = () => {
    dispatch(SitesEditorAction.updateConfig(site));
  };

  return (
    <>
      <OwnNav />
      <WithGameSidebar>
        <Heading level={2}>Contacts</Heading>
        <EditContactsContactSection site={site} />
        <Box gap="small" direction="column" pad={{ bottom: 'xlarge' }}>
          <Heading level={2}>Settings</Heading>
          <EditContactsBasicSettings site={site} onUpdateSite={setSite} />
          <EditContactsThankYouSettings site={site} onUpdateSite={setSite} />
        </Box>
      </WithGameSidebar>
      <FloatingSaveButton hasChanged={hasChanged} onSavePress={handleSave} />
    </>
  );
}
