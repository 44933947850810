import { StandardResponse } from '@core/types';

export enum EntitiesActionTypes {
  UPDATE = 'Entities/Update',
  ADD_ID = 'Entities/ADD_ID',
}

export type UpdateIndexAction = ReturnType<typeof entitiesUpdateAction>;
export type AddIdToIndex = ReturnType<typeof entitiesAddIdToIndex>;

export function entitiesUpdateAction(
  entities: StandardResponse<object | undefined>,
  indexName?: string
) {
  return {
    type: EntitiesActionTypes.UPDATE as EntitiesActionTypes.UPDATE,
    payload: {
      indexName,
      entities,
    },
  };
}

export function entitiesAddIdToIndex(
  id: string,
  indexName: string,
  entityName: string,
  type: 'prepend' | 'append' | undefined = 'append'
) {
  return {
    type: EntitiesActionTypes.ADD_ID as EntitiesActionTypes.ADD_ID,
    payload: {
      indexName,
      id,
      entityName,
      type,
    },
  };
}
