import { platformsMap } from "@core/config/platforms";
import { Box, BoxExtendedProps, Text } from "grommet";
import * as React from "react";
import DoubleIconHOC from "./icons/DoubleIconHOC";

interface Props extends BoxExtendedProps {
  opacity?: number;
  platform: string;
  extraIcon?: React.ReactNode;
  onClick?(): void;
}

const defaultStyles = {
  boxShadow: "none",
};

export default function PlatformTag({
  platform,
  onClick,
  opacity,
  style,
  extraIcon,
  ...boxProps
}: Props) {
  const platformData = platformsMap[platform];
  const Icon = platformData.icon;

  const IconRender = extraIcon
    ? DoubleIconHOC(extraIcon, <Icon size="xsmall" />)
    : Icon;

  const finalStyles =
    typeof style === "object"
      ? {
          ...defaultStyles,
          ...style,
        }
      : defaultStyles;

  return (
    <Box
      background={{
        color: platform,
        opacity: opacity,
      }}
      round="small"
      pad={{ vertical: "2px", horizontal: "small" }}
      hoverIndicator={{ opacity: 1, background: platform }}
      onClick={onClick}
      direction="row"
      align="center"
      gap="xsmall"
      style={finalStyles}
      {...boxProps}
    >
      <IconRender size="xsmall" />
      <Text size="xsmall">{platformsMap[platform].name}</Text>
    </Box>
  );
}
