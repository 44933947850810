import QueryStatusIndicator from "@core/components/atoms/QueryStatusIndicator";
import PostBrowserItem from "@core/components/molecules/PostBrowserItem";
import Comment from "@core/components/molecules/Comment";
import {
  useApiCommentsModerationQueue,
  useApiPostsModerationQueue,
} from "@core/hooks/requests";
import logger from "@server/lib/utils/logger";
import { Box, Button, Heading } from "grommet";
import * as React from "react";
import MessageBox from "../atoms/MessageBox";

export default function ModerationQueue({ siteId }: { siteId: number }) {
  const posts = useApiPostsModerationQueue(String(siteId));
  const comments = useApiCommentsModerationQueue(String(siteId));
  const handleReload = () => {
    posts
      .refetch()
      .catch((err) =>
        logger.error("Trouble fetching moderation queue for posts", err)
      );
    comments
      .refetch()
      .catch((err) =>
        logger.error("Trouble fetching moderation queue for posts", err)
      );
  };
  return (
    <Box pad={{ horizontal: "small" }} gap="medium">
      <QueryStatusIndicator {...posts} />
      <Heading margin="0" level={4}>
        Posts
      </Heading>
      {posts.isSuccess && !posts.data.length && (
        <MessageBox>
          If you configured moderation queue in your feedback category, then
          your posts will appear here.
        </MessageBox>
      )}
      {posts.data.map((post) => (
        <PostBrowserItem key={post.id} post={post} />
      ))}
      <Heading level={4} margin="0">
        Comments
      </Heading>
      <QueryStatusIndicator {...comments} />
      <MessageBox>
        If you configured moderation queue in your feedback category, then your
        comments will appear here.
      </MessageBox>
      <Box gap="small">
        {comments.data.map((comment) => (
          <Comment {...comment} key={comment.id} />
        ))}
      </Box>
      <Button label="Reload" onClick={handleReload} />
    </Box>
  );
}
