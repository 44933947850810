import {
  Button as GrommetButton,
  TextArea,
  Box,
  Text,
  BoxExtendedProps,
  ButtonExtendedProps,
} from "grommet";
import * as React from "react";
import * as Icons from "grommet-icons";
import styled from "styled-components";
import useColor from "@core/hooks/useColor";

const Button = styled(GrommetButton)`
  height: 32px;
  padding: 0 6px;
  margin: 4px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  &:hover {
    background: #ddd;
  }
`;

const Textarea = styled(TextArea)`
  outline: none;
  background: #fff;
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
`;

interface Props extends Omit<BoxExtendedProps, "onChange"> {
  value: string;
  disabled?: boolean;
  onChange(value: string): void;
}

const ToolbarButton = (props: ButtonExtendedProps) => {
  const background = useColor("dark-1");
  return <Button style={{ background }} {...props}></Button>;
};

export default function MarkdownEditor({
  value,
  onChange,
  disabled,
  ...boxProps
}: Props) {
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

  const wrapSelection = (symbol: string) => {
    const start = textareaRef.current?.selectionStart;
    const end = textareaRef.current?.selectionEnd;

    const textToBold = start === end ? "text" : value.slice(start, end);

    const newValue =
      value.slice(0, start) + symbol + textToBold + symbol + value.slice(end);
    onChange(newValue);
  };

  const handleBold = () => {
    wrapSelection("**");
  };

  const handleItalic = () => {
    wrapSelection("_");
  };

  const handleLink = () => {
    onChange(value + "\n\n[Text To Display](Url)\n");
  };

  const handleUl = () => {
    onChange(value + "\n\n - first item \n - second item\n - third item\n");
  };
  const handleOl = () => {
    onChange(value + "\n\n 1. first item \n 2. second item\n 3. third item\n");
  };

  const textareaBackground = useColor("dark-1");
  const textareaColor = useColor("light-1");

  return (
    <Box flex {...boxProps}>
      <Box direction="row" pad="xsmall" background="dark-2">
        <ToolbarButton onClick={handleBold} disabled={disabled}>
          <Text weight="bold" size="small" color="light-1">
            Bold
          </Text>
        </ToolbarButton>
        <ToolbarButton disabled={disabled}>
          <Text
            size="small"
            style={{ fontStyle: "italic" }}
            onClick={handleItalic}
            color="light-1"
          >
            Italic
          </Text>
        </ToolbarButton>
        <ToolbarButton onClick={handleUl} disabled={disabled}>
          <Icons.List color="light-1" size="small" />
        </ToolbarButton>
        <ToolbarButton onClick={handleOl} disabled={disabled}>
          <Icons.OrderedList color="light-1" size="small" />
        </ToolbarButton>
        <ToolbarButton disabled={disabled}>
          <Icons.Video color="light-1" size="small" />
          &nbsp;
          <Icons.Image color="light-1" size="small" />
        </ToolbarButton>
        <ToolbarButton onClick={handleLink} disabled={disabled}>
          <Text color="light-1" size="small">
            Link
          </Text>
          &nbsp;
          <Icons.Link color="light-1" size="small" />
        </ToolbarButton>
      </Box>
      <Textarea
        disabled={disabled}
        ref={textareaRef}
        size="small"
        style={{
          outline: "none",
          width: "100%",
          maxWidth: "100%",
          minWidth: "100%",
          minHeight: 200,
          borderRadius: "0px",
          borderWidth: 0,
          flexGrow: 1,
          color: textareaColor,
          backgroundColor: textareaBackground,
        }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Your content..."
      />
    </Box>
  );
}
