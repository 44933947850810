import * as React from "react";
import {
  Text,
  Heading,
  Paragraph,
  Markdown,
  MarkdownProps,
  Box,
  Anchor,
} from "grommet";
import styled from "styled-components";

const ListItem = styled(Text)`
  position: relative;
  &:before {
    position: absolute;
    right: 100%;
    top: 5px;
    transform: translateX(-2px);
    content: "";
    border: 5px solid rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
`;

export default function CondensedMarkdown({
  content,
  size,
  options,
}: {
  content: string;
  size?: string;
  options?: MarkdownProps["options"];
}) {
  return (
    <Markdown
      options={options}
      components={{
        h1: (props: object) => (
          <Heading
            size={size}
            margin={{ vertical: "xsmall" }}
            level={1}
            style={{ maxWidth: "none" }}
            {...props}
          />
        ),
        h2: (props: object) => (
          <Heading
            size={size}
            margin={{ vertical: "xsmall" }}
            level={2}
            {...props}
          />
        ),
        h3: (props: object) => (
          <Heading
            size={size}
            margin={{ vertical: "xsmall" }}
            level={3}
            {...props}
          />
        ),
        h4: (props: object) => (
          <Heading
            size={size}
            margin={{ vertical: "xsmall" }}
            level={4}
            {...props}
          />
        ),
        h5: (props: object) => (
          <Heading
            size={size}
            margin={{ vertical: "xsmall" }}
            level={5}
            {...props}
          />
        ),
        h6: (props: object) => (
          <Heading
            size={size}
            margin={{ vertical: "xsmall" }}
            level={6}
            {...props}
          />
        ),
        ul: (props: object) => (
          <Box as="ul" margin="0px" pad={{ left: "small" }} {...props}></Box>
        ),
        li: (props: object) => <ListItem size={size} {...props}></ListItem>,
        p: (props: object) => (
          <Paragraph
            size={size}
            margin={{ vertical: "xsmall" }}
            {...props}
            // @ts-expect-error, don't limit paragraph width
            style={{ ...(props.style || {}), maxWidth: "100%" }}
          />
        ),
        a: (props: object) => <Anchor size={size} {...props} />,
        span: (props: object) => <Text size={size} {...props} />,
      }}
      children={content}
    />
  );
}
