import * as React from 'react';
import { CardBody, Box, TextArea, Button } from 'grommet';
import * as Icons from 'grommet-icons';
import useColor from '@core/hooks/useColor';
import CondensedMarkdown from './CondensedMarkdown';
import useThankYouPageConfig from '@core/hooks/useThankYouPageConfig';
import { defaultSiteConfig } from '@core/common/siteConfigs';
import { useApiUpdateContact } from '@core/hooks/requests';
import useSessionContact from '@core/hooks/useSessionContact';
import { selectCurrentSiteId } from '@core/redux/siteConfig';
import { useAppSelector } from '@core/hooks/requestUtils';
import toast from 'react-hot-toast';

export default function BasicSocialContent() {
  const siteId = useAppSelector(selectCurrentSiteId);
  const thankYouConfig = useThankYouPageConfig();
  const textareaBackground = useColor('dark-3');
  const askForUserDetails = thankYouConfig.enableAskForUserDetails;
  const enableVirality = thankYouConfig.enableActions;
  const [contact, setContact] = useSessionContact();
  const [about, setAbout] = React.useState(contact?.about || '');

  const gotContactReward = contact?.completed_viral_actions?.some(
    (ac) => ac.actionType === 'basic_info_filled'
  );

  React.useEffect(() => {
    setAbout(contact?.about || '');
  }, [contact]);

  const updateMutation = useApiUpdateContact({
    about,
    contactId: contact?.id || '',
    siteId,
    onSuccess: (data) => {
      const firstId = data.result.contact?.[0];
      const firstElm = data.entities.contact?.[firstId || -1];
      if (firstElm) {
        // It means it just got a reward
        // TODO: Only check if
        if (!gotContactReward) {
          toast.success('Nice! Awarded 3 Entries!');
        } else {
          toast.success('Updated. Thanks!');
        }
        setContact(firstElm);
      }
    },
    onError: () => {
      toast.error('Problem updating the user :(. Please try again.');
    },
  });
  return (
    <CardBody pad="medium" background="dark-2">
      <CondensedMarkdown
        content={
          thankYouConfig.successText ||
          defaultSiteConfig.thankYouPage?.successText ||
          ''
        }
      />
      {askForUserDetails && (
        <>
          <TextArea
            autoFocus
            resize="vertical"
            size="small"
            style={{
              minHeight: '132px',
              background: textareaBackground,
            }}
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
          <Box
            margin={{ top: 'small' }}
            direction="row"
            justify="end"
            align="center"
            fill
          >
            <Button
              size="small"
              icon={
                enableVirality ? (
                  <Box direction="row" align="center">
                    {gotContactReward ? (
                      <Icons.Validate size="small" color="status-ok" />
                    ) : (
                      <Icons.New size="small" color="dark-4" />
                    )}
                    &nbsp;&nbsp;{thankYouConfig.contactWorthPoints}
                  </Box>
                ) : undefined
              }
              onClick={() => updateMutation.mutate()}
              label={contact?.about ? 'Update' : 'Save'}
              color="light-4"
              hoverIndicator={{
                color: 'light-1',
              }}
              primary
            />
          </Box>
        </>
      )}
    </CardBody>
  );
}
