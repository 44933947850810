import OwnNav from "@core/components/organisms/OwnNav";
import PageContent from "@core/components/atoms/PageContent";
import { accountsPassword } from "@core/redux/accounts/client";
import { UserContext } from "@core/components/blind/contexts/UserContext";
import {
  Box,
  Button,
  Form,
  FormExtendedEvent,
  FormField,
  Heading,
  Text,
  TextInput,
} from "grommet";
import * as React from "react";
import { Redirect } from "react-router";
import logger from "@server/lib/utils/logger";

export default function Signup() {
  const [error, setError] = React.useState<null | string>(null);
  const [passwordSuccess, setPasswordSuccess] = React.useState<boolean>(false);
  const { user, isLoggedIn } = React.useContext(UserContext) || {};
  const handleFormSubmit = (
    e: FormExtendedEvent<{
      old_password: string;
      confirm_password: string;
      password: string;
    }>
  ) => {
    const { confirm_password, password, old_password } = e.value;
    if (password !== confirm_password) {
      setError("Passwords do not match");
      return;
    }
    accountsPassword
      .changePassword(old_password, password)
      .then((_createdUser) => {
        setError(null);
        setPasswordSuccess(true);
        setTimeout(() => {
          setPasswordSuccess(false);
        }, 2000);
      })
      .catch((failed) => {
        setError(failed.message);
      });
  };

  const handleVerifyEmail = () => {
    if (user?.emails && user.emails[0]) {
      accountsPassword
        .requestVerificationEmail(user.emails[0].address)
        .then(() => alert("Reset sent."))
        .catch((err) =>
          logger.error("Problem requesting verificcation email", err)
        );
    }
  };

  if (!user) return <Redirect to="/" />;

  const email1 = (user.emails && user.emails[0]) || {
    verified: false,
    address: "",
  };

  const isVerified = email1.verified;

  return (
    <>
      {!isLoggedIn && <Redirect to="/" />}
      <OwnNav />
      <PageContent pad="medium">
        <Heading level="3">General</Heading>
        <Box pad="medium">
          {!isVerified && (
            <Button
              onClick={handleVerifyEmail}
              primary
              label="Re-send Email Verification"
            />
          )}
        </Box>
        <Heading level="3">Change Password</Heading>
        {passwordSuccess && (
          <Text color="status-ok">Password succesfully changed.</Text>
        )}
        <Form onSubmit={handleFormSubmit}>
          <FormField label="Current Password" error={error}>
            <TextInput name="old_password" type="password" />
          </FormField>
          <FormField label="New Password">
            <TextInput name="password" type="password" />
          </FormField>
          <FormField label="Confirm Password">
            <TextInput name="confirm_password" type="password" />
          </FormField>
          <Box pad="small">
            <Button
              type="submit"
              style={{ color: "white" }}
              size="medium"
              primary
              label="Change Password"
            />
          </Box>
        </Form>
      </PageContent>
    </>
  );
}
