import { SiteConfigContext } from "@core/components/blind/contexts/SiteConfigContext";
import { PreviewComponentScaled } from "@core/components/blind/PreviewComponent";
import Features from "@core/components/organisms/Features";
import { Heading, Box, Text } from "grommet";
import * as React from "react";
import { ChecklistItem, updateChecklist } from "../Checklist";
import Converters from "../Converters";
import PreparePost from "../games/id/PreparePost";
import { siteConfigExample } from "./StartSmall";

export default function SupportedEntireWay() {
  const [previewSiteConfig] = React.useState(siteConfigExample);
  const siteConfig = React.useContext(SiteConfigContext);
  const publeashConfig = {
    ...previewSiteConfig,
    config: siteConfig || previewSiteConfig.config,
  };
  return (
    <>
      <Box align="center" background="dark-4">
        <Heading margin={{ bottom: "none" }}>
          Save hours with powerful tools
        </Heading>
        <Box width="large" align="center" margin="medium">
          <Text size="large">
            We give you a lot of tools to aid you on your development journey.
          </Text>
        </Box>
      </Box>
      <Features
        features={[
          {
            title: "Update Management",
            description:
              "Write post once for your website, export for Discord, Twitter, Steam and more. No more bbcode, html and other nonsense.",
            imageElement: (
              <Box fill height="medium">
                <PreviewComponentScaled site={publeashConfig} height="medium">
                  <PreparePost />
                </PreviewComponentScaled>
              </Box>
            ),
          },
          {
            title: "Check lists",
            description:
              "Useful checklists for every step of development. From first game announcements, regular releases to full marketing launch",
            imageElement: (
              <Box fill height="medium">
                <PreviewComponentScaled site={publeashConfig} height="medium">
                  <Box width="xlarge">
                    {updateChecklist.map((item, i) => (
                      <ChecklistItem
                        id={`s_${i}`}
                        depth={1}
                        key={item.title}
                        item={item}
                      />
                    ))}
                  </Box>
                </PreviewComponentScaled>
              </Box>
            ),
          },
          {
            title: "Media Converters",
            description:
              "Easily convert assets for specific platforms. Create perfectly sized gifs for steam store page, optimised video for discord, mp4 for web and more.",
            imageElement: (
              <Box fill height="medium">
                <PreviewComponentScaled site={publeashConfig} height="medium">
                  <Converters />
                </PreviewComponentScaled>
              </Box>
            ),
          },
        ]}
      />
    </>
  );
}
