import * as React from 'react';
import * as Icons from 'grommet-icons';
import styled from 'styled-components';
import { loading as loadingAnimation } from '@core/common/animations';
import { Box } from 'grommet';

const LoadingContainer = styled(Box)`
  animation: ${loadingAnimation} 2000ms ease-in-out 0ms infinite;
`;

export default function LoadingIndicator({
  loading,
  size = 'medium',
}: {
  loading: boolean;
  size: 'medium' | 'small' | 'large';
}) {
  if (!loading) return null;
  return (
    <Box justify="center" align="center" pad="large">
      <LoadingContainer>
        {size === 'large' && <Icons.Apps />}
        {size === 'medium' && <Icons.Drag />}
        {size === 'small' && <Icons.More />}
      </LoadingContainer>
    </Box>
  );
}
