import { selectCurrentSiteId } from "@core/redux/siteConfig";
import { useApiCategories, useApiCategoryPosts } from "@core/hooks/requests";
import { useAppSelector } from "@core/hooks/requestUtils";
import { Box, Heading, Menu, TextInput } from "grommet";
import * as React from "react";
import * as Icons from "grommet-icons";
import { useHistory } from "react-router";
import { PostsSortFields, SortOrder } from "@core/types";
import LoadingIndicator from "../../../atoms/LoadingIndicator";
import PostBrowserItem from "../../../molecules/PostBrowserItem";
import useRound from "@core/hooks/useRound";
import MessageBox from "@core/components/atoms/MessageBox";

export default function PostBrowser({
  categoryId,
  onCategoryChange,
}: {
  categoryId?: string;
  onCategoryChange(id?: string): void;
}) {
  const siteId = useAppSelector(selectCurrentSiteId);
  const categories = useApiCategories(siteId);
  const [sortData, setSortData] = React.useState({
    sortBy: PostsSortFields.Date,
    sortOrder: SortOrder.Descending,
  });
  const posts = useApiCategoryPosts({
    siteId,
    categoryId,
    sortBy: sortData.sortBy,
    sortOrder: sortData.sortOrder,
  });
  const history = useHistory();

  const handleShowTop = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSortData({
      sortBy: PostsSortFields.Rating,
      sortOrder: SortOrder.Descending,
    });
  };

  const handleShowNew = () => {
    setSortData({
      sortBy: PostsSortFields.Date,
      sortOrder: SortOrder.Descending,
    });
  };

  const handleShowOld = () => {
    setSortData({
      sortBy: PostsSortFields.Date,
      sortOrder: SortOrder.Ascending,
    });
  };

  const category = useAppSelector(
    (state) => state.entities.voteCategory[categoryId || ""]
  );

  const roundSmall = useRound("small");
  const roundLarge = useRound("large");

  return (
    <Box
      background="dark-2"
      margin={{ vertical: "medium", bottom: "large" }}
      pad="small"
      round={roundSmall}
      elevation="large"
      border={{
        size: "1px",
        side: "top",
        style: "solid",
        color: "dark-1",
      }}
    >
      <Box direction="row-responsive" align="center">
        <Heading
          margin="small"
          level={3}
          style={{ flex: 1, maxWidth: "9999px" }}
        >
          All Posts
        </Heading>
        <Box
          round={roundLarge}
          background="dark-1"
          elevation="xsmall"
          margin="xsmall"
        >
          <TextInput
            size="small"
            style={{ border: "none", borderRadius: "24px" }}
            placeholder="Search..."
            icon={<Icons.Search color="light-1" />}
          />
        </Box>
        <Menu
          dropProps={{
            background: "transparent",
            round: "medium",
          }}
          label={category?.title || "Category"}
          items={categories.data
            .map((cat) => ({
              label: cat.title,
              onClick: () => onCategoryChange(cat.id),
            }))
            .concat([
              {
                label: "All posts",
                onClick: () => onCategoryChange(undefined),
              },
            ])}
        />
        <Menu
          label={`Sort By ${
            sortData.sortBy === PostsSortFields.Date ? "Date" : "Rating"
          }`}
          dropProps={{
            background: "transparent",
            round: "medium",
          }}
          items={[
            { label: "Best first", onClick: handleShowTop },
            { label: "Newest first", onClick: handleShowNew },
            { label: "Oldest first", onClick: handleShowOld },
          ]}
        />
      </Box>
      <LoadingIndicator size="large" loading={posts.isLoading} />
      {posts.data.map((post) => (
        <PostBrowserItem
          post={post}
          onClick={() => history.push(`/feedback/post/${post.id}`)}
          key={post.id}
        />
      ))}
      {!posts.isLoading && posts.data.length === 0 && (
        <MessageBox alignSelf="start" status="unknown" margin="small">
          No posts yet. Want to create one?
        </MessageBox>
      )}
    </Box>
  );
}
