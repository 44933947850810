import { SiteConfigContext } from '@core/components/blind/contexts/SiteConfigContext';
import * as React from 'react';

export function getRadiusPx(radius?: number, size?: string) {
  const multiplier = getMultiplier(size);
  return String((radius || 0) * multiplier) + 'px';
}

export default function useRound(size?: string) {
  const siteConfig = React.useContext(SiteConfigContext);
  return getRadiusPx(siteConfig?.theme.buttonRadius, size);
}
function getMultiplier(size: string | undefined) {
  switch (size) {
    case 'small':
      return 2;
    case 'large':
      return 8;
    default:
    case 'medium':
      return 4;
  }
}
