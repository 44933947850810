import SiteConfigProvider from '@core/components/blind/contexts/SiteConfigProvider';
import SiteConfigTheme from '@core/components/blind/contexts/SiteConfigThemeProvider';
import { SiteView } from '@core/redux/sitesEditor';
import { Box, BoxExtendedProps, Grommet, Layer } from 'grommet';
import * as React from 'react';
import ClientLandingPage from '../pages/client/ClientLandingPage';

interface Props {
  site: SiteView;
  children?: React.ReactNode;
}

export default function PreviewComponent({ site, children }: Props) {
  return (
    <SiteConfigProvider forceSiteConfig={site.config}>
      <SiteConfigTheme forceSiteConfig={site}>
        {(theme) => (
          <Grommet full={children ? false : true} theme={theme}>
            {children ? children : <ClientLandingPage />}
          </Grommet>
        )}
      </SiteConfigTheme>
    </SiteConfigProvider>
  );
}

const PreviewComponentWithRef = React.forwardRef<HTMLDivElement, Props>(
  ({ site, children }, ref) => (
    <Box ref={ref}>
      <PreviewComponent site={site}>{children}</PreviewComponent>
    </Box>
  )
);

export function PreviewComponentScaled({
  site,
  children,
  ...rest
}: Props & BoxExtendedProps) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const layerRef = React.useRef<HTMLDivElement | null>(null);
  const componentRef = React.useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = React.useState({
    top: 0,
    left: 0,
    scale: 1,
  });
  const handleSizing = () => {
    if (ref.current && componentRef.current && position.scale === 1) {
      const data = ref.current.getBoundingClientRect();
      const componentData = componentRef.current.getBoundingClientRect();
      const { width, height } = data;
      const fullWidth = componentData.width; // window.innerWidth;
      const fullHeight = componentData.height; // window.innerHeight;
      const scale = Math.min(height / fullHeight, width / fullWidth);

      setPosition({
        top: window.scrollY + data.y + height / 2,
        left: data.x + width / 2,
        scale,
      });
    }
  };
  React.useEffect(handleSizing, []);

  React.useEffect(() => {
    function handleResize() {
      setPosition({ top: 0, left: 0, scale: 1 });
      handleSizing();
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    setImmediate(handleSizing);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [position]); // Empty array ensures that effect is only run on mount

  React.useEffect(() => {
    if (layerRef.current?.parentElement) {
      layerRef.current.parentElement.style.position = 'absolute';
    }
  });

  return (
    <>
      <Box {...rest} ref={ref} overflow="hidden" />
      <Layer
        plain
        full
        modal={false}
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          opacity: rest.style?.opacity,
        }}
        animate={false}
        ref={layerRef}
      >
        <Box
          overflow="hidden"
          border={{
            style: 'dotted',
            color: 'brand-1',
            size: 'small',
          }}
          style={{
            opacity: position.scale === 1 ? 0 : 1,
            pointerEvents: 'none',
            transformOrigin: '0 0 ',
            transform: `scale(${position.scale}) translate(-50%, -50%)`,
            position: 'absolute',
            top: position.top,
            left: position.left,
          }}
        >
          <PreviewComponentWithRef ref={componentRef} site={site}>
            {children}
          </PreviewComponentWithRef>
        </Box>
      </Layer>
    </>
  );
}
