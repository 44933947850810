import { Box, Text } from "grommet";
import * as React from "react";
import ContactNameText from "./ContactNameText";
import ContactAvatar from "./ContactAvatar";
import { VotePostCommentView } from "@core/types";

export default function Comment(c: VotePostCommentView) {
  return (
    <Box
      background="dark-1"
      direction="row"
      pad="small"
      round="small"
      key={c.id}
      align="start"
      gap="small"
    >
      <ContactAvatar contactId={c.contactId} flex={{ shrink: 0 }} />
      <Box direction="column">
        <Box direction="row" align="center" gap="small" justify="start">
          <ContactNameText contactId={c.contactId} size="small" weight="bold" />
          <Text size="xsmall">
            {new Date(c.createdAt).toLocaleDateString()}
          </Text>
        </Box>
        <Text size="small">{c.text}</Text>
      </Box>
    </Box>
  );
}
