import * as React from "react";
import {
  Box,
  Text
} from "grommet";
import * as Icons from "grommet-icons";

export default function DiscordMessage() {
  return (
    <Box
      overflow="hidden"
      direction="row"
      flex={{ shrink: 0 }}
      background={"rgba(55,57,63, 1)"}
      border={{
        side: "bottom",
        size: "xsmall",
        color: "rgba(79, 84, 92, 0.48)",
      }}
    >
      <Box
        width="32px"
        height="100%"
        justify="center"
        background={"rgba(75,77,83, 1)"}
        hoverIndicator={{ background: "light-4" }}
        align="center"
      >
        <Icons.Add size="small" />
        <Text
          margin={{ top: "small" }}
          size="small"
          style={{ transform: "rotate(90deg)" }}
        >
          Add
        </Text>
      </Box>
      <Box fill direction="row" pad={{ bottom: "xsmall" }}>
        <Box width="74px" align="end" pad="xsmall" style={{ marginTop: "2px" }}>
          <Text size="xsmall">2:14 pm</Text>
        </Box>
        <Box direction="column">
          <Box pad="xsmall">
            <Text size="small" color="rgb(46, 204, 113)">
              meznaricnet
            </Text>
            <Text size="small" color="rgb(220, 221, 222)">
              New procedural level generation!
            </Text>
          </Box>
          <Box>
            <img
              style={{ minHeight: 60, objectFit: "contain" }}
              src="https://place-hold.it/500x200" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
