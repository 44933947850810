import dotenv from 'dotenv';

// @ts-expect-error this is set by webpack
const isBrowser = process && process.browser;

if (!isBrowser) {
  // dotenv cannot require files in browser, we sort out configs in webpack client config
  const dotenvInit = dotenv.config();
  if (dotenvInit.error) {
    console.error('Problem initializing dotenv .env', dotenvInit.error);
  }
}

export interface AppConfig {
  env: string;
  isDev: boolean;
  apiUrl: string;
  baseUrl: string;
  useRender: boolean;
  isBrowser: boolean;
  adminDomainHost: string;
  prerenderCss: boolean;
  productName: string;
  posthogKey: string;
  posthogHost: string;
  posthogEnabled: boolean;
  sentryEnabled: boolean;
  sentryDSN: string;
  cdnHost: string;
  discord: {
    clientId: string;
    clientSecret?: string;
    OAuthUrl: string;
    redirectURL: string;
    botRequestedPermissions: string;
  };
}

const env = process.env.NODE_ENV || 'development';
const isDev = env !== 'production';

// NOTE: Only shared configurations can be in here!
export const config: AppConfig = {
  env,
  productName: 'Publeash',
  isBrowser,
  isDev,
  useRender: Boolean(isBrowser && localStorage.getItem('useRender')),
  cdnHost:
    process.env.NODE_ENV !== 'production'
      ? `http://localhost:9000/publeash/`
      : 'https://ams3.digitaloceanspaces.com/api-managed/',
  // App specific
  adminDomainHost: process.env.URL_ADMIN_DOMAIN || '',
  apiUrl: process.env.URL_API || '',
  baseUrl: process.env.URL_BASE || '',
  prerenderCss: true,
  // Posthog
  posthogKey: 'phc_hmG1wTMsn5HJpYQQGAuqQkngbAHSj7jdVfq3Vshhfob',
  posthogHost: 'https://posthog.k8s.publeash.games',
  posthogEnabled:
    isBrowser && !JSON.parse(process.env.POSTHOG_DISABLED || '"false"'),
  sentryEnabled: !JSON.parse(process.env.SENTRY_DISABLED || '"false"'),
  sentryDSN:
    'https://c152509d5cc448eaba18c9a7de3d4015@o1131080.ingest.sentry.io/6175206',
  discord: {
    clientId: process.env.DISCORD_CLIENT || '',
    clientSecret: process.env.DISCORD_SECRET || '',
    OAuthUrl: 'https://discord.com/api/oauth2/authorize',
    redirectURL: `${process.env.URL_BASE}/oauth-callback/discord`,
    botRequestedPermissions: '34359806977',
  },
};
