import React from 'react';
import { Nav, Box, Sidebar, Button } from 'grommet';

export default function AdminPage() {
  // const theme = useTheme();
  return (
    <Box fill>
      <Nav>Top nav</Nav>
      <Box direction="row" fill>
        <Sidebar background="brand" width="sidebar">
          Oj
        </Sidebar>
        <Box>
          Yo
          <Button size="large" primary plain={false}>
            Yo!
          </Button>
          <Button size="medium" secondary>
            {' '}
            Don't kill me.{' '}
          </Button>
          <Button primary>Don't kill me. </Button>
        </Box>
      </Box>
    </Box>
  );
}
