import * as React from 'react';
import {
  Layer,
  Box,
  FormField,
  TextInput,
  Button,
  Heading,
  Spinner,
  Paragraph,
  Text,
  CheckBox,
  Image,
  Grid
} from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import SteamImporterAction from '../../redux/steamImporter/actions';
import { AppState } from '@core/redux/reducers';
import * as Icons from 'grommet-icons';
import { useHistory } from 'react-router';

interface Props {
  onClose(): void;
}

export default function SteamImporterModal({ onClose }: Props) {
  const dispatch = useDispatch();
  const [steamUrl, setSteamUrl] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedTheme, setSelectedTheme] = React.useState(0);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = React.useState(0);
  const [hoverTheme, setHoverTheme] = React.useState(0);
  const [mode, setMode] = React.useState<'dark' | 'light'>('dark');
  const history = useHistory();

  const themes = useSelector((state: AppState) => state.steamImporter.themes);
  const screenShots = useSelector((state: AppState) => state.steamImporter.screenShots);

  const siteId = useSelector((state: AppState) => state.steamImporter.siteId);

  const siteConfig = useSelector((state: AppState) => state.sitesEditor.sites.find(site => Number(site.id) === siteId));

  const handleImportPress = () => {
    setIsLoading(true);
    dispatch(SteamImporterAction.importFromSteamUrl(steamUrl));
  };

  const handleConfirm = () => {
    if (!siteConfig) return;
    dispatch(
      SteamImporterAction.confirmSteamImport({
        ...siteConfig,
        config: {
          ...siteConfig.config,
          general: {
            ...siteConfig.config.general,
            landingBackgroundImage: selectedBackgroundImage === -1 ? '' : screenShots[selectedBackgroundImage]
          },
          theme: {
            ...siteConfig.config.theme,
            mode,
            ...themes[selectedTheme]
          }
        }
      })
    );
    setIsLoading(true);
    setTimeout(() => {
      history.push(`/edit/${siteId}/editor`);
    }, 1000);
  };

  React.useEffect(() => {
    if (siteId) {
      setIsLoading(false);
    }
  }, [siteId]);

  const importSucceeded = !!siteConfig;

  const successLine = (label: string, value: string | undefined) => {
    if (!value) return null;
    return (
      <Box direction="row" gap="small" align="center" margin="xsmall" overflow={{ horizontal: 'hidden' }}>
        <Icons.Validate color="status-ok" />
        <Box flex="grow">
          <Text size="small" style={{ whiteSpace: 'nowrap' }}>
            {label}
          </Text>
        </Box>
        {value !== ' ' && (
          <Box flex="shrink" overflow={{ horizontal: 'scroll' }}>
            <Text weight="bold" size="small" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value}
            </Text>
          </Box>
        )}
      </Box>
    );
  };

  const goodUrl = steamUrl.match(/(https?:\/\/)?store.steampowered.com\/app\/\d*\/?.*/) !== null;

  return (
    <Layer onEsc={onClose} onClickOutside={onClose} margin="medium">
      <Box background="dark-1" pad="medium" overflow={{ vertical: 'scroll' }}>
        {isLoading && <Spinner />}
        {!isLoading && !importSucceeded && (
          <Box flex="grow">
            <Heading level={2}>Import from Steam</Heading>
            <FormField label="Steam Game Page URL">
              <TextInput value={steamUrl} onChange={e => setSteamUrl(e.target.value)} />
            </FormField>
            <Text size="small">eg. https://store.steampowered.com/app/123/GameName</Text>
            <Button primary={goodUrl} disabled={!goodUrl} label="Import" onClick={handleImportPress} />
          </Box>
        )}
        {importSucceeded && (
          <Box>
            <Box flex="grow" direction="row" justify="start" gap="medium">
              <Box width="medium" justify="start">
                <Box direction="row" align="center" justify="between">
                  <Heading level={3} margin={{ bottom: 'small', top: 'none' }}>
                    Success!
                  </Heading>
                  <Icons.DownloadOption color="status-ok" />
                </Box>
                {successLine('Game Name', siteConfig.config.general.gameName)}
                {successLine('Title', (siteConfig.config.general.title || '').slice(0, 24) + '...')}
                {successLine('Subtitle', siteConfig.config.general.subTitle)}
                {successLine('Sharing Image', ' ')}
                {successLine('Sharing Description', ' ')}
                {successLine('Example Themes Generated', ' ')}
                {successLine('Twitch URL imported', siteConfig.config.general.twitchURL)}
                {successLine('YouTube URL imported', siteConfig.config.general.youtubeChannelURL)}
                {successLine('Steam APP ID Found', siteConfig.config.general.steamAppId)}
                {successLine('Twitter URL imported', siteConfig.config.general.twitterURL)}
                <Heading level={4}>Select Background Image</Heading>
                <Grid columns={['small', 'small']} gap="small">
                  <Box
                    align="center"
                    justify="center"
                    border={{
                      side: 'all',
                      size: 'large',
                      color: selectedBackgroundImage === -1 ? 'brand-1' : 'dark-4'
                    }}
                    hoverIndicator
                    onClick={() => setSelectedBackgroundImage(-1)}>
                    None
                  </Box>
                  {importSucceeded &&
                    screenShots &&
                    screenShots.map((v, i) => {
                      return (
                        <Box
                          key={i}
                          style={{ transform: hoverTheme === i ? 'scale(1.05)' : '' }}
                          border={{
                            side: 'all',
                            size: 'large',
                            color: selectedBackgroundImage === i ? 'brand-1' : 'dark-4'
                          }}
                          round="small"
                          onMouseEnter={() => setHoverTheme(i)}
                          hoverIndicator
                          onClick={() => setSelectedBackgroundImage(i)}>
                          <Image src={v} />
                        </Box>
                      );
                    })}
                </Grid>
              </Box>

              <Box width="medium">
                {themes.length > 0 ? (
                  <Box>
                    <Heading level={4}>Select a theme</Heading>
                    <Text>These themes were generated from your Steam screenshots.</Text>
                    <Box margin="small">
                      <CheckBox
                        checked={mode === 'dark'}
                        id="toggle-enable-dark-mode"
                        onClick={e => setMode(e.currentTarget.checked ? 'dark' : 'light')}
                        label="Enable Dark Mode"
                      />
                    </Box>
                    {themes.map((theme, i) => {
                      return (
                        <Box
                          margin="small"
                          direction="row"
                          key={i}
                          round="medium"
                          background={selectedTheme === i ? 'brand-1' : 'dark-4'}
                          flex="grow"
                          hoverIndicator={selectedTheme === i ? undefined : true}
                          onMouseEnter={() => setHoverTheme(i)}
                          onClick={() => setSelectedTheme(i)}
                          pad="xsmall"
                          style={{ transform: hoverTheme === i ? 'scale(1.05)' : '' }}
                          align="center">
                          <Box margin="small">
                            <Icons.Next size="small" />
                          </Box>
                          <Box
                            round={{ corner: 'left', size: 'small' }}
                            width="xsmall"
                            height="32px"
                            background={theme.primary}
                          />
                          <Box width="xsmall" height="32px" background={theme.secondary} />
                          <Box width="xsmall" height="32px" background={theme.darkBase} />
                          <Box
                            round={{ corner: 'right', size: 'small' }}
                            width="xsmall"
                            height="32px"
                            background={theme.lightBase}
                          />
                        </Box>
                      );
                    })}
                    <Paragraph>You will be able to edit individual colors later</Paragraph>
                  </Box>
                ) : (
                  <Box margin="small" />
                )}
              </Box>
            </Box>
            <Button margin="medium" onClick={handleConfirm} primary label="Continue" />
          </Box>
        )}
      </Box>
    </Layer>
  );
}
