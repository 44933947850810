import { Avatar, AvatarExtendedProps } from "grommet";
import * as React from "react";
import * as Icons from "grommet-icons";

interface Props {
  contactId?: string;
}

export default function ContactAvatar(props: AvatarExtendedProps & Props) {
  let contactNumber = 0;
  let color = "hsl(50, 0%, 80%)";
  let colorContrast = "hsl(50, 0%, 40%)";
  if (props.contactId) {
    for (let index = 0; index < props.contactId.length; index++) {
      contactNumber += props.contactId.charCodeAt(index);
    }

    color = `hsl(${contactNumber % 350}, 66%, 90%)`;
    colorContrast = `hsl(${contactNumber % 350}, 46%, 40%)`;
  }

  return (
    <Avatar
      size="36px"
      background={color}
      border={{ color: colorContrast }}
      pad={{ top: "xsmall" }}
      {...props}
    >
      <Icons.User size={props.size || "32px"} color={colorContrast} />
    </Avatar>
  );
}
