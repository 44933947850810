import { SiteConfigContext } from '@core/components/blind/contexts/SiteConfigContext';
import ClientFooter from '@core/components/organisms/ClientFooter';
import ClientSubpageHeader from '@core/components/organisms/ClientSubpageHeader';
import useColor from '@core/hooks/useColor';
import { Box, Markdown } from 'grommet';
import React, { useContext } from 'react';

export default function PressPage() {
  const config = useContext(SiteConfigContext);
  const bgColor = useColor('dark-1');
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      body, html { 
        background: ${bgColor};
      }
      `,
        }}
      />
      <ClientSubpageHeader />
      <Box style={{ minHeight: '300px' }} align="center" background="dark-2">
        <Markdown color="#fff">{config?.general.pressMarkdown || ''}</Markdown>
      </Box>
      <ClientFooter />
    </>
  );
}
