import { Card, CardBody, CardHeader, Heading } from "grommet";
import * as React from "react";
import { Box, BoxExtendedProps } from "grommet";
import * as Icons from "grommet-icons";
import useColor from "@core/hooks/useColor";

export default function HelpCard({
  title,
  children,
  pad,
  ...boxProps
}: {
  title: string;
  children: React.ReactNode;
} & BoxExtendedProps) {
  const [isCollapse, setIsCollapsed] = React.useState(false);
  const dark1 = useColor("dark-3");
  const dark3 = useColor("dark-5");
  return (
    <Card round="small" {...boxProps}>
      <CardHeader
        background={`linear-gradient(-45deg, ${dark3} 0%, ${dark1} 100%)`}
        hoverIndicator={{
          background: `linear-gradient(-45deg, ${dark3} 0%, ${dark3} 100%)`,
        }}
        onClick={() => setIsCollapsed(!isCollapse)}
        style={{ boxShadow: "none" }}
      >
        <Heading color="light-4" level={4} margin="small">
          {title}
        </Heading>
        <Box
          margin="xsmall"
          round="large"
          pad="xsmall"
          style={{
            boxShadow: "none",
            transition: "transform 300ms ease-in-out",
            transform: isCollapse ? "rotate(90deg)" : "",
          }}
          hoverIndicator={{ background: "rgba(0,0,0,0.3)" }}
          onClick={() => setIsCollapsed(!isCollapse)}
        >
          <Icons.Down />
        </Box>
      </CardHeader>
      <CardBody
        style={{
          maxHeight: isCollapse ? "0px" : "130vh",
          transition: "all 300ms ease-out",
        }}
        overflow="hidden"
        animation="fadeIn"
        pad={!isCollapse ? pad : "0"}
      >
        {children}
      </CardBody>
    </Card>
  );
}
