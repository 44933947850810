import * as React from "react";
import styled from "styled-components";
import * as Icons from "grommet-icons";
import { Box, Heading } from "grommet";

export const BlockerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

interface Props {
  children?: React.ReactNode;
}

export function Blocker({ children }: Props) {
  const [isWipVisible, setWipVisible] = React.useState(false);
  const handleClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const hasClickedDirectly = e.target === e.currentTarget;
    if (hasClickedDirectly) {
      setWipVisible(true);
      setTimeout(() => {
        setWipVisible(false);
      }, 1500);
    }
  };
  return (
    <BlockerWrapper
      onClick={handleClick}
      style={{
        transition: "background 300ms ease-out",
        background: isWipVisible ? "rgba(0,0,0,0.8)" : "transparent",
      }}
    >
      <Box
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          opacity: isWipVisible ? 1 : 0,
          transition: "opacity 300ms linear",
          pointerEvents: isWipVisible ? "auto" : "none",
        }}
        align="center"
        justify="center"
      >
        <Heading level={3} color="dark-3">
          Work In Progress
        </Heading>
        <Icons.Lock style={{}} color="dark-4" size="xlarge" />
      </Box>
      {children}
    </BlockerWrapper>
  );
}
