import * as React from "react";
import { Button as GrommetButton, ButtonExtendedProps } from "grommet";

interface Props extends Omit<ButtonExtendedProps, "size"> {
  size: "xsmall" | "small" | "medium" | "large";
}

export default function Button({ size, ...rest }: Props) {
  return (
    <GrommetButton
      // @ts-expect-error grommet types do not with xsmall but it actually works
      size={size}
      {...rest}
    ></GrommetButton>
  );
}
