import { Menu, PageContent } from "grommet";
import * as React from "react";
import * as Icons from "grommet-icons";
import { useHistory } from "react-router";
import ButtonLink from "../pages/publeash/ButtonLink";

export default function MiniOwnNav() {
  const history = useHistory();
  const handleMenuItemNavigation = (path: string) => {
    return () => {
      history.push(path);
    };
  };

  return (
    <PageContent
      background={{ fill: "horizontal", color: "dark-1" }}
      pad="small"
      direction="row-responsive"
      gap="small"
      justify="between"
    >
      <ButtonLink
        href="/"
        icon={<Icons.CaretLeftFill />}
        label="Home"
      ></ButtonLink>
      <Menu
        label="Other Tools"
        dropProps={{
          round: "medium",
        }}
        items={[
          {
            label: "Update Management",
            onClick: handleMenuItemNavigation("/tools-preview"),
          },
          {
            label: "Checklists",
            onClick: handleMenuItemNavigation("/checklist"),
          },
          {
            label: "Grow & Engage",
            onClick: handleMenuItemNavigation("/online-presence"),
          },
        ]}
      ></Menu>
    </PageContent>
  );
}
