import { SiteConfigContext } from "@core/components/blind/contexts/SiteConfigContext";
import * as React from "react";
import useColor from "@core/hooks/useColor";
import { Box, Button, Tip } from "grommet";
import Features from "@core/components/organisms/Features";
import { selectCurrentSiteId } from "@core/redux/siteConfig";
import { AnchorLink } from "@core/components/atoms/AnchorLink";
import {
  useApiCategoryPosts,
  useApiVoteOnVotePost,
} from "@core/hooks/requests";
import { useAppSelector } from "@core/hooks/requestUtils";
import LoadingIndicator from "@core/components/atoms/LoadingIndicator";

export default function LandingVoting() {
  const site = React.useContext(SiteConfigContext);

  const siteId = useAppSelector(selectCurrentSiteId);
  const categoryId = site?.general.defaultVoteCategoryId;
  const res = useApiCategoryPosts({ siteId, categoryId });

  return (
    <>
      <LoadingIndicator size="large" loading={res.isLoading} />
      <Features
        features={
          res.data.map((post) => ({
            title: post.title,
            description: post.content,
            imageUrl: post.imageUrl,
            actions: <VoteActions votePostId={post.id} />,
          })) || []
        }
      />
      {res.isError && <span>{res.error.message}</span>}
    </>
  );
}

export function VoteActions({
  votePostId,
  hideDiscussionLink = false,
  direction = "horizontal",
  size = "medium",
}: {
  votePostId: string;
  hideDiscussionLink?: boolean;
  direction?: "vertical" | "horizontal";
  size?: "medium" | "small" | "large";
}) {
  const c = useColor("dark-4");
  const shadow = `0px 0px 2px 2px ${c}`;
  const textShadow = `0px 0.5px 3px rgba(0,0,0,0.3)`;
  const siteId = useAppSelector(selectCurrentSiteId);
  const { voteFor, voteAgainst, userVote } = useApiVoteOnVotePost(
    siteId,
    votePostId
  );
  const config = React.useContext(SiteConfigContext);
  const brandDynamic = useColor("brand-dynamic");
  if (userVote !== 0) {
    return (
      <Box
        background="dark-3"
        direction={direction === "horizontal" ? "row" : "column"}
        align="center"
        gap="small"
        margin="small"
        pad="small"
        round="medium"
        style={{ boxShadow: shadow, textAlign: "center" }}
      >
        {`${userVote === -1 ? "👎" : "👍"} Thank you for your vote!`}
        {!hideDiscussionLink && config?.general.enableFeedback && (
          <AnchorLink to={`/feedback/post/${votePostId}`}>
            View Discussion
          </AnchorLink>
        )}
      </Box>
    );
  }
  return (
    <Box
      direction={direction === "horizontal" ? "row" : "column-reverse"}
      gap="small"
      margin="small"
    >
      <Tip
        plain
        content={
          <Box
            round="small"
            background="dark-1"
            pad="xsmall"
            border={{ color: "dark-3", size: "xsmall" }}
            margin="xsmall"
          >
            I am not going to use this
          </Box>
        }
      >
        <Button
          onClick={() => voteAgainst.mutate()}
          style={{ boxShadow: shadow, textShadow }}
          label={direction === "horizontal" ? "👎" : "👎  Meh..."}
          size={size}
        />
      </Tip>

      <Tip
        plain
        content={
          <Box
            round="small"
            background="dark-1"
            pad="xsmall"
            border={{ color: "dark-3", size: "xsmall" }}
            margin="xsmall"
          >
            Let us know if it would be useful to you.
          </Box>
        }
      >
        <Button
          onClick={() => voteFor.mutate()}
          style={{ boxShadow: shadow, textShadow, color: brandDynamic }}
          size={size}
          primary
          label="Good Idea 👍"
        />
      </Tip>
    </Box>
  );
}
