import * as React from "react";
import classes from "./MaskProvider.scss";

export const MaskContext = React.createContext({
  onComplete: () => {
    console.warn("Not yet implemented");
  },
  onHighlight: (
    rect: DOMRect,
    _position: string,
    _element: React.ReactElement
  ) => console.warn("No handler yet", rect),
});

function t(x: number | undefined, y: number | undefined) {
  const tx = x ? `translateX(${x}px)` : "";
  const ty = y ? `translateY(${y}px)` : "";
  return `${tx} ${ty}`;
}

interface CurrentElementHighlight {
  position: string;
  element: React.ReactElement;
  rect: DOMRect;
}

export default function MaskProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentHighlightPos, setCurrentHighlightPos] =
    React.useState<CurrentElementHighlight | null>(null);
  const [isHiding, setIsHiding] = React.useState(false);
  const onComplete = React.useMemo(
    () => () => {
      setIsHiding(true);
      // Hiding animation
      setTimeout(() => {
        setCurrentHighlightPos(null);
        setIsHiding(false);
      }, 300);
    },
    []
  );
  const onHighlight = React.useMemo(
    () => (rect: DOMRect, position: string, element: React.ReactElement) => {
      setCurrentHighlightPos({ rect, position, element });
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (!isVisible) {
        // Check if outside of view
        window.scroll({
          top:
            rect.top +
            document.documentElement.scrollTop -
            window.innerHeight +
            rect.height,
          behavior: "smooth",
        });
      }
    },
    []
  );

  function getTransform(index: number) {
    if (!currentHighlightPos) return;
    const w = window.document.body.clientWidth;
    const h = window.document.body.clientHeight;
    const c = currentHighlightPos.rect;
    const st = document.documentElement.scrollTop;
    const top = c.top + st;

    const scale = c.width / (w - 2);
    const center = c.left + c.width / 2;
    const centerOffset = center - w / 2;
    switch (index) {
      // Left
      case 0:
        return t(c.left - w, top);
      // Top
      case 1:
        return t(0, top - h);
      // Right
      case 2:
        return t(c.left + c.width, top);
      // Bottom Mid
      case 3:
        return t(centerOffset, top + c.height) + ` scaleX(${scale})`;
      // Content div
      case 4:
      default:
        return "";
    }
  }

  function getContentStyle(): React.CSSProperties {
    if (!currentHighlightPos) return {};
    const c = currentHighlightPos.rect;
    const maxWidth = Math.min(c.left, window.innerWidth - c.right);
    const st = document.documentElement.scrollTop;
    const pos = currentHighlightPos.position;
    if (pos === "top") {
      return {
        left: c.left - maxWidth,
        right: window.innerWidth - c.right - maxWidth, // c.right - maxWidth,
        bottom: window.document.body.clientHeight - (c.top + st),
        flexDirection: "column",
        justifyContent: "flex-end",
      };
    }
    if (pos === "bottom") {
      return {
        left: c.left - maxWidth,
        right: window.innerWidth - c.right - maxWidth, // c.right - maxWidth,
        top: c.top + st + c.height,
        bottom: 0,
        flexDirection: "column",
        justifyContent: "flex-start",
      };
    }
    const bottom =
      document.documentElement.getBoundingClientRect().bottom - c.bottom;
    const top = c.top + st;
    const maxHeight = Math.min(bottom, top);
    if (pos === "left") {
      return {
        left: 0,
        top: top - maxHeight,
        bottom: bottom - maxHeight,
        right: c.left,
        flexDirection: "row",
        justifyContent: "flex-end",
      };
    }
    return {
      left: c.left + c.width,
      top: top - maxHeight,
      bottom: bottom - maxHeight,
      right: 0,
      flexDirection: "row",
      justifyContent: "flex-start",
    };
  }

  return (
    <div style={{ position: "relative" }}>
      {currentHighlightPos && (
        <div
          className={
            classes.MaskProviderOverlay +
            (isHiding ? " " + classes.MaskProviderOverlayHiding : "")
          }
        >
          <div
            style={getContentStyle()}
            className={classes.MaskProviderOverlayContent}
          >
            {currentHighlightPos.element}
          </div>
          <div
            style={{ transform: getTransform(0) }}
            className={classes.MaskProviderOverlayElement}
          />
          <div
            style={{ transform: getTransform(1) }}
            className={classes.MaskProviderOverlayElement}
          />
          <div
            style={{ transform: getTransform(2) }}
            className={classes.MaskProviderOverlayElement}
          />
          <div
            style={{ transform: getTransform(3) }}
            className={classes.MaskProviderOverlayElement}
          />
        </div>
      )}
      <MaskContext.Provider value={{ onHighlight, onComplete }}>
        {children}
      </MaskContext.Provider>
    </div>
  );
}
