import { AppState } from '@core/redux/reducers';
import { SiteView } from '@core/redux/sitesEditor';
import React from 'react';
import { useSelector } from 'react-redux';
import { SiteConfigContext } from './SiteConfigContext';

interface Props {
  children: React.ReactChild;
  forceSiteConfig?: SiteView['config'];
}

export default function SiteConfigProvider({ children, forceSiteConfig }: Props) {
  const siteConfig = useSelector<AppState, SiteView['config']>((state: AppState) => state.siteConfig.config);
  return <SiteConfigContext.Provider value={forceSiteConfig || siteConfig}>{children}</SiteConfigContext.Provider>;
}
