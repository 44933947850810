import * as React from "react";
import {
  Button,
  Box,
  Heading,
  Card,
  CardHeader,
  Layer,
  CardFooter,
  TextInput,
  Text,
  FormField,
  Select,
  CardBody,
} from "grommet";
import MarkdownEditor from "../molecules/MarkdownEditor";
import useSessionContact from "@core/hooks/useSessionContact";
import { useDispatch, useSelector } from "react-redux";
import { useApiCategories, useApiVotePostCreate } from "@core/hooks/requests";
import styled from "styled-components";
import { AnchorLink } from "../atoms/AnchorLink";
import * as Icons from "grommet-icons";
import { selectCreateAPostModal } from "@core/redux/createAPostModal/selectors";
import {
  createAPostModalChangeCategoryId,
  createAPostModalClose,
} from "@core/redux/createAPostModal/actions";
import toast from "react-hot-toast";
import ContactNameText from "../molecules/ContactNameText";
import ContactAvatar from "../molecules/ContactAvatar";
import { PostModerationSettings } from "@server/lib/utils/permissionsConfig";

const CategoryFormField = styled(FormField)`
  & > div {
    height: 100%;
    border-left: 0;
    & > button {
      padding-left: 16px;
      height: 100%;
      &[tabIndex="-1"] {
        border: 0.5px solid black;
        border-left: 0;
      }
    }
  }
`;

export default function CreateAPostModal() {
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState("");
  const [markdown, setMarkdown] = React.useState("");
  const [contact] = useSessionContact();
  const { siteId, categoryId } = useSelector(selectCreateAPostModal);
  const categories = useApiCategories(siteId);
  const createMutation = useApiVotePostCreate({
    categoryId,
    siteId,
    title,
    markdown,
    onSuccess: () => {
      toast.success("Post created!");
      // TODO: redirect?
    },
    onError: () => {
      toast.error("Problem creating a post. Please try again.");
    },
  });
  const onClose = () => {
    dispatch(createAPostModalClose());
  };
  const handleSave = () => {
    createMutation.mutate();
  };
  if (!siteId || !categoryId) return null;
  return (
    <Layer background="transparent" onClickOutside={onClose} onEsc={onClose}>
      <Card background="dark-1" width="600px">
        <CardHeader background="dark-2">
          <Heading margin="small" level={4}>
            Create A Post
          </Heading>
          <Box
            onClick={onClose}
            hoverIndicator={{ background: "dark-3" }}
            pad="small"
          >
            <Icons.Close />
          </Box>
        </CardHeader>
        <CardBody pad="small" overflow={{ vertical: "scroll" }}>
          <Box direction="row" align="stretch">
            <FormField help="Post Title" style={{ flexGrow: 1 }}>
              <TextInput
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                size="xsmall"
              />
            </FormField>
            <CategoryFormField help="Category">
              <Select
                onChange={({ option }) => {
                  const cId = categories.data[option]?.id;
                  dispatch(createAPostModalChangeCategoryId(cId));
                }}
                placeholder="None"
                value={
                  <Box>
                    <Text>
                      {categories.data.find((c) => c.id === categoryId)
                        ?.title || "Loading..."}
                    </Text>
                  </Box>
                }
                options={categories.data
                  .filter(
                    (category) =>
                      category.postModerationSettings !==
                      PostModerationSettings.Disabled
                  )
                  .map((_c, i) => i)}
              >
                {(i, _state) => {
                  const category = categories.data[i];
                  return (
                    <Box
                      key={category.id || "none"}
                      direction="row"
                      gap="small"
                      align="center"
                      pad="xsmall"
                      margin={{ bottom: "1px" }}
                    >
                      <div
                        style={{
                          width: 16,
                          height: 16,
                          borderRadius: 8,
                          background: category.color || "#fff",
                        }}
                      />
                      <Text>{category.title || "None"}</Text>
                    </Box>
                  );
                }}
              </Select>
            </CategoryFormField>
          </Box>
          <FormField info="Markdown supported">
            <MarkdownEditor value={markdown} onChange={setMarkdown} />
          </FormField>
        </CardBody>
        <CardFooter
          background="dark-2"
          pad="small"
          align="stretch"
          direction="row"
        >
          <Box direction="row" align="center">
            <ContactAvatar contactId={contact?.id} />
            <Box direction="column" margin={{ left: "small" }}>
              <ContactNameText
                contactId={contact?.id}
                size="small"
                weight="bold"
              />
              <AnchorLink to="/contact/edit" size="xsmall" onClick={onClose}>
                Change name / avatar?
              </AnchorLink>
            </Box>
          </Box>
          <Button primary label="Post Now" onClick={handleSave} />
        </CardFooter>
      </Card>
    </Layer>
  );
}
