import { ThemeContext } from 'grommet';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function SectionTabThemeProvider({ children }: Props) {
  return (
    <ThemeContext.Extend
      value={{
        tabs: {
          background: 'dark-2',
          gap: 'big',
          header: {
            background: 'dark-3',
          },
        },
        tab: {
          color: 'light-1',
          margin: '0',
          border: {
            color: 'dark-4',
            size: '4px',
          },
          active: {
            background: 'dark-4',
            color: 'light-1',
          },
        },
      }}
    >
      {children}
    </ThemeContext.Extend>
  );
}
