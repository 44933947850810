import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { IRequestArg, typedRequest } from '@core/common/authorisedFetch';
import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@core/redux/reducers';
import { entitiesUpdateAction } from '@core/redux/entities/actions';
import {
  indexItemsCombiner,
  indexSelector,
  itemsSelector,
} from '@core/redux/entities/selectors';
import { StandardResponse } from '@core/types';

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

// BackendResponse has data in it's own field
// but on the client side it arrives at the root level without nesting in data
export type ToFrontendResponse<
  Response extends StandardResponse<any> = StandardResponse<any>
> = Omit<Response, 'data'> & Response['data'];

export function useMutationRequest<Req extends IRequestArg>(
  reqData: Omit<Req, 'response'>,
  options?: UseMutationOptions
) {
  const dispatch = useDispatch();
  return useMutation<ToFrontendResponse<Req['response']>>(async () => {
    const response = await typedRequest<Req>(reqData);
    dispatch(entitiesUpdateAction(response));
    return response;
  }, options);
}

export function useLoadRequest<Req extends IRequestArg, View>(
  key: QueryKey,
  reqData: Omit<Req, 'response'>,
  wantedEntity: string,
  options?: Omit<
    UseQueryOptions<ToFrontendResponse<Req['response']>, Error>,
    'queryKey' | 'queryFn'
  >
) {
  const dispatch = useDispatch();

  const indexName = JSON.stringify(key);
  // todo, memoize
  const selector = createSelector(
    indexSelector,
    itemsSelector,
    indexItemsCombiner
  );

  const query = useQuery<ToFrontendResponse<Req['response']>, Error>(
    key,
    async () => {
      // If you want to test slower loading
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await typedRequest<Req>(reqData);
      dispatch(entitiesUpdateAction(response, indexName));
      return response;
    },
    options
  );

  const data = useAppSelector((state) =>
    selector(state, wantedEntity, indexName)
  ) as unknown as View[];
  // const newData = { ...query, data };
  return { ...query, data };
}
