import { SiteView } from "@core/redux/sitesEditor";
import { Box, Button, Form, FormField, Heading, Text } from "grommet";
import * as Icons from "grommet-icons";
import * as React from "react";
import TC from "tinycolor2";
import FontSelectInput, { NewFont } from "../../molecules/FontSelectInput";
import EditSidebar from "../../molecules/EditSidebar";
import AssetUpload from "@core/components/molecules/AssetUpload";

interface Props {
  site: SiteView;
  onUpdateSite(site: SiteView): void;
  onNextTab(): void;
}

export default function ThemeTab({ onNextTab, site, onUpdateSite }: Props) {
  const theme = site.config.theme;
  const onButtonRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRadius = parseInt(e.currentTarget.value, 10);
    onUpdateSite({
      ...site,
      config: {
        ...site.config,
        theme: {
          ...site.config.theme,
          buttonRadius: newRadius,
        },
      },
    });
  };

  const onSelectNewHeadingFont = (font: NewFont) => {
    onUpdateSite({
      ...site,
      config: {
        ...site.config,
        theme: {
          ...site.config.theme,
          headingFontFamilyUrl: font.url,
          headingFontFamilyName: font.name,
          headingFontWeight: font.weight,
        },
      },
    });
  };

  const onSelectNewBodyFont = (font: NewFont) => {
    onUpdateSite({
      ...site,
      config: {
        ...site.config,
        theme: {
          ...site.config.theme,
          fontFamilyUrl: font.url,
          fontFamilyName: font.name,
          fontWeight: font.weight,
        },
      },
    });
  };

  const onColorChange = React.useCallback(
    (colorName: string) => {
      let timer: number;
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        const newColor = e.currentTarget.value;

        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          onUpdateSite({
            ...site,
            config: {
              ...site.config,
              theme: {
                ...site.config.theme,
                [colorName]: newColor,
              },
            },
          });
          // dispatch(SiteConfigAction.updateThemeConfigValue(colorName, newColor));
        }, 100) as unknown as number;
      };
    },
    [site]
  );

  const handleDarkMode = (isDarkEnabled: boolean) => {
    onUpdateSite({
      ...site,
      config: {
        ...site.config,
        theme: {
          ...site.config.theme,
          mode: isDarkEnabled ? "dark" : "light",
        },
      },
    });
  };

  const handleChangeFavicon = (id: string) => {
    onUpdateSite({
      ...site,
      config: {
        ...site.config,
        general: {
          ...site.config.general,
          favicon: id,
        },
      },
    });
  };

  const mode = site.config.theme.mode;

  return (
    <EditSidebar site={site} title="Configure Your Theme">
      <Form>
        <Heading margin="small" level={5}>
          Fonts
        </Heading>
        <FontSelectInput
          font={{
            name: site.config.theme.headingFontFamilyName || "",
            url: site.config.theme.headingFontFamilyUrl || "",
            weight: site.config.theme.headingFontWeight,
          }}
          onSelectNew={onSelectNewHeadingFont}
          label={
            "Heading: " +
            (site.config.theme.headingFontFamilyName || "Select...")
          }
        />
        <Box pad={{ horizontal: "xsmall" }}>
          <Text size="small">
            Usually a heading font captures the theme of your game.
          </Text>
        </Box>
        <FontSelectInput
          font={{
            name: site.config.theme.fontFamilyName || "",
            url: site.config.theme.fontFamilyUrl || "",
            weight: site.config.theme.fontWeight,
          }}
          label={"Body: " + (site.config.theme.fontFamilyName || "Select...")}
          onSelectNew={onSelectNewBodyFont}
        />
        <Box pad={{ horizontal: "xsmall" }}>
          <Text size="small">
            It's recommended to select a body font with good readbility such as
            Open Sans.
          </Text>
          <Text size="xsmall">
            Note: Not all fonts have all weights available!
          </Text>
        </Box>
        <Heading level={5} margin="small">
          Colors
        </Heading>
        <Box pad="xsmall" direction="row" gap="small">
          <Button
            onClick={() => handleDarkMode(mode !== "dark")}
            id="toggle-enable-dark-mode"
            icon={
              mode === "dark" ? <Icons.CheckboxSelected /> : <Icons.Checkbox />
            }
            label="Enable Dark Mode"
          />
        </Box>
        <FormField label="Light Base Color">
          <input
            style={{ width: "100%" }}
            value={new TC(theme.lightBase).toHexString()}
            type="color"
            onChange={onColorChange("lightBase")}
          />
        </FormField>
        <FormField label="Dark Base Color">
          <input
            style={{ width: "100%" }}
            value={new TC(theme.darkBase).toHexString()}
            type="color"
            onChange={onColorChange("darkBase")}
          />
        </FormField>
        <FormField label="Primary Color">
          <input
            id="editor-primary-color"
            style={{ width: "100%" }}
            value={new TC(theme.primary).toHexString()}
            type="color"
            onChange={onColorChange("primary")}
          />
        </FormField>
        <FormField label="Secondary Color">
          <input
            value={new TC(theme.secondary).toHexString()}
            type="color"
            style={{ width: "100%" }}
            onChange={onColorChange("secondary")}
          />
        </FormField>
        <FormField label="Borders">
          <input
            value={theme.buttonRadius}
            onChange={onButtonRadiusChange}
            type="range"
            min={0}
            max={6}
            step={1}
          />
        </FormField>
        <FormField
          label="Tab Icon"
          info={
            <Box align="center" direction="row" justify="between">
              <Box direction="row" align="center" gap="small">
                <Icons.Image size="xsmall" />
              </Box>
              <Text size="small">300x300 or bigger</Text>
            </Box>
          }
        >
          <AssetUpload
            type="favicon"
            onFaviconChanged={({ id }) => handleChangeFavicon(id)}
          />
        </FormField>
        <Button onClick={onNextTab} label="Next" primary />
      </Form>
    </EditSidebar>
  );
}
