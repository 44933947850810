import { Button } from 'grommet';
import * as React from 'react';
import * as Icons from 'grommet-icons';
import MessageBox from './MessageBox';

export default function ErrorIndicator({
  isError,
  error,
  refetch,
}: {
  isError?: boolean;
  error: Error | null;
  refetch(): void;
}) {
  return (
    <>
      {isError && (
        <MessageBox status="critical">
          {error?.message || 'Problem loading this resource.'}
        </MessageBox>
      )}
      {isError && refetch && (
        <Button
          onClick={refetch}
          icon={<Icons.Refresh size="small" />}
          label="Retry"
          size="small"
        />
      )}
    </>
  );
}
