import { TextInput } from "grommet";
import * as React from "react";
import {
  Button as GrommetButton,
  TextArea as GrommetTextArea,
  Box,
  Text,
} from "grommet";
import * as Icons from "grommet-icons";
import BetterEditor from "./BetterEditor";
import styled from "styled-components";
import PlatformFormField from "../atoms/PlatformFormField";
import { SupportedPlatform } from "@core/config/platforms";
import MessageBox from "../atoms/MessageBox";
import { FieldVariations, UpdateData } from "../pages/publeash/useUpdateData";

export const TextArea = styled(GrommetTextArea)`
  width: 100%;
  border-radius: 0;
  background: ${(props) => props.theme.global.colors["dark-1"]};
  border: 0;
`;

interface Props {
  platforms: SupportedPlatform[];
  updateData: UpdateData;
  onChange(data: UpdateData): void;
}

export default function PrepareContentText({
  platforms,
  updateData,
  onChange,
}: Props) {
  const [showEditor, setShowEditor] = React.useState(false);
  React.useEffect(() => {
    setShowEditor(true);
  });

  const updateField = (field: string) => (value: FieldVariations) => {
    onChange({
      ...updateData,
      fields: {
        ...updateData.fields,
        [field]: value,
      },
    });
  };

  const getValue = (field: string): FieldVariations => {
    return updateData.fields[field] || {};
  };

  return (
    <Box
      pad="medium"
      width="large"
      justify="start"
      border={{
        side: "right",
        size: "xsmall",
        color: "dark-4",
      }}
    >
      {platforms.length === 0 && (
        <MessageBox status="info" direction="row" align="center" justify="end">
          Please select platforms on the right
          <Icons.CaretRightFill size="xlarge" />
        </MessageBox>
      )}
      <PlatformFormField
        platforms={["reddit", "website", "emails", "steam"]}
        enabledPlatforms={platforms}
        label="TITLE"
        value={getValue("title")}
        onChange={updateField("title")}
      >
        <TextInput
          size="small"
          placeholder={getValue("title").default}
          style={{
            background: "#fff",
          }}
        ></TextInput>
      </PlatformFormField>
      <PlatformFormField
        label="SUBTITLE"
        help="Appears on top of event or announcement detail page"
        info="Max 120 characters."
        platforms={["steam"]}
        value={getValue("subtitle")}
        onChange={updateField("subtitle")}
        enabledPlatforms={platforms}
      >
        <TextArea
          placeholder={getValue("subtitle").default}
          size="small"
        ></TextArea>
      </PlatformFormField>
      <PlatformFormField
        label="SUMMARY"
        info="Max 180 characters. "
        platforms={["twitter", "steam"]}
        enabledPlatforms={platforms}
        value={getValue("summary")}
        onChange={updateField("summary")}
      >
        <TextArea placeholder={getValue("summary").default}></TextArea>
      </PlatformFormField>
      {showEditor && (
        <PlatformFormField
          label="MAIN CONTENT"
          platforms={["website", "emails", "facebook", "steam", "discord"]}
          enabledPlatforms={platforms}
          value={getValue("content")}
          onChange={updateField("content")}
        >
          <BetterEditor />
        </PlatformFormField>
      )}
      <PlatformFormField
        label="COMMENT"
        value={getValue("comment")}
        onChange={updateField("comment")}
        info="Max 180 characters. "
        help="If subreddit/group permits, you should probably get a comment ready that will lead them to your store page."
        platforms={["reddit", "facebook"]}
        enabledPlatforms={platforms}
      >
        <TextArea placeholder={getValue("comment").default}></TextArea>
      </PlatformFormField>
      <Box background="dark-3" direction="row" pad="small" align="center">
        <Text size="small" color="status-ok">
          Saved <Icons.Checkmark color="status-ok" size="small" />
        </Text>
        <Box
          flex={{
            grow: 1,
            shrink: 1,
          }}
        />
        <GrommetButton
          size="small"
          label="Continue to Media Assets"
          icon={<Icons.Multimedia size="small" />}
          primary
        ></GrommetButton>
      </Box>
    </Box>
  );
}
