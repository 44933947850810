import PageContent from '@core/components/atoms/PageContent';
import SocialLinks from '@core/components/molecules/SocialLinks';
import { SiteConfigContext } from '@core/components/blind/contexts/SiteConfigContext';
import { Footer as GrommetFooter, Heading, Text } from 'grommet';
import * as React from 'react';

export default function ClientFooter() {
  const siteConfig = React.useContext(SiteConfigContext);
  const g = siteConfig?.general;
  const hasSocialLinks =
    g?.youtubeChannelURL ||
    g?.twitterURL ||
    g?.discordURL ||
    g?.twitchURL ||
    g?.steamAppId;
  return (
    <GrommetFooter background="dark-1">
      <PageContent pad="small" align="center">
        {hasSocialLinks && (
          <Heading level={4} margin={{ bottom: '0px' }}>
            Follow Us
          </Heading>
        )}
        <SocialLinks {...siteConfig?.general} size="32px" />
        {/* <Box direction="row" gap="medium" margin="small"> */
        /*   <Anchor href="/" label="Press" /> */
        /*     <Anchor href="/" label="Contact" /> */
        /*       <Anchor href="/" label="About" /> */
        /*         <Anchor href="/" label="Beta" /> */
        /* </Box> */}
        <Text size="small">Copyright &copy; {new Date().getFullYear()}</Text>
        {siteConfig?.general.studioName && (
          <Text size="small">{siteConfig.general.studioName}</Text>
        )}
      </PageContent>
    </GrommetFooter>
  );
}
