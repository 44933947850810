import * as React from "react";
import { RouteConfig, RouteConfigComponentProps } from "react-router-config";

import { PreloadAction } from "@core/config/preload";
import { ClientOnlyPages, PubleashOnlyPages } from "@core/config/routes";

export interface ExtraRouteProps {
  preloadActions?: PreloadAction | PreloadAction[];
}

export interface RouteComponentProps<T = object>
  extends RouteConfigComponentProps<T> {
  routes?: AppRoute[];
}

export interface AppRoute extends RouteConfig, ExtraRouteProps {
  routes?: AppRoute[];
  component: React.ComponentType<RouteComponentProps> | React.ComponentType;
}

export const getAppRoutes = (isOwnPage: boolean) => {
  return isOwnPage ? PubleashOnlyPages : ClientOnlyPages;
};
