import throttle from 'lodash.throttle';
import { useEffect, useRef, useState } from 'react';

declare let global:
  | { window: Window }
  | {
      window: {
        innerWidth: number;
        innerHeight: number;
        addEventListener(event: string, handler: () => void): void;
        removeEventListener(event: string, handler: () => void): void;
      };
    };

// Backend mock
const window = global.window || {
  innerHeight: 0,
  innerWidth: 0,
  addEventListener: Function,
  removeEventListener: Function,
};

function checkVisibility(el: React.RefObject<HTMLElement>, partial: boolean) {
  if (!el || !el.current) {
    return false;
  }
  const current = el.current;

  const { top, right, bottom, left, width, height } =
    current.getBoundingClientRect();

  if (top + right + bottom + left === 0) {
    return false;
  }

  const topCheck = partial ? top + height : top;
  const bottomCheck = partial ? bottom - height : bottom;
  const rightCheck = partial ? right - width : right;
  const leftCheck = partial ? left + width : left;

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  return (
    topCheck >= 0 &&
    leftCheck >= 0 &&
    bottomCheck <= windowHeight &&
    rightCheck <= windowWidth
  );
}

const throttleInterval = 150;

const useVisibility = (
  el: React.RefObject<HTMLElement>,
  { partial = false, scrollableEl = window } = {}
) => {
  const [isVisible, setIsVisible] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const handleScrollOrResize = throttle(() => {
      if (isMounted.current) {
        setIsVisible(checkVisibility(el, partial));
      }
    }, throttleInterval);

    document.addEventListener('scroll', handleScrollOrResize, {
      capture: true,
    });
    scrollableEl.addEventListener('scroll', handleScrollOrResize);
    window.addEventListener('resize', handleScrollOrResize);

    setIsVisible(checkVisibility(el, partial));

    return () => {
      scrollableEl.removeEventListener('scroll', handleScrollOrResize);
      window.removeEventListener('resize', handleScrollOrResize);
      document.removeEventListener('scroll', handleScrollOrResize, {
        capture: true,
      });
    };
  }, [el.current, partial, scrollableEl]);

  return isVisible;
};

export default useVisibility;
