import * as React from 'react';
import { SiteConfigContext } from '@core/components/blind/contexts/SiteConfigContext';
import { defaultSiteConfig } from '@core/common/siteConfigs';
import { ThankYouPageConfig } from '@core/redux/siteConfig';

export default function useThankYouPageConfig(): ThankYouPageConfig {
  const siteConfig = React.useContext(SiteConfigContext);
  const thankYouConfig =
    siteConfig?.thankYouPage ||
    (defaultSiteConfig.thankYouPage as ThankYouPageConfig);
  return thankYouConfig;
}
