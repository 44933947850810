import { AppState } from "@core/redux/reducers";
import { SiteView } from "@core/redux/sitesEditor";
import SitesEditorAction from "@core/redux/sitesEditor/actions";
import { selectIsLoggedIn } from "@core/redux/user/selectors";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import LoadingIndicator from "../atoms/LoadingIndicator";
import { WithGameSidebar } from "../molecules/WithGameSidebar";
import { AppDispatch } from "../organisms/GameListSection";
import OwnNav from "../organisms/OwnNav";

export default function <T extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<T>
) {
  return (props: T) => {
    const { id } = useParams<{ id: string | undefined }>();
    const dispatch: AppDispatch = useDispatch();

    const sites = useSelector<AppState, SiteView[]>((state) => {
      return state.sitesEditor.sites;
    });
    const site = React.useMemo<SiteView>(
      () => sites.find((s) => s.id === parseInt(id || "0", 10))!,
      [sites]
    );
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
      if (isLoggedIn && !site) {
        setIsLoading(true);
        dispatch(SitesEditorAction.loadAll())
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
      }
    }, [isLoggedIn, site]);
    if (!site || isLoading)
      return (
        <>
          <OwnNav />
          <WithGameSidebar>
            <LoadingIndicator loading={true} size="large" />;
          </WithGameSidebar>
        </>
      );
    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return <WrappedComponent {...props} />;
  };
}
