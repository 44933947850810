import Steam from '@core/components/atoms/icons/Steam';
import { SiteView } from '@core/redux/sitesEditor';
import {
  FileInput,
  FormField,
  MaskedInput,
  Text,
  TextArea,
  TextInput,
} from 'grommet';
import * as Icons from 'grommet-icons';
import * as React from 'react';
import EditSidebar from '../../molecules/EditSidebar';
import EditSidebarSection from '../../molecules/EditSidebarSection';

interface Props {
  site: SiteView;
  onUpdateSite(site: SiteView): void;
  onNextTab(): void;
}

export default function MoreContentTab({ site, onUpdateSite }: Props) {
  const handleTextUpdate = (textField: string, newGameName: string) => {
    onUpdateSite({
      ...site,
      config: {
        ...site.config,
        general: {
          ...site.config.general,
          [textField]: newGameName,
        },
      },
    });
  };

  const handleMetaDescriptionChange = (text: string) => {
    onUpdateSite({
      ...site,
      config: {
        ...site.config,
        general: {
          ...site.config.general,
          siteMeta: {
            description: text,
          },
        },
      },
    });
  };

  return (
    <EditSidebar site={site} title="Socials">
      <EditSidebarSection title="External Links">
        <FormField label="Twitter URL">
          <MaskedInput
            id="editor-twitter-url-input"
            size="small"
            mask={[{ fixed: 'https://' }, { regexp: /^.*$/ }]}
            onChange={(e) => handleTextUpdate('twitterURL', e.target.value)}
            value={site.config.general.twitterURL || ''}
            icon={<Icons.Twitter />}
          />
        </FormField>
        <FormField label="YouTube Channel URL">
          <MaskedInput
            id="editor-youtube-url-input"
            size="small"
            value={site.config.general.youtubeChannelURL || ''}
            mask={[{ fixed: 'https://' }, { regexp: /^.*$/ }]}
            onChange={(e) =>
              handleTextUpdate('youtubeChannelURL', e.target.value)
            }
            icon={<Icons.Youtube />}
          />
        </FormField>
        <FormField label="Facebook URL">
          <MaskedInput
            id="editor-facebook-url-input"
            size="small"
            value={site.config.general.facebookURL || ''}
            mask={[{ fixed: 'https://' }, { regexp: /^.*$/ }]}
            onChange={(e) => handleTextUpdate('facebookURL', e.target.value)}
            icon={<Icons.Facebook />}
          />
        </FormField>
        <FormField label="Discord Invite URL">
          <MaskedInput
            size="small"
            id="editor-discord-url-input"
            value={site.config.general.discordURL || ''}
            mask={[{ fixed: 'https://' }, { regexp: /^.*$/ }]}
            onChange={(e) => handleTextUpdate('discordURL', e.target.value)}
            icon={<Icons.Link />}
          />
        </FormField>
        <FormField label="Patreon URL">
          <MaskedInput
            id="editor-patreon-url-input"
            size="small"
            value={site.config.general.patreonURL || ''}
            mask={[{ fixed: 'https://' }, { regexp: /^.*$/ }]}
            onChange={(e) => handleTextUpdate('patreonURL', e.target.value)}
            icon={<Icons.Link />}
          />
        </FormField>
        <FormField label="Steam App Id">
          <TextInput
            onChange={(e) => handleTextUpdate('steamAppId', e.target.value)}
            icon={<Steam />}
          />
        </FormField>
      </EditSidebarSection>
      <EditSidebarSection title="Sharing">
        <Text>
          When your page is shared on social media this image & description will
          be shown.
        </Text>
        <FormField
          label="Description"
          info={
            <Text size="small">
              Use up to 90 Characters for best showcase (up to 180 if you if you
              won't share on Facebook)
            </Text>
          }
        >
          <TextArea
            id="editor-social-description-input"
            onChange={(e) => handleMetaDescriptionChange(e.target.value)}
            size="small"
            fill
            value={site.config.general.siteMeta.description || ''}
          />
        </FormField>
        <FormField
          label="Image"
          info={
            <Text size="small">
              At least 600×315 pixels, but 1200×630 or larger is preferred (up
              to 5MB).
            </Text>
          }
        >
          <FileInput />
        </FormField>
        <Text size="small" />
      </EditSidebarSection>
    </EditSidebar>
  );
}
