import { Card, Heading, Page } from "grommet";
import * as React from "react";
import { Box, Text } from "grommet";
import * as Icons from "grommet-icons";
import useColor from "@core/hooks/useColor";
import MiniOwnNav from "@core/components/organisms/MiniOwnNav";
import { AnchorLink } from "@core/components/atoms/AnchorLink";
import { ToggleRadio } from "./Checklist";
import SmallDoubleIconButton from "../../atoms/SmallDoubleIconButton";
import DiscordMessage from "../../atoms/DiscordMessage";
import HelpCard from "../../atoms/HelpCard";
import CarouselItem from "../../atoms/CarouselItem";
import BoxWithShadow from "../../atoms/BoxWithShadow";
import PrepareContentText from "../../molecules/PrepareContentText";
import PrepareContentMedia from "../../molecules/PrepareContentMedia";
import PrepareContentExport from "../../molecules/PrepareContentExport";
import { allPlatforms } from "@core/config/platforms";
import { useUpdateData } from "./useUpdateData";

enum Stages {
  TextContent,
  Media,
  Export,
}

export default function PrepareContent() {
  const [stage, setStage] = React.useState(Stages.TextContent);
  const {
    togglePlatform,
    isPlatformEnabled,
    platforms,
    updateData,
    setUpdateData,
  } = useUpdateData();

  const dark1 = useColor("dark-1");
  const dark3 = useColor("dark-3");
  const cardBg = useColor("dark-2");

  const getStage = () => {
    switch (stage) {
      case Stages.TextContent:
        return (
          <PrepareContentText
            updateData={updateData}
            onChange={setUpdateData}
            platforms={platforms}
          ></PrepareContentText>
        );
      case Stages.Export:
        return (
          <PrepareContentExport
            updateData={updateData}
            onChange={setUpdateData}
            platforms={platforms}
          ></PrepareContentExport>
        );
      case Stages.Media:
        return (
          <PrepareContentMedia
            updateData={updateData}
            onChange={setUpdateData}
            platforms={platforms}
          ></PrepareContentMedia>
        );
    }
  };

  return (
    <Page width="full">
      <MiniOwnNav />
      <Box background="dark-4" round="0" align="center">
        <Box align="center" background="dark-4" direction="row">
          <ToggleRadio
            selected={stage === Stages.TextContent}
            onChange={() => setStage(Stages.TextContent)}
            label="1. Text Content"
          />
          <ToggleRadio
            selected={stage === Stages.Media}
            onChange={() => setStage(Stages.Media)}
            label="2. Media Assets"
          />
          <ToggleRadio
            selected={stage === Stages.Export}
            onChange={() => setStage(Stages.Export)}
            label="3. Preview & Export"
          />
          <Box flex />
        </Box>
      </Box>
      <Box direction="row" background="dark-2" justify="center">
        {getStage()}
        <Box width="large" pad="medium" gap="small">
          <HelpCard title="Platforms">
            <Box direction="row" align="center">
              <BoxWithShadow shadow={`inset 0 0 12px 12px ${cardBg}`}>
                <Box pad="small" wrap direction="row">
                  {allPlatforms.map((platform) => (
                    <SmallDoubleIconButton
                      key={platform as unknown as string}
                      margin="xsmall"
                      background={platform.color}
                      Icon={platform.icon}
                      label={platform.name}
                      onClick={() => togglePlatform(platform.key)}
                      mode="checkbox"
                      selected={isPlatformEnabled(platform.key)}
                    />
                  ))}
                </Box>
              </BoxWithShadow>
            </Box>
          </HelpCard>
          <HelpCard title="Your previous updates">
            <Box
              direction="row"
              overflow={{ vertical: "hidden", horizontal: "scroll" }}
              pad="small"
              gap="small"
            >
              <Card
                round="xsmall"
                width="small"
                style={{
                  minWidth: 180,
                  transition: "background 300ms ease-out",
                }}
                align="center"
                justify="center"
                background={`linear-gradient(-45deg,${dark3} 0%, ${dark1} 100%)`}
                hoverIndicator={{
                  background: `linear-gradient(0deg,${dark3} 0%, ${dark1} 100%)`,
                }}
                onClick={() => {
                  throw new Error("Not yet implemented.");
                }}
                gap="small"
              >
                <Icons.Add />
                <Text>Create New</Text>
              </Card>
              <CarouselItem game="StressOut" title="Update 1" />
              <CarouselItem game="StressOut" title="Update 2" />
              <CarouselItem game="StressOut" title="Update 3" />
            </Box>
          </HelpCard>
          <HelpCard title="Make the most out of your updates" pad="small">
            <AnchorLink to="/">
              5 Minute Read - How to write the best update?
            </AnchorLink>
            <br />
            <AnchorLink to="/">
              Update Checklist - Don't forget anything important
            </AnchorLink>
          </HelpCard>
          <HelpCard title="Add From Discord" background={"rgba(55,57,63, 1)"}>
            <BoxWithShadow>
              <Box
                style={{
                  maxHeight: "33vh",
                  overflowY: "scroll",
                }}
              >
                <DiscordMessage />
                <DiscordMessage />
                <DiscordMessage />
              </Box>
            </BoxWithShadow>
          </HelpCard>
          <HelpCard title="Get inspiration">
            <Heading
              margin={{ horizontal: "small", bottom: "xsmall", top: "small" }}
              level={6}
            >
              Other Game Announcements
            </Heading>
            <Box
              direction="row"
              overflow={{ vertical: "hidden", horizontal: "scroll" }}
              pad="small"
              gap="small"
            >
              <CarouselItem
                game="Frostpunk 2"
                title="The era of coal is over. Long live the oil!"
              />
              <CarouselItem />
              <CarouselItem />
              <CarouselItem />
              <CarouselItem />
              <CarouselItem />
            </Box>
            <Heading
              margin={{ horizontal: "small", bottom: "xsmall", top: "small" }}
              level={6}
            >
              Amazing Update Posts
            </Heading>
            <Box
              direction="row"
              overflow={{ vertical: "hidden", horizontal: "scroll" }}
              pad="small"
              gap="small"
            >
              <CarouselItem />
              <CarouselItem />
              <CarouselItem />
              <CarouselItem />
              <CarouselItem />
              <CarouselItem />
            </Box>
          </HelpCard>
        </Box>
      </Box>
    </Page>
  );
}
