import authorisedFetch from '@core/common/authorisedFetch';
import SitesEditorAction from '@core/redux/sitesEditor/actions';
import { Box, Button, FormField, Form, Paragraph, TextInput } from 'grommet';
import * as React from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  siteId: number;
  onClose(): void;
}

export default function ConnectDomainsForm({ onClose, siteId }: Props) {
  const [domains, setDomains] = React.useState('');
  const dispatch = useDispatch();
  const handleAdd = async () => {
    const requests = domains.split(' ').map((domain) =>
      authorisedFetch<{ domain: { hostname: string; id: string } }>(
        'sites/domain',
        {
          method: 'POST',
          body: JSON.stringify({
            domain,
            siteId,
          }),
        }
      )
    );
    const finishedRequests = await Promise.all(requests);
    finishedRequests.forEach((data) =>
      dispatch(SitesEditorAction.addDomain(data.domain))
    );
    onClose();
  };
  return (
    <Box background="dark-1" pad="medium">
      <Form onSubmit={handleAdd}>
        <FormField
          label="New domain(s) you want to connect."
          info="Separate domains with spaces"
        >
          <TextInput
            value={domains}
            onChange={(e) => setDomains(e.target.value)}
            placeholder="eg. www.mygame.com mygame.com"
          />
        </FormField>
        <Button primary onClick={handleAdd} label="Add Domains" />
        {/* TODO */}
        <Paragraph>
          You have to point A record in your DNS settings to{' '}
          <code>Contact Us</code> or this will not work.
        </Paragraph>
      </Form>
    </Box>
  );
}
