import { accountsClient } from "@core/redux/accounts/client";
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../redux/user/selectors";
import { User } from "@accounts/types/src/types/user";
import UserAction from "../../../redux/user/actions";
import { config } from "@core/config/config";
import posthog from "posthog-js";
import logger from "@server/lib/utils/logger";
import toast from "react-hot-toast";

export const UserContext = createContext<null | {
  isLoggedIn: boolean;
  user: User | null;
}>(null);

interface Props {
  children: React.ReactChild;
}

function ensureSessionIsValid() {
  accountsClient.refreshSession().catch((err) => {
    logger.error("Problem refreshing session", err);
  });
}

ensureSessionIsValid();

export default function UserContextProvider({ children }: Props) {
  const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
  const [user, setUser] = useState<null | User>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const onWindowFocus = () => {
      logger.info("Refreshing session");
      accountsClient.refreshSession().catch((err) => {
        logger.error("Session Refresh Problem", err);
        toast.error(
          "You were logged out. If this happens again please contact us."
        );
        setUser(null);
      });
    };
    window.addEventListener("focus", onWindowFocus);
    return () => {
      window.removeEventListener("focus", onWindowFocus);
    };
  }, []);
  useEffect(() => {
    setUser(null);
    accountsClient
      .getUser()
      .then((newUser) => {
        if (config.posthogEnabled) {
          if (newUser) {
            posthog.identify(newUser.id);
            const email = (newUser.emails && newUser.emails[0]) || {
              address: "",
              verified: false,
            };
            const emailData = {
              email: email.address,
              verified: email.verified,
            };
            posthog.people.set(emailData);
          } else {
            posthog.reset();
          }
        }
        setUser(newUser);
        if (!isLoggedIn && newUser) dispatch(UserAction.setIsLoggedIn(true));
      })
      .catch(() => {
        if (isLoggedIn) dispatch(UserAction.setIsLoggedIn(false));
      });
  }, [isLoggedIn]);

  return (
    <UserContext.Provider value={{ isLoggedIn, user }}>
      {children}
    </UserContext.Provider>
  );
}
