export enum CreateAPostModalActionTypes {
  OPEN = 'CreateAPostModal/OPEN',
  CLOSE = 'CreateAPostModal/CLOSE',
  CHANGE_CATEGORY_ID = 'CreateAPostModal/CHANGE_CATEGORY_ID',
}

export type CreateAPostModalOpenAction = ReturnType<
  typeof createAPostModalOpen
>;
export type CreateAPostModalCloseAction = ReturnType<
  typeof createAPostModalClose
>;
export type CreateAPostModalChangeCategoryIdAction = ReturnType<
  typeof createAPostModalChangeCategoryId
>;

export function createAPostModalClose() {
  return {
    type: CreateAPostModalActionTypes.CLOSE as CreateAPostModalActionTypes.CLOSE,
    payload: {},
  };
}

export function createAPostModalOpen(siteId: number, categoryId: string) {
  return {
    type: CreateAPostModalActionTypes.OPEN as CreateAPostModalActionTypes.OPEN,
    payload: {
      siteId,
      categoryId,
    },
  };
}

export function createAPostModalChangeCategoryId(categoryId: string) {
  return {
    type: CreateAPostModalActionTypes.CHANGE_CATEGORY_ID as CreateAPostModalActionTypes.CHANGE_CATEGORY_ID,
    payload: {
      categoryId,
    },
  };
}
