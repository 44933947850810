import * as Icons from "grommet-icons";
import { Box, Nav, Sidebar } from "grommet";
import * as React from "react";
import { DropDownSidebarButton } from "./DropDownSidebarButton";
import { useParams } from "react-router";

const ENABLED_SIDEBAR = true;

export function WithGameSidebar({ children }: { children: React.ReactNode }) {
  const { id: siteId } = useParams<{ id: string | undefined }>();
  // items={[
  //   {
  //     label: 'Basic Setup',
  //     icon: <Icons.SettingsOption size="xsmall" />,
  //   },
  //   {
  //     label: 'Features',
  //     icon: <Icons.List size="xsmall" />,
  //   },
  //   {
  //     label: 'Social Links & Sharing',
  //   },
  //   {
  //     label: 'Theme',
  //   },
  //   {
  //     icon: <Icons.View size="xsmall" />,
  //     label: 'Preview',
  //   },
  // ]}
  //
  // items={[
  //   { label: 'Default Settings' },
  //   { label: 'Time Limited Events' },
  // ]}
  return (
    <Box direction="row" height={{ min: "calc(100vh - 60px)" }}>
      {ENABLED_SIDEBAR && (
        <Sidebar background="dark-3" pad="0" gap="0">
          <Nav gap="0">
            <DropDownSidebarButton
              icon={<Icons.Projects size="small" />}
              label="Dashboard"
              to={`/edit/${siteId}`}
            />
            <DropDownSidebarButton
              icon={<Icons.Domain size="small" />}
              label="General Settings"
              to={`/edit/${siteId}/editor`}
            />
            <DropDownSidebarButton
              label="Feedback"
              icon={<Icons.UserExpert size="small" />}
              to={`/edit/${siteId}/feedback`}
              items={[
                { label: "Overview", to: `/edit/${siteId}/feedback` },
                {
                  label: "Moderation Queue",
                  to: `/edit/${siteId}/feedback/moderation`,
                },
                // {
                //   label: 'Live View',
                // },
              ]}
            />
            <DropDownSidebarButton
              label="Contacts"
              icon={<Icons.Optimize size="small" />}
              to={`/edit/${siteId}/contacts`}
            />
            <DropDownSidebarButton
              icon={<Icons.Article size="small" />}
              label="Press Page"
              to={`/edit/${siteId}/press`}
            />
            <DropDownSidebarButton
              icon={<Icons.Connectivity size="small" />}
              to={`/edit/${siteId}/converters`}
              label="Post Converters"
            />
            <DropDownSidebarButton
              to={`/edit/${siteId}/integrations`}
              label="Integrations"
            />
          </Nav>
        </Sidebar>
      )}
      <Box
        flex
        background="dark-4"
        pad="small"
        style={{ position: "relative" }}
      >
        <Box
          width="xlarge"
          style={{
            margin: "0 auto",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
