import { checkboxProps } from '@core/common/siteConfigsHelpers';
import FormFieldCheckBox from '@core/components/atoms/FormFieldCheckBox';
import MessageBox from '@core/components/atoms/MessageBox';
import SectionHeading from '@core/components/atoms/SectionHeading';
import FloatingSaveButton from '@core/components/molecules/FloatingSaveButton';
import { WithGameSidebar } from '@core/components/molecules/WithGameSidebar';
import OwnNav from '@core/components/organisms/OwnNav';
import SitesEditorAction from '@core/redux/sitesEditor/actions';
import { Box, Heading, Markdown, Text } from 'grommet';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { useSiteFromParams } from './EditSitePage';
import MarkdownEditor from '../../../../molecules/MarkdownEditor';

const List = styled.ul`
  margin: 0;
`;

export default function EditPress() {
  const [site, setSite, hasChanged] = useSiteFromParams();
  const dispatch = useDispatch();
  if (!site || !site.config) return <Redirect to="/edit/sites" />;

  const handleSave = () => {
    dispatch(SitesEditorAction.updateConfig(site));
  };

  const updateMarkdown = (text: string) => {
    setSite({
      ...site,
      config: {
        ...site.config,
        general: {
          ...site.config.general,
          pressMarkdown: text,
        },
      },
    });
  };

  return (
    <>
      <OwnNav />
      <WithGameSidebar>
        <Heading level={2}>Press Page</Heading>
        <Box gap="small" direction="column" flex pad={{ bottom: 'xlarge' }}>
          <Box flex={{ grow: 1, shrink: 0 }} background="dark-1" pad="medium">
            <SectionHeading>Basics</SectionHeading>
            <Heading margin={{ top: '0', bottom: 'small' }} level={4}>
              What is a press page?
            </Heading>
            <Text>
              It's a page on your website intended for journalists and other
              people who will make content about your game.
            </Text>
            <Heading margin={{ top: 'medium', bottom: 'small' }} level={4}>
              Why do you need press page?
            </Heading>
            <List>
              <li>
                To increase the chances that journalists write about your game.
              </li>
              <li>
                To increase the chances that YouTubers show your best content.
              </li>
            </List>
            <Heading margin={{ top: 'medium', bottom: 'small' }} level={4}>
              What do I put on press page?
            </Heading>
            <Text>A good press kit contains the following things:</Text>
            <List>
              <li>Contact Information</li>
              <li>Basic Information - What makes you different?</li>
              <li>Game Logos</li>
              <li>Studio Logos</li>
              <li>Screenshots</li>
              <li>Gifs</li>
              <li>MP4s</li>
              <li>Story Ideas</li>
            </List>
          </Box>
          <Box flex={{ grow: 1, shrink: 0 }} background="dark-1" pad="medium">
            <SectionHeading>Content</SectionHeading>
            <FormFieldCheckBox
              checkboxProps={checkboxProps(
                site,
                setSite,
                'general',
                'enablePress',
                'Enable Press Page'
              )}
            />
            <Box border={{ size: 'xsmall', color: 'dark-3' }} direction="row">
              <MarkdownEditor
                disabled={!site.config.general.enablePress}
                flex
                value={site.config.general.pressMarkdown || ''}
                onChange={updateMarkdown}
              />
              <Box flex>
                <Box height="52px" align="center">
                  <Heading level={5}>Preview</Heading>
                </Box>
                <Box
                  background={
                    site.config.general.enablePress ? 'white' : 'dark-2'
                  }
                  flex
                  border={{ size: 'xsmall', side: 'left' }}
                  pad="xsmall"
                >
                  <Markdown>{site.config.general.pressMarkdown || ''}</Markdown>
                </Box>
              </Box>
            </Box>
            <Box direction="row" margin={{ top: 'small' }} justify="between">
              <MessageBox status="unknown">
                <Text size="small">
                  Always think about <strong>who</strong> is this page intended
                  for - game reporters. Address them and make their life easier.
                </Text>
              </MessageBox>
            </Box>
          </Box>
        </Box>
      </WithGameSidebar>
      <FloatingSaveButton hasChanged={hasChanged} onSavePress={handleSave} />
    </>
  );
}
