import { MaskContext } from '@core/components/blind/contexts/MaskProvider';
import * as React from 'react';
import tutorialManager from '../../common/TutorialManager';

export default function TutorialStep({
  children,
  name,
  position,
  helpComponent,
  padding = 0,
}: {
  position: 'top' | 'right' | 'left' | 'bottom';
  children: React.ReactElement;
  helpComponent: React.ReactElement;
  name: string;
  padding?: number;
}) {
  const ref = React.useRef<null | HTMLElement>(null);
  const mask = React.useContext(MaskContext);

  React.useEffect(() => {
    tutorialManager.register(
      name,
      () => {
        if (ref.current) {
          const size = ref.current.getBoundingClientRect();
          const modifiedSize = new DOMRect(
            size.x - padding,
            size.y - padding,
            size.width + padding * 2,
            size.height + padding * 2
          );
          mask.onHighlight(modifiedSize, position, helpComponent);
        }
      },
      mask.onComplete
    );
    return () => {
      // tutorialManager.next();
      tutorialManager.unregister(name);
    };
  }, []);

  return <>{React.cloneElement(children, { ref: ref })}</>;
}
