import { config } from '@core/config/config';
import history from '@client/history';
import { Dispatch } from 'redux';
import { accountsClient } from '../accounts/client';
import { AppState } from '../reducers';
import { ESitesEditorAction, SiteView } from './types';
import authorisedFetch from '@core/common/authorisedFetch';
import toast from 'react-hot-toast';

export default class SitesEditorAction {
  static newSite = () => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const tokens = await accountsClient.getTokens();
      const token = tokens?.accessToken;
      const headers = new Headers();
      if (token) {
        headers.set('authorization', token);
      }
      const response = await fetch(config.apiUrl + 'sites', {
        method: 'POST',
        headers,
      });
      const { siteConfigEntity } = await response.json();

      const newSites = [...getState().sitesEditor.sites, siteConfigEntity];

      dispatch({
        type: ESitesEditorAction.GET_ALL,
        payload: { sites: newSites },
      });
      history.push(`/edit/${siteConfigEntity.id}/editor`);
    };
  };

  static createFromBeforeLogin = () => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const tempSite = localStorage.getItem('temp-site');
        if (tempSite) {
          const tempSiteData = JSON.parse(tempSite) as SiteView;
          const { siteConfigEntity } = await authorisedFetch<{
            siteConfigEntity: SiteView;
          }>('sites', {
            method: 'POST',
          });
          siteConfigEntity.config = tempSiteData.config;
          const newSites = [...getState().sitesEditor.sites, siteConfigEntity];
          dispatch({
            type: ESitesEditorAction.GET_ALL,
            payload: { sites: newSites },
          });
          // @ts-expect-error thunks are incompatible with with dispatch
          dispatch(SitesEditorAction.updateConfig(siteConfigEntity));
          dispatch({ type: ESitesEditorAction.DELETE_SITE, payload: -1 });
          localStorage.removeItem('temp-site');
          // Wait for few seconds so we ensure the site is loaded
          // and there is no race condition between this + loading
          history.push('/redirect?delay=2&to=/edit/sites');
        } else {
          history.push('/edit/sites');
        }
      } catch (err) {
        console.error('Problem creating website from before', err);
      }
    };
  };
  static loadAll = () => {
    return async (dispatch: Dispatch) => {
      const tokens = await accountsClient.getTokens();
      const token = tokens?.accessToken;
      const headers = new Headers();
      if (token) {
        headers.set('authorization', token);
      }
      const response = await fetch(config.apiUrl + 'sites', {
        headers,
      });
      const json = await response.json();
      const sites = json.sites;
      dispatch({ type: ESitesEditorAction.GET_ALL, payload: { sites: sites } });
    };
  };
  static updateConfig = (siteConfig: SiteView) => {
    return async (dispatch: Dispatch) => {
      try {
        if (siteConfig.id !== -1) {
          const tokens = await accountsClient.getTokens();
          const token = tokens?.accessToken;
          const headers = new Headers();
          if (token) {
            headers.set('authorization', token);
            headers.set('content-type', 'application/json');
          }
          const response = await fetch(config.apiUrl + 'sites', {
            method: 'PUT',
            headers,
            body: JSON.stringify({ siteConfig: siteConfig }),
          });
          const json = await response.json();
          dispatch({
            type: ESitesEditorAction.UPDATE_SITE_DATA,
            payload: json.site,
          });
          toast.success('Successfully updated.');
        } else {
          dispatch({
            type: ESitesEditorAction.UPDATE_SITE_DATA,
            payload: siteConfig,
          });
        }
      } catch (err) {
        toast.error('Problem updating.');
      }
    };
  };
  static addDomain = (domain: object) => ({
    type: ESitesEditorAction.ADD_DOMAIN,
    payload: domain,
  });
}
