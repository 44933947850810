import { SiteView } from '@core/redux/sitesEditor';
import { Anchor, Box, Button, Form, FormField, Text, TextInput } from 'grommet';
import * as React from 'react';
import EditSidebar from '../../molecules/EditSidebar';
import EditSidebarSection from '../../molecules/EditSidebarSection';
import * as Icons from 'grommet-icons';
import AssetUpload from '@core/components/molecules/AssetUpload';

interface Props {
  site: SiteView;
  onUpdateSite(site: SiteView): void;
  onNextTab(): void;
}

const STEPS = 4;

export default function BasicSetupTab({ onNextTab, site, onUpdateSite }: Props) {
  const handleTextUpdate = (textField: string, newGameName: string) => {
    onUpdateSite({
      ...site,
      config: {
        ...site.config,
        general: {
          ...site.config.general,
          [textField]: newGameName
        }
      }
    });
  };

  const [step, setStep] = React.useState(site.config.general.gameName ? STEPS : 0);

  const handleFormSubmit = () => {
    if (step > STEPS - 2) {
      onNextTab();
    } else {
      setStep(step + 1);
    }
  };

  const onBack = () => {
    setStep(step - 1);
  };

  const hasPreviousPage = step > 0 && step < STEPS - 1;

  const Suggestion = ({ text, id }: { text: string; id?: string }) => (
    <Anchor
      margin="xsmall"
      size="small"
      style={{ display: 'block' }}
      id={id}
      onClick={() => handleTextUpdate('subTitle', text)}
      label={text}
    />
  );

  return (
    <EditSidebar title="Let's get started" site={site}>
      <Form onSubmit={handleFormSubmit}>
        {(step === 0 || step === STEPS) && (
          <>
            <EditSidebarSection title="What is the name of your studio/publisher?">
              <FormField help="This will be used in footer and emails">
                <TextInput
                  placeholder="Studio Name"
                  id="text-input-studio-name"
                  style={{ width: '100%' }}
                  autoFocus
                  value={site.config.general.studioName || ''}
                  type="text"
                  onChange={e => handleTextUpdate('studioName', e.target.value)}
                />
              </FormField>
            </EditSidebarSection>
            <EditSidebarSection title="What is the name of your game?">
              <FormField help="You will be able to set the game Logo later.">
                <TextInput
                  placeholder="Cyberfunk"
                  id="text-input-game-name"
                  style={{ width: '100%' }}
                  autoFocus
                  value={site.config.general.gameName || ''}
                  type="text"
                  onChange={e => handleTextUpdate('gameName', e.target.value)}
                />
              </FormField>
            </EditSidebarSection>
          </>
        )}
        {(step === 1 || step === STEPS) && (
          <EditSidebarSection title="What is your game all about?">
            <FormField label="In a short sentence explain what do you do, what is unique and why people should care about your game.">
              <TextInput
                id="text-input-description"
                size="small"
                placeholder="Chaotic Destruction in VR"
                style={{ width: '100%' }}
                value={site.config.general.title || ''}
                type="text"
                autoFocus
                onChange={e => handleTextUpdate('title', e.target.value)}
              />
            </FormField>
          </EditSidebarSection>
        )}
        {(step === 2 || step === STEPS) && (
          <EditSidebarSection title="What state is the game in now?">
            <FormField label="Tell the people if they can buy it, wishlist, or just skip this step">
              <TextInput
                size="small"
                style={{ width: '100%' }}
                placeholder="Coming soon to Steam"
                value={site.config.general.subTitle || ''}
                type="text"
                onChange={e => handleTextUpdate('subTitle', e.target.value)}
                autoFocus
              />
            </FormField>
            <Text size="small" weight="bold">
              Suggestions:
            </Text>
            <Suggestion id="editor-suggestion-button" text="In Active Development" />
            <Suggestion text="Work in Progress" />
            <Suggestion text="Available now!" />
            <Suggestion text="Try Early Access Now" />
            <Suggestion text="Wishlist now on Steam" />
            <Suggestion text="Early Access in Q4 2022" />
            <Suggestion text="Coming in Q4 2022" />
          </EditSidebarSection>
        )}
        {(step === 3 || step === STEPS) && (
          <>
            <EditSidebarSection title="Trailer?">
              <FormField label="Youtube URL">
                <TextInput
                  size="small"
                  style={{ width: '100%' }}
                  id="text-input-trailer-url"
                  placeholder="https://www.youtube.com/watch?v="
                  value={site.config.general.youTubeTrailerURL || ''}
                  type="text"
                  onChange={e => handleTextUpdate('youTubeTrailerURL', e.target.value)}
                  autoFocus
                />
              </FormField>
            </EditSidebarSection>
            <EditSidebarSection title="Logo?">
              <FormField
                help="It will be used instead of your Game Name in the middle of the landing section"
                info={
                  <Box align="center" direction="row" justify="between">
                    <Box direction="row" align="center" gap="small">
                      <Icons.Image size="xsmall" />
                    </Box>
                    <Text size="small">Around 600x300</Text>
                  </Box>
                }>
                <AssetUpload onValueChange={({ url }) => handleTextUpdate('logoImage', url)} type="logo" />
              </FormField>
            </EditSidebarSection>
            <EditSidebarSection title="Background image?">
              <FormField
                info={
                  <Box align="center" direction="row" justify="between">
                    <Box direction="row" align="center" gap="small">
                      <Icons.Image size="xsmall" />
                    </Box>
                    <Text size="small">1920x1080 or bigger</Text>
                  </Box>
                }>
                <AssetUpload
                  onValueChange={({ url }) => handleTextUpdate('landingBackgroundImage', url)}
                  type="landingBackground"
                />
              </FormField>
            </EditSidebarSection>
          </>
        )}
        <Box direction="row" justify="between" align="center" margin={{ vertical: 'medium' }}>
          {hasPreviousPage && <Button onClick={onBack} secondary label="Previous" />}
          <Button primary label="Next" id="editor-button-next-page" type="submit" fill={!hasPreviousPage} />
        </Box>
      </Form>
    </EditSidebar>
  );
}
