import OwnNav from '@core/components/organisms/OwnNav';
// import { accountsClient } from '@core/features/accounts/client';
import GameListSection from '@core/components/organisms/GameListSection';
import { Box } from 'grommet';
import * as React from 'react';

export default function GameListPage() {
  return (
    <Box background="dark-1" style={{ minHeight: '100vh' }}>
      <OwnNav />
      <GameListSection />
    </Box>
  );
}
