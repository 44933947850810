import useColor from '@core/hooks/useColor';
import useVisibility from '@core/hooks/useVisibility';
import Features from '@core/components/organisms/Features';
import Feature from '@core/components/molecules/Feature';
import OwnNav from '@core/components/organisms/OwnNav';
import PageContent from '@core/components/atoms/PageContent';
import { config } from '@core/config/config';
import LandingVoting from '@core/components/organisms/LandingVoting';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Grid,
  Heading,
  Markdown,
  ResponsiveContext,
  Text,
} from 'grommet';
import * as Icons from 'grommet-icons';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Footer from '@core/components/molecules/Footer';

const SoloHeading = styled(Heading)`
  max-width: none;
  line-height: 1.4em;
  strong {
    color: ${(props) => props.theme.global.colors['dark-1']};
    background-color: ${(props) => props.theme.global.colors['brand-1']};
    padding: 0 8px;
    font-weight: ${(props) => props.theme.heading.weight};
  }
`;

function UserCard({
  name,
  description,
  emoji,
}: {
  emoji: string;
  name: string;
  description: string;
}) {
  return (
    <Box margin="small" align="center" pad="xsmall" direction="column">
      <Text size="72px">{emoji}</Text>
      <Heading margin="xsmall" level={3} textAlign="center">
        {name}
      </Heading>
      <Text textAlign="center">{description}</Text>
    </Box>
  );
}

function Stages() {
  const box = React.useRef<HTMLDivElement>(null);
  const isVisible = useVisibility(box, { partial: true });
  const [hasBeenVisible, setHasBeenVisible] = React.useState(false);
  React.useEffect(() => {
    if (isVisible && !hasBeenVisible) setHasBeenVisible(true);
  }, [isVisible]);

  return (
    <Box fill ref={box} align="center" pad={{ horizontal: 'large' }}>
      <Card
        fill
        background="dark-1"
        animation={{ type: hasBeenVisible ? 'fadeIn' : undefined, delay: 250 }}
        style={{ opacity: 0 }}
      >
        <CardHeader background="brand-1" pad="small">
          <Text weight="bold">1. Development</Text>
        </CardHeader>
        <CardBody>
          <Box direction="row" gap="small" pad="small">
            <Icons.Checkmark />
            Newsletter Email Collection
          </Box>
        </CardBody>
      </Card>
      <Box
        animation={{ type: hasBeenVisible ? 'fadeIn' : undefined, delay: 1250 }}
        style={{ opacity: 0, minHeight: 24 }}
      >
        <Icons.CaretDownFill color="brand" />
      </Box>
      <Card
        fill
        height="xsmall"
        background="dark-1"
        animation={{ type: hasBeenVisible ? 'fadeIn' : undefined, delay: 2000 }}
        style={{ opacity: 0 }}
      >
        <CardHeader background="brand-2" pad="small">
          <Text weight="bold">
            2. Alpha / Beta <small>(wip)</small>
          </Text>
        </CardHeader>
        <CardBody>
          <Box direction="row" gap="small" pad="small">
            <Icons.Checkmark />
            Separate Sign Up List
          </Box>
          <Box direction="row" gap="small" pad="small">
            <Icons.Checkmark />
            Virality systems built-in
          </Box>
        </CardBody>
      </Card>
      <Box
        animation={{ type: hasBeenVisible ? 'fadeIn' : undefined, delay: 3500 }}
        style={{ opacity: 0 }}
      >
        <Icons.CaretDownFill color="brand" />
      </Box>
      <Card
        fill
        height="xsmall"
        background="dark-1"
        animation={{ type: hasBeenVisible ? 'fadeIn' : undefined, delay: 4000 }}
        style={{ opacity: 0 }}
      >
        <CardHeader background="brand-3" pad="small">
          <Text weight="bold">
            3. Release <small>(wip)</small>
          </Text>
        </CardHeader>
        <CardBody>
          <Box direction="row" gap="small" pad="small">
            <Icons.Checkmark />
            Checklists to help you
          </Box>
          <Box direction="row" gap="small" pad="small">
            <Icons.Checkmark />
            Templates and Resources for Press
          </Box>
        </CardBody>
      </Card>
      <Card pad="xsmall" margin="small">
        <Text size="small">This is not a complete feature list</Text>
      </Card>
    </Box>
  );
}

export default function OwnLanding() {
  const c = useColor('dark-4');
  const shadow = `0px 0px 2px 2px ${c}`;
  const history = useHistory();
  const handleExamples = () => {
    history.push('/examples');
  };
  const handleStartFree = () => {
    history.push('/edit/sites');
  };
  const deviceSize = React.useContext(ResponsiveContext) as
    | 'small'
    | 'medium'
    | 'large';
  return (
    <>
      <Helmet title="Game Publishing Made Easy" />
      <OwnNav fixed />
      <Box
        background="dark-1"
        style={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <PageContent width="large" pad="medium" align="center">
          <SoloHeading
            textAlign="center"
            level={1}
            fill
            color="brand-2"
            margin={{ top: 'xlarge' }}
          >
            <Markdown>
              {`Start **building**, **growing** and **engaging** your gaming community in less than 5 minutes with **${config.productName}**. `}
            </Markdown>
          </SoloHeading>
        </PageContent>
        <Box alignSelf="center" gap="small" pad="large">
          <Box direction="row" gap="small">
            <Box>
              <Button
                onClick={handleExamples}
                id="examples-button"
                size={deviceSize}
                style={{ whiteSpace: 'nowrap', boxShadow: shadow }}
                label="See Examples"
              />
            </Box>
            <Box align="center">
              <Button
                onClick={handleStartFree}
                size={deviceSize}
                style={{ whiteSpace: 'nowrap' }}
                label="Start Free Now"
                id="landing-start-free-now"
                primary
              />
              <Text size="small">No Credit Card required</Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box background="dark-1">
        <PageContent direction="row" align="end">
          <Text style={{ lineHeight: '100px' }} size="100px">
            🧙‍♂️
          </Text>
          <Box
            margin={{ left: 'medium', vertical: 'large', right: 'large' }}
            round={{ size: 'medium' }}
            pad="medium"
            width="large"
            background="brand-1"
            style={{ borderBottomLeftRadius: 0 }}
          >
            Create and customize landing page from scratch or simply provide a
            link to game's Steam page.
          </Box>
        </PageContent>
      </Box>

      <Feature
        i={1}
        side="right"
        feature={{
          title:
            '**Maintain your focus**<br /> on game development and create amazing landing pages <br />and  **showcase your games**<br /> to perfection',
          description: '',
          imageUrl: '/SteamImport.png',
        }}
      />
      <Box background="dark-3">
        <PageContent direction="row" align="end" justify="end">
          <Box
            margin={{ right: 'medium', vertical: 'large', left: 'large' }}
            round={{ size: 'medium' }}
            pad="medium"
            background="brand-1"
            width="large"
            style={{ borderBottomRightRadius: 0 }}
          >
            Paste the URL to your game on Steam, choose colours that{' '}
            {config.productName} detects automatically, input a few more details
            and you're good to go.
          </Box>
          <Text style={{ lineHeight: '100px' }} size="100px">
            🧛🏽
          </Text>
        </PageContent>
      </Box>
      <Features
        features={[
          {
            title:
              '**Grow** and nurture your **social presence** by engaging the **community** early and often through different stages of your development',
            imageElement: <Stages />,
            buttonText: 'Try it now for free',
            buttonUrl: '/signup',
          },
        ]}
      />
      <Box pad="medium" background="dark-2">
        <PageContent pad="medium">
          <Heading alignSelf="center" level={2}>
            Who is it for?
          </Heading>
          <Grid
            fill
            columns={
              deviceSize === 'small'
                ? ['flex', 'flex']
                : ['flex', 'flex', 'flex', 'flex']
            }
          >
            <UserCard
              name="Explorer"
              description="Start off by discovering the idea of the game you have in your head and save tons of money and time."
              emoji="🤷"
            />
            <UserCard
              emoji="🧛"
              name="Commited Dev"
              description="Showcase your game to attract publishers and raise whishlist count."
            />
            <UserCard
              emoji="🧙"
              name="Proven Dev"
              description="Validate new features to the existing game and increase the popularity of your game."
            />
            <UserCard
              emoji="👨‍👨‍👦‍👦"
              name="Publisher"
              description="Showcase multiple games and inter connect your gamers."
            />
          </Grid>
        </PageContent>
      </Box>
      <Box pad="small" background="dark-3">
        <PageContent>
          <Feature
            feature={{
              title: 'The future of releasing games',
              description: `At ${config.productName}, we believe in a more **efficient showcasing** of new games and features.

Focus and community are the key components of every successful game as they have a significant impact on development flow and exceptional experiences that resonate with gamers.

For too long, game developers wandered without support in kickstarting communities.
${config.productName} is the first tool that helps game developers **establish a social presence** and grow through constant community interaction. 

Designed by gamers and developers for game developers, ${config.productName} immediately enhances social presence and fits into every step of the game development cycle.  `,
            }}
            i={1}
            side="center"
          />
        </PageContent>
      </Box>
      <Box pad="medium" background="brand-1" id="landing-voting-section">
        <PageContent pad="medium">
          <Heading margin="0" level={2}>
            Help us decide what to build next
          </Heading>
          <Text>
            Here are some of the things we would like to build, but we need your
            feedback and support to make it happen.
          </Text>
          <Text>
            You can vote & comment now. We will consider every piece of
            feedback.
          </Text>
          <Box align="end">
            <Heading margin="0" textAlign="end" level={3} color="dark-3" />
          </Box>
        </PageContent>
      </Box>
      <LandingVoting />
      <Footer />
    </>
  );
}
