import PageContent from '@core/components/atoms/PageContent';
import { Button, Header, Box, ResponsiveContext } from 'grommet';
import * as React from 'react';
import * as Icons from 'grommet-icons';
import { config } from '@core/config/config';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
// import { SiteView } from '@core/redux/sitesEditor';
import StartSmall from './examples/StartSmall';
import GoViral from './examples/GoViral';
import SupportedEntireWay from './examples/SupportedEntireWay';
import { AnchorLink } from '@core/components/atoms/AnchorLink';
import styled from 'styled-components';
import Footer from '@core/components/molecules/Footer';

const Link = styled(AnchorLink)`
  outline: none;
  text-decoration: none;
  color: white;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.1);
  &:hover {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.5);
  }
`;

function NPButton({
  id,
  children,
  to,
}: {
  id?: string;
  children: React.ReactNode;
  to: string;
}) {
  if (!to) {
    return <Box width="small" />;
  }
  return (
    <Link to={to} id={id} gap="small">
      <Box
        width="small"
        direction="row"
        justify="center"
        gap="small"
        align="center"
        pad="small"
      >
        {children}
      </Box>
    </Link>
  );
}

const examplePages = [
  {
    path: '/examples/start-small',
    component: StartSmall,
  },
  {
    path: '/examples/virality-engine',
    component: GoViral,
  },
  {
    path: '/examples/tools',
    component: SupportedEntireWay,
  },
];

export default function () {
  const history = useHistory();

  const size = React.useContext(ResponsiveContext);

  const handleHome = () => {
    history.push('/');
  };

  // const [currentExample, setCurrentExample] = React.useState(0);
  // const [currentSiteConfig, setCurrentSiteConfig] =
  //   React.useState<SiteView | null>(null);

  // React.useEffect(() => {
  //   const x = async () => {
  //     setCurrentSiteConfig(null);
  //     const response = await fetch(
  //       config.apiUrl + 'config/' + examples[currentExample].domain
  //     );
  //     const json = await response.json();
  //     if (json.siteConfig) {
  //       // @ts-ignore
  //       setCurrentSiteConfig({ config: json.siteConfig });
  //     }
  //   };
  //   x();
  // }, [currentExample]);

  const firstPath = examplePages[0].path;
  const { pathname } = useLocation();
  const currentIndex = examplePages.findIndex((page) => page.path === pathname);

  const prevPath = examplePages[currentIndex - 1]?.path;
  const nextPath = examplePages[currentIndex + 1]?.path;

  return (
    <>
      <Header background="brand-4" pad="small">
        <PageContent direction="row-responsive" gap="small">
          <Box direction="row">
            <Button
              style={{ color: 'white' }}
              hoverIndicator
              onClick={handleHome}
              label={config.productName + ' Home'}
              icon={<Icons.Home color="white" />}
              color="brand-4"
            />
          </Box>
          <Box flex />
          <NPButton id="examples-previous" to={prevPath}>
            <Icons.Previous /> Previous&nbsp;&nbsp;
          </NPButton>
          <NPButton id="examples-next" to={nextPath}>
            &nbsp;&nbsp;Next <Icons.Next />
          </NPButton>
          <Button
            href="/signup"
            secondary
            id="examples-try-it-now"
            fill={size === 'small'}
            label={
              <Box direction="row" justify="center" gap="small" align="center">
                Try it now
              </Box>
            }
          />
        </PageContent>
      </Header>
      <Box fill background="dark-2">
        <Switch>
          <Redirect to={firstPath} from="/examples" exact />
          {examplePages.map((page, i) => (
            <Route key={i} exact component={page.component} path={page.path} />
          ))}
        </Switch>
      </Box>
      <Footer />
    </>
  );
}
