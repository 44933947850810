import * as React from "react";
import { Button as GrommetButton, ButtonExtendedProps } from "grommet";
import * as Icons from "grommet-icons";
import useColor from "@core/hooks/useColor";
import DoubleIconHOC from "@core/components/atoms/icons/DoubleIconHOC";
import { isDark } from "@core/common/getSiteTheme";
import { IconProps } from "grommet-icons";

interface SmallItemProps extends ButtonExtendedProps {
  background: string;
  mode: "radio" | "checkbox";
  Icon: React.ElementType<IconProps>;
  selected?: boolean;
}
// type SmallItemProps = ButtonExtendedProps & {
//   color: string,
//   type: 'radio' | 'checkbox',
//   Icon: any
// }
export default function SmallDoubleIconButton({
  Icon,
  onClick,
  label,
  background,
  selected,
  mode,
  ...rest
}: SmallItemProps) {
  const modColor = useColor(background);

  const textColor = useColor(isDark(modColor) ? "light-4" : "dark-1");

  let icon: React.ReactNode;
  if (mode === "radio") {
    if (selected) {
      icon = <Icons.RadialSelected color={textColor} size="small" />;
    } else {
      icon = <Icons.Radial color={textColor} size="small" />;
    }
  } else {
    if (selected) {
      icon = <Icons.CheckboxSelected color={textColor} size="small" />;
    } else {
      icon = <Icons.Checkbox color={textColor} size="small" />;
    }
  }

  const ModIcon = DoubleIconHOC(<Icon color={textColor} size="small" />, icon);
  return (
    <GrommetButton
      label={label}
      icon={<ModIcon />}
      onClick={onClick}
      size="small"
      hoverIndicator={{ elevation: "small", background: "red" }}
      style={{ background: modColor, color: textColor }}
      {...rest}
    />
  );
}
