import {
  CheckBox,
  FormField,
  ThemeContext,
  FormFieldExtendedProps,
  CheckBoxExtendedProps,
} from 'grommet';
import * as React from 'react';

export default function FormFieldCheckBox({
  checkboxProps,
  ...rest
}: FormFieldExtendedProps & {
  checkboxProps: CheckBoxExtendedProps;
  gridArea?: string;
}) {
  return (
    <ThemeContext.Extend
      value={{
        formField: {
          border: {
            position: 'outer',
            size: '0',
          },
          content: {
            pad: {
              left: '0',
              top: 'small',
              bottom: 'small',
            },
          },
        },
      }}
    >
      <FormField {...rest}>
        <CheckBox {...checkboxProps} />
      </FormField>
    </ThemeContext.Extend>
  );
}
