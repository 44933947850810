import {
  Card,
  CardBody,
  CardHeader
} from "grommet";
import * as React from "react";
import { Text } from "grommet";
import useColor from "@core/hooks/useColor";

export default function CarouselItem({ game, title }: { game?: string; title?: string; }) {
  const dark1 = useColor("dark-3");
  const dark3 = useColor("dark-5");
  return (
    <Card round="xsmall" width="small" style={{ minWidth: 180 }}>
      <CardHeader
        pad={{ horizontal: "small", vertical: "xsmall" }}
        background={`linear-gradient(-45deg, ${dark3} 0%, ${dark1} 100%)`}
        hoverIndicator={{
          background: `linear-gradient(-45deg, ${dark3} 0%, ${dark3} 100%)`,
        }}
      >
        <Text weight="bold" size="xsmall">
          {game || "ARK: Survival Evolved Events"}
        </Text>
      </CardHeader>
      <CardBody pad="xsmall" style={{ minHeight: 48 }}>
        <Text size="small">
          {title || "Welcome to the ARK! Coming to Early Access, June 2"}
        </Text>
      </CardBody>
    </Card>
  );
}
