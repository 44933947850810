import React from 'react';
import { IconProps, Blank } from 'grommet-icons';

function Twitch(props: IconProps) {
  return (
    <Blank {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M.975 4.175v16.694h5.749V24h3.139l3.134-3.132h4.705l6.274-6.258V0H2.542zm3.658-2.09h17.252v11.479l-3.66 3.652h-5.751L9.34 20.343v-3.127H4.633z" />
        <path d="M10.385 6.262h2.09v6.26h-2.09zM16.133 6.262h2.091v6.26h-2.091z" />
      </svg>
    </Blank>
  );
}

export default Twitch;
