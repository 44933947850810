import {
  Box, FormField, TextInput,
  Paragraph
} from 'grommet';
import * as React from 'react';
import { PreviewComponentScaled } from '@core/components/blind/PreviewComponent';
import { checkboxProps, inputProps } from '@core/common/siteConfigsHelpers';
import { SubsectionProps } from '../pages/publeash/games/id/EditContacts';
import SectionHeading from '../atoms/SectionHeading';
import FormFieldCheckBox from '../atoms/FormFieldCheckBox';

export default function EditContactsBasicSettings({ site, onUpdateSite }: SubsectionProps) {
  return (
    <Box flex background="dark-1" pad="medium">
      <Box direction="row-responsive" gap="small">
        <Box width="medium">
          <SectionHeading>Newsletter Collection</SectionHeading>
          <Paragraph>
            It's recommended to have an email list, especially if you plan on
            launching multiple games or play tests.
          </Paragraph>
          <FormFieldCheckBox
            checkboxProps={checkboxProps(
              site,
              onUpdateSite,
              'general',
              'collectNewsletterEmails',
              'Enable Default Email Collection'
            )} />
          <FormField help="This will be shown just above the email input">
            <TextInput
              id="text-input-persuasion"
              type="text"
              {...inputProps(
                site,
                onUpdateSite,
                'general',
                'collectNewsletterEmailsText'
              )} />
          </FormField>
        </Box>
        <Box flex fill>
          <PreviewComponentScaled height="50vh" site={site} />
        </Box>
      </Box>
    </Box>
  );
}
