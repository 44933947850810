import { TUTORIAL } from '@core/common/constants';
import { defaultSiteConfig } from '@core/common/siteConfigs';
import Discord from '@core/components/atoms/icons/Discord';
import OwnNav from '@core/components/organisms/OwnNav';
import { AppState } from '@core/redux/reducers';
import { SiteView } from '@core/redux/sitesEditor';
import { Box } from 'grommet';
import * as Icons from 'grommet-icons';
import { Twitter } from 'grommet-icons';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import HelpComponent from '../../../../molecules/HelpComponent';
import NavBoxItems from '../../../../molecules/NavBoxItems';
import NavBoxesTemplate, {
  Groups,
} from '../../../../templates/NavBoxesTemplate';
import tutorialManager from '../../../../../common/TutorialManager';
import TutorialStep from '../../../../blind/TutorialStep';
import { WithGameSidebar } from '@core/components/molecules/WithGameSidebar';

// const options = {
//   Setup: ['Website', 'Leaderboards'],
//   'Multi Posts': ['Announcement', 'Update', 'Release', 'Scheduler'],
//   Feedback: ['Configure Voting', 'Enable Idea Suggestions', 'Enable Bug Reporting', 'Run Alpha/Beta'],
//   Checklists: ['Announcement', 'Alpha/Beta', 'Release', 'Booth'],
// };

// const options = {
//   'Quick Actions': ['Configure Website', 'Content Posting', 'Feedback Management', 'Organise Alphas'],
//   Checklists: ['Announcement', 'Alpha/Beta', 'Release', 'Booth'],
// };

export default function () {
  const { id } = useParams<{ id: string | undefined }>();

  const sites = useSelector<AppState, SiteView[]>((state) => {
    return state.sitesEditor.sites;
  });
  const site = React.useMemo<SiteView>(
    () => sites.find((s) => s.id === parseInt(id || '0', 10))!,
    [sites]
  );

  if (!site || !site.config) return <Redirect to="/edit/sites" />;

  const configureBasics = {
    title: 'Configure Basics',
    completed: !!site.config.general.gameName,
  };
  const theme = {
    title: 'Set up theme',
    completed: site.config.theme.primary !== defaultSiteConfig.theme.primary,
  };

  const pressKitItems = [
    {
      title: 'Enable Press Subpage',
      completed: !!site.config.general.enablePress,
    },
    'Make it easy for media to share your work',
  ];

  const growthItems = [
    {
      title: 'Enable Contact Collection',
      completed: !!site.config.general.collectNewsletterEmails,
    },
    {
      title: 'Enable Viral Actions',
      completed: !!site.config.thankYouPage?.enableActions,
    },
    'Manage Contact Collection',
  ];

  const options: Groups = {
    General: [
      {
        title: 'Configure Website',
        icon: <Icons.Domain color="brand-1" size="large" />,
        children: (
          <NavBoxItems items={[configureBasics, theme, 'Connect Domain']} />
        ),
        to: `/edit/${id}/editor`,
      },
      {
        title: 'Growth & Virality',
        icon: <Icons.Optimize color="brand-1" size="large" />,
        children: <NavBoxItems items={growthItems} />,
        to: `/edit/${id}/contacts`,
      },
      {
        title: 'Prepare Press Kit',
        children: <NavBoxItems items={pressKitItems} />,
        icon: <Icons.Article color="brand-1" size="large" />,
        to: `/edit/${id}/press`,
      },
      {
        title: 'Feedback Management',
        children: (
          <NavBoxItems
            items={[
              {
                title: 'Enable Feedback Pages',
                completed: !!site.config.general.enableFeedback,
              },
              'Gather ideas, bugs and other feedback',
              'Review Comments and Posts',
            ]}
          />
        ),
        to: `/edit/${id}/feedback`,
        icon: (
          <TutorialStep
            position="top"
            helpComponent={
              <HelpComponent
                content={
                  <Box width="medium">
                    Some of the features are just concepts{' '}
                    <Box
                      background="dark-1"
                      style={{
                        display: 'inline-block',
                        padding: '3px',
                        borderRadius: '50%',
                        width: '1.5em',
                        height: '1.5em',
                      }}
                      align="center"
                      justify="center"
                    >
                      <Icons.InProgress color="brand-1" size="small" />
                    </Box>{' '}
                    You can check out what we are thinking and with your
                    feedback we can build something really useful.
                  </Box>
                }
                buttonText="Alright!"
                title="Tell us what you need"
              />
            }
            padding={16}
            name="wip"
          >
            <div>
              <Icons.UserExpert color="brand-1" size="large" />
            </div>
          </TutorialStep>
        ),
      },
      {
        title: 'Content Preparation',
        icon: <Icons.Connectivity color="brand-1" size="large" />,
        children: (
          <NavBoxItems
            items={[
              'Convert for Steam',
              'Convert for Twitter',
              'Convert for Reddit',
            ]}
          />
        ),
        to: `/edit/${id}/converters`,
      },
      {
        title: 'Integrations',
        icon: (
          <Box gap="small" direction="row" margin={{ right: 'large' }}>
            <Twitter color="brand-1" opacity={WIP_OPACITY * 2} size="large" />
            <Discord color="brand-1" opacity={WIP_OPACITY * 2} size="large" />
          </Box>
        ),
        wip: true,
        children: (
          <NavBoxItems
            items={[
              {
                completed: false,
                title: 'Connect Discord for advanced functionality',
              },
              { completed: false, title: 'Connect Twitter for auto posting' },
            ]}
          />
        ),
        to: `/edit/${id}/integrations`,
      },
      {
        title: 'Set up leaderboards',
        icon: (
          <Icons.InProgress
            color="brand-1"
            opacity={WIP_OPACITY}
            size="large"
          />
        ),
        wip: true,
        disabled: true,
        to: `/edit/${id}/leaderboards`,
        children: (
          <NavBoxItems
            items={[
              { completed: false, title: 'Configure Leaderboards' },
              'Perfect for single player games',
              'Integrates with Discord and engages community',
            ]}
          />
        ),
      },
    ],
    Checklists: [
      {
        title: 'Announcement',
        icon: <Icons.Task color="dark-5" />,
        to: '/checklist',
      },
      {
        title: 'Limited Event',
        icon: <Icons.Task color="dark-5" />,
        to: '/checklist',
      },
      {
        title: 'Release',
        icon: <Icons.Task color="dark-5" />,
        to: '/checklist',
      },
      {
        title: 'Real-life Event',
        icon: <Icons.Task color="dark-5" />,
        to: '/checklist',
      },
    ],
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (!localStorage.getItem(TUTORIAL.HAS_SEEN_WIP_TUTORIAL)) {
        tutorialManager.start(['wip']);
        localStorage.setItem(
          TUTORIAL.HAS_SEEN_WIP_TUTORIAL,
          JSON.stringify(true)
        );
      }
    }, 300);
  }, []);

  return (
    <Box background="dark-1">
      <OwnNav />
      <WithGameSidebar>
        <NavBoxesTemplate
          title={`${site.config.general.gameName || 'Unnamed Game'} Dashboard`}
          groups={options}
        />
      </WithGameSidebar>
    </Box>
  );
}

const WIP_OPACITY = 0.3;
