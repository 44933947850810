import * as React from 'react';
import { Text, Heading, Box } from 'grommet';
import * as Icons from 'grommet-icons';
import { Icon as GrommetIcon } from 'grommet-icons';
import styled from 'styled-components';
import { loading } from '@core/common/animations';
import useRound from '@core/hooks/useRound';
import toast from 'react-hot-toast';
import { useApiCompleteActionContact } from '@core/hooks/requests';
import { useAppSelector } from '@core/hooks/requestUtils';
import { selectCurrentSiteId } from '@core/redux/siteConfig';
import useSessionContact from '@core/hooks/useSessionContact';
import { ActionTypes, ContactView } from '@core/types';

const colors = {
  background: 'light-5',
  rightComplete: 'status-ok',
  right: 'light-4',
};
const LoadingIcon = styled(Icons.New)`
  animation: ${loading} 2000ms ease-in-out 0ms infinite;
`;

interface Props {
  color: string;
  name: string;
  draws: number;
  Icon: GrommetIcon;
  actionType: ActionTypes;
  link?: string;
  instructions?: string;
}

const hasCompleted = (contact: ContactView | null, actionType: ActionTypes) => {
  return !!contact?.completed_viral_actions?.some(
    (t) => t.actionType === actionType
  );
};

export default function SocialActionInput({
  color,
  name,
  draws,
  Icon,
  actionType,
  instructions,
  link,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [contact, setContact] = useSessionContact();
  const complete = hasCompleted(contact, actionType);
  const gradientEnd = 'transparent';
  const gradientStart = color;
  const linearGradient = `linear-gradient(to right, ${gradientStart} 50%, ${gradientEnd} 80%);`;
  const round = useRound();
  const siteId = useAppSelector(selectCurrentSiteId);
  const actionMutation = useApiCompleteActionContact({
    siteId,
    contactId: contact?.id || '-1',
    actionType: actionType,
    onSuccess: (data) => {
      const firstId = data.result.contact?.[0];
      const firstElm = data.entities.contact?.[firstId || -1];
      if (firstElm) {
        toast.success(`Awarded ${draws} Entries!`);
        setContact(firstElm);
      }
    },
  });
  const handleClick = () => {
    setIsLoading(true);
    const toastId = toast.loading(instructions || 'Waiting...');
    window.open(link, '_blank');
    setTimeout(() => {
      setIsLoading(false);
      toast.dismiss(toastId);
      actionMutation.mutate();
    }, 10000);
  };
  if (!link) return null;
  return (
    <Box
      background="light-4"
      margin={{ vertical: 'xsmall' }}
      round={round}
      overflow="hidden"
    >
      <Box
        background={linearGradient}
        style={{ transition: 'background 240ms linear' }}
        justify="center"
        onClick={handleClick}
        hoverIndicator={{
          background: 'light-1',
        }}
      >
        <Box direction="row">
          <Box pad="small" justify="center" background={color}>
            <Icon color="white" size="medium" />
          </Box>
          <Box pad="xsmall" flex justify="center">
            <Heading
              margin={{ horizontal: 'xsmall', vertical: '0' }}
              level={4}
              color="white"
            >
              {name}
            </Heading>
          </Box>
          <Box
            pad="small"
            justify="center"
            background={complete ? colors.rightComplete : colors.right}
            direction="row"
            align="center"
            width="72px"
          >
            {complete ? (
              <Icons.Validate size="medium" color="white" />
            ) : (
              <>
                {isLoading ? (
                  <LoadingIcon size="small" />
                ) : (
                  <>
                    <Icons.New size="small" />
                    &nbsp; <Text weight="bold">{draws}</Text>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
