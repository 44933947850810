import { Box, BoxExtendedProps } from 'grommet';
import React from 'react';

interface Props extends BoxExtendedProps {
  children: React.ReactNode;
}

export default function PageContent(props: Props) {
  const { children, ...rest } = props;
  return (
    <Box margin={{ horizontal: 'auto' }} width="xlarge" {...rest}>
      {children}
    </Box>
  );
}
