import ClientLandingPage from '@core/components/pages/client/ClientLandingPage';
import ClientEditorPage from '@core/components/pages/client/ClientEditorPage';
import GameListPage from '@core/components/pages/publeash/games/GameListPage';
import EditSitePage from '@core/components/pages/publeash/games/id/EditSitePage';
import Pricing from '@core/components/pages/publeash/Pricing';
import PubleashAdminPage from '@core/components/pages/publeash/AdminPage';
import Signup from '@core/components/pages/publeash/user/Signup';
import Settings from '@core/components/pages/publeash/user/Settings';
import Examples from '@core/components/pages/publeash/Examples';
import Redirect from '@core/components/pages/Redirect';
import Checklist from '@core/components/pages/publeash/Checklist';
import Login from '@core/components/pages/publeash/user/Login';
import { AppRoute } from '@core/common/routes';
import GameDashboard from '@core/components/pages/publeash/games/id/GameDashboard';
import EditPress from '@core/components/pages/publeash/games/id/EditPress';
import EditIntegrations from '@core/components/pages/publeash/games/id/EditIntegrations';
import EditFeedback from '@core/components/pages/publeash/games/id/EditFeedback';
import EditContacts from '@core/components/pages/publeash/games/id/EditContacts';
import PreparePost from '@core/components/pages/publeash/games/id/PreparePost';
import Converters from '@core/components/pages/publeash/Converters';
import FeedbackPost from '@core/components/pages/feedback/post/FeedbackPost';
import FeedbackPostList from '@core/components/pages/feedback/posts/FeedbackPostList';
import FeedbackCategoryList from '@core/components/pages/feedback/categories/FeedbackCategoryList';
import OAuthCallback from '@core/components/pages/publeash/OAuthCallback';
import ClientSocialsPage from '@core/components/pages/client/ClientSocialsPage';
import VerifyTokenPage from '@core/components/pages/VerifyTokenPage';
import PressPage from '@core/components/pages/client/PressPage';
import ViewContacts from '@core/components/pages/publeash/games/id/ViewContacts';
import EnsureSiteLoadedHOC from '@core/components/blind/EnsureSiteLoadedHOC';
import LandingPageB from '@core/components/pages/publeash/LandingPageB';
import LandingPage from '@core/components/pages/publeash/LandingPage';
import PrepareContent from '@core/components/pages/publeash/PrepareContent';

const SharedPages: AppRoute[] = [
  {
    path: '/feedback/post/:postId',
    component: FeedbackPost,
  },
  {
    path: '/feedback/category/:categoryId',
    component: FeedbackPostList,
  },
  {
    path: '/feedback',
    component: FeedbackCategoryList,
  },
  {
    path: '/verify-token/:token',
    component: VerifyTokenPage,
  },
];

const ClientOnlyPages = [
  ...SharedPages,
  {
    component: ClientSocialsPage,
    path: '/socials',
  },
  {
    component: PressPage,
    path: '/press',
  },
  {
    component: ClientLandingPage,
    path: '/',
  },
];

const PubleashOnlyPages: AppRoute[] = [
  ...SharedPages,
  {
    component: Redirect,
    path: '/redirect',
    exact: true,
  },
  {
    component: Checklist,
    path: '/checklist/:checklistName?',
    exact: true,
  },
  {
    component: Examples,
    path: '/examples',
  },
  {
    component: Settings,
    path: '/settings',
    exact: true,
  },
  {
    component: Login,
    path: '/login',
    exact: true,
  },
  {
    component: Signup,
    path: '/signup',
    exact: true,
  },
  {
    component: PubleashAdminPage,
    path: '/admin',
    exact: true,
  },
  {
    component: GameListPage,
    path: '/edit/sites',
  },
  {
    component: EnsureSiteLoadedHOC(GameDashboard),
    path: '/edit/:id',
    exact: true,
  },
  {
    component: EnsureSiteLoadedHOC(EditSitePage),
    path: '/edit/:id/editor',
  },
  {
    component: EnsureSiteLoadedHOC(EditFeedback),
    path: '/edit/:id/feedback',
  },
  {
    component: EnsureSiteLoadedHOC(ViewContacts),
    path: '/edit/:id/contacts/all',
  },
  {
    component: EnsureSiteLoadedHOC(EditContacts),
    path: '/edit/:id/contacts',
  },
  {
    component: EnsureSiteLoadedHOC(PreparePost),
    path: '/edit/:id/converters/prepare-post',
  },
  {
    component: EnsureSiteLoadedHOC(Converters),
    path: '/edit/:id/converters',
  },
  {
    component: EnsureSiteLoadedHOC(EditPress),
    path: '/edit/:id/press',
  },
  {
    component: EnsureSiteLoadedHOC(EditIntegrations),
    path: '/edit/:id/integrations',
  },
  {
    component: ClientEditorPage,
    path: '/editor',
  },
  {
    component: Pricing,
    path: '/pricing',
  },
  {
    component: PrepareContent,
    path: '/content',
  },
  {
    component: LandingPage,
    path: '/B',
    exact: true,
  },
  {
    component: OAuthCallback,
    path: '/oauth-callback/:service',
  },
  {
    component: LandingPageB,
    path: '/:modal?',
  },
];

export { PubleashOnlyPages, ClientOnlyPages };
