import { BoxExtendedProps, Card, Heading } from 'grommet';
import * as React from 'react';

interface Props extends BoxExtendedProps {
  title?: string;
  children: React.ReactNode;
}

export default function EditSidebarSection(props: Props) {
  const { title, children, ...rest } = props;
  return (
    <Card background="dark-2" pad="small" margin={{ horizontal: 'small', vertical: 'medium' }} flex="grow" {...rest}>
      {title && (
        <Heading margin={{ top: 'xsmall', bottom: 'xsmall' }} level={4}>
          {title}
        </Heading>
      )}
      {children}
    </Card>
  );
}
