import { config } from '@core/config/config';
import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { ESiteConfigAction } from './types';

export default class SiteConfigAction {
  static loadConfigAction = () => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const ourSubdomain = getState().siteConfig.domain || '';

      const response = await fetch(config.apiUrl + 'config/' + ourSubdomain);
      const json = await response.json();

      dispatch({
        type: ESiteConfigAction.GET_ALL,
        payload: { config: json.siteConfig, siteId: json.siteId },
      });
    };
  };

  static updateGeneralConfigValue(keyName: string, value: string) {
    return {
      type: ESiteConfigAction.UPDATE_GENERAL_VALUE,
      payload: { keyName, value },
    };
  }

  static updateThemeConfigValue(keyName: string, value: string | number) {
    return {
      type: ESiteConfigAction.UPDATE_THEME_VALUE,
      payload: { keyName, value },
    };
  }

  static setSiteDomain = (domain: string) => ({
    type: ESiteConfigAction.SET_SITE_DOMAIN,
    payload: domain,
  });
}
