import { Box, Heading, Markdown, Text } from 'grommet';
import * as React from 'react';
import { VotePostView } from '@core/types';
import CategoryTag from '../atoms/CategoryTag';
import useRound from '@core/hooks/useRound';
import * as Icons from 'grommet-icons';

export default function PostBrowserItem({
  post,
  onClick,
  size = 'medium',
}: {
  post: VotePostView;
  size?: 'small' | 'medium';
  onClick?(): void;
}) {
  const dynamicSmall = size === 'medium' ? 'small' : 'xsmall';
  const dynamicMedium = size === 'medium' ? 'medium' : 'small';
  const round = useRound(dynamicMedium);
  return (
    <Box
      className="PostBrowserItem"
      key={post.id}
      hoverIndicator={{ background: 'dark-4' }}
      onClick={onClick}
      background="dark-3"
      elevation={dynamicSmall}
      pad={{ vertical: dynamicSmall, horizontal: dynamicMedium }}
      margin={{ vertical: 'xsmall' }}
      style={{ position: 'relative' }}
      round={round}
      direction="row"
      align="center"
      gap={dynamicMedium}
    >
      <Heading
        margin="0"
        level={size === 'small' ? 4 : 3}
        style={{ whiteSpace: 'nowrap' }}
      >
        <Icons.Like size={size === 'small' ? 'small' : 'medium'} />{' '}
        {post.rating}
      </Heading>
      <Box flex={{ grow: 1, shrink: 1 }} gap="xsmall">
        <Text weight="bold" size={dynamicMedium}>
          {post.title}
        </Text>
        <Box direction="row" gap="small">
          <Text size="xsmall">
            <Icons.Chat size="xsmall"/> {post.commentsCount}{' '}
            Comments
          </Text>
          <Text size="xsmall">
            {new Date(post.createdAt).toLocaleDateString()}
          </Text>
        </Box>
        {size !== 'small' && (
          <Text
            size={dynamicSmall}
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <Markdown
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              options={{
                forceInline: true,
                overrides: {
                  img: { component: () => null },
                },
              }}
            >
              {post.content}
            </Markdown>
          </Text>
        )}
      </Box>
      <CategoryTag categoryId={post.categoryId} />
    </Box>
  );
}
