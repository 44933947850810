import {
  Heading,
  HeadingExtendedProps
} from 'grommet';
import * as React from 'react';

export default function SectionHeading(props: HeadingExtendedProps) {
    return (
        <Heading level={3} margin={{ top: '0', bottom: 'small' }} {...props} />
    );
}
