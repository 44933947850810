import { useApiExchangeToken } from '@core/hooks/requests';
import { useAppSelector } from '@core/hooks/requestUtils';
import useSessionContact from '@core/hooks/useSessionContact';
import { selectCurrentSiteId } from '@core/redux/siteConfig';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Box, Text } from 'grommet';
import PageContent from '../atoms/PageContent';
import ClientSubpageHeader from '../organisms/ClientSubpageHeader';
import MessageBox from '../atoms/MessageBox';
import logger from '@server/lib/utils/logger';
import * as Icons from 'grommet-icons';
import EmailInputCollection from '../molecules/EmailInputCollection';

export default function VerifyTokenPage() {
  const { token } = useParams<{ token: string }>();
  const siteId = useAppSelector(selectCurrentSiteId);
  const exchangeTokenMutation = useApiExchangeToken(siteId, token);

  const [_contact, setContact] = useSessionContact();

  const history = useHistory();

  useEffect(() => {
    exchangeTokenMutation
      .mutateAsync()
      .then((data) => {
        const contactId = data.result.contact?.[0] || -1;
        const contact = data.entities.contact?.[contactId];
        setContact(contact || null, data.token);
        history.replace('/socials');
      })
      .catch((err) => {
        logger.error('Error', { err });
      });
  }, []);

  return (
    <>
      <ClientSubpageHeader/>
      <PageContent align="center" justify="center" pad="small" gap="medium">
        {exchangeTokenMutation.isLoading && (
          <MessageBox status="info" width="medium" pad="small">
            <Box direction="row" wrap={false} align="center" gap="small">
              <Icons.Action size="small" />
              <Text>Verifying login...</Text>
            </Box>
          </MessageBox>
        )}
        {exchangeTokenMutation.isError && (
          <MessageBox status="error" width="medium">
            <Box
              direction="row"
              wrap={false}
              align="center"
              gap="small"
              pad="small"
            >
              <Icons.Clock size="medium" />
              <Text>Link expired. Enter your email to re-send.</Text>
            </Box>
          </MessageBox>
        )}
        {exchangeTokenMutation.isError && <EmailInputCollection />}
      </PageContent>
    </>
  );
}
