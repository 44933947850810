import Discord from '@core/components/atoms/icons/Discord';
import Steam from '@core/components/atoms/icons/Steam';
import { WithGameSidebar } from '@core/components/molecules/WithGameSidebar';
import OwnNav from '@core/components/organisms/OwnNav';
import { Box } from 'grommet';
import { Reddit, Twitter } from 'grommet-icons';
import * as React from 'react';
import { useParams } from 'react-router';
import NavBoxItems from '../../molecules/NavBoxItems';
import NavBoxesTemplate, { Groups } from '../../templates/NavBoxesTemplate';

export default function Converters() {
  const { id } = useParams<{ id: string | undefined }>();
  const navGroups: Groups = {
    ['Write Once Use Everywhere']: [
      {
        title: 'Publeash → Multiple Platforms',
        to: `/edit/${id}/converters/prepare-post`,
        children: (
          <NavBoxItems
            items={[
              'Write a post on Publeash',
              'Get exports for multiple platforms at once',
            ]}
          />
        ),
        icon: (
          <Box gap="small" direction="row" margin={{ right: 'large' }}>
            <Discord />
            <Steam />
            <Twitter />
          </Box>
        ),
      },
    ],
    ['Convert Text']: [
      {
        title: 'Publeash → Multi Post',
      },
      {
        title: 'Markdown → Steam BB',
        icon: <Steam />,
      },
    ],
    ['Prepare Videos']: [
      {
        title: 'Video → Steam Store Gif',
        icon: <Steam />,
        children: (
          <NavBoxItems
            items={[
              'Steam Event Posts can only use Gifs',
              'Use this page to convert video files to Steam optimised gif ',
            ]}
          />
        ),
      },
      {
        title: 'Discord Video',
        icon: <Discord />,
        children: (
          <NavBoxItems
            items={[
              'Make sure Discord video is played without interaction',
              'Make sure video is under 8MB in size for free Discord servers',
            ]}
          />
        ),
      },
      {
        title: 'Video → Reddit',
        icon: <Reddit />,
        children: (
          <NavBoxItems
            items={['Make sure it plays well on all devices and clients.']}
          />
        ),
      },
    ],
    ['Prepare Images']: [
      {
        title: 'Steam Assets',
        wip: true,
      },
      {
        title: 'Social Media Headers',
        wip: true,
      },
    ],
  };

  return (
    <Box background="dark-1">
      <OwnNav />
      <WithGameSidebar>
        <NavBoxesTemplate
          title="Prepare your files for..."
          description="A set of well tested converters and formatters for popular marketing channels in game dev."
          groups={navGroups}
        />
      </WithGameSidebar>
    </Box>
  );
}
