import * as React from "react";
import { Box, Text, Heading, Image } from "grommet";
import { SupportedPlatform } from "@core/config/platforms";
import HelpCard from "../atoms/HelpCard";
import * as Icons from "grommet-icons";
import Button from "../atoms/Button";
import { UpdateData } from "../pages/publeash/useUpdateData";

interface Props {
  platforms: SupportedPlatform[];
  updateData: UpdateData;
  onChange(data: UpdateData): void;
}

export default function PrepareContentExport({ platforms }: Props) {
  return (
    <Box
      pad="medium"
      width="large"
      justify="start"
      border={{
        side: "right",
        size: "xsmall",
        color: "dark-4",
      }}
      gap="small"
    >
      <HelpCard title="Emails">
        <Box pad="small" direction="row" gap="small">
          <Button
            icon={<Icons.Copy size="small" />}
            size="small"
            primary
            label="Copy HTML"
          ></Button>
          <Box flex></Box>
          <Button
            icon={<Icons.View size="small" />}
            size="small"
            secondary
            label="Preview"
          ></Button>
        </Box>
      </HelpCard>
      <HelpCard title="Discord" pad="small">
        <Box margin="medium">
          <ImagePreview size={{ x: 1280, y: 720 }} label="Video" />
        </Box>
        <Box direction="row" gap="small">
          <Button
            icon={<Icons.Copy size="small" />}
            size="small"
            primary
            label="Copy Message"
          ></Button>
          <Box flex></Box>
          <Button
            icon={<Icons.View size="small" />}
            size="small"
            secondary
            label="Preview"
          ></Button>
        </Box>
      </HelpCard>
      <HelpCard title="Steam" pad="small">
        <Heading level={6} margin={{ vertical: "xsmall" }}>
          Assets
        </Heading>
        <Box pad="medium" gap="small">
          <ImagePreview
            label="Cover image"
            size={{ x: 800, y: 450 }}
          ></ImagePreview>
          <ImagePreview
            label="Spotlight image"
            size={{ x: 2104, y: 460 }}
          ></ImagePreview>
          <ImagePreview
            label="Header image"
            size={{ x: 1922, y: 622 }}
          ></ImagePreview>
        </Box>
        <Box direction="row" gap="small">
          <Button
            icon={<Icons.Copy size="small" />}
            size="small"
            primary
            label="Copy BBCode"
          ></Button>
          <Box flex></Box>
          <Button
            icon={<Icons.View size="small" />}
            size="small"
            secondary
            label="Preview"
          ></Button>
        </Box>
      </HelpCard>
      <HelpCard title="Website">c</HelpCard>
    </Box>
  );
}

function ImagePreview({
  size,
  label,
}: {
  label: string;
  size: { x: number; y: number };
}) {
  const { x, y } = size;
  return (
    <Box style={{ position: "relative" }}>
      <Text
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          background: "rgba(0,0,0,0.3)",
          padding: 8,
        }}
        textAlign="center"
      >
        {label}
      </Text>
      <Box
        style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
        direction="row"
        pad="small"
        justify="end"
        gap="small"
      >
        <Button
          icon={<Icons.Download size="xsmall" />}
          size="xsmall"
          label="Download"
        ></Button>
        <Button
          icon={<Icons.Copy size="xsmall" />}
          size="xsmall"
          label="Copy Link"
        ></Button>
      </Box>
      <Image
        style={{ aspectRatio: `${x} / ${y}` }}
        fallback={`https://place-hold.it/${x}x${y}`}
      />
    </Box>
  );
}
