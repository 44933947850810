import { FormField, FormFieldExtendedProps } from "grommet";
import * as React from "react";
import { Text } from "grommet";

export default function SmallFormField({
  label,
  children,
  help,
  info,
  ...rest
}: FormFieldExtendedProps) {
  return (
    <FormField
      label={
        label && (
          <Text size="small" weight="bold">
            {label}
          </Text>
        )
      }
      help={help && <Text size="small">{help}</Text>}
      info={typeof info === "string" ? <Text size="small">{info}</Text> : info}
      {...rest}
    >
      {children}
    </FormField>
  );
}
