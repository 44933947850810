import * as React from 'react';
import { Route, Switch } from 'react-router';

import { AppRoute } from '../../common/routes';

export default function TopLevelRoutes({routes}: {routes?: AppRoute[]}) {
  if (!routes) return null;

  return <Switch>{routes.map(props => props.path && <Route {...props} key={props.path.toString()} />)}</Switch>;
}
