import ClientLandingSection from '@core/components/organisms/ClientLandingSection';
import { Box, Button } from 'grommet';
import * as React from 'react';

export default function ClientEditorPage() {
  return (
    <>
      <ClientLandingSection />
      <Box style={{ width: '100vw', height: '100vh' }}>
        Second Section Does it work?
        <Box background="dark-1" pad="small" />
        <Box background="dark-2" pad="small" />
        <Box background="dark-3" pad="small" />
        <Box background="dark-4" pad="small" />
        <Box background="dark-5" pad="small" />
        <Box background="dark-6" pad="small" />
        --
        <Box background="light-1" pad="small" />
        <Box background="light-2" pad="small" />
        <Box background="light-3" pad="small" />
        <Box background="light-4" pad="small" />
        <Box background="light-5" pad="small" />
        <Box background="light-6" pad="small" />
        --
        <Box background="brand-1" pad="small" />
        <Box background="brand-2" pad="small" />
        <Box background="brand" pad="small" />
        <Box background="brand-3" pad="small" />
        <Box background="brand-4" pad="small" />
        --
        <Box background="accent-1" pad="small" />
        <Box background="accent-2" pad="small" />
        <Box background="accent-3" pad="small" />
        <Box background="accent-4" pad="small" />
        --
        <Box background="neutral-1" pad="small" />
        <Box background="neutral-2" pad="small" />
        <Box background="neutral-3" pad="small" />
        <Box background="neutral-4" pad="small" />
        --
        <Button size="large" label="Primary" primary />
        <Button size="large" secondary label="Secondary" />
      </Box>
    </>
  );
}
