import { Box, Header, Heading, Nav } from "grommet";
import * as React from "react";
import { SiteConfigContext } from "../blind/contexts/SiteConfigContext";
import { AnchorLink, AnchorLinkProps } from "../atoms/AnchorLink";
import styled from "styled-components";
import useColor from "@core/hooks/useColor";
import TC from "tinycolor2";

const Title = styled(Heading)`
  text-align: center;
  text-shadow: ${(props: { shadow: string }) => props.shadow} 0.3rem 0.3rem
      0.3rem,
    ${(props: { shadow: string }) => props.shadow} -0.3rem 0.3rem 0.3rem;
`;

const NavItemInner = styled(AnchorLink)<{
  background?: string;
  isActive: boolean;
  activeColor: string;
  shadowColor: string;
}>`
  padding: 12px 16px;
  ${({ isActive, activeColor, shadowColor }) =>
    isActive ? `box-shadow: inset 0px -4px 24px 4px ${shadowColor};` : ""}
  &:hover {
    text-decoration: none;
    box-shadow: inset 0px -4px 24px 4px rgba(255, 255, 255, 0.2);
  }
  &:focused {
    box-shadow: none;
  }
`;

const NavItem = (props: AnchorLinkProps & { background?: string }) => {
  const isActive =
    typeof window !== "undefined" && props.to === window.location.pathname;
  const activeColor = useColor("dark-1");
  const config = React.useContext(SiteConfigContext);
  const shadowColor =
    config?.theme?.mode === "light"
      ? "rgba(255,255,255,0.45)"
      : "rgba(0,0,0,0.45)";
  return (
    <NavItemInner
      shadowColor={shadowColor}
      activeColor={activeColor}
      isActive={isActive}
      {...props}
    />
  );
};

export default function ClientSubpageHeader() {
  const config = React.useContext(SiteConfigContext);
  const isDark = config?.theme.mode === "dark";
  const navItemBg = useColor("brand-4");
  const dark1 = useColor("dark-1");
  const dark1Transparent = new TC(dark1).setAlpha(0.3).toRgbString();
  const bgImage = config?.general.landingBackgroundImage;
  return (
    <>
      <Header
        background={isDark ? "brand--1" : "dark-1"}
        direction="column"
        pad="large"
        gap="xsmall"
        justify="center"
        style={
          bgImage
            ? {
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }
            : {}
        }
      >
        <Title
          shadow={dark1Transparent}
          level={1}
          margin={{ horizontal: "small", vertical: "0" }}
          textAlign="center"
          color="brand-1"
        >
          {config?.general.gameName}
        </Title>
        <Title
          shadow={dark1Transparent}
          level={3}
          margin={{ horizontal: "small", vertical: "0" }}
          textAlign="center"
          color="brand-1"
        >
          {config?.general.title}
        </Title>
      </Header>
      <Box
        fill
        background={isDark ? "brand--1" : "brand-1"}
        align="center"
        border={{ side: "horizontal", size: "1.5px", color: "brand-4" }}
      >
        <Nav direction="row">
          <NavItem
            background={navItemBg}
            color={isDark ? "light-1" : "dark-1"}
            to="/"
          >
            Home
          </NavItem>
          {config?.general.enableFeedback && (
            <NavItem
              background={navItemBg}
              color={isDark ? "light-1" : "dark-1"}
              to="/feedback"
            >
              {config.general.feedbackPageMenuName || "Roadmap"}
            </NavItem>
          )}
          {config?.general.collectNewsletterEmails && (
            <NavItem
              background={navItemBg}
              color={isDark ? "light-1" : "dark-1"}
              to="/socials"
            >
              {config.general.socialsThankYouPageMenuName || "Thank you"}
            </NavItem>
          )}
          {config?.general.enablePress && (
            <NavItem
              background={navItemBg}
              color={isDark ? "light-1" : "dark-1"}
              to="/press"
            >
              Press
            </NavItem>
          )}
        </Nav>
      </Box>
    </>
  );
}
