import { FormFieldExtendedProps } from "grommet";
import * as React from "react";
import { Box, Text } from "grommet";
import SmallFormField from "./SmallFormField";
import { platformsMap, SupportedPlatform } from "@core/config/platforms";
import useColor from "@core/hooks/useColor";
import PlatformTag from "./PlatformTag";
import * as Icons from "grommet-icons";
import { FieldVariations } from "../pages/publeash/useUpdateData";

interface Props extends Omit<FormFieldExtendedProps, "onChange" | "value"> {
  platforms: SupportedPlatform[];
  enabledPlatforms: SupportedPlatform[];
  onChange(variations: FieldVariations): void;
  value: FieldVariations;
}

export default function PlatformFormField({
  platforms,
  info,
  children,
  label,
  enabledPlatforms,
  onChange,
  value,
  ...rest
}: Props) {
  const [editingPlatform, setEditingPlatform] = React.useState<null | string>(
    null
  );
  const finalPlatforms = platforms.filter(
    (platform) => enabledPlatforms.indexOf(platform) !== -1
  );

  const handleChange = (e: string | object) => {
    if (typeof e === "string") {
      onChange({
        ...value,
        [editingPlatform || "default"]: e,
      });
    } else {
      console.log(typeof e, e);
    }
  };

  if (finalPlatforms.length === 0) return null;

  const borderColor = useColor(
    (editingPlatform && platformsMap[editingPlatform].color) || "brand-1"
  );

  const isDefaultHighlighted =
    finalPlatforms.length === 1 || editingPlatform === null;

  return (
    <>
      <SmallFormField
        {...rest}
        label={label}
        info={
          (info || finalPlatforms) && (
            <Box justify="between" direction="row">
              <Text size="small">{info}</Text>
              <Box alignSelf="end" direction="row" gap="xsmall">
                {(platforms?.length || 0) > 1 && (
                  <Box margin={{ right: "xsmall" }} flex={{ shrink: 2 }}>
                    <Text size="small">
                      Set{" "}
                      {typeof label === "string" ? label.toLowerCase() : label}{" "}
                      for{" "}
                    </Text>
                  </Box>
                )}
                {(finalPlatforms?.length || 0) > 1 && (
                  <Box
                    background={{ color: "brand-1", opacity: 1 }}
                    round="small"
                    pad={{ vertical: "2px", horizontal: "small" }}
                    onClick={() => setEditingPlatform(null)}
                    style={{ opacity: isDefaultHighlighted ? 1 : 0.6 }}
                  >
                    <Text size="xsmall">Default - All</Text>
                  </Box>
                )}
                {finalPlatforms?.map((platform) => (
                  <PlatformTag
                    extraIcon={
                      value[platform] ? (
                        <Icons.Brush size="xsmall" />
                      ) : undefined
                    }
                    platform={platform}
                    key={platform}
                    opacity={
                      finalPlatforms.length === 1 ||
                      editingPlatform === platform
                        ? 1
                        : value[platform]
                        ? 0.85
                        : 0.5
                    }
                    onClick={
                      finalPlatforms.length > 1
                        ? () => setEditingPlatform(platform)
                        : undefined
                    }
                  />
                ))}
              </Box>
            </Box>
          )
        }
        {...rest}
      >
        <Box style={{ position: "relative" }}>
          {React.isValidElement(children) &&
            React.cloneElement(children, {
              value: value ? value[editingPlatform || "default"] || "" : "",
              style: { borderBottom: `2px solid ${borderColor}` },
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e.target.value),
            })}
          {editingPlatform && (
            <PlatformTag
              style={{ position: "absolute", right: 8, top: 8 }}
              platform={editingPlatform}
            />
          )}
        </Box>
      </SmallFormField>
    </>
  );
}
