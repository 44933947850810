import { accountsClient } from '@core/redux/accounts/client';
import { QueryObj, Request, RequestMethodTypes } from '@core/types';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { getContactToken } from '@core/components/blind/contexts/ContactContext';

export default async function authorisedFetch<T = object>(
  input: RequestInfo,
  init?: RequestInit & { contentType?: string }
): Promise<T> {
  const contactToken = getContactToken();
  const tokens = await accountsClient.getTokens();
  const token = tokens?.accessToken;
  const headers = new Headers();
  if (token) {
    headers.set('authorization', token);
  }
  if (contactToken) {
    headers.set('contact-authorization', contactToken);
  }
  // Uploading may need a different filetype
  if (init?.contentType !== 'auto') {
    headers.set('content-type', init?.contentType || 'application/json');
  }

  const fetchInit = init ? { ...init, headers } : { headers };

  let fetchPromise;
  if (typeof input === 'string') {
    const url = `/api/${input}`;
    fetchPromise = fetch(url, fetchInit);
  } else {
    const data = {
      ...input,
      url: `/api/${input.url}`,
    };

    fetchPromise = fetch(data, fetchInit);
  }

  const fetchResponse = await fetchPromise;

  return fetchResponse.json() as Promise<T>;
}

export async function hookFetch<T = object>(
  input: RequestInfo,
  init?: RequestInit & { contentType?: string }
): Promise<T> {
  const tokens = await accountsClient.getTokens();
  const token = tokens?.accessToken;
  const contactToken = getContactToken();
  const headers = new Headers();
  if (token) {
    headers.set('authorization', token);
  }
  if (contactToken) {
    headers.set('contact-authorization', contactToken);
  }
  // Uploading may need a different filetype
  if (init?.contentType !== 'auto') {
    headers.set('content-type', init?.contentType || 'application/json');
  }

  const fetchInit = init ? { ...init, headers } : { headers };

  let fetchPromise;
  let url = '';
  if (typeof input === 'string') {
    url = `/api/${input}`;
    fetchPromise = fetch(url, fetchInit);
  } else {
    url = `/api/${input.url}`;
    const data = {
      ...input,
      url,
    };

    fetchPromise = fetch(data, fetchInit);
  }

  const fetchResponse = await fetchPromise;

  const contentType = fetchResponse.headers.get('Content-Type');
  // TODO: handle statusCode & input errors
  if (!fetchResponse.ok) {
    throw new Error('Internet connectivity problems...');
  }

  if (!contentType?.startsWith('application/json')) {
    throw new Error(`Problems with our servers. ${url}`);
  }

  return fetchResponse.json() as Promise<T>;
}

export type IRequestArg = Request<
  string,
  RequestMethodTypes,
  object | undefined,
  Record<string, string> | undefined,
  QueryObj | undefined,
  object | undefined
>;

export async function typedRequest<T extends IRequestArg>({
  url,
  method,
  params,
  body,
  query,
}: Omit<T, 'response'>): Promise<T['response']> {
  let fullUrl: string = url;
  // Replace params in the url
  if (params) {
    fullUrl = Object.keys(params).reduce(
      (acc, current) => acc.replace(`:${current}`, params[current]),
      fullUrl
    );
  }
  // Add query
  if (query) {
    fullUrl += '?' + new URLSearchParams(omitBy(query, isEmpty)).toString();
  }

  // Make a request
  const response = await hookFetch<T['response']>(fullUrl, {
    method,
    body: body ? JSON.stringify(body) : undefined,
  });

  return response;
}
