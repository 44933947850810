import PageContent from '@core/components/atoms/PageContent';
import {
  Box,
  Button,
  Heading,
  Markdown,
  Paragraph,
  ResponsiveContext,
  Text,
} from 'grommet';
import * as React from 'react';
import styled from 'styled-components';
import { ImageSection } from '../organisms/ImageSection';
import TC from 'tinycolor2';

export interface Feature {
  title?: string;
  description?: string;
  imageElement?: React.ReactNode;
  imageUrl?: string;
  buttonText?: string;
  buttonUrl?: string;
  webm?: string;
  mp4?: string;
  mov?: string;
  actions?: React.ReactNode;
}

export const SoloHeading = styled(Heading)`
  max-width: none;
  line-height: 1.4em;
  strong {
    color: ${(props) => {
      const lightness = TC(props.theme.global.colors['brand-1']).toHsl().l;

      const darkBase = props.theme.dark ? 'dark-4' : 'light-1';
      const lightBase = props.theme.dark ? 'light-4' : 'dark-1';

      return lightness > 0.5
        ? props.theme.global.colors[darkBase]
        : props.theme.global.colors[lightBase];
    }};
    background-color: ${(props) => props.theme.global.colors['brand-1']};
    padding: 0 8px;
    font-weight: ${(props) => props.theme.heading.weight};
  }
`;

const HighlightParagraph = styled(Paragraph)`
  line-height: 1.4em;
  max-width: none;
  strong {
    color: ${(props) => props.theme.global.colors['dark-1']};
    background-color: ${(props) => props.theme.global.colors['brand-1']};
    padding: 0 8px;
    font-weight: ${(props) => props.theme.global.font.weight};
  }
`;

export const alternateColors = ['dark-4', 'dark-3', 'dark-2'];

export default function FeatureComponent({
  feature,
  side,
  i,
}: {
  i: number;
  feature: Feature;
  side: 'left' | 'right' | 'center';
}) {
  const deviceSize = React.useContext(ResponsiveContext);
  const align =
    deviceSize === 'small'
      ? 'center'
      : side === 'left'
      ? 'start'
      : side === 'center'
      ? 'center'
      : 'end';

  const direction =
    deviceSize === 'small'
      ? side === 'left'
        ? 'column'
        : 'column-reverse'
      : 'row-responsive';

  return (
    <Box background={alternateColors[i % alternateColors.length]} pad="small">
      <PageContent
        direction={direction}
        pad={{ horizontal: 'medium', vertical: 'large' }}
        align="center"
        justify="stretch"
      >
        {side === 'left' && <ImageSection feature={feature} />}
        <Box fill pad="large" align={align} justify="center">
          {feature.description ? (
            <SoloHeading
              color="light-2"
              style={{ maxWidth: 'none', marginTop: -12 }}
              level={3}
              textAlign={align}
            >
              <Markdown>{feature.title}</Markdown>
            </SoloHeading>
          ) : (
            <SoloHeading
              level={3}
              color="light-2"
              size="large"
              margin="none"
              textAlign={align}
            >
              <Markdown>{feature.title}</Markdown>
            </SoloHeading>
          )}
          {feature.description && (
            <Text
              size="large"
              color="light-4"
              style={{ lineHeight: '1.8em' }}
              textAlign={align}
            >
              <Markdown
                components={{
                  p: (props: object) => (
                    <HighlightParagraph size="large" {...props} />
                  ),
                }}
              >
                {feature.description}
              </Markdown>
            </Text>
          )}
          {feature.actions}
          {feature.buttonText && feature.buttonUrl && (
            <div style={{ display: 'inline-block' }}>
              <Button
                color="brand-dynamic"
                margin={{ top: 'medium' }}
                size="medium"
                onClick={() => {
                  if (feature.buttonUrl) {
                    window.location.replace(feature.buttonUrl);
                  }
                }}
                primary
                label={feature.buttonText}
              />
            </div>
          )}
        </Box>
        {side === 'right' && <ImageSection feature={feature} />}
      </PageContent>
    </Box>
  );
}
