import { GrommetProps } from "grommet";
import { deepMerge } from "grommet/utils";
import * as React from "react";
import { Helmet } from "react-helmet";
import { SiteConfigContext } from "./SiteConfigContext";
import TC from "tinycolor2";

import { defaultSiteConfig } from "@core/common/siteConfigs";
import { SiteView } from "@core/redux/sitesEditor";
import { grommetThemeReference } from "@core/redux/siteConfig/grommetThemeReference";
import useRound from "@core/hooks/useRound";
import getSiteTheme from "@core/common/getSiteTheme";

interface Props {
  children: (theme: GrommetProps["theme"]) => React.ReactElement;
  forceSiteConfig?: SiteView;
}

const defaultTheme: GrommetProps["theme"] = {
  global: {
    colors: {
      brand: "hsla(25, 100%, 89%, 1)",
      primary: "hsla(224,  43%, 22%, 100)",
      secondary: "hsla(25,100%, 89%, 1)",
      selected: "hsla(25, 100%, 89%, 1)",
      // Overriding existing grommet colors
      // 'accent-1': '#6FFFB0',
      // 'accent-2': '#7FFFB0',
      // 'accent-3': '#8FFFB0',
      // 'accent-4': '#9FFFB0',
      // 'neutral-1': '#10873D',
      // 'neutral-2': '#20873D',
      // 'neutral-3': '#30873D',
      // 'neutral-4': '#40873D',
      // focus: '#000',
      steam: "rgb(29, 34, 42)",
      youtube: "rgb(255,0,0)",
      discord: "#5865F2",
      twitter: "#55acee",
      facebook: "#3b5999",
      reddit: "#ff4500",
      mail: "#da552f",
      emails: "#da552f",
      website: "#ffffff",
      "status-ok": "#00C781",
      "status-warning": "#faad14",
      "status-error": "#ff4d4f",
      "status-critical": "#cf1322",
    },
    edgeSize: grommetThemeReference.global.edgeSize,
    size: {
      sidebar: "64px",
      ...grommetThemeReference.global.size,
    },
  },
  pagination: {
    button: {
      color: "red",
    },
    icons: { color: "brand-1" },
    container: {},
  },
  formField: {
    label: {
      color: "light-1",
      margin: { vertical: "xsmall", horizontal: "0" },
    },
    help: {
      color: "light-1",
      margin: "none",
    },
    border: {
      side: "all",
    },
    info: {},
  },
  icon: {
    size: {
      xsmall: "12px",
      small: "16px",
      medium: "24px",
      large: "48px",
      xlarge: "96px",
    },
  },
};

export default function SiteConfigTheme({ children, forceSiteConfig }: Props) {
  const siteConfig = React.useContext(SiteConfigContext);

  const round = useRound();

  const theme =
    (forceSiteConfig && forceSiteConfig.config.theme) ||
    siteConfig?.theme ||
    defaultSiteConfig.theme;
  const primaryBase = TC(theme.primary);
  const primaryShadow = primaryBase.clone().setAlpha(0.25).toRgbString();
  const colors = getSiteTheme(theme);

  const themeFromSiteConfig: GrommetProps["theme"] = {
    card: {
      container: {
        round: {
          size: round,
        },
      },
    },
    global: {
      elevation: {
        light: {
          none: "none",
          // You can override the values for box-shadow here.
          xsmall: `0px 2px 2px ${primaryShadow}`,
          small: `0px 4px 4px ${primaryShadow}`,
          medium: `0px 6px 8px ${primaryShadow}`,
          large: `0px 8px 16px ${primaryShadow}`,
          xlarge: `0px 12px 24px ${primaryShadow}`,
        },
        dark: {
          none: "none",
          // You can override the values for box-shadow here.
          xsmall: `0px 2px 2px ${primaryShadow}`,
          small: `0px 4px 4px ${primaryShadow}`,
          medium: `0px 6px 8px ${primaryShadow}`,
          large: `0px 8px 16px ${primaryShadow}`,
          xlarge: `0px 12px 24px ${primaryShadow}`,
        },
      },

      colors,
      font: {
        family: theme.fontFamilyName || "sans serif",
        weight: theme.fontWeight || 400,
      },
    },
    textArea: {
      extend: () => `border-radius: ${(theme.buttonRadius || 0) * 4}px`,
    },
    tabs: {
      background: "brand-3",
      gap: "big",
      header: {
        background: "brand",
      },
    },
    tab: {
      color: "dark-1",
      pad: {
        horizontal: "medium",
        vertical: "small",
      },
      margin: "0",
      border: {
        color: "dark-4",
        size: "4px",
      },
      active: {
        background: "dark-2",
      },
    },
    heading: {
      weight: theme.headingFontWeight || 600,
      font: {
        family: theme.headingFontFamilyName,
      },
    },
    menu: {
      icons: {
        color: "light-1",
      },
      background: "brand-4",
      // extend: () => `border-radius: ${(theme.buttonRadius || 0) * 4}px;`,
    },
    accordion: {
      heading: {
        margin: "medium",
      },
      hover: {
        color: "light-1",
      },
    },
    button: {
      // extend: `color: ${theme.mode !== 'dark' ? 'black' : 'white'}`,
      border: {
        radius: `${(theme.buttonRadius || 0) * 4}px`,
      },
      hover: {
        default: {
          background: "dark-4",
        },
        primary: {
          color: "dark-1",
          background: "brand-3",
        },
        secondary: {
          color: "dark-2",
          background: "accent-1",
        },
      },
      primary: {
        color: "dark-1",
        background: "brand-1",
      },
      secondary: {
        color: "dark-1",
        background: "accent-3",
      },
      default: {
        background: "dark-2",
        color: "light-1",
      },
      size: {
        small: {
          pad: {
            vertical: "8px",
          },
          border: {
            radius: `${(theme.buttonRadius || 0) * 4}px`,
          },
        },
        medium: {
          pad: {
            vertical: "12px",
          },
          border: {
            radius: `${(theme.buttonRadius || 0) * 6}px`,
          },
        },
        large: {
          pad: {
            vertical: "16px",
          },
          border: {
            radius: `${(theme.buttonRadius || 0) * 8}px`,
          },
        },
      },
    },
    anchor: {
      color: { dark: "accent-3", light: "light-1" },
    },
  };

  const finalTheme = deepMerge(defaultTheme || {}, themeFromSiteConfig);
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href={addSwap(theme.fontFamilyUrl)} />
        <link rel="stylesheet" href={addSwap(theme.headingFontFamilyUrl)} />
      </Helmet>
      {children(finalTheme)}
    </>
  );
}

function addSwap(link?: string) {
  if (!link) return link;
  if (link.indexOf("display=swap")) return link;
  if (link.indexOf("?") !== -1) {
    return `${link}&display=swap`;
  }
  return link;
}
