import OwnNav from '@core/components/organisms/OwnNav';
import { Box, BoxProps } from 'grommet';
import * as React from 'react';

interface Props extends BoxProps {
  children: React.ReactNode;
}

export default function PageLayout({ children, ...rest }: Props) {
  return (
    <>
      <OwnNav />
      <Box background="dark-1" style={{ minHeight: 'calc(100vh - 60px)' }} {...rest}>
        {children}
      </Box>
    </>
  );
}
