import {
  CreateAPostModalActionTypes,
  CreateAPostModalChangeCategoryIdAction,
  CreateAPostModalCloseAction,
  CreateAPostModalOpenAction,
} from './actions';

export interface CreateAPostModalState {
  siteId: number | null;
  categoryId: string | null;
}

type Actions =
  | CreateAPostModalChangeCategoryIdAction
  | CreateAPostModalOpenAction
  | CreateAPostModalCloseAction;

const defaultState = {
  siteId: null,
  categoryId: null,
};

export function reducer(
  state: CreateAPostModalState = defaultState,
  action: Actions
): CreateAPostModalState {
  switch (action.type) {
    case CreateAPostModalActionTypes.OPEN:
      return {
        siteId: action.payload.siteId,
        categoryId: action.payload.categoryId,
      };
    case CreateAPostModalActionTypes.CLOSE:
      return defaultState;
    case CreateAPostModalActionTypes.CHANGE_CATEGORY_ID:
      return {
        ...state,
        categoryId: action.payload.categoryId,
      };
    default:
      return state;
  }
}
