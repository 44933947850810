import useColor from "@core/hooks/useColor";
import useRound from "@core/hooks/useRound";
import { Box, BoxExtendedProps, Heading, Text } from "grommet";
import * as React from "react";
import TC from "tinycolor2";

interface Props {
  children: React.ReactNode;
  title?: string;
  status?:
    | "critical"
    | "error"
    | "warning"
    | "disabled"
    | "unknown"
    | "ok"
    | "info";
}

export default function MessageBox({
  children,
  title,
  status,
  ...rest
}: Props & BoxExtendedProps) {
  const round = useRound("small");
  const colorString = `status-${status || "warning"}`;
  const color = useColor(colorString);
  // alert(`${color} ${TC(color).toHex()}`);
  return (
    <Box
      background={TC(color).lighten(10).desaturate(10).toHexString()}
      pad="small"
      round={round}
      elevation="large"
      border={{
        color: colorString,
        side: "bottom",
        size: "medium",
      }}
      {...rest}
    >
      {title && (
        <Heading margin="0" level={5}>
          {title}
        </Heading>
      )}
      {typeof children === "string" ? (
        <Text size="small">{children}</Text>
      ) : (
        children
      )}
    </Box>
  );
}
