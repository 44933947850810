import { SupportedPlatform } from "@core/config/platforms";
import { Box, Text, BoxExtendedProps } from "grommet";
import * as React from "react";
import MessageBox from "../atoms/MessageBox";
import PlatformFormField from "../atoms/PlatformFormField";
import PlatformTag from "../atoms/PlatformTag";
import * as Icons from "grommet-icons";
import Discord from "../atoms/icons/Discord";
import Button from "../atoms/Button";
import { FieldVariations, UpdateData } from "../pages/publeash/useUpdateData";

interface Props {
  platforms: SupportedPlatform[];
  updateData: UpdateData;
  onChange(data: UpdateData): void;
}

export default function PrepareContentMedia({ platforms }: Props) {
  console.log("Here");
  return (
    <Box
      width="large"
      pad="medium"
      justify="start"
      border={{
        side: "right",
        size: "xsmall",
        color: "dark-4",
      }}
    >
      <PlatformFormField
        platforms={["facebook", "reddit", "twitter", "discord"]}
        enabledPlatforms={platforms}
        value={{} as FieldVariations}
        onChange={() => {
          console.log("Changing");
        }}
        label="Engaging Short Clip"
        help="Capture attention in first 3 seconds, then excite them about the update and finally finish with useful information such as game name, links and supported platforms"
      >
        <PlatformAsset
          aspect="1280/720"
          info="1280x720 or bigger"
          type="video"
        />
      </PlatformFormField>
      <PlatformFormField
        label="Cover image"
        platforms={["steam"]}
        enabledPlatforms={platforms}
        value={{} as FieldVariations}
        help="It will appear on your store page in recent announcements, in lists of events and announcements, and on your game's library detail page. It's used to link to your event/announcement and may be displayed alongside content from multiple games, so the image should be clear and recognizable."
        onChange={() => {
          console.log("onchange");
        }}
      >
        <PlatformAsset aspect="800/450" info="800x450 or bigger" />
      </PlatformFormField>
      <PlatformFormField
        label="Header image"
        platforms={["steam"]}
        enabledPlatforms={platforms}
        value={{} as FieldVariations}
        help="It will appear on the top of your event, acting to establish your branding and provide color for the detail views."
        onChange={() => {
          console.log("onchange");
        }}
      >
        <PlatformAsset aspect="1922/622" info="1922x622 or bigger. Optional." />
      </PlatformFormField>
      <PlatformFormField
        label="Spotlight image"
        platforms={["steam"]}
        enabledPlatforms={platforms}
        value={{} as FieldVariations}
        help="By default, your event's title and subtitle will be laid on top of the right half of your spotlight image. You can choose to turn off the text and use the entire banner space to show off your event or announcement."
        onChange={() => {
          console.log("onchange");
        }}
      >
        <PlatformAsset info="2104x460 or bigger. Optional" />
      </PlatformFormField>
      <MessageBox status="info">
        Email and website images will be automatically taken from text
        "CONTENT".
        <Box direction="row" gap="small" margin={{ top: "small" }}>
          <PlatformTag platform="emails" />
          <PlatformTag platform="website" />
        </Box>
      </MessageBox>
    </Box>
  );
}

export function PlatformAsset({
  type = "image",
  info,
  aspect,
  style,
  ...rest
}: {
  info?: string;
  aspect?: string;
  type?: "video" | "image";
} & BoxExtendedProps) {
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     const type = file.type;
  //     console.log("Selected file", { type, size: file.size });
  //   } else {
  //     console.warn("You need to select a file to use it?");
  //   }
  // };
  return (
    <Box
      margin="xsmall"
      border={{ style: "dashed", color: "light-1", side: "all" }}
      pad="medium"
      align="center"
      justify="center"
      {...rest}
      style={{ position: "relative", aspectRatio: aspect, ...style }}
    >
      {info && (
        <Text size="xsmall" style={{ position: "absolute", top: 8, left: 8 }}>
          {info}
        </Text>
      )}
      <Text>Drag your {type} here</Text>
      <Text weight="bold">or</Text>
      <Box direction="row" gap="small">
        <Button
          label="Browse Discord Files"
          color="discord"
          size="xsmall"
          style={{ color: "white" }}
          icon={<Discord color="white" />}
        ></Button>
        <Button
          size="xsmall"
          label="Browse local PC..."
          color="dark-1"
          icon={<Icons.PersonalComputer color="light-1" />}
        ></Button>
      </Box>
    </Box>
  );
}
