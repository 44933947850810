import { Box, BoxExtendedProps } from 'grommet';
import React from 'react';
import styled from 'styled-components';

const CardFooterButtonInner = styled(Box)`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 0.8em;
  &:last-of-type {
    border-right: 0px;
  }
`;

export default (props: BoxExtendedProps & { selected?: boolean }) => (
  <CardFooterButtonInner
    align="center"
    hoverIndicator
    background={props.selected ? 'dark-3' : 'light-1'}
    gap="small"
    justify="center"
    pad={{ right: 'small', left: 'xsmall' }}
    style={{ fontWeight: 'bold' }}
    flex
    direction="row"
    {...props}
  />
);
