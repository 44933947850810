import DoubleIconHOC from "@core/components/atoms/icons/DoubleIconHOC";
import ToggleButton from "@core/components/atoms/ToggleButton";
import CondensedMarkdown from "@core/components/molecules/CondensedMarkdown";
import MiniOwnNav from "@core/components/organisms/MiniOwnNav";
import { config } from "@core/config/config";
import {
  Box,
  Button,
  ButtonExtendedProps,
  CheckBox,
  Heading,
  Page,
  ResponsiveContext,
  Text,
} from "grommet";
import {
  Checkbox,
  CheckboxSelected,
  Radial,
  RadialSelected,
} from "grommet-icons";
import * as React from "react";
import * as Icons from "grommet-icons";
import { allPlatforms, SupportedPlatform } from "@core/config/platforms";
import HelpCard from "@core/components/atoms/HelpCard";
import BoxWithShadow from "@core/components/atoms/BoxWithShadow";
import SmallDoubleIconButton from "@core/components/atoms/SmallDoubleIconButton";
import useColor from "@core/hooks/useColor";
import { useUpdateData } from "./useUpdateData";
import PlatformTag from "@core/components/atoms/PlatformTag";
import ButtonLink from "./ButtonLink";
import useUpdateEffect from "use-update-effect";

const ChecklistPlatformsContext = React.createContext<SupportedPlatform[]>([]);
const ChecklistStateContext = React.createContext<{
  toggleItem: (key: string) => void;
  items: Record<string, boolean>;
}>({
  toggleItem: (_key) => {
    // We good, default value only
  },
  items: {},
});

function ChecklistStateProvider({ children }: { children: React.ReactNode }) {
  const [items, setItems] = React.useState<Record<string, boolean>>({});
  const toggleItem = (key: string) => {
    setItems({
      ...items,
      [key]: !items[key],
    });
  };

  React.useEffect(() => {
    const data = localStorage.getItem("CHECKLIST_DATA");
    if (data) {
      setItems(JSON.parse(data));
    }
  }, []);

  useUpdateEffect(() => {
    localStorage.setItem("CHECKLIST_DATA", JSON.stringify(items));
  }, [items]);

  return (
    <ChecklistStateContext.Provider value={{ toggleItem, items }}>
      {children}
    </ChecklistStateContext.Provider>
  );
}

function ChecklistProvider({
  children,
  platforms,
}: {
  children: React.ReactNode;
  platforms: SupportedPlatform[];
}) {
  return (
    <ChecklistPlatformsContext.Provider value={platforms}>
      {children}
    </ChecklistPlatformsContext.Provider>
  );
}

type ChecklistType = "Planning" | "Update" | "Launch";

interface Item {
  title: string;
  subText?: string | React.ReactNode;
  items?: Item[];
  platforms?: SupportedPlatform[];
}

export const updateChecklist: Item[] = [
  {
    title: "General",
    subText: `It's better to release updates consistently than 1 big update per year. Keep your community engaged.`,
    items: [
      {
        title: "Update has been tested and passes quality standards",
      },
      {
        title: "Builds for all platforms are built and ready",
      },
    ],
  },
  {
    title: "Steam Event Post",
    platforms: ["steam"],
    items: [
      {
        title: "Texts",
        items: [
          {
            title: "Title (max 80 characters - Required)",
            subText: "Catchy one liner. Why should people check it out?",
          },
          {
            title: "Subtitle (max 120 - Optional)",
          },
          {
            title: "Summary (max 180 - Required)",
          },
          { title: "Content (BBCode)" },
        ],
      },
      {
        title: "Media",
        items: [
          { title: "Cover Image (800x450 - Required)" },
          { title: "Header Image (1920x622 - Optional)" },
          { title: "Cover Image (2108x460 - Suggested if major update)" },
        ],
      },
    ],
  },
  {
    title: "Discord",
    platforms: ["discord"],
    items: [
      {
        title: "Content (Markdown)",
        subText: "Include a link to steam event / discussion page.",
      },
      {
        title: "Short video. Less than 8MB if you are using free Discord.",
      },
    ],
  },
  {
    title: "Reddit",
    platforms: ["reddit"],
    items: [
      {
        title: "Catchy title, why should people check it out right now?",
        subText: "No links, leave that for a comment",
      },
      {
        title: "Video is Ready",
        subText: "Super engaging video clip that is <15 seconds long is ready",
      },
      {
        title: "Accompanying comment is ready",
        subText:
          "Don't just make a post. Write a comment and open yourself for questions. If it's allowed link to your store page.",
      },
      {
        title: "We decided on subreddits and read the rules",
        subText:
          "Different subreddits have wildly different rules. Sometimes self promotion is okay sometimes it is not. Be careful and don't spam!",
      },
    ],
  },
  {
    title: "Twitter",
    platforms: ["twitter"],
    items: [
      {
        title: "Catchy text is ready",
        subText: "Less than 280 characters",
      },
      {
        title: "Hash tags are ready",
        subText:
          "Keep in mind #gamedev and similar are usually good for marketing to other indie gamers not to your target audience.",
      },
      {
        title: "Video clip is ready",
        subText:
          "Capture attention immediately, keep it short and include your [CTA](https://en.wikipedia.org/wiki/Call_to_action_%28marketing%29)",
      },
    ],
  },
  {
    title: "Facebook",
    platforms: ["facebook"],
    items: [
      {
        title: "Main content is ready",
        subText:
          "Keep it short, engage with video and drive them to your store.",
      },
      {
        title: "Accompanying comment is ready",
        subText:
          "Don't just make a post. Write a comment and open yourself for questions. If it's allowed link to your store page.",
      },
      {
        title: "Links have been sent to friends and team members",
        subText:
          "If a group allows it, feel free to ask your friends for likes and comment. In 2022 comments drive further engagement!",
      },
    ],
  },
  {
    title: "Website",
    platforms: ["website"],
    items: [
      {
        title: "Update Title is ready",
      },
      {
        title: "Patch notes are ready",
      },
      {
        title: "Patch notes are accessible via URL",
      },
      {
        title: "Patch notes contain a CTA",
      },
    ],
  },
  {
    title: "Emails",
    platforms: ["emails"],
    items: [
      {
        title: "Subject line is ready",
        subText:
          "You can use [Mailmeteor](https://mailmeteor.com/email-subject-line-tester) to get some ideas for improvements",
      },
      {
        title: "Content is ready",
        subText: "Make it *valuable* for users. Include your CTA.",
      },
    ],
  },
];

export const planningChecklist: Item[] = [
  {
    title: "Team Match 👥",
    items: [
      {
        title: "You know why we are building this",
        subText: `If you plan to to run a game development business then consider the difficulty!
 - Did you evaluate the industry?
 - Do you understand how hard it is to succeed as an indie developer?`,
      },
      {
        title: "Game vision is written down and everyone agrees on it",
        subText: "Write down 10 year goal, 3 year target and 1 year plan.",
      },
      {
        title: "Team share common values and goals",
      },
    ],
  },
  {
    title: "Market Research",
    platforms: ["steam"],
    items: [
      {
        title: "Steam",
        platforms: ["steam"],
        subText:
          "Read more in [How I did my market research on Steam](http://adrian.gaudebert.fr/blog/post/how-i-did-my-market-research-on-steam) by Adrian Gaudebert",
        items: [
          {
            title: "Analyse which genrese steam shoppers love to play",
            subText:
              "Read [more evidence of which genres steam shoppers love to play](https://howtomarketagame.com/2022/05/30/more-evidence-of-which-genres-steam-shoppers-love-to-play/) by howtomarketagame.com",
          },
          { title: "Determine the genre of your game" },
          {
            title: "Find Steam tags for your game",
          },
          { title: "Make a list of games similar to yours" },
          {
            title: 'Use the "More like this" feature to find similar games',
            subText:
              "Use Game Data Crunch for fine-tuned exploration of genres and tags.",
          },
          {
            title:
              "Compute the average and median revenue of similar games during the first year.",
            subText:
              "Find relevant numbers using the [Game-Stats](https://games-stats.com/) and [SteamSpy](https://steamspy.com/) tool",
          },
          {
            title:
              "Extrapolate expected revenue for your game based on that data",
            subText: "Be conservative",
          },
        ],
      },
    ],
  },
  {
    title: "Production and Announcement plan",
    subText:
      "In this stage, it’s important that you start building a community and engagement.",
    items: [
      {
        title: "General Plan",
        items: [
          {
            title: "Choose the relevant social media",
            subText:
              "Don't try to cover all channels poorly, rather cover few channels properly.",
          },
          {
            title: "Trailer/Teaser is ready",
          },
          {
            title: "Content to keep the hype is ready",
            subText:
              "It's really important you don't announce and then wait for a year. Keep your community engaged. You should be aiming to have at least 1 post per week ready.",
          },
        ],
      },
      {
        title: "Reddit",
        platforms: ["reddit"],
        subText:
          "Read [How to post about your game without being flamed (or deleted)](https://www.reddit.com/r/gamedev/comments/8zwmio/how_to_post_about_your_game_without_being_flamed/)",
        items: [
          {
            title: "Find relevant subreddit and read what works/doesn't work",
            subText:
              "Example list in a link above - make your own for your own game!",
          },
          {
            title: "If posting a video make it short (MP4 or MOV).",
            subText:
              "Max video length is 15 minutes, but you should probably deliver fun content and information in 15 seconds.",
          },
          {
            title: "Accompanying comment is ready",
            subText:
              "Don't just make a post. Write a comment and open yourself for questions. If it's allowed link to your store page.",
          },
          {
            title: "Before posting to a subreddit you have to",
            subText:
              "Read [How to post your game on Reddit?](https://medium.com/game-marketing/how-to-post-your-game-on-reddit-2049f613e1ed)",
            items: [
              {
                title: "Read the subreddit rules!",
              },
              {
                title: "Check out the content of the latest succesful posts",
              },
              {
                title:
                  "Check if posts have flair (aka labels to identify content)",
              },
              {
                title: "Read the subreddit rules",
              },
            ],
          },
        ],
      },
      {
        title: "Steam",
        platforms: ["steam"],
        items: [
          {
            title: "Learn how steam works",
            subText: `##### Summary:
 - Popular games are similar to existing games
 - Steam recommends similar games that you've already played
 - Goals of the Steam page:
   - For players: Demonstrate quality and attractiveness
   - For algorithm: Know the type of the game`,
          },
          {
            title: "Choose the correct genre of your game",
            items: [
              { title: "Be very specific in choosing a genre" },
              {
                title:
                  "Find games that are similar to your game (You have to understand your game!)",
                items: [
                  { title: "Copy capsule" },
                  { title: "Short description" },
                  { title: "Copy tags of the game" },
                  {
                    title:
                      'Find the "More like this" section to find more games',
                  },
                  { title: "Go to YouTube" },
                ],
              },
              {
                title:
                  "Identify key gameplay elements (What you do over and over again in your game)",
              },
            ],
          },
          {
            title:
              "Capsules (small images that Steam displays for the game). Good capsule design increases wishlist count!",
            items: [
              {
                title: "Hints genre",
              },
              {
                title: "Displays mood",
              },
              { title: "Demonstrates quality" },
              {
                title: "Eye-catching",
              },
              {
                title: "Apply same principles to other assets",
                items: [
                  {
                    title: "Header capsule (460 x 215px) - In your store page",
                  },
                  {
                    title:
                      "Smaller capsule (231 x 87px) - displays in search resutls",
                  },
                  {
                    title:
                      "Main capsule (616 x 333px) - Displays if your game gets featured on the email when your game is launched or on sale.",
                  },
                ],
              },
              {
                title: "Make great caption",
                subText:
                  "If you are not an artist then outsource your capsule! Capsule image will improve your sale.",
              },
            ],
          },
          {
            title: "Tags",
            items: [
              { title: "See the tags that exist on similar games" },
              {
                title:
                  "Note down genres that exist in the majority of similar games you found",
              },
              {
                title:
                  "Go to “Edit Store Page” on your STEAM WORKS and select “Tag my Title”",
              },
              { title: "Add all the genres" },
              { title: "Order them correctly and Apply" },
            ],
          },
          {
            title: "Prepare Screenshots & Videos (Ask us how)",
          },
          {
            title: "Validate the Hoover tooltip (Ask us how)",
          },
          { title: "Know how shoppers browse your page (Ask us how)" },
          { title: "Write a short description (Ask us how)" },
          { title: "How to write the About this game section (Ask us how)" },
          { title: "Monitor visibility metrics (Ask us how)" },
          { title: "Get feedback (Ask us how)" },
          {
            title: "Steam Event Post",
            subText:
              "Steam allows you post events as part of your store/game page. You should probably prepare an announcement post. This is for the people that want to dig deeper than just a store page and maybe want to get to know you.",
            items: [
              {
                title:
                  "Announcement post is ready and prepared inside steam events page.",
              },
              {
                title:
                  "Announcement post contains some more information than just a store page.",
              },
              {
                title:
                  "Announcement post is not a dead end. Lead gamers to your website to signup to email list.",
              },
            ],
          },
        ],
      },
      {
        title: "Discord",
        platforms: ["discord"],
        items: [
          { title: "Make a Short and memorable discord server name" },
          { title: "Prepare Server Icon: 512 x 512px" },
          { title: "Prepare Profile Photo: 128 x 128px" },
          { title: "Prepare Server Banner: 960 x 540px" },

          {
            title: "Must have channels:",
            subText: `Use this awesome tool to for [templates](https://discordtemplates.me/templates/782453877781561406) *alo*`,
            items: [
              { title: "General" },
              { title: "Server-rules" },
              { title: "Announcement" },
              { title: "Information channel" },
              { title: "Staff channel (to meet up and discuss things)" },
            ],
          },
          { title: "Optional: Add emojis" },
        ],
      },
      {
        title: "Twitter",
        platforms: ["twitter"],
        items: [
          { title: "Set up Twitter" },
          { title: "The username must reflect your game" },
          {
            title:
              "Write a compelling Bio (Must express uniqueness of your game)",
          },
          { title: "Add a link to your Steam page and Discord channel" },
          { title: "Profile picture Size: 400 x 400px" },
          { title: "Header image Size: 1500 x 500px" },
          { title: "Prepare relevant content (Ask us how)" },
        ],
      },
      {
        title: "Facebook",
        platforms: ["facebook"],
        items: [
          { title: "Prepare a Facebook page" },
          { title: "Add good description and bio" },
          {
            title: "Prepare Profile images:",
            items: [
              { title: "Profile Photo: 2048 x 2048 pixels; ratio 1:1" },
              { title: "Cover Photo: 2037 x 754 pixels; ratio 2.7:1" },
            ],
          },
          {
            title: "Prepare Page images:",
            items: [
              { title: "Profile Photo: 2048 x 2048 pixels; ratio 1:1" },
              { title: "Cover Photo: 1958 x 745 pixels; ratio 2.63:1" },
            ],
          },
          {
            title: "(Optional)Prepare Facebook Group images",
            items: [{ title: "Cover Photo: 1640 x 922 pixels; ratio 1.78:1" }],
          },
          {
            title: "Prepare Facebook Photo Posts images (in the News Feed):",
            items: [{ title: "Featured image: 1200 x 628 pixels" }],
          },
          {
            title: "Prepare Facebook Page Like Ad images:",
            items: [
              { title: "Link/Offer in Desktop News Feed: 1200 x 628 pixels" },
              { title: "Link/Offer in Desktop Sidebar Ad: 254 x 133 pixels" },
              { title: "Link/Offer on Mobile News Feed: 560 x 292 pixels" },
              { title: "Image Posts: 2048 pixels wide" },
              { title: "Image Posts (Mobile): Up to 626 x 840 pixels" },
            ],
          },
          { title: "Prepare relevant content (Ask us how)" },
        ],
      },
      {
        title: "Emails",
        platforms: ["emails"],
        items: [
          { title: "Understand the goals of email marketing (Ask us how)" },
          { title: "Setup your template (Ask us how)" },
          { title: "Send Single-subject emails" },
          { title: "Funnel people to your email list (Ask us how)" },
          { title: "Setup Automation" },
          { title: "Want to know more? (Ask us how)" },
        ],
      },
      {
        title: "Website",
        platforms: ["website"],
        items: [
          {
            title: "Set up compelling landing page",
            items: [
              {
                title: "Provide platform information",
              },
              {
                title: "What state is the game currently in?",
              },
            ],
          },
          {
            title: "Collect emails",
            subText: `Don't underestimate email marketing. It's one of the most powerful tools at your displosal. Watch this amazing talk: [Build Your Own Fan Club: How to Use Your Email List] from GDC by Chris Zukowski `,
          },
          {
            title: "Set up Google Alerts",
            subText:
              'You can set up google alerts to send you notification when your game is mentioned "anywhere" on the internet.',
          },
        ],
      },
    ],
  },
  {
    title: "Game Announcement - Start Posting 🚨",
    items: [
      {
        title: "Post to Reddit",
        platforms: ["reddit"],
      },
      {
        title: "Post Announcement Tweet to Twitter",
        platforms: ["twitter"],
      },
      {
        title: "Post to all Facebook groups",
        platforms: ["facebook"],
      },
      {
        title: "Post to Imgur",
      },
      {
        title: "Post to 9gag",
      },
      {
        title: "Update the website",
        platforms: ["website"],
        subText:
          "Start collecting emails. Offer something in returns, like a free character in game, extra lore or whatever would be valuable to your players.",
      },
    ],
  },
];

const launchItems: Item[] = [
  {
    title: "Launch",
    items: [
      {
        title: "Game",
        items: [
          {
            title: "There are no bugs.",
            subText:
              "Don't just test the last feature you built. Test entire game.",
          },
          {
            title: "Play tests have been conducted",
            subText:
              "Get other people to play the game before you release. Too often things are obvious only to developers but not players.",
            items: [
              {
                title: "Players enjoy the game",
              },
              {
                title:
                  "Players don't get stuck because they can't figure something out",
              },
              {
                title: "Players can complete the game in it's entirety",
              },
              {
                title: "Game difficulty is appropriate",
              },
              {
                title: "Game is accessible",
              },
            ],
          },
        ],
      },
      {
        title: "Marketing",
        items: [
          {
            title: "Podcasts are ready",
          },
          {
            title: "Pricing has been determined",
          },
          {
            title: "Steam",
            platforms: ["steam"],
            items: [
              {
                title: "Steam page is ready",
                subText:
                  "If you plan to sell on Steam, it would be a waste if you announced without the goal of them wishlisting the game. Make sure you have the page ready & live so people can wishlist your game.",
              },
              {
                title: "Steam announcement is ready",
                subText:
                  "You can use our post builder to get some ideas: [converters](/converter)",
              },
              {
                title: "Game has been reviewed",
                subText:
                  "Don't make a mistake of getting the page ready and then having to wait for Steam to review just as you are about to post the announcement.",
              },
              {
                title: "Launch Discount is set",
                subText:
                  "This is really important. Changing it after a launch requires you to contact Steam and everyone that gets notified because you are on their wishlist won't know about the discount.",
              },
            ],
          },
          {
            title: "Website",
            platforms: ["website"],
            items: [
              {
                title: "Press Kit is Ready",
              },
              {
                title: "Roadmap is Live",
              },
              {
                title: "Texts have been updated",
              },
            ],
          },
          {
            title: "PR",
            items: [
              {
                title: "Press have content scheduled to go live",
                subText:
                  "Before launch you should get in touch with bunch of different websites for your target audience (think IGN, GameSpot, Kotaku...)",
              },
            ],
          },
          {
            title: "Content",
            items: [],
          },
          {
            title: "Ads",
            items: [
              {
                title: "Platforms have been selected",
              },
              {
                title: "Different versions were tested and best was selected",
                subText:
                  "You don't necessarily need to run ads to test, you can ask fellow gamers.",
              },
              {
                title: "Ads have been scheduled",
              },
            ],
          },
          {
            title: "Reviews",
            items: [
              {
                title: "List of reviews is ready",
                subText:
                  "It's important you get good reviews at the beginning. What loads of people do is get a list of reviews ready then they ask the friends to buy the game and ask them to review while providing them with the list of example reviews.",
              },
              {
                title: "Friends purchased a game",
              },
              {
                title: "Friends wrote reviews",
              },
            ],
          },
        ],
      },
    ],
  },
];

type Level = 1 | 2 | 3 | 4 | 5 | 6;

export function ChecklistItem({
  item,
  depth,
  id,
}: {
  id: string;
  item: Item;
  depth: Level;
}) {
  const size = React.useContext(ResponsiveContext);
  const marginLeftSize = size === "large" ? 4 : size === "medium" ? 3 : 1;
  const platforms = React.useContext(ChecklistPlatformsContext);
  const { items, toggleItem } = React.useContext(ChecklistStateContext);
  const pad = {
    vertical: "0px",
    right: "xsmall",
    left: `${marginLeftSize * depth}px`,
  };
  const didNotSelectPlatform =
    item.platforms &&
    item.platforms.every((platform) => !platforms.includes(platform));
  if (didNotSelectPlatform) {
    return null;
  }
  return (
    <Box
      background={`dark-${depth - 1}`}
      border={
        depth === 1
          ? { size: "0" }
          : {
              side: "left",
              color: `light-${depth}`,
              size: `${marginLeftSize}px`,
            }
      }
      margin={pad}
      pad={{ vertical: "xsmall", left: `${marginLeftSize * 3}px` }}
    >
      <Box direction="row" align="center">
        {item.items && (
          <Heading
            level={Math.min(6, depth + 1) as Level}
            size="small"
            margin={{ vertical: "xsmall" }}
            style={{ maxWidth: "none" }}
          >
            {item.title}
          </Heading>
        )}
        {item.platforms && <Box flex />}
        {item.items &&
          item.platforms?.map((platform) => (
            <PlatformTag key={platform} margin="xsmall" platform={platform} />
          ))}
      </Box>
      {!!item.items &&
        (typeof item.subText === "string" ? (
          <CondensedMarkdown size="small" content={item.subText} />
        ) : (
          item.subText
        ))}
      {item.items &&
        item.items.map((i, index) => (
          <ChecklistItem
            id={`${id}_${index}`}
            key={i.title}
            item={i}
            depth={Math.min(6, depth + 1) as Level}
          />
        ))}
      {!item.items && (
        <Box>
          <Box direction="row" align="center">
            <CheckBox
              checked={!!items[id]}
              onChange={() => toggleItem(id)}
              label={item.title}
            />
            {item.platforms && <Box flex />}
            {item.platforms?.map((platform) => (
              <PlatformTag
                key={platform}
                margin={{ horizontal: "xsmall" }}
                platform={platform}
              />
            ))}
          </Box>
          <Box margin={{ left: `${marginLeftSize * 9}px` }}>
            {typeof item.subText === "string" ? (
              <CondensedMarkdown
                options={{}}
                size="small"
                content={"\n\n" + item.subText + "\n"}
              />
            ) : null}
          </Box>
        </Box>
      )}
    </Box>
  );
}

function toMarkdown(items: Item[], depth = 1) {
  let markdown = "";
  const inset = "" + "  ".repeat(depth - 1);
  markdown += items.reduce((acc, item) => {
    const prefix = !item.items ? "- [ ] " : "- ";
    const boldWrap = item.items ? "**" : "";
    acc += `${inset}${prefix}${boldWrap}${item.title}${boldWrap}\n`;
    if (item.items) {
      acc += toMarkdown(item.items, depth + 1);
    }
    if (item.subText) {
      if (typeof item.subText === "string") {
        acc +=
          item.subText
            .split("\n")
            .map((line) => `${inset}  ${line}`)
            .join("\n") + "\n";
      } else {
        acc += ``;
      }
    }
    return acc;
  }, "");
  return markdown;
}

function getChecklist(checklist: ChecklistType) {
  switch (checklist) {
    case "Launch":
      return launchItems;
    case "Planning":
      return planningChecklist;
    case "Update":
      return updateChecklist;
    default:
      return [];
  }
}

export default function Checklist() {
  const [checklist, setChecklist] = React.useState<ChecklistType>("Planning");
  const activeChecklist = getChecklist(checklist);
  const { togglePlatform, isPlatformEnabled, platforms } = useUpdateData();

  const handleCopyAsMarkdown = () => {
    let markdown = toMarkdown(activeChecklist);
    // Make markdown links absolute
    markdown = markdown.replace(
      /\[(.*)\]\(\/(.*)\)/g,
      `[$1](${config.baseUrl}$2)`
    );

    console.log(markdown);
    window.navigator.clipboard
      ?.writeText(markdown)
      .catch((err) => console.error(err));
  };
  const cardBg = useColor("dark-2");
  const size = React.useContext(ResponsiveContext);

  return (
    <Page kind="full">
      <MiniOwnNav />
      <Box fill direction="row-responsive" background="dark-4" align="center">
        <ToggleRadio
          selected={checklist === "Planning"}
          onChange={() => setChecklist("Planning")}
          label="Planning"
          description="Production and Announcement plan"
        />
        <ToggleRadio
          selected={checklist === "Update"}
          onChange={() => setChecklist("Update")}
          label="Update"
          description="To Do for every game update you make"
        />
        <ToggleRadio
          selected={checklist === "Launch"}
          onChange={() => setChecklist("Launch")}
          label="Launch"
          description="Things to do for game launch"
        />
        <Box flex />
        <Button
          onClick={handleCopyAsMarkdown}
          size="small"
          label="Copy as Markdown"
          margin="small"
        ></Button>
      </Box>
      <Box
        direction={size === "small" ? "column-reverse" : "row-responsive"}
        background="dark-2"
        justify="center"
      >
        <Box
          width="xlarge"
          justify="start"
          border={{
            side: "right",
            size: "xsmall",
            color: "dark-4",
          }}
        >
          <ChecklistStateProvider>
            <ChecklistProvider platforms={platforms}>
              {activeChecklist.map((item, index) => (
                <ChecklistItem
                  id={`${checklist}_${index}`}
                  depth={1}
                  key={item.title}
                  item={item}
                />
              ))}
            </ChecklistProvider>
          </ChecklistStateProvider>
        </Box>
        <Box width="large" pad="medium" gap="small">
          <HelpCard title="Platforms">
            <Box direction="row" align="center">
              <BoxWithShadow shadow={`inset 0 0 12px 12px ${cardBg}`}>
                <Box pad="small" wrap direction="row">
                  {allPlatforms.map((platform) => (
                    <SmallDoubleIconButton
                      key={platform.key}
                      margin="xsmall"
                      background={platform.color}
                      Icon={platform.icon}
                      label={platform.name}
                      onClick={() => togglePlatform(platform.key)}
                      mode="checkbox"
                      selected={isPlatformEnabled(platform.key)}
                    />
                  ))}
                </Box>
              </BoxWithShadow>
            </Box>
          </HelpCard>
          <HelpCard title="Need more tools, not just checklists?" pad="small">
            We are working on a tool that helps you prepare content for multiple
            platforms in one go.
            <ButtonLink
              margin="xsmall"
              href="/tools-preview"
              label="Sign Up for Beta now"
            ></ButtonLink>
          </HelpCard>
        </Box>
      </Box>
    </Page>
  );
}

export function ToggleOption({
  label,
  onChange,
  color,
  selected,
}: {
  label: string;
  color?: string;
  selected: boolean;
  onChange(): void;
}) {
  return (
    <ToggleButton
      round="large"
      color={color}
      label={label}
      onSelect={onChange}
      selected={selected}
      SelectedIcon={CheckboxSelected}
      UnselectedIcon={Checkbox}
    />
  );
}

export function ToggleRadio({
  label,
  onChange,
  selected,
  description,
  ...buttonProps
}: {
  label: string;
  selected: boolean;
  description?: string;
  onChange(): void;
} & ButtonExtendedProps) {
  return (
    <ToggleButton
      {...buttonProps}
      label={
        <Text>
          {label}

          {description && (
            <>
              <br />
              <Text size="small">{description}</Text>
            </>
          )}
        </Text>
      }
      onSelect={onChange}
      selected={selected}
      SelectedIcon={RadialSelected}
      UnselectedIcon={Radial}
      color={selected ? "dark-1" : "dark-4"}
    />
  );
}

export function DoubleIconCheckbox({
  icon,
  color,
  label,
  selected,
  onChange,
}: {
  label: string;
  icon: React.ReactNode;
  color: string;
  selected: boolean;
  onChange?(): void;
}) {
  return (
    <ToggleButton
      style={{ border: "1px solid rgba(0,0,0,0.15)" }}
      label={label}
      SelectedIcon={DoubleIconHOC(icon, <Icons.CheckboxSelected />)}
      UnselectedIcon={DoubleIconHOC(icon, <Icons.Checkbox />)}
      selected={selected}
      color={color}
      onSelect={() => onChange && onChange()}
    ></ToggleButton>
  );
}
