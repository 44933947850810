import { Box, Button, TableCell, TableRow, Text } from 'grommet';
import * as Icons from 'grommet-icons';
import * as React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

// interface AutoPostTypeProps {
//   name: string;
//   facebook: boolean;
//   twitter: boolean;
//   email: boolean;
//   discord: boolean;
// }
//
// function AutoPostType({ name, facebook, twitter, discord, email }: AutoPostTypeProps) {
//   return (
//     <TableRow>
//       <TableCell>
//         <CheckBox checked={facebook} />
//       </TableCell>
//       <TableCell>
//         <CheckBox checked={discord} />
//       </TableCell>
//       <TableCell>
//         <CheckBox checked={twitter} />
//       </TableCell>
//       <TableCell>
//         <CheckBox checked={email} />
//       </TableCell>
//       <TableCell>
//         <Button size="small" icon={<Icons.View />} />
//       </TableCell>
//       <TableCell>
//         <Text weight="bold">{name}</Text>
//       </TableCell>
//     </TableRow>
//   );
// }
//

interface StageProps {
  from?: string;
  to?: string;
  info?: string;
  finished?: boolean;
  enableEdit?: boolean;
  signupCount: number;
  name: string;
  href?: string;
  onDownload?(): void;
}

const TableRowWithFinish = styled(TableRow)<{ finished?: boolean }>`
  position: relative;
  opacity: ${({ finished }) => (finished ? 0.5 : 1)};
  ${({ finished }) =>
    finished &&
    `
  &:after {
    content: '';
    position:absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 4px;
    background: rgba(0,0,0,0.2);
  }
  `}
`;

export default function StageRow({
  name,
  enableEdit = true,
  signupCount,
  info,
  to,
  from,
  finished,
  href,
  onDownload,
}: StageProps) {
  const history = useHistory();
  const handleClick = () => {
    if (href) {
      history.push(href);
    }
  };

  return (
    <TableRowWithFinish finished={finished}>
      <TableCell width="medium">
        <Text weight="bold">{name}</Text>
        {info && (
          <Text size="small" color="status-info">
            {info}
          </Text>
        )}
      </TableCell>
      <TableCell>{from || ''}</TableCell>
      <TableCell>{to || ''}</TableCell>
      <TableCell>
        <Button
          size="small"
          label="Download"
          onClick={onDownload}
          icon={<Icons.DocumentCsv size="small" />}
        />
      </TableCell>
      <TableCell colSpan={enableEdit ? 1 : 2}>
        <Button onClick={handleClick} size="small" label="View" />
      </TableCell>
      {enableEdit && (
        <TableCell>
          <Button
            secondary
            size="small"
            label="Edit"
            icon={<Icons.Edit size="small" />}
          />
        </TableCell>
      )}
      <TableCell align="center">
        <Box
          background="dark-4"
          pad={{ horizontal: 'small', vertical: 'xxsmall' }}
          round={{ size: 'small' }}
          justify="center"
        >
          <Text size="small">{signupCount}</Text>
        </Box>
      </TableCell>
    </TableRowWithFinish>
  );
}
