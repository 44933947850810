import OwnNav from '@core/components/organisms/OwnNav';
import { AppState } from '@core/redux/reducers';
import { SiteView } from '@core/redux/sitesEditor';
import { Text, Tab, Tabs, Box, Tip } from 'grommet';
import * as Icons from 'grommet-icons';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import PreviewComponent from '../../../../blind/PreviewComponent';
import ThemeTab from '../../../../organisms/EditSiteTabs/ThemeTab';
import BasicSetup from '../../../../organisms/EditSiteTabs/BasicSetupTab';
import FeaturesTab from '../../../../organisms/EditSiteTabs/FeaturesTab';
import MoreContentTab from '../../../../organisms/EditSiteTabs/MoreContentTab';
import PublishTab from '../../../../organisms/EditSiteTabs/PublishTab';
import CircleNumber from '../../../../atoms/CircleNumber';

// This hook...
const useSiteChanges = (site: SiteView) => {
  const sites = useSelector<AppState, SiteView[]>((state) => {
    return state.sitesEditor.sites;
  });
  const savedSite = React.useMemo(
    () => sites.find((s) => s.id === site.id, 10),
    [sites, site]
  );

  const data = React.useMemo(() => {
    const themeChanges = Object.entries(site.config.theme).some(
      ([colorKey, value]) => {
        if (savedSite && savedSite.config.theme[colorKey] !== value) {
          return true;
        }
        return false;
      }
    );

    const generalChanges = Object.entries(site.config.general).reduce<string[]>(
      (acc, [configKey, value]) => {
        const isObject = typeof value;
        if (isObject) {
          const hasObjectDifferences = Object.keys(value).some(
            (key) => value[key] !== savedSite?.config.general[configKey]?.[key]
          );
          if (hasObjectDifferences) {
            acc.push(configKey);
          }
        } else if (savedSite && savedSite.config.general[configKey] !== value) {
          acc.push(configKey);
        }
        return acc;
      },
      []
    );

    const featuresChanged =
      !!savedSite && site.config.features !== savedSite.config.features;

    const hasChanges = !!(
      featuresChanged ||
      themeChanges ||
      generalChanges.length > 0
    );
    return {
      featuresChanged,
      hasChanges,
      generalChanges,
      themeChanges,
      savedSite: savedSite!,
    };
  }, [site, sites, savedSite]);

  React.useEffect(() => {
    // TODO: deal with site navigation
    function beforeUnload(e: Event) {
      e.preventDefault();
      if (data.hasChanges) {
        e.returnValue = true;
        return 'Changes that you made may not be saved. Are you sure?';
      }
      return '';
    }

    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [data.hasChanges]);
  return data;
};

export function useSiteFromParams() {
  const { id } = useParams<{ id: string | undefined }>();
  const sites = useSelector<AppState, SiteView[]>((state) => {
    return state.sitesEditor.sites;
  });
  const stateSite = React.useMemo<SiteView>(
    () => sites.find((s) => s.id === parseInt(id || '-1', 10))!,
    [sites]
  );
  const [site, setSite] = React.useState<SiteView>(stateSite);
  const hasChanged = stateSite !== site;
  return [site, setSite, hasChanged] as const;
}

export default function EditSitePage() {
  const [site, setSite] = useSiteFromParams();
  if (!site || !site.config) return <Redirect to="/edit/sites" />;

  const handleSiteUpdate = (newSite: SiteView) => {
    setSite(newSite);
  };

  const [canEdit, setCanEdit] = React.useState(!!site.config.general.gameName);

  const [tab, setTab] = React.useState(0);
  const siteChanges = useSiteChanges(site);

  return (
    <>
      <OwnNav />
      <Tabs
        activeIndex={tab}
        onActive={setTab}
        style={{ minHeight: 'calc(100% - 64px)' }}
      >
        <Tab
          id="tab-basic-setup"
          icon={<CircleNumber counter="1." />}
          title="Basic Setup"
        >
          <BasicSetup
            onNextTab={() => {
              setCanEdit(true);
              setTab(1);
            }}
            site={site}
            onUpdateSite={handleSiteUpdate}
          />
        </Tab>
        <Tab
          id="tab-theme"
          icon={<CircleNumber counter="2." />}
          title="Theme"
          disabled={!canEdit}
        >
          <ThemeTab
            onNextTab={() => {
              setTab(2);
            }}
            site={site}
            onUpdateSite={handleSiteUpdate}
          />
        </Tab>

        <Tab
          id="tab-socials"
          icon={<CircleNumber counter="3." />}
          title="Socials"
          disabled={!canEdit}
        >
          <MoreContentTab
            onNextTab={() => {
              setTab(2);
            }}
            site={site}
            onUpdateSite={handleSiteUpdate}
          />
        </Tab>
        {canEdit && (
          <Tab
            id="tab-features"
            icon={<Icons.List size="small" />}
            title="Features"
          >
            <FeaturesTab onUpdateSite={handleSiteUpdate} site={site} />
          </Tab>
        )}
        {canEdit && (
          <Tab
            id="tab-preview"
            icon={<Icons.View size="small" />}
            title="Preview"
          >
            <PreviewComponent site={site} />
          </Tab>
        )}
        {canEdit && (
          <Tab
            id="tab-publish"
            icon={<Icons.Share size="small" />}
            title={<PublishTitle hasChanges={siteChanges.hasChanges} />}
          >
            <PublishTab
              {...siteChanges}
              onUpdateSite={handleSiteUpdate}
              site={site}
            />
          </Tab>
        )}
      </Tabs>
    </>
  );
}

function PublishTitle({ hasChanges }: { hasChanges: boolean }) {
  return (
    <Tip
      plain
      content={
        hasChanges ? (
          <Box pad="small" round="small" background="white">
            You have pending changes.
          </Box>
        ) : undefined
      }
    >
      <Box gap="small" direction="row" align="center" hoverIndicator={false}>
        <Text>Publish</Text>
        {hasChanges && (
          <Box animation="pulse">
            <Icons.StatusWarning size="18px" color="status-warning" />
          </Box>
        )}
      </Box>
    </Tip>
  );
}
