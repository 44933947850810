import { UserReducerType, EUserAction } from './types';

type Actions = ActionReducerType<UserReducerType, EUserAction>;

const initialState: UserReducerType = { isLoggedIn: false };

export const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case EUserAction.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn
      };
    default:
      return state;
  }
};
