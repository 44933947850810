import PageContent from "@core/components/atoms/PageContent";
import {
  Heading,
  Markdown,
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  CheckBox,
  FormField,
  FileInput,
} from "grommet";
import * as React from "react";
import PageLayout from "../../../../organisms/PageLayout";
// @ts-expect-error types are missing
import md2bb from "md2bb";
import Discord from "@core/components/atoms/icons/Discord";
import Steam from "@core/components/atoms/icons/Steam";
import * as Icons from "grommet-icons";
import CardFooterButton from "@core/components/atoms/CardFooterButton";
import MarkdownEditor from "../../../../molecules/MarkdownEditor";

const preloadedText = `# Hello

Enter your markdown here
`;

export default function PreparePost() {
  const [markdown, setMarkdown] = React.useState(preloadedText);
  return (
    <>
      <PageLayout flex background="brand-2">
        <PageContent pad={{ horizontal: "medium", top: "medium" }}>
          <Heading margin="0" level={1}>
            Write once, use everywhere
          </Heading>
        </PageContent>
        <PageContent
          direction="row"
          justify="stretch"
          flex
          background="brand-2"
          pad="medium"
          gap="medium"
        >
          <Card flex elevation="xsmall" background="dark-2">
            <CardHeader
              height="xxsmall"
              background="dark-3"
              pad={{ vertical: "small", horizontal: "medium" }}
            >
              <Heading level={3} margin={{ vertical: "0" }}>
                Your markdown
              </Heading>
            </CardHeader>
            <CardBody background="dark-2" flex>
              <MarkdownEditor value={markdown} onChange={setMarkdown} />
            </CardBody>
            <CardFooter
              align="stretch"
              background="dark-3"
              direction="column"
              pad="medium"
              gap="xsmall"
            >
              <Heading level={3} margin={{ horizontal: "0", vertical: "0" }}>
                Attach a file
              </Heading>
              <CheckBox toggle label="Convert for Discord" />
              <CheckBox toggle label="Convert for Steam" />
              <CheckBox toggle label="Convert for Web" />
              <FormField>
                <FileInput />
              </FormField>
            </CardFooter>
          </Card>
          <Card flex elevation="xsmall" background="dark-2">
            <CardHeader
              background="dark-3"
              gap="0"
              height="xxsmall"
              align="stretch"
            >
              <CardFooterButton selected>
                <Icons.Domain />
                Preview
              </CardFooterButton>
              <CardFooterButton>
                <Steam />
                Steam Export
              </CardFooterButton>
              <CardFooterButton>
                <Discord />
                Discord Export
              </CardFooterButton>
            </CardHeader>
            <CardBody flex pad="small">
              <Markdown>{markdown}</Markdown>
              <pre>{new md2bb().parse(markdown)}</pre>
            </CardBody>
            <CardFooter align="stretch" background="dark-3" gap="0" />
          </Card>
        </PageContent>
      </PageLayout>
    </>
  );
}
