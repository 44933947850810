import * as React from 'react';
import {
  ReadCategoryPosts,
  VoteOnVotePost,
  VotePostLoad,
  VotePostView,
  ReadCategories,
  VoteCategoryView,
  SortOrder,
  PostsSortFields,
  SiteContactCreate,
  SiteContactUpdate,
  SiteContactCompleteAction,
  ActionTypes,
  VotePostComment,
  VotePostGetComments,
  VotePostCommentView,
  SiteContactExchangeToken,
  CreateVoteCategory,
  UpdateVoteCategory,
  CreateVotePost,
  SiteContactsGet,
  ContactView,
  VotePostGetModerationQueueComments,
  ReadCategoryModerationQueuePosts,
  GetSitesIdContactsIdRequest,
} from '@core/types';
import {
  ToFrontendResponse,
  useLoadRequest,
  useMutationRequest,
} from './requestUtils';
import storage from '@core/common/storage';
import { useDispatch } from 'react-redux';
import { entitiesAddIdToIndex } from '@core/redux/entities/actions';

const MINUTE = 60 * 1000;
const ONE_HOUR = 60 * MINUTE;

export function useApiVoteOnVotePost(siteId: string, votePostId: string) {
  // Note: This is only to show correct client side state
  const [userVote, setUserVote] = React.useState(
    storage.getItem('user_has_voted:' + votePostId) || 0
  );

  const createVoteRequest = (increment: number) =>
    useMutationRequest<VoteOnVotePost>(
      {
        method: 'POST',
        url: 'sites/:siteId/vote-posts/:votePostId/vote',
        params: {
          votePostId,
          siteId,
        },
        body: {
          increment,
        },
      },
      {
        onError: () => {
          setUserVote(increment);
          // Set localstorage for next time
          storage.setItem('user_has_voted:' + votePostId, increment);
        },
        onSuccess: () => {
          setUserVote(increment);
          // Set localstorage for next time
          storage.setItem('user_has_voted:' + votePostId, increment);
        },
      }
    );

  const voteFor = createVoteRequest(1);
  const voteAgainst = createVoteRequest(-1);

  return { voteFor, voteAgainst, userVote };
}

export function useApiVotePostCreate({
  categoryId,
  siteId,
  title,
  markdown,
  onSuccess,
  onError,
}: {
  categoryId: string | null;
  siteId: number | null;
  title: string;
  markdown: string;
  onSuccess?(): void;
  onError?(): void;
}) {
  return useMutationRequest<CreateVotePost>(
    {
      method: 'POST',
      url: 'sites/:siteId/vote-categories/:categoryId/vote-posts',
      params: { siteId: String(siteId), categoryId: String(categoryId) },
      body: { title, markdown },
    },
    {
      onSuccess: (_data: CreateVotePost['response']) => {
        // TODO: invalidate requests or add to indexes, do something clever.
        if (onSuccess) {
          onSuccess();
        }
      },
      onError,
    }
  );
}

export function useApiCategoryCreate(
  category: Partial<VoteCategoryView>,
  siteId: string,
  onSuccess: () => void,
  onError: () => void
) {
  const dispatch = useDispatch();

  return useMutationRequest<CreateVoteCategory>(
    {
      method: 'POST',
      url: 'sites/:siteId/vote-categories',
      params: { siteId },
      body: category,
    },
    {
      onSuccess: (data: VotePostComment['response']) => {
        const categoryId = data.result.voteCategory?.[0];
        if (categoryId) {
          dispatch(
            entitiesAddIdToIndex(
              categoryId,
              JSON.stringify(['siteCategories', siteId]),
              'voteCategory'
            )
          );
        }

        if (onSuccess) {
          onSuccess();
        }
      },
      onError,
    }
  );
}

export function useApiCategoryUpdate(
  category: Partial<VoteCategoryView>,
  siteId: string,
  onSuccess: () => void,
  onError: () => void
) {
  return useMutationRequest<UpdateVoteCategory>(
    {
      method: 'PUT',
      url: 'sites/:siteId/vote-categories/:categoryId',
      params: { siteId, categoryId: String(category.id) },
      body: category,
    },
    {
      onSuccess,
      onError,
    }
  );
}

export function useApiCategories(siteId: string | number | null) {
  return useLoadRequest<ReadCategories, VoteCategoryView>(
    ['siteCategories', siteId],
    {
      params: { siteId: String(siteId) },
      url: 'sites/:siteId/vote-categories',
      method: 'GET',
    },
    'voteCategory',
    {
      staleTime: ONE_HOUR,
      enabled: !!siteId,
    }
  );
}

export function useApiCategoryPosts({
  siteId,
  categoryId,
  sortBy,
  sortOrder,
}: {
  siteId: string;
  categoryId?: string;
  sortBy?: PostsSortFields;
  sortOrder?: SortOrder;
}) {
  return useLoadRequest<ReadCategoryPosts, VotePostView>(
    ['sitePosts', siteId, categoryId, sortBy, sortOrder],
    {
      query: { categoryId: categoryId, sortBy, sortOrder },
      params: { siteId },
      url: 'sites/:siteId/vote-posts',
      method: 'GET',
    },
    'votePost',
    {
      staleTime: ONE_HOUR,
    }
  );
}

export function useApiVotePostLoad(siteId: string, votePostId: string) {
  return useLoadRequest<VotePostLoad, VotePostView>(
    ['votePosts', votePostId],
    {
      url: 'sites/:siteId/vote-posts/:votePostId',
      method: 'GET',
      params: {
        siteId,
        votePostId,
      },
    },
    'votePost',
    {
      staleTime: ONE_HOUR,
    }
  );
}

export function useApiCreateContact({
  onSuccess,
  onError,
  siteId,
  email,
}: {
  email: string;
  siteId: string;
  onSuccess(data: ToFrontendResponse<SiteContactCreate['response']>): void;
  onError?(): void;
}) {
  return useMutationRequest<SiteContactCreate>(
    {
      method: 'POST',
      url: 'sites/:siteId/contacts',
      params: { siteId },
      body: { email },
    },
    {
      onSuccess,
      onError,
    }
  );
}

export function useApiUpdateContact({
  onSuccess,
  onError,
  siteId,
  contactId,
  about,
}: {
  contactId: string;
  siteId: string;
  about: string;
  onSuccess(data: SiteContactUpdate['response']): void;
  onError?(): void;
}) {
  return useMutationRequest<SiteContactUpdate>(
    {
      method: 'PUT',
      url: 'sites/:siteId/contacts/:id',
      params: { siteId, id: contactId },
      body: { about },
    },
    {
      onSuccess,
      onError,
    }
  );
}

export function useApiExchangeToken(siteId: string, token: string) {
  return useMutationRequest<SiteContactExchangeToken>({
    method: 'POST',
    url: 'sites/:siteId/contacts/exhange-token',
    params: {
      siteId,
    },
    body: {
      token,
    },
  });
}

export function useApiCompleteActionContact({
  siteId,
  contactId,
  actionType,
  onSuccess,
}: {
  siteId: string;
  contactId: string;
  actionType: ActionTypes;
  onSuccess(data: SiteContactCompleteAction['response']): void;
}) {
  return useMutationRequest<SiteContactCompleteAction>(
    {
      url: 'sites/:siteId/contacts/:id/complete-action',
      method: 'POST',
      body: { actionType },
      params: {
        id: contactId,
        siteId,
      },
    },
    {
      onSuccess,
    }
  );
}

export function useApiVotePostComment({
  text,
  onSuccess,
  siteId,
  votePostId,
}: {
  text: string;
  siteId: string;
  votePostId: string;
  onSuccess?(data: VotePostComment['response']): void;
}) {
  const dispatch = useDispatch();
  return useMutationRequest<VotePostComment>(
    {
      url: 'sites/:siteId/vote-posts/:votePostId/comments',
      method: 'POST',
      params: {
        siteId,
        votePostId,
      },
      body: {
        text,
      },
    },
    {
      onSuccess: (data: VotePostComment['response']) => {
        const commentId = data.result.votePostComment?.[0];
        if (commentId) {
          dispatch(
            entitiesAddIdToIndex(
              commentId,
              JSON.stringify(['GET_comments', votePostId, siteId]),
              'votePostComment'
            )
          );
        }

        if (onSuccess) {
          onSuccess(data);
        }
      },
    }
  );
}

export function useApiCommentsModerationQueue(siteId: string) {
  return useLoadRequest<
    VotePostGetModerationQueueComments,
    VotePostCommentView
  >(
    ['GET_comments_moderation_queue', siteId],
    {
      url: 'sites/:siteId/comments/moderation-queue',
      method: 'GET',
      params: {
        siteId,
      },
    },
    'votePostComment',
    { staleTime: ONE_HOUR, enabled: !!siteId }
  );
}

export function useApiPostsModerationQueue(siteId: string) {
  return useLoadRequest<ReadCategoryModerationQueuePosts, VotePostView>(
    ['GET_posts_moderation_queue', siteId],
    {
      url: 'sites/:siteId/vote-posts/moderation-queue',
      method: 'GET',
      params: {
        siteId,
      },
    },
    'votePost',
    { staleTime: ONE_HOUR, enabled: !!siteId }
  );
}

export function useApiVotePostGetComments({
  votePostId,
  siteId,
}: {
  siteId: string;
  votePostId: string;
}) {
  return useLoadRequest<VotePostGetComments, VotePostCommentView>(
    ['GET_comments', votePostId, siteId],
    {
      url: 'sites/:siteId/vote-posts/:votePostId/comments',
      method: 'GET',
      params: {
        siteId,
        votePostId,
      },
    },
    'votePostComment',
    { staleTime: ONE_HOUR, enabled: !!votePostId && !!siteId }
  );
}

export function useApiContactsGet({ siteId }: { siteId: string }) {
  return useLoadRequest<SiteContactsGet, ContactView>(
    ['GET_contacts', siteId],
    {
      params: { siteId },
      method: 'GET',
      url: 'sites/:siteId/contacts',
      query: {},
    },
    'contact',
    { staleTime: MINUTE * 5 }
  );
}

export function useApiContactGet({
  siteId,
  contactId,
}: {
  siteId: number;
  contactId?: string | null;
}) {
  return useLoadRequest<GetSitesIdContactsIdRequest, ContactView>(
    ['GET_contact', siteId, contactId],
    {
      params: { siteId: String(siteId), contactId: String(contactId) },
      method: 'GET',
      url: 'sites/:siteId/contacts/:contactId',
    },
    'contact',
    { staleTime: MINUTE * 5, enabled: !!contactId }
  );
}
