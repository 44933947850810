type LocalStorageAllowedDataTypes = object | string | number | null | undefined;
// @ts-expect-error well, it's not always available
const localStorage: Storage = global.localStorage || {
  getItem: (_key: string) => null,
  setItem: (_key: string, _data: LocalStorageAllowedDataTypes) =>
    console.warn('No localStorage available'),
};

function setItem(key: string, data: LocalStorageAllowedDataTypes) {
  return localStorage.setItem(key, JSON.stringify(data));
}

function getItem<T>(key: string): T | null {
  const storedData = localStorage.getItem(key);
  return storedData ? (JSON.parse(storedData) as T) : null;
}

const storage = {
  setItem,
  getItem,
};

export default storage;
