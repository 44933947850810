import { fadeIn } from '@core/common/animations';
import { Box, Button, Heading } from 'grommet';
import * as React from 'react';
import styled from 'styled-components';
import tutorialManager from '../../common/TutorialManager';

export const ContinueButton = styled(Button)`
  align-self: center;
  opacity: 1;
  animation: ${fadeIn} 300ms linear 400ms backwards;
`;

const Holder = styled(Box)`
  animation: ${fadeIn} 300ms linear 400ms backwards;
`;

const HelpComponent = React.forwardRef<
  HTMLDivElement,
  { title: string; buttonText: string; content: React.ReactNode }
>(({ title, buttonText, content }, ref) => {
  return (
    <Holder
      ref={ref}
      alignContent="center"
      justify="center"
      pad="medium"
      key={Math.random()}
    >
      <Heading textAlign="center" color="white" level={2} margin="0">
        {title}
      </Heading>
      <Box
        style={{ color: 'white', textAlign: 'center', display: 'inline-block' }}
        margin="medium"
      >
        {content}
      </Box>
      <ContinueButton
        primary
        label={buttonText}
        onClick={() => tutorialManager.next()}
      />
    </Holder>
  );
});

export default HelpComponent;
