import * as React from 'react';
import { Text, CardBody, Heading, Box } from 'grommet';
import * as Icons from 'grommet-icons';
import useColor from '@core/hooks/useColor';
import useRound from '@core/hooks/useRound';
import useThankYouPageConfig from '@core/hooks/useThankYouPageConfig';
import { defaultSiteConfig } from '@core/common/siteConfigs';

export default function AmbasadorSocialContent() {
  const gradientEnd = useColor('dark-2');
  const gradientStart = useColor('dark-1');
  const gradientEndHover = useColor('dark-4');
  const linearGradient = `linear-gradient(to right, ${gradientStart} 50%, ${gradientEnd} 80%);`;
  const linearGradientHover = `linear-gradient(-35deg, ${gradientEnd} 40%, ${gradientEndHover} 70%);`;
  const round = useRound();
  const tpConfig = useThankYouPageConfig();
  return (
    <CardBody pad="medium" background="dark-2" gap="small">
      {tpConfig.ambassadorText ||
        defaultSiteConfig.thankYouPage?.ambassadorText ||
        ''}
      {tpConfig.ambassadorBoxes.map((val, i) => (
        <Box
          key={i}
          border={{
            size: '1px',
            color: val.link ? 'light-1' : 'dark-4',
          }}
          style={{ transition: 'all 300ms ease-out' }}
          direction="row"
          round={round}
          overflow="hidden"
          background={linearGradient}
          elevation={val.link ? 'medium' : '0'}
          hoverIndicator={{ background: linearGradientHover }}
          onClick={val.link ? () => window.open(val.link, '_blank') : undefined}
        >
          <Box flex pad="small">
            <Heading level={4} margin="0">
              {val.title}
            </Heading>
            <Text size="small">{val.description}</Text>
          </Box>
          {val.link && (
            <Box
              background={gradientEnd}
              direction="row"
              align="center"
              justify="center"
              pad="small"
              width="72px"
            >
              <Icons.Link size="small" />
              &nbsp;
            </Box>
          )}
        </Box>
      ))}
    </CardBody>
  );
}
