import PageContent from '@core/components/atoms/PageContent';
import { Heading } from 'grommet';
import * as React from 'react';
import { useLocation, useParams } from 'react-router';
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function OAuthCallback() {
  const query = useQuery();
  const code = query.get('code');
  // This can happen when user denis a request.
  const error = query.get('error');
  const errorDescription = query.get('error_description');
  // TODO: Track this error in sentry!

  const { service } = useParams<{ service?: string }>();
  React.useEffect(() => {
    if (error || errorDescription) {
      console.warn({ error, errorDescription });
      const oauthLoginChannel = new BroadcastChannel('oauthLoginChannel');
      oauthLoginChannel.postMessage({ type: 'error' });
      oauthLoginChannel.close();
      window.close();
    }
  }, []);

  React.useEffect(() => {
    if (service && code) {
      const oauthLoginChannel = new BroadcastChannel('oauthLoginChannel');
      oauthLoginChannel.postMessage({ type: 'code', code }); // send the code
      oauthLoginChannel.close();
      window.close();
    }
  }, [service, code]);
  return (
    <PageContent>
      {!service ||
        (!code && (
          <Heading>Something went wrong during authorisation.</Heading>
        ))}
    </PageContent>
  );
}
