class Logger {
  // Warns shouldn't happen
  warn(log: string, extraData?: any) {
    console.warn(log, JSON.stringify(extraData));
  }
  // Logs can be used for basic statistics via grafana
  info(log: string, extraData?: any) {
    if (extraData) {
      console.log(log, JSON.stringify(extraData));
    } else {
      console.log(log);
    }
  }
  // Errors should be used when exceptions happen
  // and things really error. Not just for bad user input.
  error(log: string, error?: any) {
    console.error(log, JSON.stringify(error));
  }

  timeLog(key: string, label?: string, extraData?: any) {
    this.addStageTiming(key);
    if (this.timers[key].length === 1) {
      this.info(`${key} started | ${label}`, { ...extraData });
    } else {
      const lastIndex = this.timers[key].length - 1;
      const stageDuration =
        this.timers[key][lastIndex] - this.timers[key][lastIndex - 1];
      this.info(`${key} step duration\t${stageDuration}ms\t | ${label}`, {
        ...extraData,
      });
    }
  }

  timeEnd(key: string) {
    this.addStageTiming(key);
    const lastIndex = this.timers[key].length - 1;
    const fullDuration = this.timers[key][lastIndex] - this.timers[key][0];

    this.info(`${key} completed in ${fullDuration}ms`);
    delete this.timers[key];
  }

  private timers: Record<string, number[]> = {};

  private addStageTiming(key: string) {
    if (!this.timers[key]) {
      this.timers[key] = [];
    }

    const currentTime = new Date().getTime();
    this.timers[key].push(currentTime);
  }
}

const logger = new Logger();

export default logger;
