import { Box } from "grommet";
import styled from "styled-components";

export default styled(Box) <{ shadow?: string; }> `
  position: relative;
  &:after {
    pointer-events: none;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    box-shadow: ${(props) => props.shadow
    ? props.shadow
    : "inset 0 -10px 20px 2px rgba(66, 72, 93, 1)"};
  }
`;
