import fontList from '@core/config/fontList';
import { Box, Text, DropButton, Pagination, TextInput } from 'grommet';
import * as React from 'react';
import * as Icons from 'grommet-icons';
import { Helmet } from 'react-helmet';

const itemsPerPage = 15;

export interface NewFont {
  name: string;
  url: string;
  weight: number;
}

interface Props {
  label: string;
  font: NewFont;
  onSelectNew(newFont: NewFont): void;
}

const fontWeightNames = {
  '100': 'Thin',
  '200': 'Extra light',
  '300': 'Light',
  '400': 'Regular',
  '500': 'Medium',
  '600': 'Semi-Bold',
  '700': 'Bold',
  '800': 'Extra Bold',
  '900': 'Heavy',
};

export default function FontSelectInput({ label, font, onSelectNew }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isWeightSelectorOpen, setIsWeightSelectorOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState('');
  const resultFonts =
    searchQuery.length === 0
      ? fontList
      : fontList.filter(
          (f) => f.toLowerCase().includes(searchQuery.toLowerCase())
        );

  const handleWeightClick = (weight: number) => {
    onSelectNew({
      url: getFontUrl(font.name, [weight]),
      name: font.name,
      weight: weight,
    });
  };

  return (
    <Box direction="row" gap="0" margin="xsmall">
      <Helmet>
        <link
          rel="stylesheet"
          href={getFontUrl(
            font.name,
            Object.keys(fontWeightNames).map((val) => parseInt(val, 10))
          )}
        />
      </Helmet>
      <DropButton
        gap="medium"
        dropAlign={{ top: 'bottom' }}
        dropProps={{
          round: 'small',
        }}
        style={{
          flex: 1,
          fontFamily: font.name,
          fontWeight: font.weight,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        icon={<Icons.Down size="small" />}
        label={label}
        open={isOpen}
        size="small"
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        dropContent={
          <>
            <Box pad="small">
              <TextInput
                size="small"
                onChange={(e) => {
                  setPage(1);
                  setSearchQuery(e.target.value);
                }}
                value={searchQuery}
                style={{ borderRadius: 0 }}
                placeholder="Search..."
                icon={<Icons.Search />}
              />
              {resultFonts
                .slice(
                  Math.max(0, (page - 1) * itemsPerPage),
                  page * itemsPerPage
                )
                .map((resultFont) => {
                  return (
                    <Box
                      key={resultFont}
                      style={{ fontFamily: resultFont }}
                      hoverIndicator
                      pad={{ vertical: 'xsmall', horizontal: 'small' }}
                      onClick={() => {
                        onSelectNew({
                          url: getFontUrl(resultFont, [
                            findDefaultAvailableWeight(resultFont, font.weight),
                          ]),
                          name: resultFont,
                          weight: findDefaultAvailableWeight(
                            resultFont,
                            font.weight
                          ),
                        });
                        setIsOpen(false);
                      }}
                    >
                      {resultFont}
                      <Helmet>
                        <link
                          rel="stylesheet"
                          href={getFontUrl(
                            resultFont,
                            Object.keys(fontWeightNames).map((val) =>
                              parseInt(val, 10)
                            )
                          )}
                        />
                      </Helmet>
                    </Box>
                  );
                })}
            </Box>
            <Pagination
              page={page}
              onChange={({ page: newPage }) => setPage(newPage)}
              numberItems={resultFonts.length}
              step={itemsPerPage}
            />
          </>
        }
      />
      <DropButton
        gap="medium"
        style={{
          fontFamily: font.name,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        dropProps={{ align: { top: 'bottom' } }}
        icon={<Icons.Down size="small" />}
        label={fontWeightNames[font.weight]}
        open={isWeightSelectorOpen}
        size="small"
        onOpen={() => setIsWeightSelectorOpen(true)}
        onClose={() => setIsWeightSelectorOpen(false)}
        dropContent={
          <Box>
            {Object.entries(fontWeightNames).map(([weight, name]) => (
              <FontWeightItem
                key={weight}
                fontFamily={font.name}
                name={name}
                weight={parseInt(weight, 10)}
                onClick={handleWeightClick}
              />
            ))}
          </Box>
        }
      />
    </Box>
  );
}

function FontWeightItem({
  name,
  weight,
  fontFamily,
  onClick,
}: {
  name: string;
  weight: number;
  fontFamily?: string;
  onClick(weight: number): void;
}) {
  let isWeightAvailable;
  try {
    isWeightAvailable = isFontAvailable(fontFamily || 'nope', weight);
  } catch {
    isWeightAvailable = false;
  }

  if (!isWeightAvailable) {
    return null;
  }
  return (
    <Box
      onClick={() => onClick(weight)}
      hoverIndicator
      pad={{ horizontal: 'small', vertical: 'xsmall' }}
    >
      <Text size="small" style={{ fontFamily }} weight={weight}>
        {weight} - {name}
      </Text>
    </Box>
  );
}

function getFontUrl(name: string, weights: number[]) {
  return `https://fonts.googleapis.com/css?family=${encodeURIComponent(
    name
  )}:${weights.join(',')}`;
}

function findDefaultAvailableWeight(
  fontFamily: string,
  tryWeightFirst: number
) {
  return (
    [tryWeightFirst, 400, 500, 600, 300, 800, 900].find((weight) =>
      isFontAvailable(fontFamily, weight)
    ) || 400
  );
}

function isFontAvailable(fontFamily: string, weight: number) {
  const fonts = document.fonts as unknown as Map<FontFace, FontFace>;
  const values = fonts.values();
  let result = values.next();
  while (!result.done) {
    if (
      result.value.weight === String(weight) &&
      result.value.family === fontFamily
    ) {
      return true;
    }
    result = values.next();
  }

  return false;
}
