import logger from '@server/lib/utils/logger';

const DEBUG = true;
const DEBUG_PREFIX = 'TutorialManager.ts';

export class TutorialManager {
  tutorialSteps: Record<string, { complete(): void; highlight(): void }> = {};
  currentSteps: string[] = [];
  currentIndex = 0;

  public start(names: string[]) {
    this.currentSteps = names;
    this.show(names[0]);
    this.currentIndex = 0;
    if (DEBUG) {
      logger.info(`${DEBUG_PREFIX} Start`, this);
    }
  }

  public next() {
    if (DEBUG) {
      logger.info('Next', this);
    }

    // Complete current tutorial
    if (this.currentIndex === this.currentSteps.length - 1) {
      const step = this.currentSteps[this.currentIndex];
      if (step) {
        this.tutorialSteps[step]?.complete();
      }
      this.currentIndex = 0;
      return;
    }
    // Show next step
    this.currentIndex += 1;
    const nextStep = this.currentSteps[this.currentIndex];
    this.show(nextStep);
  }

  public register(name: string, highlight: () => void, complete: () => void) {
    if (DEBUG) {
      logger.info(`${DEBUG_PREFIX} Register`, this);
    }
    this.tutorialSteps[name] = { highlight, complete };
  }

  public unregister(name: string) {
    if (DEBUG) {
      logger.info(`${DEBUG_PREFIX} Unregister`, this);
    }
    // If currently active tutorial becomes unavailable
    // then move to the next one
    if (name === this.currentSteps[this.currentIndex]) {
      this.tutorialSteps[name]?.complete();
    }
    delete this.tutorialSteps[name];
  }

  private show(name: string) {
    if (DEBUG) {
      logger.info(`${DEBUG_PREFIX} Show`, this);
    }
    if (this.tutorialSteps[name]) {
      this.tutorialSteps[name].highlight();
    } else {
      logger.warn(`No step for tutorial ${name}`);
    }
  }
}
export default new TutorialManager();
