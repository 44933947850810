import * as React from 'react';
import FeatureComponent, { Feature } from '../molecules/Feature';

interface Props {
  features: Feature[];
}

export default function Features({ features }: Props) {
  return (
    <>
      {features.map((feature, i) => {
        const isEven = i % 2 === 0;
        const hasMedia =
          feature.mov ||
          feature.mp4 ||
          feature.webm ||
          feature.imageElement ||
          feature.imageUrl;
        const side = hasMedia ? (isEven ? 'left' : 'right') : 'center';
        return <FeatureComponent i={i} side={side} feature={feature} key={i} />;
      })}
    </>
  );
}
