import { AnchorLink } from '@core/components/atoms/AnchorLink';
import OwnNav from '@core/components/organisms/OwnNav';
import { config } from '@core/config/config';
import { accountsPassword } from '@core/redux/accounts/client';
import SitesEditorAction from '@core/redux/sitesEditor/actions';
import UserAction from '@core/redux/user/actions';
import { selectIsLoggedIn } from '@core/redux/user/selectors';
import {
  Box,
  Form,
  Button,
  FormField,
  Layer,
  TextInput,
  Text,
  Heading,
  FormExtendedEvent,
} from 'grommet';
import posthog from 'posthog-js';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
export default function Signup() {
  const [error, setError] = React.useState<null | string>(null);
  const dispatch = useDispatch();
  const handleFormSubmit = (
    e: FormExtendedEvent<{ email: string; password: string }>
  ) => {
    accountsPassword
      .createUser({
        email: e.value.email,
        password: e.value.password,
      })
      .then((_createdUser) => {
        if (config.posthogEnabled) {
          posthog.capture('Sign Up Succeess');
        }
        dispatch(UserAction.setIsLoggedIn(true));
        dispatch(SitesEditorAction.createFromBeforeLogin());
      })
      .catch((failed) => {
        setError(failed.message);
      });
  };
  const history = useHistory();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const navigatedAlready = React.useRef(false);

  return (
    <>
      {isLoggedIn && <Redirect to="/" />}
      <OwnNav />
      <Layer
        modal={true}
        onClickOutside={() => {
          if (!navigatedAlready.current) {
            history.goBack();
            navigatedAlready.current = true;
          }
        }}
        onEsc={() => history.goBack()}
      >
        <Box pad="medium">
          <Heading alignSelf="center" level="3">
            Create Account
          </Heading>
          <Form onSubmit={handleFormSubmit}>
            <FormField label="Email" error={error}>
              <TextInput id="email-text-input" name="email" />
            </FormField>
            <FormField label="Password">
              <TextInput
                id="password-text-input"
                name="password"
                type="password"
              />
            </FormField>
            <Box pad="small">
              <Text size="small">
                By Signing Up you agree to{' '}
                <AnchorLink to="/terms-conditions">
                  Terms & Conditions
                </AnchorLink>
              </Text>
              <Box pad="small">
                <Button
                  id="sign-up-submit"
                  type="submit"
                  style={{ color: 'white' }}
                  size="medium"
                  primary
                  label="Sign Up"
                />
              </Box>
            </Box>
          </Form>
        </Box>
      </Layer>
    </>
  );
}
