import {
  CardBody,
  Text,
  CardHeader,
  Heading,
  Page,
  PageContent,
  Grid,
  ResponsiveContext,
  Box,
  Paragraph,
  Layer,
  Anchor,
} from "grommet";
import * as React from "react";
import { FancyCard } from "./Pricing";
import * as Icons from "grommet-icons";
import Steam from "@core/components/atoms/icons/Steam";
import Discord from "@core/components/atoms/icons/Discord";
import EmailInputCollection from "@core/components/molecules/EmailInputCollection";
import SocialLinksC from "@core/components/molecules/SocialLinks";
import { SiteConfigContext } from "@core/components/blind/contexts/SiteConfigContext";
import toast from "react-hot-toast";
import useSessionContact from "@core/hooks/useSessionContact";
import MessageBox from "@core/components/atoms/MessageBox";
import ButtonLink from "./ButtonLink";
import styled, { keyframes } from "styled-components";
import TextTransition, { presets } from "react-text-transition";
import ContactAvatar from "@core/components/molecules/ContactAvatar";
import useColor from "@core/hooks/useColor";
import { useHistory, useParams } from "react-router";

const sliderLoop = keyframes`
  0% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(-100%)
  }
`;

const VerticalShadowBox = styled(Box)<{ shadowColor: string }>`
  position: relative;
  &:before {
    position: absolute;
    height: 50px;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 10;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0),
      ${(props) => props.shadowColor}
    );
  }
  &:after {
    position: absolute;
    height: 50px;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 10;
    background: linear-gradient(
      0deg,
      ${(props) => props.shadowColor},
      rgba(0, 0, 0, 0)
    );
  }
`;

const QuoteListBox = styled(Box)`
  animation: ${sliderLoop} calc(8s * 5) linear infinite;
  flex-shrink: 0;
`;

interface ModalProps {
  isVisible: boolean;
  onClose(): void;
}

function ModalGrowth({ onClose, isVisible }: ModalProps) {
  const handleEmailSuccess = () => {
    toast.success(
      "Thank you for showing your interest. I will get back to you as soon as there is something for you!",
      {
        position: "top-center",
        duration: 10000,
      }
    );
  };

  const [contact] = useSessionContact();
  if (!isVisible) return null;
  return (
    <Layer onClickOutside={onClose}>
      <Box overflow="scroll">
        <Heading level={3} alignSelf="center">
          Sign up for early access
        </Heading>
        <Box direction="row-responsive" flex={{ shrink: 0 }}>
          <Box pad="medium">
            <Heading level={4} margin="0">
              About Growth Engine:
            </Heading>
            <Paragraph>
              I want to explore the growth engine that was partially used in our
              game and it worked quite well.
            </Paragraph>
            <Paragraph>
              It allowed us to <strong>double</strong> our wishlist count. Hence
              I want to develop it further and explore how powerful it really
              is.
            </Paragraph>
          </Box>
          <Box pad="medium">
            <Heading margin="0" level={4}>
              Be the first to try it out
            </Heading>
            <Paragraph>
              Growth Engine lets you build an engaging viral loop for your
              pre-launch play tests.
            </Paragraph>
            <EmailInputCollection
              onSuccess={handleEmailSuccess}
              hideDetailsButton
            />
            {!!contact && (
              <MessageBox status="ok">
                You will be notified as I progress with the tools.
              </MessageBox>
            )}
          </Box>
        </Box>
        <Text margin="medium" size="small">
          Note: Because these tools will require hosting and beefy servers you
          need to expect that some tools will cost money to be used. Please sign
          up and lets chat. Early adopters will be rewarded.
        </Text>
      </Box>
    </Layer>
  );
}

const HeadingCenterWhenSmall = styled(Heading)`
  @media (max-width: 768px) {
    text-align: center;
  }
`;

function ModalGuidedContent({ onClose, isVisible }: ModalProps) {
  const handleEmailSuccess = () => {
    toast.success(
      "Thank you for showing your interest. I will get back to you as soon as I have something for you!",
      {
        position: "top-center",
        duration: 10000,
      }
    );
  };

  const [contact] = useSessionContact();
  if (!isVisible) return null;
  return (
    <Layer onClickOutside={onClose}>
      <Box overflow="scroll" width="xlarge">
        <Heading level={3} alignSelf="center">
          Sign up for early access
        </Heading>
        <Box direction="row-responsive" flex={{ shrink: 0 }}>
          <Box pad="medium">
            <Heading level={4} margin="0">
              About Guided Content Preparation:
            </Heading>
            <Paragraph>
              If you are serious about your game updates, you will need to
              prepare content for multiple platforms (social medias, game
              stores, ...). This process alone can take a couple of hours.
            </Paragraph>
            <Box width="large" round="medium" overflow="hidden">
              <video controls={true}>
                <source key="video" src="/content_demo.mp4" type="video/mp4" />
              </video>
            </Box>
            <Paragraph>
              I am building the tools that I believe will{" "}
              <strong>
                reduce the time from couple of hours to few minutes
              </strong>{" "}
              while improving effectivnes of your updates.
            </Paragraph>
          </Box>
          <Box pad="medium">
            <Heading margin="0" level={4}>
              Be the first to try it out
            </Heading>
            <Paragraph>
              The tools let you prepare <strong>1 post</strong> and then get{" "}
              <strong>exports for all other platforms</strong>, such as Steam,
              Discord, Twitter, Reddit...
            </Paragraph>
            <EmailInputCollection
              onSuccess={handleEmailSuccess}
              hideDetailsButton
            />
            {!!contact && (
              <MessageBox status="ok">
                You will be notified as I progress with the tools.
              </MessageBox>
            )}
          </Box>
        </Box>
        <Text margin="medium" size="small">
          Note: Because these tools will require hosting and beefy servers you
          need to expect that some tools will cost money to be used. Please sign
          up and lets chat. Early adopters will be rewarded.
        </Text>
      </Box>
    </Layer>
  );
}

export default function LandingPageB() {
  const size = React.useContext(ResponsiveContext);
  const siteConfig = React.useContext(SiteConfigContext);
  const { modal } = useParams<{ modal?: string }>();

  const history = useHistory();

  const shadowColor = useColor("dark-1");
  const handleEmailSuccess = () => {
    toast.success(
      "Thank you for showing your interest. I will get back to you as soon as there is something for you!",
      {
        position: "bottom-center",
        duration: 10000,
      }
    );
  };
  return (
    <>
      <ModalGuidedContent
        isVisible={modal === "online-presence"}
        onClose={() => history.push("/")}
      />
      <ModalGrowth
        isVisible={modal === "tools-preview"}
        onClose={() => history.push("/")}
      />
      <Page kind="wide">
        <PageContent
          pad={{ horizontal: "medium" }}
          background={{ fill: "horizontal", color: "dark-1" }}
          justify="center"
          direction="row-responsive"
          style={{ minHeight: "50vh" }}
          align="center"
        >
          <Box flex width="medium" alignSelf="center">
            <HeadingCenterWhenSmall level={1} size="small">
              Guidelines and tools for game development teams and agencies
            </HeadingCenterWhenSmall>
            <HeadingCenterWhenSmall level={2} size="small">
              Save time, deliver better updates and boost your
              <br />
              <ChangingText
                texts={[
                  "wishlist count.",
                  "community engagement.",
                  "feedback quality.",
                ]}
              />
            </HeadingCenterWhenSmall>
          </Box>
          <VerticalShadowBox
            shadowColor={shadowColor}
            flex
            style={{ maxHeight: "500px" }}
            margin="large"
            overflow="hidden"
          >
            <QuoteListBoxTestemonials />
            <QuoteListBoxTestemonials />
          </VerticalShadowBox>
        </PageContent>
        <PageContent
          pad="medium"
          style={{ minHeight: "50vh" }}
          background={{ fill: "horizontal", color: "dark-2" }}
        >
          <Heading alignSelf="center" level={2}>
            Use the tools
          </Heading>
          <Grid
            columns={size === "small" ? ["flex"] : ["flex", "flex", "flex"]}
            gap="large"
            justify="center"
          >
            <Tool
              title="Guidelines for Launching a Successful Game"
              icon={<Icons.List />}
            >
              <Box gap="small" flex>
                <Box gap="small" flex>
                  <Text>
                    Track and learn about game development cycles and reach
                    important milestones by following{" "}
                    <strong>checklists</strong>.
                  </Text>
                </Box>
                <ButtonLink
                  color="brand-1"
                  primary
                  href="/checklist"
                  label="Start Now"
                  icon={<Icons.LinkNext color="white" />}
                ></ButtonLink>
              </Box>
            </Tool>
            <Tool title="Build Your Online Presence" icon={<Icons.Network />}>
              <Box gap="small">
                <Text>
                  Drastically speed up and improve quality of your game updates.
                </Text>
                <Text>
                  Learn, follow and implement successful social media strategies
                </Text>
                <Box
                  direction="row"
                  justify="center"
                  gap="small"
                  pad="small"
                  flex
                >
                  <Icons.Reddit color="reddit" />
                  <Steam color="steam" />
                  <Discord color="discord" />
                  <Icons.Twitter color="twitter" />
                  <Icons.Mail color="emails" />
                  <Icons.Domain />
                </Box>
                <ButtonLink
                  color="brand-3"
                  href="/online-presence"
                  primary
                  label="View More"
                  icon={<Icons.LinkNext color="white" />}
                ></ButtonLink>
              </Box>
            </Tool>
            <Tool title="Focus on what matters" icon={<Icons.Optimize />}>
              <Box gap="small" flex>
                <Box flex gap="small">
                  <Text>
                    We have bunch of tools that can help you with boring stuff,
                    so you can focus on what you enjoy - creative part.
                  </Text>
                  <Text>
                    Build gaming websites, collect feedback, organise viral
                    testing rounds and more...
                  </Text>
                </Box>
                <ButtonLink
                  color="brand-3"
                  href="/tools-preview"
                  primary
                  label="View More"
                  icon={<Icons.LinkNext color="white" />}
                ></ButtonLink>
              </Box>
            </Tool>
          </Grid>
        </PageContent>
      </Page>
      <Page kind="narrow">
        <PageContent
          pad="medium"
          background={{ fill: "horizontal", color: "dark-1" }}
        >
          <Heading alignSelf="center" textAlign="center" level={3}>
            Stay updated as I release more tools:
          </Heading>
          <EmailInputCollection
            onSuccess={handleEmailSuccess}
            hideDetailsButton
          />
          <SocialLinksC {...siteConfig?.general} />
          <Heading level={3} alignSelf="center">
            Why am I building this?
          </Heading>
          <Box direction="row-responsive" gap="medium">
            <Box>
              <Heading level={4}>
                1. I want to put tools I built <br />
                to a good use
              </Heading>
              <Paragraph>
                I built a bunch of tools for our current game, wrote a lot of
                docs did a lot of research, found many good resource
                materials... It feels like a waste for all of this to stay
                private.
              </Paragraph>
              <Paragraph>
                Over time I am planning to prepare more and more resources from
                our library, checklists felt the most useful to start with.
              </Paragraph>
            </Box>
            <Box>
              <Heading level={4}>
                2. Figure out if I should continue building the tools further
              </Heading>
              <Paragraph>
                I want to make the tools that don't cost money to run free (such
                as checklists and some other ideas I have). But the best ones
                come from the things that require constant maintenance and have
                attached ongoing cost. Therefore, for the best features to be
                available I need to figure out if this is commercially viable
                product to build, that could help community en large.
              </Paragraph>
            </Box>
          </Box>
          <Paragraph alignSelf={size !== "small" ? "center" : "start"}>
            So if you think you need tools that will help you market, develop
            and engage your game and community, then you should probably sign
            up.
          </Paragraph>
        </PageContent>
      </Page>
    </>
  );
}

const FillingBox = styled(Box)`
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 4px;
  border-radius: 2px;
  transition: width 3000ms ease-in-out;
`;

export function ChangingText({ texts }: { texts: string[] }) {
  const currentIndex = React.useRef(0);
  const [currentText, setCurrentText] = React.useState(texts[0]);

  React.useEffect(() => {
    const nextText = () => {
      currentIndex.current += 1;
      setCurrentText(texts[currentIndex.current % texts.length]);
    };
    const interval = setInterval(nextText, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Text size="inherit" style={{ position: "relative" }}>
      <FillingBox background="dark-2" style={{ width: "100%" }} />
      <FillingBox
        background="dark-5"
        style={{ width: currentIndex.current % 2 === 0 ? "0%" : "100%" }}
      />
      <TextTransition inline springConfig={presets.default}>
        {currentText}
      </TextTransition>
    </Text>
  );
}

export function Tool({
  title,
  children,
  icon,
}: {
  title: string;
  children: React.ReactChild;
  icon: React.ReactNode;
}) {
  return (
    <FancyCard
      width="100%"
      elevation="xlarge"
      startColor={`dark-2`}
      endColor={`dark-4`}
    >
      <CardHeader
        pad={{ horizontal: "medium", top: "small", bottom: "xsmall" }}
      >
        <Heading color="light-4" margin="0" level={3} size="small">
          {title}
        </Heading>
        {icon}
      </CardHeader>
      <CardBody
        pad={{ horizontal: "medium", top: "xsmall", bottom: "medium" }}
        gap="small"
      >
        {children}
      </CardBody>
    </FancyCard>
  );
}

const QuoteBox = styled(Box)`
  transform: scale(0.9);
  transition: all 300ms ease-out;
  &:hover {
    transform: scale(1);
  }
`;

function QuoteListBoxTestemonials() {
  return (
    <QuoteListBox>
      <Quote
        author="Alain (dev)"
        image="/users/alchemical.jpeg"
        link="https://store.steampowered.com/publisher/alchemicalworks"
        from="Alchemical Works"
        text="That's one of the struggles! The goal is to build a Wishlist! I spend 15 hours a week setting up everything from YouTube to Twitter. 10 Hours now goes for updates on social media."
      />
      <Quote
        author="Lucas (dev)"
        image="/users/shroeder.jpeg"
        link="https://store.steampowered.com/curator/33994560-Shroeder-Studios/"
        from="Shroeder Studios"
        text="People would want to build Wishlist. Every social media is different. It takes 3 - 4 hours to prepare the content. Everything starts up with an idea (record, edit). Manual data entry takes the most time. Simplifying the process to upload stuff would help immensely."
      />
      <Quote
        author="Ramos/Popa Cristian (marketing wizard)"
        link="https://store.steampowered.com/developer/16bitnights"
        image="/users/16bit.jpeg"
        from="16 BIT NIGHTS"
        text="Invest more in more in audience research in the beginning and focus on marketing. The guidelines and checklists to guide devs or agencies could be very useful. You must be constructive. For each week of the marketing campaign I make a folder. Inside, I put content and what to post for every day of the week. After, you Must engage with your community often!"
      />
      <Quote
        author="Adam (influencer, educator and dev)"
        link="https://www.youtube.com/c/AdamCYounis"
        from="AdamCYounis"
        image="/users/adamc.jpeg"
        text="It's important to not wait with the marketing to the very end and ignore it in your development cycle. Guiding dev is important, especially the first-time devs."
      />
      <Quote
        image="/users/shavorich.webp"
        author="Nik (co-founder StressOut)"
        link="https://stressout.net"
        from="VRmadillo Studios"
        text="It's vital for every game dev business with commercial goals to include marketing as a priority from day 1."
      />
    </QuoteListBox>
  );
}

function Quote({
  text,
  author,
  from,
  link,
  image,
}: {
  text: string;
  author: string;
  from: string;
  link: string;
  image?: string;
}) {
  return (
    <QuoteBox
      round="small"
      background="dark-2"
      pad="small"
      direction="row"
      gap="medium"
      margin={{ vertical: "xsmall" }}
      flex={{ shrink: 0 }}
      style={{ animation: `` }}
    >
      <Box style={{ minWidth: 48 }}>
        <ContactAvatar src={image} contactId={author} size="48px" />
      </Box>
      <Box gap="small">
        <Text size="small">{text}</Text>
        <Text size="xsmall" color="light-1">
          {author} from <Anchor href={link}>{from}</Anchor>
        </Text>
      </Box>
    </QuoteBox>
  );
}
