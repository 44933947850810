import Discord from "@core/components/atoms/icons/Discord";
import PageContent from "@core/components/atoms/PageContent";
import { Box, Paragraph, Text } from "grommet";
import { Twitter } from "grommet-icons";
import * as React from "react";
import NavBoxItems from "../../../../molecules/NavBoxItems";
import NavBoxesTemplate, {
  Groups,
} from "../../../../templates/NavBoxesTemplate";
import { useMutationRequest } from "@core/hooks/requestUtils";
import { SiteDiscordIntegrationPost } from "@core/types";
import { config } from "@core/config/config";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import toast from "react-hot-toast";
import PageVote from "@core/components/molecules/PageVote";
import { TUTORIAL } from "@core/common/constants";
import TutorialManager from "@core/common/TutorialManager";
import OwnNav from "@core/components/organisms/OwnNav";
import { WithGameSidebar } from "@core/components/molecules/WithGameSidebar";

export default function EditIntegrations() {
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [code, setCode] = React.useState("");

  React.useEffect(() => {
    setTimeout(() => {
      const seenWipTutorial = localStorage.getItem(
        TUTORIAL.HAS_SEEN_VOTE_TUTORIAL
      );
      const steps = [];
      if (!seenWipTutorial) {
        localStorage.setItem(
          TUTORIAL.HAS_SEEN_VOTE_TUTORIAL,
          JSON.stringify(true)
        );
        steps.push("vote");
      }
      TutorialManager.start(steps);
      // TutorialManager.start(['categories', 'queue'].concat(steps));
    }, 300);
  }, []);

  const mutation = useMutationRequest<SiteDiscordIntegrationPost>(
    {
      url: "sites/:siteId/integrations/discord",
      method: "POST",
      params: {
        siteId: "123",
      },
      body: {
        code,
      },
    },
    {
      onSuccess: () => {
        alert("Discord connected");
        setCode("");
      },
    }
  );

  React.useEffect(() => {
    if (code) {
      mutation.mutate();
    }
  }, [code]);

  const handleConnectDiscord = () => {
    // TODO: Pass state to window open
    const params = {
      response_type: "code",
      client_id: config.discord.clientId,
      redirect_uri: config.discord.redirectURL,
      scope: "bot",
      permissions: config.discord.botRequestedPermissions,
    };
    const queryString = new URLSearchParams(omitBy(params, isEmpty)).toString();

    // Create a BroadcastChannel for the popup window to return the auth code
    // see: https://mdn.io/BroadcastChannel
    const oauthLoginChannel = new BroadcastChannel("oauthLoginChannel");
    oauthLoginChannel.onmessage = (e) => {
      const message = e.data as
        | { type: "error" }
        | { type: "code"; code: string };

      if (message.type === "error") {
        // TODO: Handle error
        alert("Problem authorising discord. Ask for feedback?");
      } else {
        try {
          // Send this code to the AccountsJS-based server
          // await accountsClient.loginWithService('oauth', {
          //   provider: 'discord',
          //   code: message.code,
          // });
          // // the 'provider' is key you specify in AccountsOauth config
          // const user = await accountsClient.getUser();
          // console.log('User in LoginDialog success', user);
          toast.success(
            "Discord connected! Now configure an integration below."
          );
          setIsSuccess(true);
          setCode(message.code);
        } catch (e) {
          // TODO: Handle error
          console.error(
            "Failed to authenticate with received token",
            message,
            e
          );
        }
      }
    };

    // Open popup window with OAuth provider page
    const width = 600,
      height = 850;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    window.open(
      `${config.discord.OAuthUrl}?${queryString}`,
      "",
      `toolbar=no, location=no, directories=no, status=no, menubar=no,
    scrollbars=no, resizable=no, copyhistory=no, width=${width},
    height=${height}, top=${top}, left=${left}`
    );
  };
  const groups: Groups = {
    // Running: [],
    "Link Publeash with other platforms": [
      {
        title: "Discord Server Connected",
        icon: <Discord color="discord" />,
        onClick: handleConnectDiscord,
        disabled: !isSuccess,
        children: (
          <NavBoxItems
            items={[
              { completed: isSuccess, title: 'Server "Publeash" Authorised' },
            ]}
          />
        ),
      },
      {
        title: "Connect Twitter Now",
        icon: <Twitter color="twitter" />,
        disabled: true,
        children: (
          <NavBoxItems
            items={[
              {
                completed: false,
                title: "No Twitter account connected",
              },
            ]}
          />
        ),
      },
    ],
    "Create New Automation": [
      {
        title: "For Admins: Stay Up To Date",
        icon: <Discord color="discord" />,
        children: (
          <NavBoxItems
            items={[
              "Get useful & important notifications straight into your private Discord channel",
            ]}
          />
        ),
      },
      {
        title: "For Community: Engage Autopilot",
        icon: (
          <Box gap="small" direction="row" margin={{ right: "medium" }}>
            <Twitter color="twitter" />
            <Discord color="discord" />
          </Box>
        ),
        children: (
          <NavBoxItems
            items={[
              "Post roadmap updates, comments and more into public or private Discord channel",
            ]}
          />
        ),
      },
      {
        title: "For Community: Integrate Leaderboards",
        icon: (
          <Box gap="small" direction="row" margin={{ right: "medium" }}>
            <Twitter color="twitter" />
            <Discord color="discord" />
          </Box>
        ),
        children: (
          <NavBoxItems
            items={[
              "Post world records to Twitter or Discord",
              "Announce new leaderboards",
            ]}
          />
        ),
      },
    ],
  };
  return (
    <>
      <OwnNav />
      <WithGameSidebar>
        <PageContent style={{ pointerEvents: "none" }}>
          <NavBoxesTemplate
            title="Integrations"
            description={
              <Text margin={{ vertical: "small" }}>
                Connect few services then setup automation to rip the benefits!
              </Text>
            }
            groups={groups}
          />
        </PageContent>
        <PageVote
          votePostId="818efea7-5e21-4d49-b70c-8e4c38147b71"
          title="Core Idea"
          explanation={
            <>
              <Paragraph
                style={{ maxWidth: "100%" }}
                margin={{ vertical: "xsmall" }}
              >
                We want to build a system for seamless feedback gathering.
                Feedback can be received via Discord, website or straight from
                the game.
              </Paragraph>
              <Paragraph
                style={{ maxWidth: "100%" }}
                margin={{ vertical: "xsmall" }}
              >
                The same system can be used to build a roadmap where users can
                vote for features.
              </Paragraph>
            </>
          }
        />
      </WithGameSidebar>
    </>
  );
}
