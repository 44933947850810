import { SiteConfig } from '@core/redux/siteConfig';

export const defaultThankYouPageSettings = {
  ambassadorSettings: {
    title: 'Become an Ambassador',
    tips: [
      {
        title: 'Give valuable feedback',
        content:
          'We are organising playtests to be able to deliver the best experience at the end. In depth feedback can be of immense value.',
      },
      {
        title: 'Participate in Discord',
        content:
          'There is more than just plain feedback, be with us on our journey so we are fully motivated and get the best game possible in your hands.',
      },
    ],
  },
};

export const defaultTheme: SiteConfig['theme'] = {
  mode: 'light',
  primary: '#003F63',
  secondary: '#F2B138',
  darkBase: '#222222',
  lightBase: '#EEEEEE',
  buttonRadius: 2,
  headingFontWeight: 600,
  fontWeight: 400,
};

export const defaultSiteConfig: SiteConfig = {
  thankYouPage: {
    contactWorthPoints: 3,
    actionsText: `We are incredibly gratefuly for helping us ❤️`,
    actionsTitle: 'Get Your Key Sooner',
    successText: `### Thank you!

*We really appreciate having you on our journey. *

Let us know a bit more about you... How can you
help us? Got any special hardware we can test? Are you
streamer or a youtuber? Let us know.`,
    successTitle: 'Success',
    ambassadorTitle: 'What to do after?',
    ambassadorText: 'Become our MVP...',
    enableActions: true,
    enableAmbassador: true,
    enableAskForUserDetails: true,
    ambassadorBoxes: [
      {
        title: 'Give valuable feedback',
        description:
          'We are organising playtests to be able to deliver the best experience at the end. In depth feedback can be of immense value.',
      },
      {
        title: 'Participate in Discord',
        description:
          'There is more than just plain feedback, be with us on our journey so we are fully motivated and get the best game possible in your hands.',
      },
      {
        title: 'Help the community',
        description:
          'Either by helping fellow gamers in Discord or by creating video tutorials on YouTube.',
        link: 'https://twitter.com',
      },
    ],
  },
  general: {
    collectNewsletterEmailsText: 'Exclusive updates for email subscribers.',
    enableFeedback: false,
    enablePress: false,
    pressMarkdown: '',
    feedbackPageMenuName: 'Feedback',
    socialsThankYouPageMenuName: 'Thank you',
    siteMeta: {},
  },
  theme: {
    ...defaultTheme,
    primary: '#020F59',
    secondary: '#0477BF',
    fontFamilyName: 'Source Sans Pro',
    fontFamilyUrl:
      'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap',
    headingFontFamilyUrl:
      'https://fonts.googleapis.com/css2?family=Rationale&display=swap',
    headingFontFamilyName: 'Rationale',
  },
};

export const minimalSiteConfig: SiteConfig = {
  general: {
    gameName: 'Pampam',
    title: 'Rouglike Romance',
    subTitle: 'Coming in Q4 2021',
    enableFeedback: false,
    enablePress: false,
    pressMarkdown: '',
    feedbackPageMenuName: 'Feedback',
    socialsThankYouPageMenuName: 'Thank you',
    siteMeta: {},
  },
  theme: defaultTheme,
};

export const minimalDarkSiteConfig: SiteConfig = {
  general: {
    gameName: 'Dark Game',
    title: 'Rouglike Horror',
    subTitle: 'Early Access Available now',
    enableFeedback: false,
    enablePress: false,
    pressMarkdown: '',
    feedbackPageMenuName: 'Feedback',
    socialsThankYouPageMenuName: 'Thank you',
    siteMeta: {},
  },
  theme: {
    ...defaultTheme,
    mode: 'dark',
    primary: '#A6341B',
    secondary: '#F9C743',
  },
};

export const roninSiteConfig: SiteConfig = {
  general: {
    gameName: 'Ronin 2072',
    title: 'Become a wall-running Ronin in this new Cyberpunk Roguelite',
    subTitle: 'Coming Q4 2021',
    logoImage: 'https://www.criminalsinc.com/img/logo.png',
    youTubeTrailerURL: 'https://www.youtube.com/watch?v=HGy9zxTd-dc',
    steamAppId: '1163160',
    twitterURL: 'https://twitter.com/playronin2072',
    youtubeChannelURL:
      'https://www.youtube.com/channel/UCMzyXy2n1he_QuhLvjwbC_w',
    landingBackgroundImage: 'https://www.criminalsinc.com/img/bg-masthead2.jpg',
    enableFeedback: false,
    enablePress: false,
    pressMarkdown: '',
    feedbackPageMenuName: 'Feedback',
    socialsThankYouPageMenuName: 'Thank you',
    siteMeta: {},
  },
  theme: {
    ...defaultTheme,
    mode: 'dark',
    primary: '#e7354d',
    headingFontFamilyUrl:
      'https://fonts.googleapis.com/css2?family=Nunito&display=swap',
    headingFontFamilyName: 'Nunito',
    fontFamilyName: 'Nunito',
  },
};

export const site1Config: SiteConfig = {
  general: {
    gameName: 'Tomato Run',
    title: 'Co-op runner game with super powers',
    subTitle: 'Available this summer',
    steamAppId: '499460',
    youTubeTrailerURL: 'https://www.youtube.com/watch?v=B63Pd1P8z28',
    enableFeedback: false,
    enablePress: false,
    pressMarkdown: '',
    feedbackPageMenuName: 'Feedback',
    socialsThankYouPageMenuName: 'Thank you',
    siteMeta: {},
  },
  theme: {
    ...defaultTheme,
    mode: 'light',
    primary: '#FFC700',
    darkBase: '#212121',
    lightBase: '#d4d4d4',
    headingFontFamilyUrl:
      'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap',
    headingFontFamilyName: 'Bebas Neue',
    fontFamilyUrl: 'https://fonts.googleapis.com/css2?family=Cabin',
    fontFamilyName: 'Cabin',
  },
};

export const stressoutSiteConfig: SiteConfig = {
  general: {
    gameName: 'StressOut',
    title: 'Chaotic Destruction in VR',
    subTitle: 'Available now in Early Access',
    logoImage: 'https://stressout.net/static/logo.svg',
    landingBackgroundImage: 'https://stressout.net/static/piano203.png',
    youTubeTrailerURL: 'https://www.youtube.com/watch?v=0cDkmQ0F0Jw',
    twitterURL: '',
    steamAppId: '1143770',
    enableFeedback: false,
    enablePress: false,
    pressMarkdown: '',
    feedbackPageMenuName: 'Feedback',
    socialsThankYouPageMenuName: 'Thank you',
    siteMeta: {},
    collectNewsletterEmails: false,
  },
  features: [
    {
      title: 'Made For Daily StressOut',
      description: `Game optimised for fun. We want to bring you daily dose of relaxation that you can't have in real life.`,
      imageUrl:
        'https://stressout-assets.ams3.cdn.digitaloceanspaces.com/website-assets/Fun.jpg',
      webm: 'https://stressout-assets.ams3.cdn.digitaloceanspaces.com/website-assets/Fun.webm',
      mp4: 'https://stressout-assets.ams3.cdn.digitaloceanspaces.com/website-assets/Fun.mp4',
    },
    {
      title: 'Destruction Focused',
      description:
        'It starts small in a grocery store, then takes you through larger indoor spaces such as warehouse and finally it scales up to large scale building destruction.',
      webm: 'https://stressout-assets.ams3.cdn.digitaloceanspaces.com/website-assets/New_Destruction.mp4.webm',
      imageUrl:
        'https://stressout-assets.ams3.cdn.digitaloceanspaces.com/website-assets/New_Destruction.mp4.jpg',
      mp4: 'https://stressout-assets.ams3.cdn.digitaloceanspaces.com/website-assets/New_Destruction.mp4.jpg',
    },
  ],
  theme: {
    primary: '#65bfb5',
    secondary: 'rgb(255, 175, 64)',
    darkBase: 'rgb(15, 20, 20)', // rgb(15, 20, 20)
    lightBase: 'white',
    mode: 'dark',
    fontFamilyUrl:
      'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
    fontFamilyName: 'Open Sans',
    headingFontFamilyUrl:
      'https://fonts.googleapis.com/css2?family=Righteous&display=swap',
    headingFontFamilyName: 'Righteous',
    buttonRadius: 8,
    headingFontWeight: 600,
    fontWeight: 400,
  },
};
