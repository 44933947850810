export enum PostModerationSettings {
  /** All posts are visible immediatelly */
  AllVisible = 'AllVisible',
  /** Posts from verified users are visible, others go into moderation queue */
  VerifiedVisible = 'VerifiedVisible',
  /** All posts go into moderation queue */
  NoneVisible = 'NoneVisible',
  /** Only admins can post */
  Disabled = 'Disabled',
}

export type Roles =
  // Publeash Admin
  | 'admin'
  // Publeash User
  | 'site_owner'
  // Site owner contact
  | 'contact'
  // Everyone else
  | 'public';

const g = (
  resource: string,
  role: Roles,
  action: string,
  possession: string,
  attributes: string[]
) => ({
  resource,
  role,
  action,
  possession,
  attributes,
});

export const grants = [
  g('site', 'site_owner', 'read', 'own', ['*']),
  g('domain', 'site_owner', 'read', 'any', ['*']),
  // Admins & Site owners can read entire contact_list
  g('contact_list', 'admin', 'read', 'own', ['*']),
  g('contact_list', 'site_owner', 'read', 'own', ['*']),

  // Anyone can create any type of contact
  g('contact', 'admin', 'create', 'own', ['*']),
  g('contact', 'site_owner', 'create', 'own', ['*']),
  g('contact', 'contact', 'create', 'own', ['*']),

  // Updating about
  g('contact', 'contact', 'update', 'own', ['about', 'name']),
  g('contact', 'site_owner', 'update', 'any', ['publicTags', 'privateTags']),
  // Contact views
  g('contact', 'admin', 'read', 'any', ['*']),
  g('contact', 'site_owner', 'read', 'any', ['*']),
  g('contact', 'contact', 'read', 'any', ['*']),
  g('contact', 'public', 'read', 'any', ['id', 'name', 'publicTags']),
  // Viral actions for contacts
  g('contact_completed_viral_action', 'contact', 'create', 'own', [
    'actionType',
  ]),
  g('contact_exchange_token', 'contact', 'create', 'own', ['token']),

  // NOTE: Categories have no concept of "OWN" views just yet.
  g('voteCategory', 'site_owner', 'read', 'any', ['*']),

  g('voteCategory', 'site_owner', 'create', 'own', [
    'title',
    'template',
    'description',
    'color',
    'imageUrl',
    'postModerationSettings',
    'commentModerationSettings',
    'hidden',
    'private',
  ]),
  g('voteCategory', 'site_owner', 'update', 'own', [
    'title',
    'template',
    'description',
    'color',
    'imageUrl',
    'postModerationSettings',
    'commentModerationSettings',
    'hidden',
    'private',
  ]),

  g('voteCategory', 'admin', 'read', 'any', ['*']),
  g('voteCategory', 'contact', 'read', 'any', [
    'id',
    'title',
    'color',
    'template',
    'postModerationSettings',
    'commentModerationSettings',
    'imageUrl',
  ]),
  g('voteCategory', 'public', 'read', 'any', [
    'id',
    'title',
    'color',
    'template',
    'description',
    'postModerationSettings',
    'commentModerationSettings',
    'imageUrl',
  ]),
  // TODO: Creating posts
  g('voteCategory_votePost', 'contact', 'create', 'any', ['title', 'markdown']),
  g('voteCategory_votePost', 'site_owner', 'create', 'any', [
    'title',
    'markdown',
  ]),

  // Reading posts
  g('votePost', 'public', 'read', 'any', ['*']),
  g('votePost', 'contact', 'read', 'any', ['*']),
  g('votePost', 'site_owner', 'read', 'any', ['*']),
  // Comments
  g('votePostComment', 'site_owner', 'create', 'own', [
    'text',
    'contactId',
    'createdAt',
  ]),
  g('votePostComment', 'contact', 'create', 'own', [
    'text',
    'contactId',
    'createdAt',
  ]),
  g('votePostComment', 'public', 'create', 'own', [
    'text',
    'contactId',
    'createdAt',
  ]),
  g('votePostComment', 'public', 'read', 'any', [
    'text',
    'id',
    'createdAt',
    'contactId',
  ]),
  g('votePostComment', 'site_owner', 'read', 'any', [
    'text',
    'id',
    'createdAt',
    'contactId',
  ]),
  g('votePostComment', 'contact', 'read', 'any', [
    'text',
    'id',
    'createdAt',
    'contactId',
  ]),
];
