import { SiteView } from '@core/redux/sitesEditor';
import { Box, Heading, Sidebar } from 'grommet';
import * as React from 'react';
import PreviewComponent from '../blind/PreviewComponent';

interface Props {
  site: SiteView;
  title: string;
  children: React.ReactNode;
  preview?: React.ReactNode;
}

export default function EditSidebar({ site, title, preview, children }: Props) {
  return (
    <Box style={{ position: 'relative', minHeight: '100vh' }}>
      <PreviewComponent site={site}>{preview}</PreviewComponent>
      <Sidebar
        background="dark-1"
        border={{ side: 'right', size: '1px', color: 'dark-4' }}
        style={{ width: 400, overflowY: 'scroll', position: 'absolute', top: 0, bottom: 0, zIndex: 10 }}>
        <Heading level={3} textAlign="center" margin={{ bottom: '0' }}>
          {title}
        </Heading>
        {children}
        <Box flex="grow" height="xsmall" />
      </Sidebar>
    </Box>
  );
}
