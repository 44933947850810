import PageContent from '@core/components/atoms/PageContent';
import {
  Card,
  Text,
  CardBody,
  CardHeader,
  Grid,
  Heading,
  ResponsiveContext,
} from 'grommet';
import * as React from 'react';
import NavBox from '../molecules/NavBox';

export type Groups = Record<string, {
    title: string;
    children?: React.ReactChild;
    icon?: React.ReactNode;
    to?: string;
    wip?: boolean;
    disabled?: boolean;
    onClick?(): void;
  }[]>;

interface Props {
  title: string;
  groups: Groups;
  description?: string | React.ReactNode;
}

export default function NavBoxesTemplate({
  title,
  groups,
  description,
}: Props) {
  const size = React.useContext(ResponsiveContext);
  const hasDescription = !!description;
  return (
    <PageContent pad="medium">
      <Heading margin="none" level={2}>
        {title}
      </Heading>
      {hasDescription &&
        (typeof description === 'string' ? (
          <Text>{description}</Text>
        ) : (
          description
        ))}
      {Object.entries(groups).map(([name, groupOptions], gi) => (
        <Card key={name} margin={{ vertical: 'small' }}>
          <CardHeader
            pad={{ horizontal: 'medium' }}
            background="white"
            border={{ side: 'bottom', color: 'dark-1', size: '1px' }}
          >
            <Heading level={2} margin={{ vertical: 'small' }}>
              {name}
            </Heading>
          </CardHeader>
          <CardBody pad="small" background="white">
            <Grid
              gap="small"
              columns={size === 'small' ? ['flex'] : ['flex', 'flex']}
            >
              {groupOptions.map(
                (
                  {
                    title: boxTitle,
                    children,
                    icon,
                    to,
                    wip,
                    disabled,
                    onClick,
                  },
                  i
                ) => (
                  <NavBox
                    onClick={onClick}
                    id={`card-shortcut-${gi}-${i}`}
                    title={boxTitle}
                    key={boxTitle}
                    icon={icon}
                    to={to}
                    wip={wip}
                    disabled={disabled}
                  >
                    {children}
                  </NavBox>
                )
              )}
            </Grid>
          </CardBody>
        </Card>
      ))}
    </PageContent>
  );
}
