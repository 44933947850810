import { Text, Box, BoxExtendedProps } from "grommet";
import * as React from "react";

export default function Tag({
  children,
  color,
  size = "normal",
  ...rest
}: BoxExtendedProps & {
  children: React.ReactNode;
  color: string;
  size?: "small" | "normal";
}) {
  return (
    <Box
      flex={{ shrink: 0 }}
      margin={{ right: "xsmall", bottom: "xsmall" }}
      background={color}
      style={{ display: "inline-flex", whiteSpace: "nowrap" }}
      pad={{ horizontal: size === "small" ? "8px" : "12px" }}
      round="small"
      direction="row"
      justify="center"
      align="center"
      height={size === "small" ? "16px" : "24px"}
      {...rest}
    >
      {typeof children === "string" ? (
        <Text size={size === "small" ? "10px" : "xsmall"}>{children}</Text>
      ) : (
        children
      )}
    </Box>
  );
}
