import * as React from 'react';
import { SiteConfigContext } from '@core/components/blind/contexts/SiteConfigContext';
import { Text, Box, Button, Heading, Image, ResponsiveContext } from 'grommet';
import { FormDown, PlayFill } from 'grommet-icons';
import styled, { keyframes } from 'styled-components';
import YouTube from 'react-youtube';
import useColor from '@core/hooks/useColor';
import SocialLinksC from '@core/components/molecules/SocialLinks';
import PageContent from '@core/components/atoms/PageContent';
import TC from 'tinycolor2';
import toast from 'react-hot-toast';
import { defaultSiteConfig } from '@core/common/siteConfigs';
import EmailInputCollection from '../molecules/EmailInputCollection';

const Title = styled(Heading)`
  text-align: center;
  text-shadow: ${(props: { shadow: string }) => props.shadow} 0.3rem 0.3rem
      0.3rem,
    ${(props: { shadow: string }) => props.shadow} -0.3rem 0.3rem 0.3rem;
`;

const GameName = styled(Title)``;

const Subtitle = styled(Heading)`
  text-align: center;
  text-shadow: ${(props: { shadow: string }) => props.shadow} -1.5px 1.5px 0px;
`;

const PlayButton = styled(Button)`
  border-width: 0;
  background: linear-gradient(
    110deg,
    ${(props: { color: string; color1: string; color2: string }) =>
        props.color2}
      0%,
    ${(props: { color: string; color1: string; color2: string }) =>
        props.color1}
      100%
  );
  > div {
    color: ${(props: { color: string; color1: string; color2: string }) =>
      props.color};
  }
  padding: 12px 24px;
  &:hover {
    background: linear-gradient(
      110deg,
      ${(props: { color: string; color1: string; color2: string }) =>
          props.color2}
        0%,
      ${(props: { color: string; color1: string; color2: string }) =>
          props.color1}
        50%
    );
  }
`;

function blinkingEffect() {
  return keyframes`
  0% {
    opacity: 0.5;
  }
    50% {
      opacity: 0;
    }
  100% {
    opacity: 0.5;
  }
  `;
}

const VideoWrapper = styled(Box)`
  iframe,
  > div,
  .holder {
    width: 100%;
    height: 100%;
  }
`;

const AnimatedFormDown = styled(FormDown)`
  position: absolute;
  bottom: 0;
  animation: ${blinkingEffect} 2s ease-in-out infinite;
`;

const zIndexes = {
  videoMaskOverlay: 3,
  socialLinks: 2,
  content: 1,
  imageMask: 0,
};

const PLAY_TRANSITION_DURATION = 500;
const SHADOW_OPACITY = 0.3;

export default function ClientLandingSection() {
  const [isPlayingTrailer, setIsPlayingTrailer] = React.useState(false);
  const siteConfig = React.useContext(SiteConfigContext);
  // const theme = useTheme();
  const logo = siteConfig?.general.logoImage;
  const name = siteConfig?.general.gameName;
  const youtubeVideoIdMatches =
    siteConfig?.general.youTubeTrailerURL?.match(/v=([\w\d]*)/);
  const youtubeVideoId = youtubeVideoIdMatches && youtubeVideoIdMatches[1];
  const dark1 = useColor('dark-1');
  const dark1Transparent = new TC(dark1).setAlpha(SHADOW_OPACITY).toRgbString();
  const landingBg = siteConfig?.general.landingBackgroundImage;
  // const dark4 = useColor('dark-4');
  // const secondary = useColor('secondary');
  const light6 = useColor('light-1');
  const brand4 = useColor('brand-4');
  const brand = useColor('brand-1');
  const title = siteConfig?.general.title || '';
  const titleSize = title.length > 50 ? 'small' : 'large';
  const shadowColor = useColor('dark-1');
  const featuresCount = siteConfig?.features?.length || 0;
  const hasNewsletter = siteConfig?.general.collectNewsletterEmails;
  const isDark = siteConfig?.theme.mode === 'dark';

  const haveCta = !!youtubeVideoIdMatches || hasNewsletter;

  const ctaFontSize = '20px';
  const [videoMaskTranslateY, setVideoMaskTranslateY] = React.useState(1);

  const handleError = () => {
    toast.error('Problem playing a video... Try again later.');
    handleStop();
  };

  const handlePlay = () => {
    setVideoMaskTranslateY(0);
    setTimeout(() => {
      setIsPlayingTrailer(true);
    }, PLAY_TRANSITION_DURATION);
    // setTimeout(() => {
    //   setIsPlayingTrailer(false);
    //   setVideoMaskTranslateY(-1);
    // }, 5000);
  };

  const handleReady = () => {
    setTimeout(() => {
      setVideoMaskTranslateY(-1);
    }, 100);
  };

  const handleStop = () => {
    setVideoMaskTranslateY(0);
    setTimeout(() => {
      setIsPlayingTrailer(false);
      setVideoMaskTranslateY(1);
    }, PLAY_TRANSITION_DURATION);
  };

  const deviceSize = React.useContext(ResponsiveContext);

  const shadowTransparent = new TC(shadowColor)
    .setAlpha(SHADOW_OPACITY)
    .toRgbString();

  let maxLogoHeight = 600;

  if (hasNewsletter) {
    maxLogoHeight -= 64;
  }
  if (siteConfig?.general.title) {
    maxLogoHeight -= 48;
  }

  if (siteConfig?.general.subTitle) {
    maxLogoHeight -= 32;
  }

  if (youtubeVideoIdMatches) {
    maxLogoHeight -= 48;
  }

  if (siteConfig?.general.steamAppId) {
    maxLogoHeight -= 190;
  }

  return (
    <Box
      style={{
        height: '100vh',
        position: 'relative',
      }}
      background="dark-1"
      pad="0"
      align="center"
    >
      <Box
        overflow="hidden"
        align="center"
        justify="center"
        fill
        style={{
          position: 'relative',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: landingBg && `url(${landingBg})`,
          width: '100vw',
        }}
      >
        {youtubeVideoId && isPlayingTrailer ? (
          <VideoWrapper fill>
            <YouTube
              opts={{
                playerVars: {
                  autoplay: 1,
                  modestbranding: 1,
                  controls: 1,
                  playsinline: 0,
                  rel: 0,
                },
              }}
              onEnd={handleStop}
              onPause={handleStop}
              onReady={handleReady}
              onError={handleError}
              videoId={youtubeVideoId}
            />
          </VideoWrapper>
        ) : (
          <>
            <Image
              src="/webmask.png"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '100%',
                height: '60%',
                opacity: landingBg ? 0.4 : 0.2,
                transform: 'translateX(-50%) translateY(-50%)',
                zIndex: zIndexes.imageMask,
              }}
            />
            <Box
              justify="center"
              align="center"
              fill
              style={{ zIndex: zIndexes.content }}
            >
              {logo ? (
                <Image
                  style={{
                    filter: `drop-shadow(0px 6px 8px ${shadowTransparent})`,
                    maxWidth: deviceSize === 'small' ? '100%' : '800px',
                    maxHeight: maxLogoHeight,
                  }}
                  margin={{ bottom: 'small' }}
                  src={logo}
                  alt={name}
                />
              ) : (
                <GameName
                  size="xlarge"
                  color="brand-1"
                  shadow={brand4}
                  level={1}
                >
                  {siteConfig?.general.gameName}
                </GameName>
              )}
              {youtubeVideoId && (
                <PlayButton
                  color={light6}
                  color1={brand4}
                  color2={brand}
                  hoverIndicator={true}
                  style={{
                    fontWeight: 'bold',
                    textShadow: `0px 0px 2px ${dark1}`,
                    fontSize: ctaFontSize,
                    width: 'auto',
                    flexShrink: 1,
                    flexGrow: 0,
                  }}
                  icon={
                    <PlayFill
                      style={{ filter: `drop-shadow(0px 0.5px 1px ${dark1})` }}
                      size="medium"
                      color="light-1"
                    />
                  }
                  onClick={handlePlay}
                />
              )}
              {title && (
                <Title
                  size={titleSize}
                  color="light-1"
                  level={2}
                  shadow={dark1Transparent}
                  margin={{ top: 'medium', bottom: 'small' }}
                >
                  {title}
                </Title>
              )}
              {siteConfig?.general.subTitle && (
                <Subtitle
                  size="medium"
                  margin="xsmall"
                  color="brand-1"
                  level={3}
                  shadow={dark1Transparent}
                >
                  {siteConfig.general.subTitle}
                </Subtitle>
              )}
              {haveCta && (
                <Box
                  direction="column"
                  align="center"
                  margin="medium"
                  gap="medium"
                >
                  {hasNewsletter && (
                    <>
                      <Text
                        size="large"
                        margin={{ bottom: '4px' }}
                        style={{
                          textShadow: `0px 0px 2px ${dark1Transparent}`,
                        }}
                      >
                        {siteConfig.general.collectNewsletterEmailsText ||
                          defaultSiteConfig.general.collectNewsletterEmailsText}
                      </Text>
                      <EmailInputCollection />
                    </>
                  )}
                </Box>
              )}
              {siteConfig && !haveCta && (
                <Box margin="large">
                  <SocialLinksC size="medium" {...siteConfig.general} />
                </Box>
              )}
            </Box>
            {featuresCount > 0 && (
              <AnimatedFormDown color="light-1" size="large" />
            )}
          </>
        )}
        <Box
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            zIndex: zIndexes.videoMaskOverlay,
            opacity: 1 - Math.abs(videoMaskTranslateY),
            transform: `translateX(${videoMaskTranslateY * 100}%)`,
            transition: `transform  ${PLAY_TRANSITION_DURATION}ms ease-out, opacity  ${PLAY_TRANSITION_DURATION}ms linear`,
          }}
          fill
          background={isDark ? 'brand-4' : 'brand-1'}
        />
        <Box
          style={{
            position: 'absolute',
            zIndex: zIndexes.videoMaskOverlay + 1,
            opacity: 1 - Math.abs(videoMaskTranslateY),
            transform: `scale(${
              videoMaskTranslateY === -1 ? 2 : videoMaskTranslateY === 0 ? 1 : 0
            })`,
            transition: `transform  ${PLAY_TRANSITION_DURATION}ms ease-out, opacity  ${PLAY_TRANSITION_DURATION}ms ease-in`,
          }}
          fill
          align="center"
          justify="center"
        >
          <PlayFill
            onClick={handleStop}
            style={{
              filter: `drop-shadow(0px 0.5px 1px ${dark1Transparent})`,
              cursor: 'pointer',
            }}
            size="xlarge"
          />
          <br />
          Loading...
        </Box>
      </Box>
      {haveCta && siteConfig && (
        <Box
          style={{ position: 'absolute', top: 0, zIndex: zIndexes.socialLinks }}
        >
          <PageContent align="end">
            <SocialLinksC {...siteConfig.general} />
          </PageContent>
        </Box>
      )}
      {siteConfig?.general.steamAppId && (
        <Box
          width="100vw"
          pad="24px"
          style={{ flexShrink: 0 }}
          background={
            siteConfig.general.landingBackgroundImage ? 'dark-1' : 'dark-2'
          }
        >
          <Box style={{ maxWidth: '900px' }} fill alignSelf="center">
            <iframe
              style={{ filter: `drop-shadow(0px 6px 8px ${shadowColor})` }}
              src={`https://store.steampowered.com/widget/${siteConfig.general.steamAppId}/`}
              frameBorder="0"
              height="190px"
              width="100%"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
