import { Feature } from '@core/components/molecules/Feature';

export interface SocialLinks {
  // Links
  youTubeTrailerURL?: string;
  discordURL?: string;
  youtubeChannelURL?: string;
  twitterURL?: string;
  facebookURL?: string;
  patreonURL?: string;
  twitchURL?: string;
}

export interface ThankYouPageConfig {
  enableAmbassador: boolean;
  enableAskForUserDetails: boolean;
  contactWorthPoints: number;
  ambassadorTitle: string;
  ambassadorText: string;
  ambassadorBoxes: { title: string; description: string; link?: string }[];
  enableActions: boolean;
  actionsTitle: string;
  actionsText: string;
  successText: string;
  successTitle: string;
}

export interface SiteConfig {
  thankYouPage?: ThankYouPageConfig;
  // Landing page;
  general: SocialLinks & {
    // Texts
    gameName?: string;
    title?: string;
    subTitle?: string;
    studioName?: string;
    mainDomain?: string;

    // Images
    // favicon suffixes:
    //  // no prefix = .ico
    //  -32
    //  -32
    //  -48
    //  -167
    //  -180
    //  -192
    favicon?: string;
    logoImage?: string;
    landingBackgroundImage?: string;

    // Other settings
    steamAppId?: string;
    domain?: string;
    googleAnalyticsUTMTag?: string;
    siteMeta: {
      description?: string;
      imageUrl?: string;
    };
    contactEmail?: string;
    collectNewsletterEmails?: boolean;
    collectNewsletterEmailsText?: string;
    defaultVoteCategoryId?: string;
    // Show roadmap or not
    enableFeedback: boolean;
    enablePress: boolean;
    pressMarkdown: string;
    // Menu texts
    feedbackPageMenuName: string;
    socialsThankYouPageMenuName: string;
  };
  features?: Feature[];
  theme: {
    mode: 'dark' | 'light';
    primary: string;
    secondary: string;
    darkBase: string;
    lightBase: string;
    fontFamilyUrl?: string;
    fontFamilyName?: string;
    headingFontFamilyUrl?: string;
    headingFontFamilyName?: string;
    headingFontWeight: number;
    fontWeight: number;
    buttonRadius: number;
  };
}

export interface SiteConfigReducerType {
  config: SiteConfig;
  domain: string | null;
  siteId: string | null;
}

export enum ESiteConfigAction {
  GET_ALL = 'siteConfig_get_all',
  SET_SITE_DOMAIN = 'siteConfig_set_site_domain',
  UPDATE_THEME_VALUE = 'siteConfig_update_theme_value',
  UPDATE_GENERAL_VALUE = 'siteConfig_update_general_value',
}
