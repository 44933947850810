import * as React from "react";
import { Box } from "grommet";
import { IconProps } from "grommet-icons";

export default function DoubleIconHOC(
  Icon: React.ReactNode,
  ExtraIcon: React.ReactNode
) {
  return ({ ...props }: IconProps) => {
    return (
      <Box gap="xsmall" direction="row" {...props}>
        {Icon}
        {ExtraIcon}
      </Box>
    );
  };
}
