import * as React from "react";
import PageContent from "@core/components/atoms/PageContent";
import PageLayout from "../../organisms/PageLayout";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Heading,
  ResponsiveContext,
  Text,
  ThemeType,
  Tip,
} from "grommet";
import styled from "styled-components";
import * as Icons from "grommet-icons";
import Discord from "@core/components/atoms/icons/Discord";
import Footer from "@core/components/molecules/Footer";

const showcaseFeatures = [
  {
    title: "Viral Growth System",
    description:
      "Schedule play testing sessions with viral sharing systems built in.",
    icon: <Icons.LineChart />,
  },
  {
    title: "Website builder",
    description:
      "Includes HTTPs and custom domain support, feature voting, email collection and more.",
    icon: <Icons.Domain />,
  },
  {
    title: "Feedback board",
    description:
      "With discord integration, moderation queue and more... your own mini “reddit”.",
    icon: <Icons.Layer />,
  },
  {
    title: "Discord Integration",
    description: "Sync your Discord with your website and Twitter",
    icon: <Discord />,
  },
  {
    title: "Media Converter",
    description:
      "Video → Steam Post\nVideo → Discord\nVideo → Reddit\nVideo → Twitter",
    icon: <Icons.Video />,
  },
  {
    title: "Post Converter",
    description:
      "Write your post once and get export formats for Twitter, Steam, Discord and more...",
    icon: <Icons.Twitter />,
  },
  {
    title: "Checklists",
    description: "Never forget an important event in your release cycle. ",
    icon: <Icons.Checkmark />,
  },
  //  {
  //    title: 'Unlimited Tracked Users',
  //    description:
  //      'Free plan is limited to 500 tracked users.
  //      This includes discord users, website posters and emails collected.',
  //  },
  //  {
  //    title: 'Unlimited Asset Hosting',
  //    description: 'Free plan is limited to 1GB of space',
  //  },
  //  {
  //    title: 'Unlimited Games',
  //    description: 'Free and Pro plans are limited to 2 games.',
  //  },
];

interface FancyCardProps {
  startColor: string;
  endColor: string;
  theme: ThemeType;
}

function getColor(color: string) {
  return (props: FancyCardProps) => {
    const colors = props.theme.global?.colors || {};
    const colorName = props[color];
    if (typeof colorName !== "string") return "#000";
    return colors[colorName];
  };
}
export const FancyCard = styled(Card)<{ translate?: boolean } & FancyCardProps>`
  z-index: 1;
  ${(props) =>
    props.elevation ? "" : "box-shadow: 4px 4px 16px 8px rgba(0, 0, 0, 0.3);"}

  &:nth-of-type(3n-1),
  &:last-of-type {
    transform: ${(props) => (props.translate ? "translateY(-50px)" : "")};
      ease-out;
  }
  background: linear-gradient(
    160deg,
    ${getColor("startColor")} 0%,
    ${getColor("endColor")} 100%
  );
`;

export default function Pricing() {
  const isSmall = React.useContext(ResponsiveContext);
  return (
    <PageLayout background="brand-1">
      <PageContent align="center">
        <Heading textAlign="center">Usage Based Pricing</Heading>
        <Heading
          level={2}
          textAlign="center"
          margin={{ horizontal: "medium" }}
          style={{ maxWidth: "600px" }}
        >
          -50% for customers that sign up during our early period
        </Heading>
        <Box
          direction="row-responsive"
          margin="large"
          gap="small"
          style={{ position: "relative" }}
        >
          <Card
            style={{
              position: "absolute",
              right: "100%",
              display: isSmall ? "none" : "box",
            }}
            elevation="0"
            width="medium"
            pad="medium"
          >
            <CardHeader height="100px" />
            <PlanItem title="Bandwidth" />
            <PlanItem
              title="Tracked Users"
              info="Web emails collected + Feedback board users + Discord users"
            />
            <PlanItem
              title="Optimised Asset Storage"
              info="Assets for website and social media are heavily optimised, so you can squeeze a lot into a bit of space. 1GB upload may result in 30MB consumed space for example."
              subtitle=""
            />
            <PlanItem title="Games" />
            <PlanItem title="Support" />
          </Card>
          <Plan name="Free" price="$0 / month">
            <PlanItem title="Unlimited" />
            <PlanItem title="500 users" />
            <PlanItem title="1GB of space" />
            <PlanItem title="5 Games" />
            <PlanItem />
          </Plan>
          <Plan name="Pro" price="$59 / month" newPrice="$29 / month" highlight>
            <PlanItem title="Unlimited" />
            <PlanItem title="2,000 users" subtitle="$10 per 1000 extra" />
            <PlanItem title="100GB of space" subtitle="$10 for 10GB extra" />
            <PlanItem title="5 Games" subtitle="$50 for each extra game" />
            <PlanItem />
          </Plan>
          <Plan name="Business" price="$399 / month" newPrice="$199 / month">
            <PlanItem title="Unlimited" />
            <PlanItem title="10,000 users" subtitle="$5 per 1000 extra" />
            <PlanItem title="1TB" subtitle="$10 for 1TB extra" />
            <PlanItem title="Unlimited games" />
            <PlanItem title="Priority Support" />
          </Plan>
        </Box>
        <Heading textAlign="center" level={2} margin={{ horizontal: "small" }}>
          The Toolbox to have for Game Marketers
        </Heading>
        <Grid
          align={isSmall ? "stretch" : "center"}
          pad={isSmall ? "small" : "xlarge"}
          gap={{
            row: isSmall ? "small" : "large",
            column: isSmall ? "small" : "large",
          }}
          columns={{ count: isSmall ? 2 : 3, size: "auto" }}
        >
          {showcaseFeatures.map((feature, i) => (
            <FancyCard
              elevation="0"
              key={i}
              startColor={`brand-${(i % 3) + 1}`}
              endColor={`brand-${(i % 3) + 2}`}
              style={i === showcaseFeatures.length - 1 ? { gridColumn: 2 } : {}}
              // @ts-expect-error this is valid but typed wrong
              translate={!isSmall}
            >
              <CardHeader
                pad={{ horizontal: "medium", top: "small", bottom: "xsmall" }}
              >
                <Heading color="dark-5" margin="0" level={4}>
                  {feature.title}
                </Heading>
                {feature.icon}
              </CardHeader>
              <CardBody
                pad={{ horizontal: "medium", top: "xsmall", bottom: "medium" }}
              >
                <Text size="small" color="dark-3">
                  {feature.description.split("\n").map((row, index) => (
                    <React.Fragment key={index}>
                      {row}
                      <br />
                    </React.Fragment>
                  ))}
                </Text>
              </CardBody>
            </FancyCard>
          ))}
        </Grid>
        <Heading level={2}>Compare Value</Heading>
        <Heading level={3} textAlign="center">
          There is no service like Publeash
        </Heading>
        <Box
          direction="row"
          pad={{ vertical: "large" }}
          gap="small"
          style={{ position: "relative", overflowX: "scroll" }}
        >
          <Card
            elevation="0"
            width="small"
            pad="medium"
            align="end"
            style={{
              position: isSmall ? "static" : "absolute",
              minWidth: "128px",
              right: "100%",
            }}
          >
            <CardHeader height="100px" />
            <PlanItem title="Website Builder" />
            <PlanItem title="Virality systems" />
            <PlanItem title="Email collection" />
            <PlanItem title="Feedback board" />
            <PlanItem title="Gaming Integrations" />
          </Card>
          <Comparison
            name="Publeash"
            price=""
            highlight
            features={{
              websiteBuilder: true,
              virality: true,
              emailCollection: true,
              feedback: true,
              gaming: true,
            }}
          />
          <Comparison
            name="Wix"
            price=""
            features={{
              websiteBuilder: true,
              virality: false,
              emailCollection: false,
              feedback: false,
              gaming: false,
            }}
          />
          <Comparison
            name="Gleam"
            price=""
            features={{
              websiteBuilder: false,
              virality: true,
              emailCollection: true,
              feedback: false,
              gaming: false,
            }}
          />
          <Comparison
            name="Canny.io"
            price=""
            features={{
              websiteBuilder: false,
              virality: false,
              emailCollection: true,
              feedback: true,
              gaming: false,
            }}
          />
        </Box>
      </PageContent>
      <Footer />
    </PageLayout>
  );
}

function Comparison({
  highlight,
  features,
  price,
  name,
}: {
  highlight?: boolean;
  price: string;
  name: string;
  features: {
    websiteBuilder: boolean;
    virality: boolean;
    emailCollection: boolean;
    feedback: boolean;
    gaming: boolean;
  };
}) {
  return (
    <Plan
      showSignup={!!highlight}
      width="small"
      name={name}
      highlight={highlight}
      price={price}
    >
      <PlanItem title=" ">
        {features.websiteBuilder ? <Icons.Checkmark /> : <Icons.Close />}
      </PlanItem>
      <PlanItem>
        {features.virality ? <Icons.Checkmark /> : <Icons.Close />}
      </PlanItem>
      <PlanItem>
        {features.emailCollection ? <Icons.Checkmark /> : <Icons.Close />}
      </PlanItem>
      <PlanItem>
        {features.feedback ? <Icons.Checkmark /> : <Icons.Close />}
      </PlanItem>
      <PlanItem>
        {features.gaming ? <Icons.Checkmark /> : <Icons.Close />}
      </PlanItem>
    </Plan>
  );
}

function Plan({
  name,
  children,
  price,
  highlight,
  width = "244px",
  showSignup = true,
  newPrice,
}: {
  width?: string;
  name: string;
  children: React.ReactNode;
  price: string;
  showSignup?: boolean;
  highlight?: boolean;
  newPrice?: string;
}) {
  return (
    <Card
      width={width}
      elevation="0"
      pad="medium"
      background={highlight ? "brand-1" : "brand-2"}
      border={
        highlight
          ? { style: "dashed", color: "brand-3", size: "small" }
          : undefined
      }
      style={{ boxShadow: "inset 0 0 40px rgba(0,0,0,0.3)", minWidth: "128px" }}
    >
      <CardHeader pad="0" align="start" direction="column" height="100px">
        <Heading margin="0" level={3}>
          {name}
        </Heading>
        <Heading
          color="dark-3"
          margin={{ bottom: "medium", top: "0" }}
          level={4}
        >
          <Text
            color={newPrice ? "light-1" : "dark-4"}
            style={{ textDecoration: newPrice ? "line-through" : "none" }}
          >
            {price}
          </Text>
          <Text>&nbsp;{newPrice}</Text>
        </Heading>
      </CardHeader>
      <CardBody>{children}</CardBody>
      {showSignup && (
        <CardFooter>
          <Button size="small" label="Sign Up" />
        </CardFooter>
      )}
    </Card>
  );
}

function PlanItem({
  children,
  title,
  subtitle,
  info,
}: {
  children?: React.ReactNode;
  title?: string;
  info?: string;
  subtitle?: string;
}) {
  return (
    <Box style={{ maxHeight: 56, height: 56 }}>
      {children}
      {title && (
        <Box align="center" direction="row" gap="xsmall">
          <Text size="small" weight="bold">
            {title}
          </Text>
          {info && (
            <Tip
              content={
                <Box
                  pad="xsmall"
                  background="light-1"
                  width="small"
                  round="xsmall"
                >
                  <Text size="small" color="dark-4">
                    {info}
                  </Text>
                </Box>
              }
              plain
            >
              <Icons.CircleInformation size="small" color="dark-5" />
            </Tip>
          )}
        </Box>
      )}
      {subtitle && <Text size="small">{subtitle}</Text>}
    </Box>
  );
}
