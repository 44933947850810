import { SiteConfig } from '@core/redux/siteConfig';
import TC from 'tinycolor2';

export type ThemeColors = ReturnType<typeof getSiteTheme>;

export function isDark(color: string) {
  const lightness = TC(color).toHsl().l;
  return lightness > 0.7;
}

/**
 * Based on the brand color it will return the light or dark one, which one contrasts better
 */
export function getDynamicBrandColor(theme: SiteConfig['theme']) {
  const darkBase = theme.mode === 'dark' ? 'dark-4' : 'light-1';
  const lightBase = theme.mode === 'dark' ? 'light-4' : 'dark-1';

  return isDark(theme.primary) ? darkBase : lightBase;
}

export default function getSiteTheme(theme: SiteConfig['theme']) {
  const toBrandVriance = (color: string) => {
    const { v } = TC(lightBase).toHsv();
    const { h, s, a } = TC(color).toHsv();
    return TC({ h, s, a, v }).darken(3).toHexString();
  };

  const lightBase = TC(theme.lightBase);
  const darkBase = TC(theme.darkBase);
  const primaryBase = TC(theme.primary);
  const grays =
    theme.mode === 'dark'
      ? {
          'dark-1': darkBase.toHexString(),
          'dark-2': darkBase.lighten(5).toHexString(),
          'dark-3': darkBase.lighten(5).toHexString(),
          'dark-4': darkBase.lighten(5).toHexString(),
          'dark-5': darkBase.lighten(5).toHexString(),
          'dark-6': darkBase.lighten(5).toHexString(),
          'light-1': lightBase.toHexString(),
          'light-2': lightBase.darken(5).toHexString(),
          'light-3': lightBase.darken(5).toHexString(),
          'light-4': lightBase.darken(5).toHexString(),
          'light-5': lightBase.darken(5).toHexString(),
          'light-6': lightBase.darken(5).toHexString(),
        }
      : {
          'light-6': darkBase.toHexString(),
          'light-5': darkBase.lighten(5).toHexString(),
          'light-4': darkBase.lighten(5).toHexString(),
          'light-3': darkBase.lighten(5).toHexString(),
          'light-2': darkBase.lighten(5).toHexString(),
          'light-1': darkBase.lighten(5).toHexString(),
          'dark-1': lightBase.toHexString(),
          'dark-2': lightBase.darken(5).toHexString(),
          'dark-3': lightBase.darken(5).toHexString(),
          'dark-4': lightBase.darken(5).toHexString(),
          'dark-5': lightBase.darken(5).toHexString(),
          'dark-6': lightBase.darken(5).toHexString(),
        };

  const dynamicBrandColor = grays[getDynamicBrandColor(theme)];

  const colors = {
    brand: theme.primary,
    primary: theme.primary,
    selected: theme.primary,
    focus: theme.primary,
    secondary: theme.secondary,
    'accent-1': new TC(theme.secondary).darken(15).toHexString(),
    'accent-2': new TC(theme.secondary).darken(5).toHexString(),
    'accent-3': theme.secondary,
    'accent-4': new TC(theme.secondary).lighten(5).toHexString(),
    'accent-5': new TC(theme.secondary).lighten(15).toHexString(),
    'brand-1': primaryBase.clone().lighten(10).toHexString(),
    'brand-2': primaryBase.clone().lighten(5).toHexString(),
    'brand-3': primaryBase.clone().darken(5).toHexString(),
    'brand-4': primaryBase.clone().darken(5).toHexString(),
    'brand-0': primaryBase.clone().darken(15).toHexString(),
    'brand--0': primaryBase.clone().desaturate(0).toHexString(),
    'brand--1': primaryBase.clone().darken(40).toHexString(),
    'brand-dynamic': dynamicBrandColor,
    'status-warning': toBrandVriance('#faad14'),
    'status-info': toBrandVriance('#1890ff'),
    'status-critical': toBrandVriance('#f5222d'),
    'status-error': toBrandVriance('#ff4d4f'),
    'status-ok': toBrandVriance('#00c781'),
    // Darkest
    ...grays,
  };
  return colors;
}
