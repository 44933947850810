import * as React from "react";
import { Router } from "react-router-dom";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";

import { App } from "@core/app";
import { config } from "@core/config/config";
import { configureStore } from "@core/redux/store";

import { registerServiceWorker } from "./serviceWorker";
import history from "@client/history";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { FeatureFlagProvider } from "@core/components/blind/contexts/FeatureFlagProvider";

if (config.sentryEnabled) {
  Sentry.init({
    dsn: config.sentryDSN,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: config.isDev ? 1.0 : 0.2,
  });
}

if (config.posthogEnabled) {
  if (config.isDev) {
    // console.log('Posthog enabled.');
  }
  posthog.init(config.posthogKey, {
    api_host: config.posthogHost,
  });
}

const initialState = window.__INITIAL_STATE__;
console.log(initialState);
const renderMethod = config.isDev && config.useRender ? render : hydrate;

const store = configureStore(initialState);

registerServiceWorker();

history.listen((arg) => {
  if (config.posthogEnabled) {
    posthog.capture("$pageview");
  }
  if (!arg.pathname.match(/online-presence|tools-preview/)) {
    window.scrollTo({
      behavior: "auto",
      top: 0,
    });
  }
});

/**
 * To be able to reload the page
 * and see the latest code changes,
 * you must set the "localStorage.useRender" value in development mode
 */
renderMethod(
  <>
    <FeatureFlagProvider>
      <Provider store={store}>
        <Router history={history}>
          <App isOwn={window.location.hostname === config.adminDomainHost} />
        </Router>
      </Provider>
    </FeatureFlagProvider>
  </>,
  document.getElementById("root"),
  () => delete window.__INITIAL_STATE__
);
