import Features from "@core/components/organisms/Features";
import ClientFooter from "@core/components/organisms/ClientFooter";
import ClientLandingSection from "@core/components/organisms/ClientLandingSection";
import { SiteConfigContext } from "@core/components/blind/contexts/SiteConfigContext";
import * as React from "react";
import LandingVoting from "@core/components/organisms/LandingVoting";

export default function ClientLandingPage() {
  const siteConfig = React.useContext(SiteConfigContext);
  const featuresCount = siteConfig?.features?.length || 0;

  return (
    <>
      <ClientLandingSection />
      {siteConfig?.features && <Features features={siteConfig.features} />}
      <LandingVoting />
      {featuresCount > 0 && <ClientFooter />}
    </>
  );
}
