import { ESteamImporterAction, PartialThemes } from './types';
import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import authorisedFetch from '@core/common/authorisedFetch';
import { ESitesEditorAction, SiteView } from '../sitesEditor';
import SitesEditorAction from '../sitesEditor/actions';

export default class SteamImporterAction {
  static setRetreivedData(
    screenShots: string[],
    themes: PartialThemes,
    siteId: number | null
  ) {
    return {
      type: ESteamImporterAction.SET_RETREIVED_DATA,
      payload: { screenShots, themes, siteId },
    };
  }

  static importFromSteamUrl(steamUrl: string) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const { screenShots, siteConfigEntity, themes } = await authorisedFetch<{
        siteConfigEntity: SiteView;
        themes: PartialThemes;
        screenShots: string[];
      }>('sites/from-steam', {
        method: 'POST',
        body: JSON.stringify({ steamUrl }),
      });
      const newSites = [...getState().sitesEditor.sites, siteConfigEntity];

      dispatch({
        type: ESitesEditorAction.GET_ALL,
        payload: { sites: newSites },
      });
      dispatch(
        SteamImporterAction.setRetreivedData(
          screenShots,
          themes,
          Number(siteConfigEntity.id)
        )
      );
    };
  }

  static confirmSteamImport(siteView: SiteView) {
    return (dispatch: Dispatch) => {
      // @ts-expect-error dispatch doesn't match thunk actions for some reason
      dispatch(SitesEditorAction.updateConfig(siteView));
      dispatch(SteamImporterAction.setRetreivedData([], [], null));
    };
  }
}
