import Features from "@core/components/organisms/Features";
import { Feature } from "@core/components/molecules/Feature";
import { SiteView } from "@core/redux/sitesEditor";
import {
  Box,
  Button,
  CheckBox,
  FormField,
  Text,
  TextArea,
  TextInput,
} from "grommet";
import * as React from "react";
import EditSidebar from "../../molecules/EditSidebar";
import EditSidebarSection from "../../molecules/EditSidebarSection";
import * as Icons from "grommet-icons";
import AssetUpload, {
  ImageResponse,
  VideoResponse,
} from "@core/components/molecules/AssetUpload";

interface Props {
  site: SiteView;
  onUpdateSite(site: SiteView): void;
}

export default function FeaturesTab({ site: sitex, onUpdateSite }: Props) {
  const [site, setSite] = React.useState(sitex);
  const features = site.config.features || [];
  const handleAddNew = () => {
    const updatedSite = {
      ...site,
      config: {
        ...site.config,
        features: (site.config.features || []).concat({
          title: "Immersive World",
          description: `Immerse youself in a world of ${site.config.general.gameName}. The world like no other. Split between 42 factions fighting for survival.\n\n Will you be the one to get out?`,
          // imageUrl: 'http://placehold.it/500x300',
        }),
      },
    };
    setSite(updatedSite);
    onUpdateSite(updatedSite);
  };
  const handleUpdateFeature = (updatedFeature: Feature, index: number) => {
    const updatedFeatures = [...(site.config.features || [])];
    updatedFeatures[index] = updatedFeature;
    const updatedSite = {
      ...site,
      config: {
        ...site.config,
        features: updatedFeatures,
      },
    };
    setSite(updatedSite);
    onUpdateSite(updatedSite);
  };

  return (
    <EditSidebar
      preview={<Features features={site.config.features || []} />}
      site={site}
      title="Features Section"
    >
      {features.map((feature, i) => (
        <FeatureEditorCard
          onUpdateFeature={(updatedFeature) =>
            handleUpdateFeature(updatedFeature, i)
          }
          key={i}
          feature={feature}
        />
      ))}
      <EditSidebarSection
        title="Add New"
        flex="shrink"
        style={{ minHeight: "120px" }}
      >
        <Button
          id="editor-feature-add-button"
          onClick={handleAddNew}
          icon={<Icons.Add />}
          label="Create"
          secondary
        />
      </EditSidebarSection>
    </EditSidebar>
  );
}

type MixResponse = {
  url?: string;
  webm?: string;
  mp4?: string;
};

function isImageResponse(response: MixResponse): response is ImageResponse {
  return !!response && !!response.url && !response.mp4 && !response.webm;
}

function isVideoResponse(response: MixResponse): response is VideoResponse {
  return !!(response.mp4 || response.webm);
}

function FeatureEditorCard({
  feature,
  onUpdateFeature,
}: {
  feature: Feature;
  onUpdateFeature(updatedFeature: Feature): void;
}) {
  const [buttonLinkEnabled, setButtonLinkEnabled] = React.useState(
    !!feature.buttonText || !!feature.buttonUrl
  );
  const handleButtonLinkEnabled = (enabled: boolean) => {
    if (!enabled) {
      onUpdateFeature({
        ...feature,
        buttonUrl: undefined,
        buttonText: undefined,
      });
    }
    setButtonLinkEnabled(enabled);
  };

  const handleFileInputChange = (response: VideoResponse | ImageResponse) => {
    if (isVideoResponse(response)) {
      onUpdateFeature({
        ...feature,
        mp4: response.mp4,
        webm: response.webm,
        imageUrl: response.url,
      });
      return;
    }
    if (isImageResponse(response)) {
      onUpdateFeature({
        ...feature,
        mp4: "",
        webm: "",
        imageUrl: response.url,
      });
      return;
    }
  };

  return (
    <EditSidebarSection>
      <FormField label="Title" info="**to highlight a word**">
        <TextInput
          value={feature.title || ""}
          onChange={(e) =>
            onUpdateFeature({ ...feature, title: e.target.value })
          }
        />
      </FormField>
      <FormField
        label="Text"
        info="(markdown supported)"
        help="Try to keep it short and sweet"
      >
        <TextArea
          size="small"
          style={{ minHeight: "132px" }}
          value={feature.description}
          onChange={(e) =>
            onUpdateFeature({ ...feature, description: e.target.value })
          }
        />
      </FormField>
      <FormField>
        <CheckBox
          onChange={(e) => handleButtonLinkEnabled(e.currentTarget.checked)}
          checked={buttonLinkEnabled}
          label="Add Button Link"
        />
      </FormField>

      {buttonLinkEnabled && (
        <>
          <FormField>
            <TextInput
              onChange={(e) =>
                onUpdateFeature({ ...feature, buttonText: e.target.value })
              }
              value={feature.buttonText}
              placeholder="Button Text"
            />
          </FormField>
          <FormField>
            <TextInput
              onChange={(e) =>
                onUpdateFeature({ ...feature, buttonUrl: e.target.value })
              }
              value={feature.buttonUrl}
              placeholder="Button Link"
            />
          </FormField>
        </>
      )}
      <FormField
        info={
          <Box align="center" direction="row" justify="between">
            <Box direction="row" align="center" gap="small">
              <Icons.Image size="xsmall" />
              or <Icons.Video size="xsmall" />
            </Box>
            <Text size="small">300x300 or bigger</Text>
          </Box>
        }
        label="We will convert the video into suitable web formats and resize to correct size."
      >
        <AssetUpload type="feature" onValueChange={handleFileInputChange} />
      </FormField>
    </EditSidebarSection>
  );
}
