import { SiteConfig } from '../siteConfig';

export interface DomainView {
  id: number;
  hostname: string;
}

export interface SiteView {
  id: number;
  config: SiteConfig;
  createdAt: string;
  updatedAt: string;
  domains: DomainView[];
}

export interface SitesEditorReducerType {
  sites: SiteView[];
}

export enum ESitesEditorAction {
  GET_ALL = 'sitesEditor_get_all',
  UPDATE_SITE_DATA = 'siteConfig_update_site_data',
  DELETE_SITE = 'siteConfig_delete_site',
  ADD_DOMAIN = 'sitesEditor_add_domain',
}
