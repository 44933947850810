import * as React from "react";
import { SiteConfigContext } from "@core/components/blind/contexts/SiteConfigContext";
import { Text, Box, TextInput, Button } from "grommet";
import useColor from "@core/hooks/useColor";
import toast from "react-hot-toast";
import { useApiCreateContact } from "@core/hooks/requests";
import { selectCurrentSiteId } from "@core/redux/siteConfig";
import { useAppSelector } from "@core/hooks/requestUtils";
import { useHistory } from "react-router";
import useSessionContact from "@core/hooks/useSessionContact";
import styled from "styled-components";

const SignupButton = styled(Button)`
  border: 1px solid transparent;
  &:focus {
    box-shadow: none;
    border: 1px solid gray;
  }
`;

const EmailInput = styled(TextInput)`
  text-shadow: 0px 0px 2px black;
  color: white;
  height: 48px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  &:focus {
    box-shadow: none;
  }
  background: linear-gradient(
    110deg,
    ${(props: { color: string; color1: string; color2: string }) =>
        props.color2}
      0%,
    ${(props: { color: string; color1: string; color2: string }) =>
        props.color1}
      100%
  );
  ::placeholder {
    color: ${(props) => props.color};
  }
  &:hover {
    background: linear-gradient(
      110deg,
      ${(props: { color: string; color1: string; color2: string }) =>
          props.color2}
        0%,
      ${(props: { color: string; color1: string; color2: string }) =>
          props.color1}
        50%
    );
  }
`;

export default function EmailInputCollection({
  onSuccess,
  hideDetailsButton,
}: {
  onSuccess?(): void;
  hideDetailsButton?: true;
}) {
  const brand4 = useColor("brand-4");
  const brand = useColor("brand-1");
  const siteConfig = React.useContext(SiteConfigContext);
  const emailRadius = (siteConfig?.theme.buttonRadius || 0) * 6;
  const ctaFontSize = "20px";
  const [email, setEmail] = React.useState("");
  const siteId = useAppSelector(selectCurrentSiteId);
  const history = useHistory();
  const [userContact, setUserContact] = useSessionContact();

  const createContact = useApiCreateContact({
    siteId,
    email,
    onSuccess: (data) => {
      if (data.status === "success") {
        const contactId = data.result.contact?.[0] || -1;
        const contact = data.entities.contact?.[contactId];
        setUserContact(contact || null, data.token);
        if (!onSuccess) {
          history.push("/socials");
        } else {
          onSuccess();
        }
      } else if (data.status === "pending_verification") {
        toast.loading("Check your email for login link!", {
          duration: 10000,
        });
      }
    },
  });

  const handleFormSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // Redirect to socials / beta
    if (userContact) {
      if (!onSuccess) {
        history.push("/socials");
      } else {
        onSuccess();
      }
    } else {
      // Create user
      createContact.mutate();
    }
  };

  const showButton = !userContact || !hideDetailsButton;

  return (
    <form
      onSubmit={handleFormSubmit}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        width="medium"
        direction="row"
        style={{ borderRadius: emailRadius, maxWidth: "90%" }}
      >
        <EmailInput
          color="white"
          color1={brand4}
          color2={brand}
          plain
          value={email}
          disabled={!!userContact}
          onChange={(e) => setEmail(e.currentTarget.value)}
          style={{
            opacity: userContact ? 0.6 : 1,
            fontSize: ctaFontSize,
            paddingLeft: 8 + emailRadius / 4,
            borderTopLeftRadius: emailRadius,
            borderBottomLeftRadius: emailRadius,
            ...(showButton
              ? {}
              : {
                  borderTopRightRadius: emailRadius,
                  borderBottomRightRadius: emailRadius,
                }),
          }}
          placeholder={userContact ? userContact.email : "E-mail"}
        />
        {showButton && (
          <SignupButton
            disabled={createContact.isLoading}
            type="button"
            onClick={handleFormSubmit}
            style={{
              outline: "none",
              height: 48,
              fontSize: ctaFontSize,
              whiteSpace: "nowrap",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: emailRadius,
              borderBottomRightRadius: emailRadius,
            }}
            label={userContact ? "View Details" : "Sign Up"}
          />
        )}
      </Box>
      <Text color="secondary" size="small" weight="bold">
        {createContact.isError ? (
          "Trouble signing you up. Did you enter valid email?"
        ) : (
          <>&nbsp;</>
        )}
      </Text>
    </form>
  );
}
