import {
  ContactView,
  VoteCategoryView,
  VotePostCommentView,
  VotePostView,
} from '@core/types/views';
import { combineReducers } from 'redux';
import { DomainView, SiteView } from '../sitesEditor';
import {
  AddIdToIndex,
  EntitiesActionTypes,
  UpdateIndexAction,
} from './actions';

export type EntityType =
  | 'voteCategory'
  | 'votePost'
  | 'contact'
  | 'domain'
  | 'site'
  | 'votePostComment';

type EntityState<T> = Record<string, T>;

type Indexes = Record<string, Record<string, string[]>>;

export interface EntitiesState {
  voteCategory: EntityState<VoteCategoryView>;
  votePost: EntityState<VotePostView>;
  votePostComment: EntityState<VotePostCommentView>;
  contact: EntityState<ContactView>;
  indexes: Indexes;
}

type Actions = UpdateIndexAction | AddIdToIndex;

function createEntityReducer<T extends object>(name: string) {
  return (state = {}, action: Actions): EntityState<T> => {
    switch (action.type) {
      case EntitiesActionTypes.UPDATE:
        if (!action.payload.entities.entities[name]) {
          return state;
        }
        return {
          ...state,
          ...action.payload.entities.entities[name],
        };
      default:
        return state;
    }
  };
}

function indexesReducer(state: Indexes = {}, action: Actions): Indexes {
  switch (action.type) {
    case EntitiesActionTypes.UPDATE:
      if (!action.payload.entities.result || !action.payload.indexName) {
        return state;
      }
      return {
        ...state,
        [action.payload.indexName]: action.payload.entities.result,
      };
    case EntitiesActionTypes.ADD_ID:
      // eslint-disable-next-line no-case-declarations
      const existingIds =
        state[action.payload.indexName][action.payload.entityName] || [];

      // eslint-disable-next-line no-case-declarations
      const newIds =
        action.payload.type === 'append'
          ? [...existingIds, action.payload.id]
          : [action.payload.id, ...existingIds];

      return {
        ...state,
        [action.payload.indexName]: {
          ...state[action.payload.indexName],
          [action.payload.entityName]: newIds,
        },
      };
    default:
      return state;
  }
}

export const reducer = combineReducers({
  voteCategory: createEntityReducer<VoteCategoryView>('voteCategory'),
  votePost: createEntityReducer<VotePostView>('votePost'),
  votePostComment: createEntityReducer<VotePostCommentView>('votePostComment'),
  contact: createEntityReducer<ContactView>('contact'),
  site: createEntityReducer<SiteView>('site'),
  domain: createEntityReducer<DomainView>('domain'),
  indexes: indexesReducer,
});
