import { Box, Button } from 'grommet';
import React from 'react';
import * as Icons from 'grommet-icons';

interface Props {
  hasChanged: boolean;
  onSavePress(): void;
}

export default function FloatingSaveButton({ hasChanged, onSavePress }: Props) {
  return (
    <Box
      pad={hasChanged ? 'medium' : 'xsmall'}
      background="dark-1"
      elevation="large"
      align="center"
      width={hasChanged ? 'medium' : 'small'}
      round={{
        corner: 'top',
        size: 'small',
      }}
      style={{
        transform: 'translateX(-560px)',
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transition: 'all 300ms ease-in-out',
      }}
    >
      <Button
        disabled={!hasChanged}
        size={hasChanged ? 'medium' : 'small'}
        style={{
          transition: 'all 300ms ease-in-out',
          whiteSpace: 'nowrap',
        }}
        onClick={onSavePress}
        primary
        label="Save Changes"
        icon={<Icons.FolderOpen />}
      />
    </Box>
  );
}
