import useVisibility from '@core/hooks/useVisibility';
import { Box, Image as GrommetImage } from 'grommet';
import * as React from 'react';
import styled from 'styled-components';
import { Feature } from '../molecules/Feature';

const Video = styled.video``;

export function ImageSection({ feature }: { feature: Feature }) {
  const componentRef = React.useRef(null);
  const isVisible =
    useVisibility && useVisibility(componentRef, { partial: true });

  const [hasEverBeenVisible, setHasEverBeenVisible] = React.useState(false);

  React.useEffect(() => {
    // Load image so we get the dimensions
    if (feature.imageUrl) {
      const img = new Image();
      img.addEventListener('load', () => {
        const { width, height } = img;
        setVideoDimensions({ height, width });
      });
      img.src = feature.imageUrl;
    }
  }, [feature.imageUrl]);

  const [videoDimensions, setVideoDimensions] = React.useState({
    width: 300,
    height: 150,
  });

  React.useEffect(
    () => setHasEverBeenVisible(isVisible || hasEverBeenVisible),
    [isVisible]
  );

  const handleLoadedMetadata = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    // const { videoWidth, videoHeight } = e.currentTarget;
    // setVideoDimensions({ height: videoHeight, width: videoWidth });
  };

  const didLoadImage =
    videoDimensions.width !== 300 && videoDimensions.height !== 150;

  const aspectRatio = String(videoDimensions.width / videoDimensions.height);

  const showVideo = (feature.mp4 || feature.webm) && hasEverBeenVisible;
  if (feature.imageElement) {
    return <>{feature.imageElement}</>;
  }
  return (
    <Box
      fill
      style={{
        overflow: 'hidden',
        transition: 'all 300ms ease-out',
        opacity: didLoadImage ? 1 : 0,
        justifyContent: 'center',
        transform: didLoadImage ? 'translateY(0)' : 'translateY(10px)',
      }}
      pad="small"
      ref={componentRef}
    >
      {!showVideo && feature.imageUrl && (
        <GrommetImage
          alignSelf="center"
          style={{
            aspectRatio,
            maxWidth: '100%',
          }}
          src={feature.imageUrl}
        />
      )}
      {showVideo && (
        <Video
          style={{
            maxWidth: '100%',
            aspectRatio,
          }}
          autoPlay
          loop
          muted
          playsInline
          poster={feature.imageUrl}
          onLoadedMetadata={handleLoadedMetadata}
        >
          {feature.webm && <source src={feature.webm} type="video/webm" />}
          {feature.mp4 && <source src={feature.mp4} type="video/mp4" />}
        </Video>
      )}
    </Box>
  );
}
