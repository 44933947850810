import React, { useContext, useState } from "react";
import { AppState } from "@core/redux/reducers";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Heading,
  Image,
  ResponsiveContext,
  Text,
} from "grommet";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  SitesEditorReducerType,
  SiteView,
} from "../../redux/sitesEditor/types";
import * as Icons from "grommet-icons";
import PageContent from "@core/components/atoms/PageContent";
import SitesEditorAction from "../../redux/sitesEditor/actions";
import { useHistory } from "react-router";
import Steam from "@core/components/atoms/icons/Steam";
import SteamImporterModal from "@core/components/organisms/SteamImporterModal";
import { selectIsLoggedIn } from "@core/redux/user/selectors";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import LoadingIndicator from "@core/components/atoms/LoadingIndicator";
import { config } from "@core/config/config";

interface LocalProps {
  sites?: SitesEditorReducerType["sites"];
}

export type AppDispatch = ThunkDispatch<AppState, object, AnyAction>;

function GamesListPage(props: LocalProps) {
  const { sites } = props;
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [isLoading, setIsLoading] = useState(isLoggedIn);
  const handleNewSite = () => dispatch(SitesEditorAction.newSite());
  const [isImportingFromSteam, setIsImportingFromSteam] = React.useState(false);

  const handleEdit = (site: SiteView) => {
    return () => {
      history.push(`/edit/${site.id}`);
    };
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      dispatch(SitesEditorAction.loadAll())
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }, [isLoggedIn]);

  const size = useContext(ResponsiveContext);
  const cdnEndpoint = config.cdnHost;
  const cdnImage = (image: string, ext: string) =>
    `${cdnEndpoint}${image}${ext}`;

  return (
    <PageContent pad="medium">
      <Card
        round="medium"
        pad="medium"
        margin="medium"
        background="white"
        direction={size === "small" ? "column" : "row"}
        gap="medium"
      >
        <Grid columns={["flex", "flex"]} gap="medium">
          <Card background="dark-2">
            <CardHeader pad="small" background="dark-3">
              <Heading margin="0" level={3}>
                Create New Game
              </Heading>
              <Icons.Add />
            </CardHeader>
            <CardBody pad="small" background="dark-2" />
            <CardFooter pad="small" background="dark-3">
              <Button
                onClick={handleNewSite}
                size="large"
                primary
                label="Start Blank"
                id="editor-start-blank"
                icon={<Icons.Add size="medium" />}
              />
            </CardFooter>
          </Card>
          <Card background="dark-2">
            <CardHeader pad="small" background="dark-3">
              <Heading margin="0" level={3} style={{ whiteSpace: "nowrap" }}>
                Import From Steam
              </Heading>
              <Steam color="steam" />
            </CardHeader>
            <CardBody pad="small">
              <Text>
                We will import <strong>basic information</strong> (name,
                descriptions), some <strong>images</strong> and we will generate
                a <strong>theme</strong>.
              </Text>
            </CardBody>
            <CardFooter pad="small" background="dark-3">
              <Button
                onClick={() => setIsImportingFromSteam(true)}
                color="steam"
                style={{ color: "white", flexGrow: 1 }}
                size="large"
                label="Import from Steam"
              />
            </CardFooter>
          </Card>
        </Grid>
      </Card>
      <Card background="white" margin="medium">
        <CardHeader
          pad={{ horizontal: "medium" }}
          background="white"
          border={{ side: "bottom", color: "dark-1", size: "1px" }}
        >
          <Heading level={2} margin={{ vertical: "small" }}>
            My Games
          </Heading>
        </CardHeader>
        {!isLoading && sites?.length === 0 && (
          <CardBody pad="small">
            No games yet. Please createa new one above.
          </CardBody>
        )}
        {sites &&
          sites.map((site) => (
            <Box
              round="small"
              margin="small"
              key={String(site.id)}
              direction="row"
              pad="small"
              align="center"
              background="dark-1"
              border={{ color: "dark-3", size: "1px" }}
              onClick={handleEdit(site)}
              hoverIndicator
            >
              {site.config.general.favicon && (
                <Image
                  margin={{ right: "small" }}
                  width="48px"
                  height="48px"
                  src={cdnImage(site.config.general.favicon, "-167.png")}
                  alt="Icon"
                />
              )}
              <Box
                fill
                style={{ borderLeft: "4px solid " + site.config.theme.primary }}
                pad="small"
              >
                <Heading level={3} margin="0">
                  {site.config.general.gameName}
                </Heading>
                {site.domains.map(({ id, hostname }, index) => (
                  <Text size="medium" key={id || index}>
                    {hostname}
                  </Text>
                ))}
              </Box>
              <Box fill />
              <Box
                style={{ flexShrink: 0 }}
                direction="column"
                align="center"
                margin="small"
              >
                <Text size="small" weight="bold">
                  Updated At
                </Text>
                <Text size="small">
                  {new Date(site.updatedAt).toDateString()}
                </Text>
              </Box>
              <Box
                style={{ flexShrink: 0 }}
                direction="column"
                align="center"
                margin="small"
              >
                <Text size="small" weight="bold">
                  Created At
                </Text>
                <Text size="small">
                  {new Date(site.createdAt).toDateString()}
                </Text>
              </Box>
              <Icons.CaretNext />
            </Box>
          ))}
        <LoadingIndicator size="large" loading={isLoading} />
      </Card>
      {isImportingFromSteam && (
        <SteamImporterModal onClose={() => setIsImportingFromSteam(false)} />
      )}
    </PageContent>
  );
}

export default connect<object, object, object, AppState>((state) => ({
  sites: state.sitesEditor.sites,
}))(GamesListPage);
