import { defaultSiteConfig } from '@core/common/siteConfigs';
import { SiteConfigReducerType, ESiteConfigAction } from './types';

const initialState: SiteConfigReducerType = {
  config: defaultSiteConfig,
  domain: null,
  siteId: '',
};

type Actions =
  | ActionReducerType<
      { siteId: string; config: SiteConfigReducerType['config'] },
      ESiteConfigAction.GET_ALL
    >
  | ActionReducerType<string, ESiteConfigAction.SET_SITE_DOMAIN>
  | ActionReducerType<
      { keyName: string; value: string },
      ESiteConfigAction.UPDATE_THEME_VALUE
    >
  | ActionReducerType<
      { keyName: string; value: string },
      ESiteConfigAction.UPDATE_GENERAL_VALUE
    >;

export const reducer = (
  state = initialState,
  action: Actions
): SiteConfigReducerType => {
  switch (action.type) {
    case ESiteConfigAction.GET_ALL:
      return {
        ...state,
        config: action.payload.config,
        siteId: action.payload.siteId,
      };
    case ESiteConfigAction.UPDATE_THEME_VALUE:
      return {
        ...state,
        config: {
          ...state.config,
          theme: {
            ...state.config.theme,
            [action.payload.keyName]: action.payload.value,
          },
        },
      };
    case ESiteConfigAction.UPDATE_GENERAL_VALUE:
      return {
        ...state,
        config: {
          ...state.config,
          general: {
            ...state.config.general,
            [action.payload.keyName]: action.payload.value,
          },
        },
      };
    case ESiteConfigAction.SET_SITE_DOMAIN:
      return {
        ...state,
        domain: action.payload,
      };
    default:
      return state;
  }
};
