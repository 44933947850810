import { useAppSelector } from '@core/hooks/requestUtils';
import { Box, Text } from 'grommet';
import * as React from 'react';

export default function CategoryTag({ categoryId }: { categoryId: string }) {
  const category = useAppSelector(
    (state) => state.entities.voteCategory[categoryId]
  );

  if (!category) return null;

  return (
    <Box
      flex={{ shrink: 0 }}
      background={category.color || 'dark-4'}
      round="small"
      pad={{ vertical: 'xsmall', horizontal: 'small' }}
      style={{ whiteSpace: 'nowrap' }}
      elevation="xsmall"
    >
      <Text size="small">{category.title}</Text>
    </Box>
  );
}
