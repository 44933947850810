import { Button, ButtonExtendedProps } from 'grommet';
import * as React from 'react';
import styled from 'styled-components';
import useColor from '@core/hooks/useColor';
import { useHistory } from 'react-router';

const SidebarButtonStyled = styled(Button)<{
  border: string;
  background: string;
}>`
  padding: 12px 24px;
  border-bottom: 1px solid ${(props) => props.border};
  background: ${(props) => props.background};
  &:hover {
    background: white;
  }
`;

interface Props extends ButtonExtendedProps {
  to?: string;
}
export function SidebarButton(props: Props) {
  const bigBackground = useColor('dark-1');
  const smallBackground = useColor('dark-2');
  const brand = useColor('dark-4');
  const border = useColor('dark-3');
  const history = useHistory();
  let background = props.size === 'small' ? smallBackground : bigBackground;
  const activeColor = `linear-gradient(to right, ${brand} 0%, transparent 100%), ${background}`; // useColor('dark-3');
  background =
    typeof window !== 'undefined' && window.location.pathname === props.to
      ? activeColor
      : background;
  return (
    <SidebarButtonStyled
      plain
      justify="start"
      border={border}
      background={background}
      style={{
        fontSize: props.size === 'small' ? '14px' : '16px',
      }}
      hoverIndicator={{
        color: 'brand-1',
      }}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e as React.MouseEvent<HTMLButtonElement>);
        }
        if (props.to && window.location.pathname !== props.to) {
          history.push(props.to);
        }
      }}
      margin="0"
      icon={props.icon}
      label={props.label}
    />
  );
}
