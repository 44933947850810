import { entitiesUpdateAction } from "@core/redux/entities/actions";
import { ContactView } from "@core/types";
import logger from "@server/lib/utils/logger";
import React, { createContext } from "react";
import { useDispatch } from "react-redux";

export const ContactContext = createContext<
  [
    ContactView | null,
    (contact: ContactView | null, token?: string | null) => void
  ]
>([null, () => logger.warn("No contact context provider!")]);

interface Props {
  children: React.ReactChild;
}

let contactToken: string | null = null;
export function getContactToken() {
  return contactToken;
}

export default function ContactContextProvider({ children }: Props) {
  const dispatch = useDispatch();
  const [userContact, setUserContact] = React.useState<ContactView | null>(
    null
  );

  const onContactSet = (contact: ContactView | null, token?: string | null) => {
    if (typeof token !== "undefined") {
      contactToken = token;
      if (token !== null) {
        localStorage.setItem("contact_token", token);
      } else {
        localStorage.removeItem("contact_token");
      }
    }

    if (contact === null) {
      localStorage.removeItem("signedup_contact");
    } else {
      localStorage.setItem("signedup_contact", JSON.stringify(contact || null));
    }
    setUserContact(contact || null);
  };

  React.useEffect(() => {
    try {
      contactToken = localStorage.getItem("contact_token") || null;
      const contact =
        (JSON.parse(
          localStorage.getItem("signedup_contact") || "null"
        ) as ContactView) || null;
      setUserContact(contact);
      if (contact) {
        dispatch(
          entitiesUpdateAction({
            entities: {
              contact: {
                [contact?.id]: contact,
              },
            },
            data: {},
            result: {},
          })
        );
      }
    } catch (err) {
      logger.error("Session restore: ", err);
    }

    function handleStorageChanged(event: StorageEvent) {
      if (event.storageArea !== localStorage) return;
      if (event.key === "signedup_contact") {
        contactToken = localStorage.getItem("contact_token") || "null";
        setUserContact(
          (JSON.parse(event.newValue || "null") as ContactView) || null
        );
      }
    }

    // In case any other tab changes the contact
    window.addEventListener("storage", handleStorageChanged);
    return () => {
      window.removeEventListener("storage", handleStorageChanged);
    };
  }, []);

  return (
    <ContactContext.Provider value={[userContact, onContactSet]}>
      {children}
    </ContactContext.Provider>
  );
}
