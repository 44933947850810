import OwnNav from "@core/components/organisms/OwnNav";
import { useSiteFromParams } from "./EditSitePage";
import { WithGameSidebar } from "../../../../molecules/WithGameSidebar";
import { Redirect } from "react-router";
import {
  Text,
  Heading,
  Box,
  Button,
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  Tip,
} from "grommet";
import * as React from "react";
import * as Icons from "grommet-icons";
import { SiteView } from "@core/redux/sitesEditor";
import { useApiContactsGet } from "@core/hooks/requests";
import { ContactView } from "@core/types";
import useColor from "@core/hooks/useColor";
import QueryStatusIndicator from "@core/components/atoms/QueryStatusIndicator";
import Tag from "../../../../atoms/Tag";
import ViewContactModal from "./ViewContactModal";

export interface SubsectionProps {
  site: SiteView;
  onUpdateSite(site: SiteView): void;
}

export default function ViewContacts() {
  const [site, _setSite, _hasChanged] = useSiteFromParams();
  const [editContactId, setEditContactId] = React.useState<null | string>(null);
  // const dispatch = useDispatch();
  if (!site || !site.config) return <Redirect to="/edit/sites" />;

  // const _handleSave = () => {
  //   dispatch(SitesEditorAction.updateConfig(site));
  // };

  return (
    <>
      <OwnNav />
      <WithGameSidebar>
        <Heading level={2}>Contacts - All</Heading>
        <Box background="dark-1">
          <SiteContactsList
            onViewContactPress={setEditContactId}
            siteId={String(site.id)}
          />
        </Box>
      </WithGameSidebar>
      <ViewContactModal
        contactId={editContactId}
        onClose={() => setEditContactId(null)}
        isOpen={!!editContactId}
      />
    </>
  );
}

function SiteContactsModalRow({
  onViewPress,
  ...contact
}: ContactView & { onViewPress(): void }) {
  return (
    <TableRow>
      <TableCell
        style={{
          maxWidth: 60,
          overflow: "hidden",
          textOverflow: "ellipsisx",
          whiteSpace: "nowrap",
          fontSize: "0.8em",
        }}
      >
        <Tip
          plain
          content={
            <Box pad="xsmall" background="dark-1">
              {contact.id}
            </Box>
          }
        >
          <Box>{contact.id.slice(0, 5)}...</Box>
        </Tip>
      </TableCell>
      <TableCell>
        {contact.verified && <Icons.Checkmark size="small" />}
      </TableCell>
      <TableCell>
        <Text size="small">{contact.email}</Text>
      </TableCell>
      <TableCell gap="small">
        <Text size="small">
          {contact.about && (
            <Tag color="neutral-3">
              <Icons.Checkmark
                style={{ margin: "0.5px 3px 0 -2px" }}
                size="xsmall"
              />
              <Text size="xsmall">About</Text>
            </Tag>
          )}
          {contact.entries > 1 && contact.entries < 5 && (
            <Tag color="neutral-1">Engaged</Tag>
          )}
          {contact.entries >= 5 && <Tag color="neutral-1">Engaged 🔥</Tag>}
          {contact.publicTags?.map((tag, index) => (
            <Tag key={index} color={tag.color}>
              {tag.text}
            </Tag>
          ))}
        </Text>
      </TableCell>
      <TableCell>
        <Box align="center" direction="row" gap="small">
          <Icons.Validate size="small" />
          <Text size="small">{contact.entries}</Text>
        </Box>
      </TableCell>
      <TableCell>
        <Text size="small">
          {new Date(contact.createdAt || "").toLocaleDateString()}
        </Text>
      </TableCell>
      <TableCell>
        <Button
          onClick={onViewPress}
          icon={<Icons.View size="small" />}
          label="View"
          size="small"
        />
      </TableCell>
    </TableRow>
  );
}

function SiteContactsList({
  siteId,
  onViewContactPress,
}: {
  siteId: string;
  onViewContactPress(contactId: string): void;
}) {
  const contacts = useApiContactsGet({ siteId });

  const headerBg = useColor("dark-2");
  const headerBorder = useColor("dark-4");

  return (
    <Box>
      <Box>
        <Table>
          <TableHeader
            style={{
              position: "sticky",
              top: 0,
              background: headerBg,
              borderBottom: `1px solid ${headerBorder}`,
            }}
          >
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>
                <Tip
                  plain
                  content={
                    <Box
                      pad="xsmall"
                      background="dark-2"
                      round="xsmall"
                      border={{ size: "xsmall", color: "dark-4" }}
                    >
                      Is email verified?
                    </Box>
                  }
                >
                  <Icons.Mail size="small" />
                </Tip>
              </TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            {(contacts.isLoading || contacts.isError) && (
              <TableRow>
                <TableCell colSpan={7}>
                  <QueryStatusIndicator {...contacts} />
                </TableCell>
              </TableRow>
            )}
            {contacts.data
              // .reduce((acc, contact) => {
              //  for (let index = 0; index < 99; index++) {
              //    acc.push({
              //      ...contact,
              //      id: contact.id + index,
              //    });
              //  }
              //  return acc;
              // }, [] as ContactView[])
              .map((contact) => (
                <SiteContactsModalRow
                  onViewPress={() => onViewContactPress(contact.id)}
                  key={contact.id}
                  {...contact}
                />
              ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
