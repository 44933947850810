import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(12px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const loading = keyframes`
0% {
  transform: rotate(0deg) scale(100%);
}
25% {
  transform: rotate(90deg) scale(100%);
}
50% {
  transform: rotate(180deg) scale(150%);
}
75% {
  transform: rotate(270deg) scale(100%);
}
100% {
  transform: rotate(360deg) scale(100%);
}
`;
