import { SitesEditorReducerType, ESitesEditorAction, SiteView } from './types';

interface State {
  sites: SiteView[];
}

export const reducer = (
  state: State = { sites: [] },
  action:
    | ActionReducerType<SitesEditorReducerType, ESitesEditorAction.GET_ALL>
    | ActionReducerType<SiteView, ESitesEditorAction.UPDATE_SITE_DATA>
    | ActionReducerType<number, ESitesEditorAction.DELETE_SITE>
    | ActionReducerType<
        { hostname: string; id: number },
        ESitesEditorAction.ADD_DOMAIN
      >
) => {
  switch (action.type) {
    case ESitesEditorAction.ADD_DOMAIN:
      return {
        ...state,
        sites: state.sites.map((site) => {
          // @ts-expect-error action payloads issues...
          if (site.id === action.payload.siteId) {
            return {
              ...site,
              domains: [...site.domains, action.payload],
            };
          }
          return site;
        }),
      };
    case ESitesEditorAction.GET_ALL:
      return { ...state, ...action.payload };
    case ESitesEditorAction.UPDATE_SITE_DATA:
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.id) {
            return action.payload;
          }
          return site;
        }),
      };
    case ESitesEditorAction.DELETE_SITE:
      return {
        ...state,
        sites: state.sites.filter((site) => site.id === action.payload),
      };
    default:
      return state;
  }
};
