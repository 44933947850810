import { Box, Button, ButtonExtendedProps } from "grommet";
import { Icon } from "grommet-icons";
import * as React from "react";

type ToggleProps = {
  label: string | React.ReactNode;
  SelectedIcon: Icon;
  UnselectedIcon: Icon;
  selected: boolean;
  color?: string;
  onSelect(): void;
  round?: string;
} & ButtonExtendedProps;

export default function ToggleButton({
  label,
  SelectedIcon,
  UnselectedIcon,
  selected,
  color,
  onSelect,
  round,
  ...rest
}: ToggleProps) {
  return (
    <Button onClick={onSelect} color={color} {...rest}>
      <Box
        pad={{ vertical: "small", horizontal: "medium" }}
        background={color || "brand-4"}
        round={round}
        direction="row"
        align="center"
        gap="small"
      >
        {selected ? (
          <SelectedIcon size="small" />
        ) : (
          <UnselectedIcon size="small" />
        )}
        {label}
      </Box>
    </Button>
  );
}
