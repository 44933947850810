import { Box, Button, ResponsiveContext, Text } from 'grommet';
import * as React from 'react';
import * as Icons from 'grommet-icons';

export default function PageNavigation({
  items,
}: {
  items: { label: string; onClick?: () => void }[];
}) {
  const maxItems = React.useContext(ResponsiveContext) === 'small' ? 2 : 999;
  const itemsFinal = items.slice(0, maxItems);
  return (
    <Box
      className="PageNavigation"
      gridArea="header"
      pad={{ horizontal: 'none', bottom: 'small' }}
      direction="row"
      align="center"
    >
      {itemsFinal.map((item, i) => (
        <React.Fragment key={i}>
          {item.onClick ? (
            <Button size="small" label={item.label} onClick={item.onClick} />
          ) : (
            <Text
              margin={{ horizontal: 'xsmall' }}
              size="small"
              weight="bold"
              color="light-1"
            >
              {item.label}
            </Text>
          )}
          {i !== itemsFinal.length - 1 && (
            <Icons.CaretRightFill color="dark-4" />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}
