import * as Icons from "grommet-icons";
import { Box, Collapsible } from "grommet";
import * as React from "react";
import { SidebarButton } from "../atoms/SidebarButton";

interface DropDownSidebarButtonProps {
  label: string;
  icon?: React.ReactElement;
  to?: string;
  items?: {
    label: string;
    icon?: React.ReactElement;
    to?: string;
    onClick?(): void;
  }[];
}
export function DropDownSidebarButton({
  label,
  items,
  to,
  icon,
}: DropDownSidebarButtonProps) {
  const [isOpen, setIsOpen] = React.useState(true);
  const iconFull = (
    <Box direction="row" align="center" gap="small" justify="center">
      {items ? (
        isOpen ? (
          <Icons.Up size="small" />
        ) : (
          <Icons.Down size="small" />
        )
      ) : (
        <Icons.Blank size="small" />
      )}
      {icon ? icon : <Icons.Blank size="small" />}
    </Box>
  );

  return (
    <>
      <SidebarButton
        icon={iconFull}
        onClick={() => setIsOpen(!isOpen)}
        to={to}
        label={label}
      />

      {items && (
        <Collapsible open={isOpen}>
          <Box direction="column">
            {items.map((item) => (
              <SidebarButton
                key={item.label}
                size="small"
                label={item.label}
                onClick={() => item.onClick?.()}
                icon={item.icon ? item.icon : <Icons.Blank size="small" />}
                to={item.to}
              />
            ))}
          </Box>
        </Collapsible>
      )}
    </>
  );
}
