import { Box, Heading } from 'grommet';
import * as Icons from 'grommet-icons';
import * as React from 'react';
import HelpComponent from './HelpComponent';
import TutorialStep from '../blind/TutorialStep';
import { VoteActions } from '@core/components/organisms/LandingVoting';
import { Blocker } from '../atoms/Blocker';

interface Props {
  title: string;
  explanation?: React.ReactNode;
  votePostId: string;
}
export default function PageVote({ title, explanation, votePostId }: Props) {
  return (
    <Blocker>
      <TutorialStep
        position="top"
        name="vote"
        helpComponent={
          <HelpComponent
            title="Every vote matters"
            content="Let us know if you would find this useful."
            buttonText="Okay"
          />
        }
      >
        <Box
          style={{
            height: '256px',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            pointerEvents: 'auto',
            zIndex: 2,
          }}
          pad="medium"
          background="brand-2"
        >
          <Box
            margin="0 auto"
            width="xlarge"
            direction="row"
            align="center"
            justify="between"
          >
            <Box pad={{ horizontal: 'small' }}>
              <Heading level={3} margin="0">
                {title}
              </Heading>
              {explanation}
              <Box direction="row" align="center">
                <Icons.InProgress color="light-1" size="medium" />
                <Heading
                  color="light-1"
                  level={3}
                  margin={{ vertical: 'small', horizontal: 'small' }}
                >
                  Work In Progress
                </Heading>
              </Box>
            </Box>
            <Box gap="small" align="center" width="medium">
              <Heading level={3} margin="0">
                Vote Now
              </Heading>
              <VoteActions direction="vertical" votePostId={votePostId} />
            </Box>
          </Box>
        </Box>
      </TutorialStep>
    </Blocker>
  );
}
