import { AppState } from '../reducers';

export const indexSelector = (
  state: AppState,
  entityType: string,
  indexName: string
) => {
  const indexes = state.entities.indexes;
  return (indexes[indexName] && indexes[indexName][entityType]) || [];
};

export const itemsSelector = (state: AppState, entityType: string) => {
  return state.entities[entityType];
};

export const indexItemsCombiner = (
  index: number[] | string[],
  items: Record<string, object>
) => {
  return index.map((i) => items[i]).filter(Boolean);
};
