import authorisedFetch from "@core/common/authorisedFetch";
import { Box, FileInput, Spinner, Text } from "grommet";
import * as React from "react";

export interface VideoResponse {
  mp4: string;
  webm: string;
  url: string;
}
export interface ImageResponse {
  url: string;
}
export interface FaviconResponse {
  id: string;
}

interface Props {
  type: "logo" | "landingBackground" | "feature" | "favicon";
  onValueChange?(response: VideoResponse | ImageResponse): void;
  onFaviconChanged?(faviconResponse: FaviconResponse): void;
}

interface State {
  isLoading: boolean;
  haveError: boolean;
  messageIndex: number;
}
const messages = ["Uploading", "Processing", "Optimizing"];

export default class AssetUpload extends React.Component<Props, State> {
  state: State = {
    isLoading: false,
    messageIndex: 0,
    haveError: false,
  };
  updateIntervalId = -1;

  handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { isLoading } = this.state;
    const { onValueChange, onFaviconChanged, type } = this.props;
    if (isLoading) return;
    if (!e || !e.target || !e.target.files || !e.target.files[0]) {
      if (type === "favicon") {
        if (onFaviconChanged) {
          onFaviconChanged({ id: "" });
        }
      } else {
        if (onValueChange) {
          onValueChange({ mp4: "", webm: "", url: "" });
        }
      }
      return;
    }
    this.changeIsLoading();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("type", type);
    formData.append("file", file, e.target.files[0].name);
    try {
      const responseData = await authorisedFetch<
        ImageResponse | VideoResponse | FaviconResponse
      >("assets", {
        method: "POST",
        body: formData,
        contentType: "auto",
      });
      if (type === "favicon") {
        if (onFaviconChanged) {
          onFaviconChanged(responseData as FaviconResponse);
        }
      } else {
        if (onValueChange) {
          onValueChange(responseData as VideoResponse | ImageResponse);
        }
      }
    } catch (err) {
      console.error("Problem uploading an asset.", err);
      this.setState({ haveError: true });
    }
    this.changeIsLoading();
  };

  changeIsLoading = () => {
    const { isLoading } = this.state;
    if (isLoading) {
      this.setState({ messageIndex: 0 });
      clearInterval(this.updateIntervalId);
    } else {
      this.setState({ haveError: false });
      this.updateIntervalId = setInterval(() => {
        this.setState({ messageIndex: this.state.messageIndex + 1 });
      }, 300) as unknown as number;
    }
    this.setState({ isLoading: !this.state.isLoading });
  };

  render() {
    const { haveError, isLoading, messageIndex } = this.state;
    return (
      <>
        {!isLoading && (
          <FileInput onInput={this.handleChange} multiple={false} />
        )}
        {haveError && (
          <Box pad="small">
            <Text color="status-error" size="small">
              Problem uploading asset. Try again.
            </Text>
          </Box>
        )}
        {isLoading && (
          <Box direction="row" gap="medium" align="center" pad="medium">
            <Spinner />
            <Box>
              <Text>{getMessage(messageIndex)}</Text>
              <Text size="small">Please wait for completion.</Text>
            </Box>
          </Box>
        )}
      </>
    );
  }
}

const getDots = (i: number) => {
  switch (i % 4) {
    case 1:
      return ".";
    case 2:
      return "..";
    case 3:
      return "...";
    default:
      return "";
  }
};

const loopDotsBeforeMessage = 12;
function getMessage(messageIndex: number) {
  const i =
    Math.round(messageIndex / (loopDotsBeforeMessage * messages.length)) %
    messages.length;
  return messages[i] + getDots(messageIndex);
}
