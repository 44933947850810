import { SiteView } from '@core/redux/sitesEditor';
import { TagView, VoteCategoryView } from '.';
import { Request } from './apiInterface';

export enum SortOrder {
  Descending = 'desc',
  Ascending = 'asc',
}

export enum PostsSortFields {
  Date = 'createdAt',
  Rating = 'rating',
}

export type ReadCategoryPosts = Request<
  'sites/:siteId/vote-posts',
  'GET',
  // Body
  undefined,
  // Params
  { siteId: string },
  // Query
  {
    categoryId?: string;
    sortBy?: PostsSortFields;
    sortOrder?: SortOrder;
  },
  object
>;

export type ReadCategoryModerationQueuePosts = Request<
  'sites/:siteId/vote-posts/moderation-queue',
  'GET',
  // Body
  undefined,
  // Params
  { siteId: string },
  // Query
  undefined,
  object
>;

export type VotePostComment = Request<
  'sites/:siteId/vote-posts/:votePostId/comments',
  'POST',
  { text: string },
  { siteId: string; votePostId: string }
>;

export type VotePostGetModerationQueueComments = Request<
  'sites/:siteId/comments/moderation-queue',
  'GET',
  undefined,
  { siteId: string }
>;

export type VotePostGetComments = Request<
  'sites/:siteId/vote-posts/:votePostId/comments',
  'GET',
  undefined,
  { siteId: string; votePostId: string }
>;

export type CreateVotePost = Request<
  'sites/:siteId/vote-categories/:categoryId/vote-posts',
  'POST',
  { title: string; markdown: string },
  { siteId: string; categoryId: string }
>;

export type VoteOnVotePost = Request<
  'sites/:siteId/vote-posts/:votePostId/vote',
  'POST',
  {
    increment: number;
  },
  {
    siteId: string;
    votePostId: string;
  }
>;

export type VotePostLoad = Request<
  'sites/:siteId/vote-posts/:votePostId',
  'GET',
  undefined,
  {
    votePostId: string;
    siteId: string;
  }
>;

export type ReadCategories = Request<
  'sites/:siteId/vote-categories',
  'GET',
  undefined,
  {
    siteId: string;
  }
>;

export type CreateVoteCategory = Request<
  'sites/:siteId/vote-categories',
  'POST',
  Partial<VoteCategoryView>,
  { siteId: string }
>;

export type UpdateVoteCategory = Request<
  'sites/:siteId/vote-categories/:categoryId',
  'PUT',
  Partial<VoteCategoryView>,
  { siteId: string; categoryId: string }
>;

export type SiteDiscordIntegrationPost = Request<
  'sites/:siteId/integrations/discord',
  'POST',
  {
    code: string;
  },
  {
    siteId: string;
  }
>;

export type SiteContactCreate = Request<
  'sites/:siteId/contacts',
  'POST',
  { email: string; useInviteCode?: string },
  {
    siteId: string;
  },
  undefined,
  | {
      contactId: string;
      token: string;
      status: 'success';
    }
  | { status: 'pending_verification' }
>;

export type SiteContactExchangeToken = Request<
  'sites/:siteId/contacts/exhange-token',
  'POST',
  { token: string },
  {
    siteId: string;
  },
  undefined,
  {
    contactId: string;
    token: string;
  }
>;

export type SiteContactUpdate = Request<
  'sites/:siteId/contacts/:id',
  'PUT',
  { about: string; publicTags?: TagView[]; privateTags?: TagView[] },
  { siteId: string; id: string }
>;

export type SiteContactCompleteAction = Request<
  'sites/:siteId/contacts/:id/complete-action',
  'POST',
  { actionType: string },
  { siteId: string; id: string }
>;

export type SiteContactsGet = Request<
  'sites/:siteId/contacts',
  'GET',
  undefined,
  {
    siteId: string;
  },
  {
    schedule?: string;
  }
>;

export type SiteCreate = Request<
  'sites',
  'POST',
  undefined,
  undefined,
  undefined,
  {
    siteConfigEntity: SiteView;
  }
>;

export type PreviewEmailGet = Request<
  'preview-email/:template',
  'GET',
  undefined,
  { template: string }
>;

/**
 * This fetches all the sites that user manages.
 *
 * Think about sites as games.
 *
 * @returns JSON - List of SiteView's
 * @category Requests
 */
export type GetSitesRequest = Request<
  'sites',
  'GET',
  undefined,
  undefined,
  undefined,
  {
    sites: SiteView[];
  }
>;

/**
 * This is intended for admins when they want to view contact details
 *
 * @returns JSON - Contact
 * @category Requests
 */
export type GetSitesIdContactsIdRequest = Request<
  'sites/:siteId/contacts/:contactId',
  'GET',
  undefined,
  { siteId: string; contactId: string }
>;
