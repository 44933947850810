import { defaultSiteConfig } from "@core/common/siteConfigs";
import PageContent from "@core/components/atoms/PageContent";
import { PreviewComponentScaled } from "@core/components/blind/PreviewComponent";
import { SiteConfig } from "@core/redux/siteConfig";
import { SiteView } from "@core/redux/sitesEditor";
import { Box, Text, Heading } from "grommet";
import * as React from "react";
import ClientLandingPage from "../../client/ClientLandingPage";

const demoFeatures = [
  {
    title: "Immersive World",
    description: `Immerse youself in a world of Rogue Survival. The world like no other. Split between 42 factions fighting for survival.\n\n Will you be the one to get out? Will you be the lord of a leading faction?`,
    buttonText: "Download Beta Now",
    buttonUrl: "https://ziplock.com",
  },
  {
    title: "Enhanced Graphics",
    imageUrl: "http://place-hold.it/600x300",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc pharetra urna sit amet nibh commodo, non ultrices elit finibus. Donec tristique purus orci, sed efficitur lorem rhoncus eu.",
  },
  {
    title: "Enhanced Graphics",
    imageUrl: "http://place-hold.it/600x300",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc pharetra urna sit amet nibh commodo, non ultrices elit finibus. Donec tristique purus orci, sed efficitur lorem rhoncus eu.",
  },
];

export const siteConfigExample: SiteView = {
  createdAt: "123",
  domains: [],
  id: -1,
  updatedAt: "123",
  config: {
    ...defaultSiteConfig,
    general: {
      gameName: "Rogue Survival",
      subTitle: "Coming soon to Steam",
      title: "Intense Action Survival Experience",
      collectNewsletterEmails: true,
      youtubeChannelURL: "https://youtube.com/",
      twitterURL: "https://youtube.com/",
      twitchURL: "https://youtube.com/",
      discordURL: "https://youtube.com/",
      // landingBackgroundImage:
      //   'https://ams3.digitaloceanspaces.com/api-managed/6e207fc4-ab1d-4a73-be1a-83f3927d458d.jpeg',
      ...defaultSiteConfig.general,
    },
    features: [],
    theme: {
      ...defaultSiteConfig.theme,
      // darkBase: '#2D2B1E',
      // lightBase: '#ece8df',
      // primary: '#827c4a', // '#58574E',
      // secondary: '#9A9287',
      // mode: 'dark',
      // headingFontFamilyName: 'Berkshire Swash',
      // headingFontFamilyUrl:
      //   'https://fonts.googleapis.com/css?family=Berkshire%20Swash:400',
      // headingFontWeight: 400,
      mode: "dark",
      lightBase: "#E6D5B8",
      darkBase: "#1B1A17",
      primary: "#E45826",
      secondary: "#F0A500",
      headingFontFamilyName: "Berkshire Swash",
      headingFontFamilyUrl:
        "https://fonts.googleapis.com/css?family=Berkshire%20Swash:400",
      headingFontWeight: 400,
      buttonRadius: 8,
    },
  },
};

export default function StartSmall() {
  const [previewSiteConfig, setPreviewSiteConfig] =
    React.useState(siteConfigExample);

  return (
    <PageContent align="center" pad={{ bottom: "small", horizontal: "medium" }}>
      <Heading level={1} margin={{ bottom: "small" }}>
        Start with a simple website
      </Heading>
      <Box width="large">
        <Text size="large" textAlign="center">
          Fill in a s much as you like or as much as you have. Website will
          automatically adjust itself to be the most usable on the mobile phone
          and desktop.
        </Text>
      </Box>
      <Heading level={2}>and make it your own</Heading>
      <ThemeSelector
        previewSiteConfig={previewSiteConfig}
        setPreviewSiteConfig={setPreviewSiteConfig}
      />
      <Text>+ 1000s of other combinations</Text>
      <PreviewComponentScaled
        key={JSON.stringify(previewSiteConfig)}
        margin={{ vertical: "medium" }}
        height={previewSiteConfig.config.features?.length ? "xlarge" : "large"}
        site={previewSiteConfig}
        style={{ minWidth: "100%" }}
      >
        <ClientLandingPage />
      </PreviewComponentScaled>
    </PageContent>
  );
}

export function ThemeSelector({
  previewSiteConfig,
  setPreviewSiteConfig,
}: {
  previewSiteConfig: SiteView;
  setPreviewSiteConfig(site: SiteView): void;
}) {
  const handleHover =
    (
      data: Partial<SiteConfig["theme"]>,
      generalData?: Partial<SiteConfig["general"]>,
      features?: Partial<SiteConfig["features"]>
    ) =>
    () => {
      setPreviewSiteConfig({
        ...previewSiteConfig,
        config: {
          ...previewSiteConfig.config,
          general: {
            ...previewSiteConfig.config.general,
            ...generalData,
          },
          theme: {
            ...previewSiteConfig.config.theme,
            ...data,
          },
          // @ts-expect-error previewSiteConfig always has things defined
          features: features || previewSiteConfig.config.features,
        },
      });
    };
  return (
    <Box direction="row" gap="small" margin="xsmall">
      <Selector
        color="#20c20e"
        mode="dark"
        onHover={handleHover(
          {
            mode: "dark",
            lightBase: "#ffffff",
            darkBase: "#000000",
            primary: "#20c20e",
            secondary: "#F0A500",
            headingFontFamilyName: "VT323",
            headingFontFamilyUrl:
              "https://fonts.googleapis.com/css?family=VT323:400",
            headingFontWeight: 400,
            buttonRadius: 0,
          },
          {
            landingBackgroundImage: "",
            logoImage: "",
            // 'https://ams3.digitaloceanspaces.com/api-managed/edc386a9-762c-46ab-939c-e4902b7ac6dc.png',
            collectNewsletterEmails: false,
            youTubeTrailerURL: "https://www.youtube.com/watch?v=8jVuvMl3N2E",
            steamAppId: "",
          },
          []
        )}
      />
      <Selector
        color="#E45826"
        mode="dark"
        onHover={handleHover(
          {
            mode: "dark",
            lightBase: "#E6D5B8",
            darkBase: "#1B1A17",
            primary: "#E45826",
            secondary: "#F0A500",
            headingFontFamilyName: "Berkshire Swash",
            headingFontFamilyUrl:
              "https://fonts.googleapis.com/css?family=Berkshire%20Swash:400",
            headingFontWeight: 400,
            buttonRadius: 8,
          },
          {
            landingBackgroundImage: "",
            logoImage:
              "https://ams3.digitaloceanspaces.com/api-managed/b12f4c64-bea4-434f-991e-09a6a4869939.png",
            // 'https://ams3.digitaloceanspaces.com/api-managed/edc386a9-762c-46ab-939c-e4902b7ac6dc.png',
            collectNewsletterEmails: false,
            youTubeTrailerURL: "https://www.youtube.com/watch?v=8jVuvMl3N2E",
            steamAppId: "",
          },
          demoFeatures
        )}
      />
      <Selector
        color="#05595B"
        mode="dark"
        onHover={handleHover(
          {
            mode: "dark",
            darkBase: "#062C30",
            lightBase: "#F5F5F5",
            secondary: "#05595B",
            primary: "#E2D784",
            headingFontFamilyName: "Righteous",
            headingFontFamilyUrl:
              "https://fonts.googleapis.com/css?family=Righteous:400",
            headingFontWeight: 400,
          },
          {
            landingBackgroundImage: "https://stressout.net/static/piano203.png",
            collectNewsletterEmails: false,
            youTubeTrailerURL: "",
            steamAppId: "420",
            logoImage: "",
          },
          demoFeatures
        )}
      />
      <Selector
        color="#C5D8A4"
        mode="light"
        onHover={handleHover(
          {
            mode: "light",
            lightBase: "#F4FCD9",
            darkBase: "#534340",
            primary: "#534340",
            secondary: "#BB9981",
            headingFontFamilyName: "ZCOOL QingKe HuangYou",
            headingFontFamilyUrl:
              "https://fonts.googleapis.com/css?family=ZCOOL%20QingKe%20HuangYou:400",
            headingFontWeight: 400,
            buttonRadius: 0,
          },
          {
            landingBackgroundImage: "",
            collectNewsletterEmails: true,
            youTubeTrailerURL: "",
            steamAppId: "420",
            logoImage: "",
          },
          []
        )}
      />
      <Selector
        color="#42C2FF"
        mode="light"
        onHover={handleHover(
          {
            mode: "light",
            lightBase: "#EFFFFD",
            darkBase: "#533E85", // 42C2FF',
            primary: "#0E185F", // 85F4FF',
            secondary: "#B8FFF9",
            headingFontFamilyName: "Wendy One",
            headingFontFamilyUrl:
              "https://fonts.googleapis.com/css?family=Wendy%20One:400",
            headingFontWeight: 400,
            buttonRadius: 4,
          },
          {
            landingBackgroundImage: "",
            collectNewsletterEmails: true,
            youTubeTrailerURL: "https://www.youtube.com/watch?v=8jVuvMl3N2E",
            steamAppId: "",
            logoImage: "",
          },
          []
        )}
      />
    </Box>
  );
}

function Selector({
  color,
  mode,
  onHover,
}: {
  color: string;
  mode: "dark" | "light";
  onHover(): void;
}) {
  const bg = mode === "light" ? "white" : "black";
  const background = `linear-gradient(130deg, ${color} 40%, ${bg} 100%)`;
  return (
    <Box
      animation="pulse"
      onMouseEnter={onHover}
      border={{ size: "small", color: "silver", side: "all" }}
      style={{ width: 32, height: 32, borderRadius: 16, background }}
    />
  );
}
